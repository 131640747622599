import React from 'react';
import {
  Grid,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Tooltip
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useHistory } from 'react-router';

import Can from '../Can/Can';
import RoleTypeBadge from '../RoleTypeBadge/RoleTypeBadge';
import UserAvatar from '../UserAvatar/UserAvatar';
import { Id, ResourceNames, Resource } from '../../types/types';
import { User } from '../../store/Users/UserModels';
import { Role } from '../../store/Roles/RoleModels';
import { Group } from '../../store/Groups/GroupModels';
import { Organization, OrganizationDomain } from '../../store/Organizations/OrganizationModels';
import { Application, Permission } from '../../store/Applications/ApplicationModels';
import { Client } from '../../store/Clients/ClientModels';
import { singularizeResource, toFullName } from '../../utility';
import useStyles from './styles';
import { FederatedGroupMapping } from '../../store/FederatedGroupMappings/FederatedGroupMappingModels';
import { PolicyRequest } from '../../store/My/MyModels';
import { useCasbin } from '../../hooks/useCasbin';

interface ActionItemProps {
  immutable?: boolean;
  permissions?: string[];
  requiredPolicy?: PolicyRequest;
  policyFunction?: Function;
  policyFunctionParams?: Id[];
}

interface RemoveItemProps extends ActionItemProps {
  handleDeleteClick: (id: Id) => void;
}

interface EditItemProps extends ActionItemProps {
  handleEditClick: (id: Id) => void;
}

interface ReadItemProps {
  // Required casbin policy
  requiredPolicy?: PolicyRequest;

  // Casbin policy function
  policyFunction?: (...args: any) => PolicyRequest;

  // Params for the function
  policyFunctionParams?: Id[];
}

interface FilterListItemProps {
  listResourceName: ResourceNames;
  resource: Resource;
  resourceId: Id;
  removeItemProps: RemoveItemProps;
  editItemProps?: EditItemProps;
  readItemProps?: ReadItemProps;
}

function FilterListItem({
  listResourceName,
  resource,
  resourceId,
  removeItemProps,
  editItemProps,
  readItemProps
}: FilterListItemProps) {
  const classes = useStyles();
  const history = useHistory();

  const setPolicy = (itemProps?: ActionItemProps | ReadItemProps): PolicyRequest | undefined => {
    const fn = itemProps?.policyFunction;
    const params = itemProps?.policyFunctionParams ?? [];
    const policy = itemProps?.requiredPolicy;

    if (policy) {
      return policy;
    }

    if (fn) {
      params.push(resourceId);
      return fn(...params);
    }
  };

  const directToItemDetailPage = (id: Id) => {
    if (isLink()) {
      history.push(`/${listResourceName}/${id}`);
    }
  };

  const isLinkingPermitted = useCasbin(setPolicy(readItemProps));

  const isLink = () => {
    return (listResourceName === 'applications' ||
      listResourceName === 'users' ||
      listResourceName === 'roles' ||
      listResourceName === 'groups') && isLinkingPermitted;
  };

  const renderListItemBody = () => {
    if (listResourceName === 'users') {
      return (
        <Grid container justifyContent="flex-start" alignItems="center">
          <ListItemAvatar><UserAvatar user={resource as User} smallBadge /></ListItemAvatar>
          <ListItemText
            primary={toFullName(resource as User)}
            secondary={(resource as User).email}
            primaryTypographyProps={{
              noWrap: true
            }}
            secondaryTypographyProps={{
              noWrap: true
            }}
          />
        </Grid>
      );
    } else if (listResourceName === 'roles') {
      /* Add Role-Type Badge for Application and System Roles */
      const nameDisplay = (resource as Role).roleType === 'organization' ? (resource as Role).name : (
        <Grid container alignItems="center">
          {(resource as Role).name} <RoleTypeBadge roleType={(resource as Role).roleType} smallBadge />
        </Grid>
      );

      return (
        <ListItemText
          primary={nameDisplay}
          primaryTypographyProps={{
            noWrap: true
          }}
        />
      );
    } else if (listResourceName === 'groups') {
      return (
        <ListItemText
          primary={(resource as Group).name}
          primaryTypographyProps={{
            noWrap: true
          }}
        />
      );
    } else if (listResourceName === 'organizations') {
      return (
        <ListItemText
          primary={(resource as Organization).name}
          secondary={(resource as Organization).code?.toUpperCase()}
          primaryTypographyProps={{
            noWrap: true
          }}
          secondaryTypographyProps={{
            noWrap: true
          }}
        />
      );
    } else if (listResourceName === 'applications') {
      return (
        <ListItemText
          primary={(resource as Application).name}
          secondary={(resource as Application).code?.toUpperCase()}
          primaryTypographyProps={{
            noWrap: true
          }}
          secondaryTypographyProps={{
            noWrap: true
          }}
        />
      );
    } else if (listResourceName === 'permissions') {
      return (
        <ListItemText
          primary={(resource as Permission)}
          primaryTypographyProps={{
            noWrap: true
          }}
        />
      );
    } else if (listResourceName === 'federated group mappings') {
      return (
        <ListItemText
          primary={(resource as FederatedGroupMapping).federatedGroupId}
          primaryTypographyProps={{
            noWrap: true
          }}
        />
      );
    } else if (listResourceName === 'clients') {
      return (
        <ListItemText
          primary={(resource as Client).name}
          secondary={(resource as Client).code?.toUpperCase()}
          primaryTypographyProps={{
            noWrap: true
          }}
          secondaryTypographyProps={{
            noWrap: true
          }}
        />
      );
    } else if (listResourceName === 'domains') {
      return (
        <ListItemText
          primary={(resource as OrganizationDomain).name}
          primaryTypographyProps={{
            noWrap: true
          }}
        />
      );
    }
  };

  return (
    <ListItem
      role="resource-list-item"
      className={classes.listItem}
      disableGutters
      divider
      sx={{ cursor: isLink() ? 'pointer' : 'default' }}
      onClick={() => directToItemDetailPage(resourceId)}
    >
      <Grid container alignItems="center">
        <Grid item xs={10}>
          {renderListItemBody()}
        </Grid>
        <Grid item xs={2}>
          <Grid container spacing={2} justifyContent="flex-end" alignItems="center" direction="row">
            {editItemProps && !editItemProps?.immutable && (
              <Grid item xs={6}>
                <Can requiredPermissions={editItemProps?.permissions ?? []}
                  requiredPolicy={setPolicy(editItemProps)}
                  partialPermissions
                >
                  <Tooltip title={`Edit ${singularizeResource(listResourceName)}`} placement="bottom-end">
                    <IconButton
                      aria-label="edit-resource-button"
                      className={classes.actionButton}
                      size="small"
                      onClick={(event: React.MouseEvent) => {
                        event.preventDefault();
                        event.stopPropagation();
                        editItemProps?.handleEditClick(resourceId);
                      }}
                      disabled={!resourceId}
                      color="primary"
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                </Can>
              </Grid>
            )}
            {!removeItemProps.immutable && (
              <Grid item xs={6}>
                <Can requiredPermissions={removeItemProps.permissions ?? []}
                  requiredPolicy={setPolicy(removeItemProps)}
                  partialPermissions
                >
                  <Tooltip title={`Remove ${singularizeResource(listResourceName)}`} placement="bottom-end">
                    <IconButton
                      aria-label="remove-resource-button"
                      className={classes.actionButton}
                      size="small"
                      onClick={(event: React.MouseEvent) => {
                        event.preventDefault();
                        event.stopPropagation();
                        removeItemProps.handleDeleteClick(resourceId);
                      }}
                      disabled={!resourceId}
                      color="primary"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </Can>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </ListItem>
  );
}

export default FilterListItem;
import { AnyAction } from 'redux';
import { merge, omit, union, without } from 'lodash';
import { Role, RolesState } from './RoleModels';
import {
  FILTER_ORGANIZATION_ROLES,
  GET_ROLES_REQUESTED,
  GET_ROLES_REQUEST_SUCCESSFUL,
  GET_ROLES_REQUEST_FAILED,
  DELETE_ROLES_REQUESTED,
  DELETE_ROLES_REQUEST_SUCCESSFUL,
  DELETE_ROLES_REQUEST_FAILED,
  GET_ROLE_DETAILS_BY_ID_REQUESTED,
  GET_ROLE_DETAILS_BY_ID_REQUEST_SUCCESSFUL,
  GET_ROLE_DETAILS_BY_ID_REQUEST_FAILED,
  CREATE_ROLE_REQUESTED,
  CREATE_ROLE_REQUEST_SUCCESSFUL,
  CREATE_ROLE_REQUEST_FAILED,
  UPDATE_ROLE_REQUESTED,
  UPDATE_ROLE_REQUEST_SUCCESSFUL,
  UPDATE_ROLE_REQUEST_FAILED,
  EXPORT_ROLES_REQUESTED,
  EXPORT_ROLES_REQUEST_FAILED,
  EXPORT_ROLES_REQUEST_SUCCESSFUL
} from './RoleActions';
import { CONTEXT_RESET } from '../Context/ContextActions';
import {
  ADD_ROLES_TO_GROUPS_REQUEST_SUCCESSFUL,
  REMOVE_ROLES_FROM_GROUP_REQUEST_SUCCESSFUL
} from '../Groups/GroupActions';
import { ById, Id } from '../../types/types';

export const initialState: RolesState = {
  allIds: [],
  byId: {},
  isCreating: false,
  isDeleting: false,
  isLoading: false,
  isExporting: false,
};

function roles(state: RolesState = initialState, action: AnyAction): RolesState {
  let byId: ById<Role>;
  let allIds: Id[];
  switch (action.type) {
    case GET_ROLE_DETAILS_BY_ID_REQUESTED:
      allIds = state.allIds.slice();
      byId = { ...state.byId };
      if (!allIds.includes(action.id)) {
        allIds = allIds.concat(action.id);
      }
      if (!byId[action.id]) {
        (byId[action.id] as Partial<Role>) = {};
      }
      allIds.forEach((id) => {
        if (id !== action.id) {
          byId[id] = { ...state.byId[id] };
          return;
        }

        byId[id] = {
          ...state.byId[id],
          id: action.id,
          isLoading: true
        };
      });
      return {
        ...state,
        allIds,
        byId
      };

    case GET_ROLE_DETAILS_BY_ID_REQUEST_SUCCESSFUL:
      byId = {};
      state.allIds.forEach((id) => {
        if (id !== action.id) {
          byId[id] = { ...state.byId[id] };
          return;
        }

        action.byId[id].isLoading = false;
        byId[id] = merge({}, state.byId[id], action.byId[id]);
      });
      return {
        ...state,
        byId
      };

    case GET_ROLE_DETAILS_BY_ID_REQUEST_FAILED:
      byId = {};
      allIds = state.allIds.slice();
      state.allIds.forEach((id) => {
        if (id !== action.id) {
          byId[id] = { ...state.byId[id] };
          return;
        }

        byId[id] = { ...state.byId[id], isLoading: false };
      });
      if (!byId[action.id]?.id) {
        delete byId[action.id];
        allIds = without(state.allIds, action.id);
      }
      return {
        ...state,
        allIds,
        byId
      };

    case GET_ROLES_REQUESTED:
      return {
        ...state,
        isLoading: true,
      };

    case GET_ROLES_REQUEST_SUCCESSFUL:
      return {
        ...state,
        allIds: union(state.allIds.slice(), action.allIds),
        byId: merge({}, state.byId, action.byId),
        isLoading: false
      };

    case GET_ROLES_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case EXPORT_ROLES_REQUESTED:
      return {
        ...state,
        isExporting: true
      };

    case EXPORT_ROLES_REQUEST_SUCCESSFUL:
    case EXPORT_ROLES_REQUEST_FAILED:
      return {
        ...state,
        isExporting: false
      };

    case CREATE_ROLE_REQUESTED:
      return {
        ...state,
        isCreating: true
      };

    case CREATE_ROLE_REQUEST_SUCCESSFUL:
      return {
        ...state,
        allIds: union(state.allIds.slice(), action.allIds),
        byId: merge({}, state.byId, action.byId),
        isCreating: false,
      };

    case CREATE_ROLE_REQUEST_FAILED:
      return {
        ...state,
        isCreating: false
      };

    case UPDATE_ROLE_REQUESTED:
      allIds = state.allIds.slice();
      byId = { ...state.byId };
      if (!allIds.includes(action.id)) {
        allIds = allIds.concat(action.id);
      }
      if (!byId[action.id]) {
        (byId[action.id] as Partial<Role>) = {};
      }
      allIds.forEach((id) => {
        if (id !== action.id) {
          byId[id] = { ...state.byId[id] };
          return;
        }
        const isUpdatingName = byId[id].name !== action.role.name;
        const isUpdatingDescription = byId[id].description !== action.role.description;

        byId[id] = {
          ...state.byId[id],
          isUpdatingDescription,
          isUpdatingName
        };
      });
      return {
        ...state,
        allIds,
        byId
      };

    case UPDATE_ROLE_REQUEST_SUCCESSFUL:
      byId = {};
      state.allIds.forEach((id) => {
        if (id !== action.id) {
          byId[id] = { ...state.byId[id] };
          return;
        }

        action.byId[id].isUpdatingName = false;
        action.byId[id].isUpdatingDescription = false;
        byId[id] = merge({}, state.byId[id], action.byId[id]);
      });
      return {
        ...state,
        byId
      };

    case UPDATE_ROLE_REQUEST_FAILED:
      byId = {};
      allIds = state.allIds.slice();
      state.allIds.forEach((id) => {
        if (id !== action.id) {
          byId[id] = { ...state.byId[id] };
          return;
        }

        byId[id] = { ...state.byId[id], isUpdatingDescription: false, isUpdatingName: false };
      });
      if (!byId[action.id]?.id) {
        delete byId[action.id];
        allIds = without(state.allIds, action.id);
      }
      return {
        ...state,
        allIds,
        byId
      };

    case DELETE_ROLES_REQUESTED:
      return {
        ...state,
        isDeleting: true
      };

    case DELETE_ROLES_REQUEST_SUCCESSFUL:
      const roleIds = (action.roles as Role[]).map(({ id }) => id);
      return {
        ...state,
        allIds: without(state.allIds, ...roleIds),
        byId: omit(state.byId, roleIds),
        isDeleting: false
      };

    case DELETE_ROLES_REQUEST_FAILED:
      return {
        ...state,
        isDeleting: false
      };

    case ADD_ROLES_TO_GROUPS_REQUEST_SUCCESSFUL:
      byId = { ...state.byId };
      action.roleIds.forEach((id: string) => {
        const role = byId[id];
        if (role) {
          byId[id] = { ...role, groupIds: union(role.groupIds, action.groupIds) };
        }
      });
      return {
        ...state,
        byId,
        isLoading: false,
      };

    case REMOVE_ROLES_FROM_GROUP_REQUEST_SUCCESSFUL:
      byId = { ...state.byId };
      action.roleIds.forEach((id: string) => {
        const role = byId[id];
        if (role) {
          byId[id] = { ...role, groupIds: without(role.groupIds, action.groupId) };
        }
      });
      return {
        ...state,
        byId,
        isLoading: false,
      };

    // Context Reset
    case FILTER_ORGANIZATION_ROLES:
      // Remove all roles except organization roles
      const organizationRoles = state.allIds.filter((id: Id) => state.byId[id].roleType !== 'organization');
      return {
        ...initialState,
        allIds: without(state.allIds, ...organizationRoles),
        byId: omit(state.byId, organizationRoles),
      };

    // Context Reset
    case CONTEXT_RESET:
      return {
        ...initialState
      };

    default:
      return state;
  }
}

export default roles;
import React, { useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { connect } from 'react-redux';
import { setPrimaryOrganizationCode } from '../../store/Context/ContextActions';
import { setMyUserProfile } from '../../store/My/MyActions';
import { userProfileFromClaims, incompleteProfileMappedFromClaims } from '../../utility';
import environment from '../../environment';
import { User } from '../../store/Users/UserModels';

interface DispatchFromProps {
  setMyUserProfile: (profile: User) => void;
  setPrimaryOrganizationCode: (orgCode: string) => void;
}

type TokenManagerProps = DispatchFromProps;

const TokenManager = ({
  setMyUserProfile,
  setPrimaryOrganizationCode
}: TokenManagerProps) => {
  const { enableTokenHookLogging } = environment;
  const { authState, oktaAuth } = useOktaAuth();

  const [removeListenerAdded, setRemoveListenerAdded] = useState<boolean>(false);

  // logout when token expires
  const setTokenListener = () => {
    if (!removeListenerAdded) {
      oktaAuth.tokenManager.on('removed', () => oktaAuth.signOut());
      setRemoveListenerAdded(true);
    }
  };

  // setup user profile in redux from Token
  useEffect(() => {
    if (authState && authState.isAuthenticated && authState.accessToken?.claims) {
      const userProfile = userProfileFromClaims(authState.accessToken.claims);
      /*
        If a claims property is missing, the token hook failed. Log user out
        and forward them to Token Hook Error page.
      */
      if (incompleteProfileMappedFromClaims(userProfile)) {
        if (enableTokenHookLogging) {
          console.log('='.repeat(50));
          console.log('Token Hook Claims:', JSON.stringify(authState.accessToken?.claims));
          console.log('Mapped Profile', JSON.stringify(userProfile));
          console.log('='.repeat(50));
        }
        oktaAuth.tokenManager.clear();
        oktaAuth.signOut();
      } else {
        setPrimaryOrganizationCode(userProfile.primaryOrganizationCode);
        setMyUserProfile(userProfile);
      }
    }
  }, [authState, enableTokenHookLogging, setPrimaryOrganizationCode, setMyUserProfile, oktaAuth]);

  return (<>
    {setTokenListener()}
  </>);
};

const mapDispatchToProps: DispatchFromProps = {
  setMyUserProfile,
  setPrimaryOrganizationCode
};

export default connect(null, mapDispatchToProps)(TokenManager);
import { AnyAction } from 'redux';
import { rolesApi } from '../../api';
import { Id } from '../../types/types';
import { normalizeRolesDetailsApiResponse, showError } from '../../utility';
import { GET_POLICY_RULES_REQUEST_SUCCESSFUL } from '../PolicyRules/PolicyRuleActions';
import { CREATE_ROLE_REQUEST_SUCCESSFUL } from '../Roles/RoleActions';
import { Role, } from '../Roles/RoleModels';
import { AppThunkAction } from '../rootReducer';

export const IMPORT_ROLES_REQUESTED: string = 'IMPORT_ROLES_REQUESTED';
export const IMPORT_ROLES_REQUEST_SUCCESSFUL: string = 'IMPORT_ROLES_REQUEST_SUCCESSFUL';
export const IMPORT_ROLES_REQUEST_FAILED: string = 'IMPORT_ROLES_REQUEST_FAILED';

export const RESET_ROLE_IMPORT: string = 'RESET_ROLE_IMPORT';

/**
 * Import a list of roles
 * @param {Id} organizationCode - organization the roles will be created under
 * @param {Partial<Role[]>} roles - the roles to import
 * @return {Function} - dispatches function response based on api results
 */
export function importRoles(organizationCode: Id, roles: Partial<Role>[]): AppThunkAction<AnyAction> {
  return async (dispatch) => {
    dispatch({ type: IMPORT_ROLES_REQUESTED });
    try {
      const importResult = await rolesApi.importRoles(organizationCode, roles);

      // Add roles to redux store
      const { allIds: roleAllIds, byId: roleById } = normalizeRolesDetailsApiResponse(importResult.roles);
      dispatch({ allIds: roleAllIds, byId: roleById, type: CREATE_ROLE_REQUEST_SUCCESSFUL });

      // Add policies to redux store
      dispatch({ policyRules: importResult.policies, type: GET_POLICY_RULES_REQUEST_SUCCESSFUL });

      // Add import results to redux store
      dispatch({ importResult, type: IMPORT_ROLES_REQUEST_SUCCESSFUL });
    } catch (err) {
      showError(err, dispatch);
      dispatch({ type: IMPORT_ROLES_REQUEST_FAILED });
    }
  };
}

/**
 * Reset the role import redux slice
 * @return {Function} - dispatches function response
 */
export function resetRoleImport(): AppThunkAction<AnyAction> {
  return async (dispatch) => {
    dispatch({ type: RESET_ROLE_IMPORT });
  };
}
import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { useDispatch } from 'react-redux';
import { createFederatedGroupMapping } from '../../../store/FederatedGroupMappings/FederatedGroupMappingActions';
import { Id } from '../../../types/types';
import useStyles from './styles';

interface AddFederaterdGroupMappingModalProps {
  isOpen: boolean;
  onClose: () => void;
  groupId: Id;
  organizationCode: string;
}

const AddFederaterdGroupMappingModal = ({
  isOpen,
  onClose,
  groupId,
  organizationCode
}: AddFederaterdGroupMappingModalProps) => {
  const dispatch = useDispatch();
  const [federatedGroup, setFederatedGroup] = useState('');
  const classes = useStyles();

  const submit = () => {
    dispatch(createFederatedGroupMapping(organizationCode, groupId, federatedGroup));
    onClose();
    setFederatedGroup('');
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby='add-federated-group-mapping-dialog'
      classes={{
        paper: classes.dialogPaper
      }}
    >
      <DialogTitle className={classes.titleContainer} id='add-federated-group-mapping-dialog'>Add Federated Group</DialogTitle>
      <DialogContent>
        <TextField
          label='Federated Group'
          variant='outlined'
          fullWidth
          id='federated-group'
          type='text'
          autoFocus
          margin='dense'
          value={federatedGroup}
          onChange={(e) => setFederatedGroup(e.target.value)}
          error={federatedGroup.length > 255}
          helperText={federatedGroup.length > 255 ? 'Must be less then 255 characters' : ''}
        >
        </TextField>
      </DialogContent>
      <DialogActions className={classes.actionsContainer}>
        <Button onClick={onClose} color="primary" variant="outlined">Close</Button>
        <Button
          type="submit"
          aria-label="create-federated-group-mapping-button"
          variant="contained"
          disabled={federatedGroup.length < 1 || federatedGroup.length > 255}
          onClick={submit}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddFederaterdGroupMappingModal;
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { ContextBannerStyleProps } from './ContextBanner';

const styles = makeStyles((theme: Theme) => ({
  button: {
    color: '#FFFFFF',
    font: 'normal normal 600 15px/32px Saira',
  },
  chipRoot: {
    backgroundColor: '#8db580',
    borderColor: '#FFF',
    borderRadius: 3,
    borderWidth: 1,
    color: '#FFF',
    fontSize: 12,
    fontWeight: 600,
    letterSpacing: 0.65,
    marginRight: theme.spacing(1),
    textTransform: 'uppercase'
  },
  collapsed: {
    display: 'none',
    height: 0,
    transition: theme.transitions.create('height', {
      duration: theme.transitions.duration.leavingScreen,
      easing: theme.transitions.easing.sharp,
    }),
  },
  contentShift: {
    marginLeft: (props: ContextBannerStyleProps) => props.shiftWidth,
    transition: theme.transitions.create('marginLeft', {
      duration: theme.transitions.duration.leavingScreen,
      easing: theme.transitions.easing.sharp,
    }),
  },
  contextMessage: {
    display: 'inline-flex'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    position: 'absolute',
    width: 400,
  },
  root: {
    backgroundColor: '#4CAF50',
    color: 'white',
    fontSize: 14,
    justifyContent: 'space-between',
    marginLeft: '69px',
    minHeight: 44,
    padding: theme.spacing(0.5, 2),
    transition: theme.transitions.create('marginLeft', {
      duration: theme.transitions.duration.enteringScreen,
      easing: theme.transitions.easing.sharp,
    })
  },
}));

export default styles;
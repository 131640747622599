import environment from '../environment';
import { FederatedGroupMapping } from '../store/FederatedGroupMappings/FederatedGroupMappingModels';
import { Id } from '../types/types';
import { handleErrors, retrieveAccessToken } from '../utility';

const { apiBasePath } = environment;

export function getFederatedGroupMappingsByGroup(organizationCode: string, groupId: Id): Promise<FederatedGroupMapping[]> {
  return fetch(`${apiBasePath}/organization/${organizationCode}/group/${groupId}/federated-group-mapping`, {
    headers: {
      Authorization: `Bearer ${retrieveAccessToken()}`
    }
  }).then(handleErrors).then((response) => response.json());
}

export function createFederatedGroupMapping(organizationCode: string, groupId: Id, federatedGroup: string): Promise<FederatedGroupMapping> {
  return fetch(`${apiBasePath}/organization/${organizationCode}/group/${groupId}/federated-group-mapping`, {
    headers: {
      Authorization: `Bearer ${retrieveAccessToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(federatedGroup),
    method: 'POST'
  }).then(handleErrors).then((response) => response.json());
}

export function updateFederatedGroupMapping(
  organizationCode: string,
  mapping: FederatedGroupMapping
): Promise<FederatedGroupMapping> {
  return fetch(`${apiBasePath}/organization/${organizationCode}/group/${mapping.groupId}/federated-group-mapping/${mapping.id}`, {
    headers: {
      Authorization: `Bearer ${retrieveAccessToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(mapping),
    method: 'PUT'
  }).then(handleErrors).then((response) => response.json());
}

export function deleteFederatedGroupMapping(
  organizationCode: string,
  groupId: Id,
  id: Id
) {
  return fetch(`${apiBasePath}/organization/${organizationCode}/group/${groupId}/federated-group-mapping/${id}`, {
    headers: {
      Authorization: `Bearer ${retrieveAccessToken()}`
    },
    method: 'DELETE'
  }).then(handleErrors);
}
import environment from '../environment';
import { Id } from '../types/types';
import { Role, RoleDetailsApiResponse } from '../store/Roles/RoleModels';
import { handleErrors, retrieveAccessToken } from '../utility';
import { RoleImportApiResponse } from '../store/RoleImports/RoleImportModels';

const { apiBasePath } = environment;

export function getRolesInOrg(organizationCode: Id): Promise<Role[]> {
  return fetch(`${apiBasePath}/organization/${organizationCode}/role`, {
    headers: {
      'Authorization': `Bearer ${retrieveAccessToken()}`
    }
  }).then(handleErrors).then((response) => response.json() as Promise<Role[]>);
}

export function getRoleWithDetailsInOrg(organizationCode: Id, roleId: Id): Promise<RoleDetailsApiResponse> {
  return fetch(`${apiBasePath}/organization/${organizationCode}/role/${roleId}?groups=true&application=true`, {
    headers: {
      'Authorization': `Bearer ${retrieveAccessToken()}`
    }
  }).then(handleErrors).then((response) => response.json() as Promise<RoleDetailsApiResponse>);
}

export function exportRoles(organizationCode: Id, roleIds: Id[]): Promise<Partial<Role>[]> {
  const params = `roleIds=${roleIds.map(encodeURIComponent).join(',')}`;

  return fetch(`${apiBasePath}/organization/${organizationCode}/role/export?${params}`, {
    headers: {
      'Authorization': `Bearer ${retrieveAccessToken()}`
    },
  }).then(handleErrors).then(response => response.json());
}

export function importRoles(organizationCode: Id, roles: Partial<Role>[]): Promise<RoleImportApiResponse> {
  return fetch(`${apiBasePath}/organization/${organizationCode}/role/import`, {
    body: JSON.stringify(roles),
    headers: {
      'Authorization': `Bearer ${retrieveAccessToken()}`,
      'Content-Type': 'application/json'
    },
    method: 'POST'
  }).then(handleErrors).then(response => response.json());
}

export function createOrganizationRole(organizationCode: Id, role: Partial<Role>) {
  return fetch(`${apiBasePath}/organization/${organizationCode}/role`, {
    body: JSON.stringify(role),
    headers: {
      'Authorization': `Bearer ${retrieveAccessToken()}`,
      'Content-Type': 'application/json'
    },
    method: 'POST',
  }).then(handleErrors).then(response => response.json());
}

export function createApplicationRole(applicationCode: Id, role: Partial<Role>) {
  return fetch(`${apiBasePath}/application/${applicationCode}/role`, {
    body: JSON.stringify(role),
    headers: {
      'Authorization': `Bearer ${retrieveAccessToken()}`,
      'Content-Type': 'application/json'
    },
    method: 'POST',
  }).then(handleErrors).then(response => response.json());
}

export function updateOrganizationRole(organizationCode: Id, role: Partial<Role>) {
  return fetch(`${apiBasePath}/organization/${organizationCode}/role/${role.id}`, {
    body: JSON.stringify(role),
    headers: {
      'Authorization': `Bearer ${retrieveAccessToken()}`,
      'Content-Type': 'application/json'
    },
    method: 'PUT',
  }).then(handleErrors).then(response => response.json());
}

export function updateApplicationRole(applicationCode: Id, role: Partial<Role>) {
  return fetch(`${apiBasePath}/application/${applicationCode}/role/${role.id}`, {
    body: JSON.stringify(role),
    headers: {
      'Authorization': `Bearer ${retrieveAccessToken()}`,
      'Content-Type': 'application/json'
    },
    method: 'PUT',
  }).then(handleErrors).then(response => response.json());
}

export function deleteOrganizationRole(organizationCode: Id, roleId: Id): Promise<void> {
  return fetch(`${apiBasePath}/organization/${organizationCode}/role/${roleId}`, {
    headers: {
      'Authorization': `Bearer ${retrieveAccessToken()}`
    },
    method: 'DELETE'
  }).then(handleErrors).then((response) => response);
}

export function deleteApplicationRole(applicationCode: Id, roleId: Id): Promise<void> {
  return fetch(`${apiBasePath}/application/${applicationCode}/role/${roleId}`, {
    headers: {
      'Authorization': `Bearer ${retrieveAccessToken()}`
    },
    method: 'DELETE'
  }).then(handleErrors);
}

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

interface SwitchContextStyleProp {
  organizationSelected?: boolean;
}

const styles = makeStyles((theme: Theme) => ({
  grow: {
    flexGrow: 1
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  column: {
    display: 'flex',
    flexDirection: 'column'
  },
  listItemTextPrimary: {
    fontSize: 14,
    fontWeight: ({ organizationSelected = false }: SwitchContextStyleProp) => organizationSelected ? 600 : 500
  },
  menuContainer: {
    borderBottom: '5px solid #FFF',
    boxShadow: theme.shadows[5],
    height: 381,
    minWidth: 320,
    overflowY: 'scroll',
    padding: theme.spacing(0, 1.5, 2, 1.5)
  },
  menuItem: {
    '&:hover': {
      background: theme.palette.grey[100],
      cursor: 'pointer'
    },
    '&:hover svg': {
      color: theme.palette.primary.light
    }
  },
  message: {
    color: theme.palette.grey[600],
    padding: theme.spacing(1.5, 1),
    textAlign: 'center'
  },
  messageContainer: {
    height: '90%'
  },
  popover: {
    borderRadius: 4,
    height: 381,
    width: 320,
    zIndex: theme.zIndex.modal - 10
  },
  root: {
    display: 'flex',
  },
  subHeader: {
    background: '#FFF',
    display: 'sticky',
    height: 60,
    marginBottom: theme.spacing(1),
    padding: theme.spacing(2, 1, 0)
  },
  switchContextButton: {
    '&:hover': {
      background: 'transparent',
      color: theme.palette.primary.light
    },
    color: '#FFF',
    fontFamily: theme.typography.fontFamily,
    fontSize: 15,
    fontWeight: 500,
    letterSpacing: 0,
    margin: theme.spacing(0, 1.5),
    textTransform: 'none'
  },
  contextLabel: {
    margin: '10px',
  }
}));

export default styles;
import React, { useState } from 'react';
import {
  Drawer,
  IconButton,
  Toolbar
} from '@mui/material';
import clsx from 'clsx';

import NavigationList from '../NavigationList/NavigationList';
import { ReactComponent as HideNavIcon } from '../../svgs/nav-hide-icon.svg';
import { ReactComponent as ShowNavIcon } from '../../svgs/nav-show-icon.svg';
import useStyles from './styles';

interface SideNavigationProps {
	onToggle?: (isOpen: boolean) => void;
}

export default function SideNavigation({ onToggle }: SideNavigationProps) {
  const classes = useStyles();
  const [open, setOpen] = useState(true);

  const handleDrawerToggle = () => {
    setOpen(!open);
    if (onToggle) {
      onToggle(!open);
    }
  };

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      <NavigationList />
      <div className={classes.grow} />
      <IconButton
        className={clsx(classes.iconButton, {
          [classes.shiftIcon]: open
        })}
        disableFocusRipple={true}
        onClick={handleDrawerToggle}
        size="large">
        {open ? <HideNavIcon className={classes.toggleButton} /> : <ShowNavIcon className={classes.toggleButton} />}
      </IconButton>
      <Toolbar />
    </Drawer>
  );
}

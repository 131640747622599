import { PolicyRequest } from '../store/My/MyModels';
import { Id } from '../types/types';

interface GroupPolicyConstants {
  getAny: (organizationCode: Id) => PolicyRequest;
  getByOrg: (organizationCode: Id) => PolicyRequest;
  getById: (organizationCode: Id, groupId: Id) => PolicyRequest;
  updateAny: (organizationCode: Id) => PolicyRequest;
  create: (organizationCode: Id) => PolicyRequest;
  update: (organizationCode: Id, groupId: Id) => PolicyRequest;
  delete: (organizationCode: Id, groupId: Id) => PolicyRequest;
  addRoles: (organizationCode: Id, groupId: Id) => PolicyRequest;
  addRoleAny: (organizationCode: Id) => PolicyRequest;
  removeRoles: (organizationCode: Id, groupId: Id) => PolicyRequest;
  addUsers: (organizationCode: Id, groupId: Id) => PolicyRequest;
  addUsersAny: (organizationCode: Id) => PolicyRequest;
  removeUsers: (organizationCode: Id, groupId: Id) => PolicyRequest;
}

interface OrganizationPolicyConstants {
  getAny: () => PolicyRequest;
  getById: (organizationCode: Id) => PolicyRequest;
  getApplications: (organizationCode: Id) => PolicyRequest;
  addApplications: (organizationCode: Id) => PolicyRequest;
  removeApplications: (organizationCode: Id, applicationCode: Id) => PolicyRequest;
  getDomains: (organizationCode: Id) => PolicyRequest;
  addDomain: (organizationCode: Id) => PolicyRequest;
  deleteDomains: (organizationCode: Id) => PolicyRequest;
  canSetStrictMode: (organizationCode: Id) => PolicyRequest;
}

interface RolePolicyConstants {
  getAll: (organizationCode: Id) => PolicyRequest;
  getAny: (organizationCode: Id) => PolicyRequest;
  getApplication: () => PolicyRequest;
  getById: (organizationCode: Id, roleId: Id) => PolicyRequest;
  exportRoles: (organizationCode: Id) => PolicyRequest;
  importRoles: (organizationCode: Id) => PolicyRequest;
  createApplication: (applicationCode: Id) => PolicyRequest;
  createAnyApplication: () => PolicyRequest;
  createOrganization: (organizationCode: Id) => PolicyRequest;
  updateApplication: (applicationCode: Id, roleId: Id) => PolicyRequest;
  updateApplicationAny: () => PolicyRequest;
  updateOrganization: (organizationCode: Id, roleId: Id) => PolicyRequest;
  deleteApplication: (applicationCode: string, roleId: Id) => PolicyRequest;
  deleteOrganization: (organizationCode: Id, roleId: Id) => PolicyRequest;
}

interface ApplicationPolicyConstants {
  getAll: () => PolicyRequest;
  getAny: () => PolicyRequest;
  getById: (applicationCode: Id) => PolicyRequest;
  getByCodeInOrganization: (organizationCode: Id, applicationCode: Id) => PolicyRequest;
  getPolicyModel: () => PolicyRequest;
  create: () => PolicyRequest;
  update: (applicationCode: Id) => PolicyRequest;
  delete: (applicationCode: Id) => PolicyRequest;
  addPermission: (applicationCode: Id) => PolicyRequest;
  removePermission: (applicationCode: Id) => PolicyRequest;
  getRoles: (applicationCode: Id) => PolicyRequest;
  getRolesAny: () => PolicyRequest;
  getOrganizations: (applicationCode: Id) => PolicyRequest;
  addOrganization: (applicationCode: Id) => PolicyRequest;
  removeOrganization: (applicationCode: Id) => PolicyRequest;
}

interface UserPolicyConstants {
  get: (organizationCode: Id) => PolicyRequest;
  getAny: (organizationCode: Id) => PolicyRequest;
  getWithGroupIds: (organizationCode: Id) => PolicyRequest;
  getById: (organizationCode: Id, userId: Id) => PolicyRequest;
  getWithGroupIdsById: (organizationCode: Id, userId: Id) => PolicyRequest;
  getDeprovisioned: (organizationCode: Id) => PolicyRequest;
  importByOrganization: (organizationCode: Id) => PolicyRequest;
  importAll: () => PolicyRequest;
  create: (organizationCode: Id) => PolicyRequest;
  update: (organizationCode: Id, userId: Id) => PolicyRequest;
  updateAny: (organizationCode: Id) => PolicyRequest;
  delete: (organizationCode: Id, userId: Id) => PolicyRequest;
  deleteAny: (organizationCode: Id) => PolicyRequest;
  activate: (organizationCode: Id, userId: Id) => PolicyRequest;
  deactivate: (organizationCode: Id, userId: Id) => PolicyRequest;
  reactivate: (organizationCode: Id, userId: Id) => PolicyRequest;
  suspend: (organizationCode: Id, userId: Id) => PolicyRequest;
  unsuspend: (organizationCode: Id, userId: Id) => PolicyRequest;
  unlock: (organizationCode: Id, userId: Id) => PolicyRequest;
  resetPassword: (organizationCode: Id, userId: Id) => PolicyRequest;
  activateAny: (organizationCode: Id) => PolicyRequest;
  deactivateAny: (organizationCode: Id) => PolicyRequest;
  reactivateAny: (organizationCode: Id) => PolicyRequest;
  suspendAny: (organizationCode: Id) => PolicyRequest;
  unsuspendAny: (organizationCode: Id) => PolicyRequest;
  unlockAny: (organizationCode: Id) => PolicyRequest;
  resetPasswordAny: (organizationCode: Id) => PolicyRequest;
  getAnyWithGroupIdsById: (organizationCode: Id) => PolicyRequest
}

interface PolicyRulePolicyConstants {
  getByRole: (organizationCode: Id, roleId: Id) => PolicyRequest;
  create: (organizationCode: Id, roleId: Id) => PolicyRequest;
  update: (organizationCode: Id, roleId: Id, policyRoleId: Id) => PolicyRequest;
  updateAny: (organizationCode: Id, roleId: Id) => PolicyRequest;
  delete: (organizationCode: Id, roleId: Id, policyRoleId: Id) => PolicyRequest;
  deleteAny: (organizationCode: Id, roleId: Id) => PolicyRequest;
}

interface ClientPolicyConstants {
  getAll: (organizationCode: Id) => PolicyRequest;
}

interface OAuthClientPolicyConstants {
  getByOrg: (organizationCode: Id) => PolicyRequest;
  getAny: (organizationCode: Id) => PolicyRequest;
  get: (oauthClientId: Id) => PolicyRequest;
  create: () => PolicyRequest;
  update: (oauthClientId: Id) => PolicyRequest;
  regenerate: (oauthClientId: Id) => PolicyRequest;
}

interface FederatedGroupMappingPolicyConstants {
  getByGroup: (organizationCode: Id, groupId: Id) => PolicyRequest;
  create: (organizationCode: Id, groupId: Id) => PolicyRequest;
  update: (organizationCode: Id, groupId: Id, federatedGroupMappingId: Id) => PolicyRequest;
  delete: (organizationCode: Id, groupId: Id, federatedGroupMappingId: Id) => PolicyRequest;
}

export interface PolicyConstants {
  groups: GroupPolicyConstants,
  organizations: OrganizationPolicyConstants,
  roles: RolePolicyConstants,
  applications: ApplicationPolicyConstants,
  users: UserPolicyConstants,
  policyRules: PolicyRulePolicyConstants,
  clients: ClientPolicyConstants,
  oauthClients: OAuthClientPolicyConstants,
  federatedGroupMappings: FederatedGroupMappingPolicyConstants
};

const policyConstants: PolicyConstants = {
  groups: {
    getAny: (organizationCode: Id) => ({ obj: `/organization/${organizationCode}/group/*`, act: 'read' }),
    getByOrg: (organizationCode: Id) => ({ obj: `/organization/${organizationCode}/group`, act: 'read' }),
    getById: (organizationCode: Id, groupId: Id) => ({ obj: `/organization/${organizationCode}/group/${groupId}`, act: 'read' }),
    updateAny: (organizationCode: Id) => ({ obj: `/organization/${organizationCode}/group/*`, act: 'write' }),
    create: (organizationCode: Id) => ({ obj: `/organization/${organizationCode}/group`, act: 'write' }),
    update: (organizationCode: Id, groupId: Id) => ({ obj: `/organization/${organizationCode}/group/${groupId}`, act: 'write' }),
    delete: (organizationCode: Id, groupId: Id) => ({ obj: `/organization/${organizationCode}/group/${groupId}`, act: 'write' }),
    addRoles: (organizationCode: Id, groupId: Id) => ({ obj: `/organization/${organizationCode}/group/${groupId}/role`, act: 'write' }),
    addRoleAny: (organizationCode: Id) => ({ obj: `/organization/${organizationCode}/group/*/role`, act: 'write' }),
    removeRoles: (organizationCode: Id, groupId: Id) => ({ obj: `/organization/${organizationCode}/group/${groupId}/role`, act: 'write' }),
    addUsers: (organizationCode: Id, groupId: Id) => ({ obj: `/organization/${organizationCode}/group/${groupId}/user`, act: 'write' }),
    addUsersAny: (organizationCode: Id) => ({ obj: `/organization/${organizationCode}/group/*/user`, act: 'write' }),
    removeUsers: (organizationCode: Id, groupId: Id) => ({ obj: `/organization/${organizationCode}/group/${groupId}/user`, act: 'write' })
  },
  organizations: {
    getAny: () => ({ obj: '/organization/*', act: 'read' }),
    getById: (organizationCode: Id) => ({ obj: `/organization/${organizationCode}`, act: 'read' }),
    getApplications: (organizationCode: Id) => ({ obj: `/organization/${organizationCode}/application`, act: 'read' }),
    addApplications: (organizationCode: Id) => ({ obj: `/organization/${organizationCode}/applications`, act: 'write' }),
    removeApplications: (organizationCode: Id) => ({ obj: `/organization/${organizationCode}/applications`, act: 'write' }),
    getDomains: (organizationCode: Id) => ({ obj: `/organization/${organizationCode}/domain`, act: 'read' }),
    addDomain: (organizationCode: Id) => ({ obj: `/organization/${organizationCode}/domain`, act: 'write' }),
    deleteDomains: (organizationCode: Id) => ({ obj: `/organization/${organizationCode}/domain`, act: 'write' }),
    canSetStrictMode: (organizationCode: Id) => ({ obj: `/organization/${organizationCode}/strict-mode`, act: 'write' })
  },
  roles: {
    getAll: (organizationCode: Id) => ({ obj: `/organization/${organizationCode}/role`, act: 'read' }),
    getAny: (organizationCode: Id) => ({ obj: `/organization/${organizationCode}/role/*`, act: 'read' }),
    getApplication: () => ({ obj: `/role/application`, act: 'read' }),
    getById: (organizationCode: Id, roleId: Id) => ({ obj: `/organization/${organizationCode}/role/${roleId}/groups`, act: 'read' }),
    exportRoles: (organizationCode: Id) => ({ obj: `/organization/${organizationCode}/role/export`, act: 'read' }),
    importRoles: (organizationCode: Id) => ({ obj: `/organization/${organizationCode}/role/import`, act: 'write' }),
    createApplication: (applicationCode: Id) => ({ obj: `/application/${applicationCode}/role`, act: 'write' }),
    createAnyApplication: () => ({ obj: `/application/*/role`, act: 'write' }),
    createOrganization: (organizationCode: Id) => ({ obj: `/organization/${organizationCode}/role`, act: 'write' }),
    updateApplication: (applicationCode: Id, roleId: Id) => ({ obj: `/application/${applicationCode}/role/${roleId}`, act: 'write' }),
    updateApplicationAny: () => ({ obj: `/application/*/role`, act: 'write' }),
    updateOrganization: (organizationCode: Id, roleId: Id) => ({ obj: `/application/${organizationCode}/role/${roleId}`, act: 'write' }),
    deleteApplication: (applicationId: string, roleId: Id) => ({ obj: `/application/${applicationId}/role/${roleId}`, act: 'write' }),
    deleteOrganization: (organizationCode: Id, roleId: Id) => ({ obj: `/organization/${organizationCode}/role/${roleId}`, act: 'write' }),
  },
  applications: {
    getAll: () => ({ obj: `/application`, act: 'read' }),
    getAny: () => ({ obj: `/application/*`, act: 'read' }),
    getById: (applicationCode: Id) => ({ obj: `/application/${applicationCode}/permissions`, act: 'read' }),
    getByCodeInOrganization: (organizationCode: Id, applicationCode: Id) =>
      ({ obj: `/organization/${organizationCode}/application/${applicationCode}/permissions`, act: 'read' }),
    getPolicyModel: () => ({ obj: `/application`, act: 'read' }),
    create: () => ({ obj: `/application`, act: 'write' }),
    update: (applicationCode: Id) => ({ obj: `/application/${applicationCode}`, act: 'write' }),
    delete: (applicationCode: Id) => ({ obj: `/application/${applicationCode}`, act: 'write' }),
    addPermission: (applicationCode: Id) => ({ obj: `/application/${applicationCode}/permission`, act: 'write' }),
    removePermission: (applicationCode: Id) => ({ obj: `/application/${applicationCode}/permission`, act: 'write' }),
    getRoles: (applicationCode: Id) => ({ obj: `/application/${applicationCode}/roles`, act: 'read' }),
    getRolesAny: () => ({ obj: `/application/*/roles`, act: 'read' }),
    getOrganizations: (applicationCode: Id) => ({ obj: `/application/${applicationCode}/organization`, act: 'read' }),
    addOrganization: (applicationCode: Id) => ({ obj: `/application/${applicationCode}/organization`, act: 'write' }),
    removeOrganization: (applicationCode: Id) => ({ obj: `/application/${applicationCode}/organization`, act: 'write' }),
  },
  users: {
    get: (organizationCode: Id) => ({ obj: `/organization/${organizationCode}/user`, act: 'read' }),
    getAny: (organizationCode: Id) => ({ obj: `/organization/${organizationCode}/user/*`, act: 'read' }),
    getWithGroupIds: (organizationCode: Id) => ({ obj: `/organization/${organizationCode}/user/groups`, act: 'read' }),
    getById: (organizationCode: Id, userId: Id) => ({ obj: `/organization/${organizationCode}/user/${userId}`, act: 'read' }),
    getWithGroupIdsById: (organizationCode: Id, userId: Id) => ({ obj: `/organization/${organizationCode}/user/${userId}/groups`, act: 'read' }),
    getAnyWithGroupIdsById: (organizationCode: Id) => ({ obj: `/organization/${organizationCode}/user/*/groups`, act: 'read' }),
    getDeprovisioned: (organizationCode: Id) => ({ obj: `/organization/${organizationCode}/user/deprovisioned`, act: 'read' }),
    importByOrganization: (organizationCode: Id) => ({ obj: `/organization/${organizationCode}/user/import`, act: 'write' }),
    importAll: () => ({ obj: '/user/import', act: 'write' }),
    create: (organizationCode: Id) => ({ obj: `/organization/${organizationCode}/user`, act: 'write' }),
    update: (organizationCode: Id, userId: Id) => ({ obj: `/organization/${organizationCode}/user/${userId}`, act: 'write' }),
    updateAny: (organizationCode: Id) => ({ obj: `/organization/${organizationCode}/user/*`, act: 'write' }),
    delete: (organizationCode: Id, userId: Id) => ({ obj: `/organization/${organizationCode}/user/${userId}`, act: 'write' }),
    deleteAny: (organizationCode: Id) => ({ obj: `/organization/${organizationCode}/user/*`, act: 'write' }),
    activate: (organizationCode: Id, userId: Id) => ({ obj: `/organization/${organizationCode}/user/${userId}/activate`, act: 'write' }),
    deactivate: (organizationCode: Id, userId: Id) => ({ obj: `/organization/${organizationCode}/user/${userId}/deactivate`, act: 'write' }),
    reactivate: (organizationCode: Id, userId: Id) => ({ obj: `/organization/${organizationCode}/user/${userId}/reactivate`, act: 'write' }),
    suspend: (organizationCode: Id, userId: Id) => ({ obj: `/organization/${organizationCode}/user/${userId}/suspend`, act: 'write' }),
    unsuspend: (organizationCode: Id, userId: Id) => ({ obj: `/organization/${organizationCode}/user/${userId}/unsuspend`, act: 'write' }),
    unlock: (organizationCode: Id, userId: Id) => ({ obj: `/organization/${organizationCode}/user/${userId}/unlock`, act: 'write' }),
    resetPassword: (organizationCode: Id, userId: Id) => ({ obj: `/organization/${organizationCode}/user/${userId}/resetpassword`, act: 'write' }),
    activateAny: (organizationCode: Id) => ({ obj: `/organization/${organizationCode}/user/*/activate`, act: 'write' }),
    deactivateAny: (organizationCode: Id) => ({ obj: `/organization/${organizationCode}/user/*/deactivate`, act: 'write' }),
    reactivateAny: (organizationCode: Id) => ({ obj: `/organization/${organizationCode}/user/*/reactivate`, act: 'write' }),
    suspendAny: (organizationCode: Id) => ({ obj: `/organization/${organizationCode}/user/*/suspend`, act: 'write' }),
    unsuspendAny: (organizationCode: Id) => ({ obj: `/organization/${organizationCode}/user/*/unsuspend`, act: 'write' }),
    unlockAny: (organizationCode: Id) => ({ obj: `/organization/${organizationCode}/user/*/unlock`, act: 'write' }),
    resetPasswordAny: (organizationCode: Id) => ({ obj: `/organization/${organizationCode}/user/*/resetpassword`, act: 'write' })
  },
  policyRules: {
    getByRole: (organizationCode: Id, roleId: Id) => ({ obj: `/organization/${organizationCode}/role/${roleId}/policy`, act: 'read' }),
    create: (organizationCode: Id, roleId: Id) => ({ obj: `/organization/${organizationCode}/role/${roleId}/policy`, act: 'write' }),
    update: (organizationCode: Id, roleId: Id, policyRoleId: Id) => (
      { obj: `/organization/${organizationCode}/role/${roleId}/policy/${policyRoleId}`, act: 'write' }),
    updateAny: (organizationCode: Id, roleId: Id) => ({ obj: `/organization/${organizationCode}/role/${roleId}/policy/*`, act: 'write' }),
    delete: (organizationCode: Id, roleId: Id, policyRoleId: Id) => (
      { obj: `/organization/${organizationCode}/role/${roleId}/policy/${policyRoleId}`, act: 'write' }),
    deleteAny: (organizationCode: Id, roleId: Id) => ({ obj: `/organization/${organizationCode}/role/${roleId}/policy/*`, act: 'write' }),
  },
  clients: {
    getAll: (organizationCode: Id) => ({ obj: `/organization/${organizationCode}/client`, act: 'read' }),
  },
  oauthClients: {
    getByOrg: (organizationCode: Id) => ({ obj: `/organization/${organizationCode}/oauth-client`, act: 'read' }),
    getAny: (organizationCode: Id) => ({ obj: `/organization/${organizationCode}/oauth-client/*`, act: 'read' }),
    get: (oauthClientId: Id) => ({ obj: `/oauth-client/${oauthClientId}`, act: 'read' }),
    create: () => ({ obj: `/oauth-client`, act: 'write' }),
    update: (oauthClientId: Id) => ({ obj: `/oauth-client/${oauthClientId}`, act: 'write' }),
    regenerate: (oauthClientId: Id) => ({ obj: `/oauth-client/${oauthClientId}/regenerate-secret`, act: 'write' }),
  },
  federatedGroupMappings: {
    getByGroup: (organizationCode: Id, groupId: Id) => ({ obj: `/organization/${organizationCode}/group/${groupId}/federated-group-mapping`, act: 'read' }),
    create: (organizationCode: Id, groupId: Id) => ({ obj: `/organization/${organizationCode}/group/${groupId}/federated-group-mapping`, act: 'write' }),
    update: (organizationCode: Id, groupId: Id, federatedGroupMappingId: Id) =>
      ({ obj: `/organization/${organizationCode}/group/${groupId}/federated-group-mapping/${federatedGroupMappingId}`, act: 'write' }),
    delete: (organizationCode: Id, groupId: Id, federatedGroupMappingId: Id) =>
      ({ obj: `/organization/${organizationCode}/group/${groupId}/federated-group-mapping/${federatedGroupMappingId}`, act: 'write' })
  }
};

export default policyConstants;
import { User } from '../store/Users/UserModels';

/**
 * Check to ensure claims mapped to all of the required fields for an authenticated user's profile
 * @param {User} userProfile - user profile object mapped from claims
 * @return {Boolean} - boolean determining if user profile is missing properties
 */
export default function incompleteProfileMappedFromClaims(userProfile: User): boolean {
  const v = Object.values(userProfile);
  return v.includes(null) || v.includes(undefined) || v.includes('');
}
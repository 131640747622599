import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

const styles = makeStyles((theme: Theme) => ({
  circle: {
    alignItems: 'center',
    backgroundColor: theme.palette.grey[100],
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center'
  },
  header: {
    color: theme.palette.grey[800],
    fontFamily: '\'Saira\', sans-serif',
    fontSize: 32,
    padding: theme.spacing(3.75, 0, 0.5)
  },
  icon: {
    color: theme.palette.primary.dark,
    height: 89,
    width: 67
  },
  subheader: {
    color: theme.palette.grey[600],
    fontSize: 16,
    textAlign: 'center',
    width: 300
  }
}));

export default styles;
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Grid, TextField } from '@mui/material';

import ApplicationField from '../ApplicationField/ApplicationField';
import ClientField from './ClientField';
import InputSkeleton from '../InputSkeleton/InputSkeleton';
import PermissionField from './PermissionField';
import useStyles from './styles';
import EffectField from './EffectField';

type PolicyRuleFieldsProps = {
  isLoading?: boolean;
};

const PolicyRuleFields = ({
  isLoading = false
}: PolicyRuleFieldsProps) => {
  const classes = useStyles();
  const { control, setValue, watch } = useFormContext();
  const applicationCode = watch('applicationCode');

  const resetPermission = () => setValue('permission', '');
  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {
            isLoading ?
              <TextField
                label="Client"
                name="client"
                fullWidth
                InputProps={{
                  inputComponent: InputSkeleton
                }}
              />
              :
              <ClientField control={control} label="Client" />
          }
        </Grid>
        <Grid item xs={12}>
          {
            isLoading ?
              <TextField
                label="Application"
                name="application"
                fullWidth
                InputProps={{
                  inputComponent: InputSkeleton
                }}
              />
              :
              <ApplicationField control={control} label="Application" onChange={resetPermission} />
          }
        </Grid>
        <Grid item xs={12}>
          {
            isLoading
              ?
              <TextField
                label="Permission"
                name="permission"
                fullWidth
                InputProps={{
                  inputComponent: InputSkeleton
                }}
              />
              :
              <PermissionField applicationCode={applicationCode} label="Permission" control={control} disabled={!applicationCode} />
          }
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="tag"
            control={control}
            defaultValue=""
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                label="Tag"
                fullWidth
                InputProps={{
                  inputComponent: isLoading ? InputSkeleton : 'input'
                }}
                inputProps={{
                  'aria-label': 'tag-field',
                }}
                FormHelperTextProps={{
                  'aria-label': 'tag-field-helper-text',
                  className: classes.helperText,
                }}
                error={Boolean(error)}
                helperText={
                  (error?.message ?? '') ||
                  'Separate each tag with a “/“. Ex: /Northeast/US/California/Irvine/*'
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          {
            isLoading
              ?
              <TextField
                label="Effect"
                name="effect"
                fullWidth
                InputProps={{
                  inputComponent: InputSkeleton
                }}
              />
              :
              <EffectField label="Effect" control={control} />
          }
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default PolicyRuleFields;
import { usersApi } from '../../api';
import { Id } from '../../types/types';
import { GroupAction } from '../Groups/GroupModels';
import { User, UserAction, UserStatusActions } from './UserModels';
import { AppThunkAction } from '../rootReducer';
import { normalizeGroupList, normalizeUserList, showError, } from '../../utility';
import { alertEnqueued } from '../Alerts/AlertActions';
import { GET_GROUPS_WITH_USERS_AND_ROLES_REQUEST_SUCCESSFUL } from '../Groups/GroupActions';
import { RoleAction } from '../Roles/RoleModels';
import history from '../../history';

// Action Types
export const GET_USER_BY_ID_REQUESTED: string = 'GET_USER_BY_ID_REQUESTED';
export const GET_USER_BY_ID_REQUEST_SUCCESSFUL: string = 'GET_USER_BY_ID_REQUEST_SUCCESSFUL';
export const GET_USER_BY_ID_REQUEST_FAILED: string = 'GET_USER_BY_ID_REQUEST_FAILED';

export const GET_USER_GROUPS_BY_ID_REQUESTED: string = 'GET_USER_GROUPS_BY_ID_REQUESTED';
export const GET_USER_GROUPS_BY_ID_REQUEST_SUCCESSFUL: string = 'GET_USER_GROUPS_BY_ID_REQUEST_SUCCESSFUL';
export const GET_USER_GROUPS_BY_ID_REQUEST_FAILED: string = 'GET_USER_GROUPS_BY_ID_REQUEST_FAILED';

export const GET_USERS_REQUESTED: string = 'GET_USERS_REQUESTED';
export const GET_USERS_REQUEST_SUCCESSFUL: string = 'GET_USERS_REQUEST_SUCCESSFUL';
export const GET_USERS_REQUEST_FAILED: string = 'GET_USERS_REQUEST_FAILED';

export const GET_USERS_DETAILS_REQUESTED: string = 'GET_USERS_DETAILS_REQUESTED';
export const GET_USERS_DETAILS_REQUEST_SUCCESSFUL: string = 'GET_USERS_DETAILS_REQUEST_SUCCESSFUL';
export const GET_USERS_DETAILS_REQUEST_FAILED: string = 'GET_USERS_DETAILS_REQUEST_FAILED';

export const GET_DEPROVISIONED_USERS_REQUESTED: string = 'GET_DEPROVISIONED_USERS_REQUESTED';
export const GET_DEPROVISIONED_USERS_REQUEST_SUCCESSFUL: string = 'GET_DEPROVISIONED_USERS_REQUEST_SUCCESSFUL';
export const GET_DEPROVISIONED_USERS_REQUEST_FAILED: string = 'GET_DEPROVISIONED_USERS_REQUEST_FAILED';

export const IMPORT_OKTA_USERS_REQUESTED: string = 'IMPORT_OKTA_USERS_REQUESTED';
export const IMPORT_OKTA_USERS_REQUEST_SUCCESSFUL: string = 'IMPORT_OKTA_USERS_REQUEST_SUCCESSFUL';
export const IMPORT_OKTA_USERS_REQUEST_FAILED: string = 'IMPORT_OKTA_USERS_REQUEST_FAILED';

export const CREATE_USER_REQUESTED: string = 'CREATE_USER_REQUESTED';
export const CREATE_USER_REQUEST_SUCCESSFUL: string = 'CREATE_USER_REQUEST_SUCCESSFUL';
export const CREATE_USER_REQUEST_FAILED: string = 'CREATE_USER_REQUEST_FAILED';

export const UPDATE_USER_REQUESTED: string = 'UPDATE_USER_REQUESTED';
export const UPDATE_USER_REQUEST_SUCCESSFUL: string = 'UPDATE_USER_REQUEST_SUCCESSFUL';
export const UPDATE_USER_REQUEST_FAILED: string = 'UPDATE_USER_REQUEST_FAILED';

export const CHANGE_USERS_STATUSES_REQUESTED: string = 'CHANGE_USERS_STATUSES_REQUESTED';
export const CHANGE_USERS_STATUSES_REQUEST_SUCCESSFUL: string = 'CHANGE_USERS_STATUSES_REQUEST_SUCCESSFUL';
export const CHANGE_USERS_STATUSES_REQUEST_FAILED: string = 'CHANGE_USERS_STATUSES_REQUEST_FAILED';

export const DELETE_USER_REQUESTED: string = 'DELETE_USER_REQUESTED';
export const DELETE_USER_REQUEST_SUCCESSFUL: string = 'DELETE_USER_REQUEST_SUCCESSFUL';
export const DELETE_USER_REQUEST_FAILED: string = 'DELETE_USER_REQUEST_FAILED';

// Action Creators

/**
  Get a single user by id from the associated organization
 * @param {String} organizationCode - organization code associated with the user
 * @param {string} userId - the user id
 * @return {Function} - dispatches function response based on api results
 */
export function getUserById(organizationCode: string, userId: Id): AppThunkAction<UserAction> {
  return async (dispatch) => {
    dispatch({ id: userId, type: GET_USER_BY_ID_REQUESTED });
    try {
      const usersApiResponse = await usersApi.getById(organizationCode, userId);
      const normalizedUser = normalizeUserList([usersApiResponse]);
      dispatch({ byId: normalizedUser.byId, id: userId, type: GET_USER_BY_ID_REQUEST_SUCCESSFUL });
    } catch (err) {
      showError(err, dispatch);
      dispatch({ id: userId, type: GET_USER_BY_ID_REQUEST_FAILED });
    }
  };
}

/**
  Get a single user's groups by id from the associated organization
 * @param {String} organizationCode - organization code associated with the user
 * @param {string} userId - the user id
 * @return {Function} - dispatches function response based on api results
 */
export function getUserGroupsById(organizationCode: string, userId: Id): AppThunkAction<UserAction | GroupAction> {
  return async (dispatch) => {
    dispatch({ id: userId, type: GET_USER_GROUPS_BY_ID_REQUESTED });
    try {
      const usersGroupsApiResponse = await usersApi.getUserGroupsById(organizationCode, userId);
      const normalizedGroups = normalizeGroupList(usersGroupsApiResponse);
      dispatch({ allIds: normalizedGroups.allIds, byId: normalizedGroups.byId, type: GET_GROUPS_WITH_USERS_AND_ROLES_REQUEST_SUCCESSFUL });
      dispatch({ groupIds: normalizedGroups.allIds, id: userId, type: GET_USER_GROUPS_BY_ID_REQUEST_SUCCESSFUL });
    } catch (err) {
      showError(err, dispatch);
      dispatch({ id: userId, type: GET_USER_GROUPS_BY_ID_REQUEST_FAILED });
    }
  };
}

/**
 * Gets users associated with the current organization in context
 * @param {string} organizationCode - organization code associated with the user
 * @return {Function} - dispatches function response based on api results
 */
export function getUsers(organizationCode: string, ...callbacksOnSuccess: (() => void)[]): AppThunkAction<UserAction> {
  return async (dispatch) => {
    dispatch({ type: GET_USERS_REQUESTED });
    try {
      const users = await usersApi.getUsers(organizationCode);
      const { allIds, byId } = normalizeUserList(users);
      dispatch({ allIds, byId, type: GET_USERS_REQUEST_SUCCESSFUL });
      for (const callbackOnSuccess of callbacksOnSuccess) {
        callbackOnSuccess();
      }
    } catch (err) {
      showError(err, dispatch);
      dispatch({ type: GET_USERS_REQUEST_FAILED });
    }
  };
}

/**
 * Gets users details associated with an organization which includes groups
 * @param {String} organizationCode - organization code used get user details
 * @return {Function} - dispatches function response based on api results
 */
export function getUsersDetails(organizationCode: string): AppThunkAction<UserAction | GroupAction | RoleAction> {
  return async (dispatch) => {
    dispatch({ type: GET_USERS_DETAILS_REQUESTED });
    try {
      const getUsersWithGroupIds = await usersApi.getUsersWithGroupIds(organizationCode);
      const { allIds, byId } = normalizeUserList(getUsersWithGroupIds);
      dispatch({ allIds, byId, type: GET_USERS_DETAILS_REQUEST_SUCCESSFUL });
    } catch (err) {
      showError(err, dispatch);
      dispatch({ type: GET_USERS_DETAILS_REQUEST_FAILED });
    }
  };
}

/**
 * Gets all deprovisioned users associated with an organization
 * @param {String} organizationCode - organization code
 * @return {Function} - dispatches function response based on api results
 */
export function getDeprovisionedUsers(organizationCode: string): AppThunkAction<UserAction> {
  return async (dispatch) => {
    dispatch({ type: GET_DEPROVISIONED_USERS_REQUESTED });
    try {
      const usersApiResponse = await usersApi.getDeprovisionedUsers(organizationCode);
      const { allIds, byId } = normalizeUserList(usersApiResponse);
      dispatch({ allIds, byId, type: GET_DEPROVISIONED_USERS_REQUEST_SUCCESSFUL });
    } catch (err) {
      showError(err, dispatch);
      dispatch({ type: GET_DEPROVISIONED_USERS_REQUEST_FAILED });
    }
  };
}

/**
 * Imports users from Okta into UMA. Imports users with a valid UMA organization id in their Okta profile.
 * @param {String} organizationCode - organization code used to filter results before storing in redux
 * @return {Function} - dispatches function response based on api results
 */
export function importAllUsers(organizationCode: Id, ...callbacksOnSuccess: (() => void)[]): AppThunkAction<UserAction> {
  return async (dispatch) => {
    dispatch({ type: IMPORT_OKTA_USERS_REQUESTED });
    try {
      const usersApiResponse = await usersApi.importAllOktaUsers();
      const filteredApiResponse = usersApiResponse.filter(u => u.primaryOrganizationCode === organizationCode);
      const { allIds, byId } = normalizeUserList(filteredApiResponse);
      dispatch({ allIds, byId, type: IMPORT_OKTA_USERS_REQUEST_SUCCESSFUL });

      const alertMessage = usersApiResponse.length === 1 ?
        `${usersApiResponse.length} User has been imported` :
        `${usersApiResponse.length} Users have been imported`;
      dispatch(alertEnqueued(alertMessage, { variant: 'success' }));

      for (const callbackOnSuccess of callbacksOnSuccess) {
        callbackOnSuccess();
      }
    } catch (err) {
      showError(err, dispatch);
      dispatch({ type: IMPORT_OKTA_USERS_REQUEST_FAILED });
    }
  };
}

/**
 * Imports users from Okta into UMA. Only imports users with the given UMA organization code in their Okta profile
 * @param {String} organizationCode - organization code
 * @return {Function} - dispatches function response based on api results
 */
export function importUsersByOrganization(organizationCode: Id, ...callbacksOnSuccess: (() => void)[]): AppThunkAction<UserAction> {
  return async (dispatch) => {
    dispatch({ type: IMPORT_OKTA_USERS_REQUESTED });
    try {
      const usersApiResponse = await usersApi.importOktaUsersByOrg(organizationCode);
      const { allIds, byId } = normalizeUserList(usersApiResponse);
      dispatch({ allIds, byId, type: IMPORT_OKTA_USERS_REQUEST_SUCCESSFUL });

      const alertMessage = usersApiResponse.length === 1 ?
        `${usersApiResponse.length} User has been imported` :
        `${usersApiResponse.length} Users have been imported`;
      dispatch(alertEnqueued(alertMessage, { variant: 'success' }));

      for (const callbackOnSuccess of callbacksOnSuccess) {
        callbackOnSuccess();
      }
    } catch (err) {
      showError(err, dispatch);
      dispatch({ type: IMPORT_OKTA_USERS_REQUEST_FAILED });
    }
  };
}

/**
 * Creates a new user
 * @param {String} organizationCode - organization code used to save group
 * @param {Partial<User>} user - properties for creating new user
 * @return {Function} - dispatches function response based on api results
 */
export function createUser(organizationCode: string, user: Partial<User>): AppThunkAction<UserAction> {
  return async (dispatch) => {
    dispatch({ type: CREATE_USER_REQUESTED });
    try {
      const createUserApiResponse = await usersApi.create(organizationCode, { ...user });
      const { allIds, byId } = normalizeUserList([createUserApiResponse]);
      dispatch({ allIds, byId, type: CREATE_USER_REQUEST_SUCCESSFUL });
      dispatch(alertEnqueued('User has been created.', { variant: 'success' }));
      history.push(`/users/${createUserApiResponse.id}`);
    } catch (err) {
      showError(err, dispatch);
      dispatch({ type: CREATE_USER_REQUEST_FAILED });
    }
  };
}

/**
 * Updates an existing user
 * @param {String} organizationCode - organization code used to save group
 * @param {string} userId - the user to update
 * @param {Partial<User>} user - user properties to update
 * @param {...Function} callbacksOnSuccess - callbacks when api call was successful
 * @return {Function} - dispatches function response based on api results
 */
export function updateUser(
  organizationCode: string,
  userId: Id,
  user: Partial<User>,
  ...callbacksOnSuccess: (() => void)[]
): AppThunkAction<UserAction> {
  return async (dispatch, getState) => {
    dispatch({ id: userId, type: UPDATE_USER_REQUESTED });
    try {
      const updateUserApiResponse = await usersApi.update(organizationCode, { id: userId, ...user });
      const normalizedUser = normalizeUserList([updateUserApiResponse]);
      dispatch({ byId: normalizedUser.byId, id: userId, type: UPDATE_USER_REQUEST_SUCCESSFUL });
      const currentAuthenticatedUser = getState().my.profile.id === userId;
      dispatch(alertEnqueued(
        `${currentAuthenticatedUser ? 'Profile' : 'User'} has been updated.`, { variant: 'success' }
      ));
      for (const callbackOnSuccess of callbacksOnSuccess) {
        callbackOnSuccess();
      }
    } catch (err) {
      showError(err, dispatch);
      dispatch({ id: userId, type: UPDATE_USER_REQUEST_FAILED });
    }
  };
}

/**
 * Changes single/multiple user(s) statuses
 * @param {String} status - the status to update the users to
 * @param {Id[]} userIds - user properties to update
 * @param {Id} organizationCode - the organizationCode of the users
 * @param {...Function} callbacksOnSuccess - callbacks when api call was successful
 * @return {Function} - dispatches function response based on api results
 */
export function changeUsersStatuses(status: UserStatusActions, userIds: Id[], organizationCode: Id, ...callbacksOnSuccess: (() => void)[])
  : AppThunkAction<UserAction> {
  return async (dispatch) => {
    dispatch({ type: CHANGE_USERS_STATUSES_REQUESTED, userIds });
    try {
      const promises: Promise<GroupAction>[] = [];
      switch (status) {
        case 'activate':
          userIds.forEach((userId) => promises.push(usersApi.activate(organizationCode, userId)));
          break;

        case 'deactivate':
          userIds.forEach((userId) => promises.push(usersApi.deactivate(organizationCode, userId)));
          break;

        case 'reactivate':
          userIds.forEach((userId) => promises.push(usersApi.reactivate(organizationCode, userId)));
          break;

        case 'suspend':
          userIds.forEach((userId) => promises.push(usersApi.suspend(organizationCode, userId)));
          break;

        case 'unsuspend':
          userIds.forEach((userId) => promises.push(usersApi.unsuspend(organizationCode, userId)));
          break;

        case 'unlock':
          userIds.forEach((userId) => promises.push(usersApi.unlock(organizationCode, userId)));
          break;

        case 'resetPassword':
          userIds.forEach((userId) => promises.push(usersApi.resetpassword(organizationCode, userId)));
          break;

        default:
          return;
      }
      await Promise.all(promises);
      dispatch({ status, type: CHANGE_USERS_STATUSES_REQUEST_SUCCESSFUL, userIds });
      dispatch(alertEnqueued(`${userIds.length > 1 ? 'Users statuses have been updated.' : 'User status has been updated.'}`, { variant: 'success' }));
      for (const callbackOnSuccess of callbacksOnSuccess) {
        callbackOnSuccess();
      }
    } catch (err) {
      showError(err, dispatch);
      dispatch({ type: CHANGE_USERS_STATUSES_REQUEST_FAILED, userIds });
    }
  };
}

/**
 * Deletes an deprovisioned user permanently from OKTA and the database
 * @param {Id} organizationCode - the organization code of the user
 * @param {Id} userId - the user to update
 * @param {User} user - user object to return full name of deleted user on success
 * @param {...Function} callbacksOnSuccess - callbacks when api call was successful
 * @return {Function} - dispatches function response based on api results
 */
export function deleteUser(organizationCode: Id, userId: Id, user: User, ...callbacksOnSuccess: (() => void)[]
): AppThunkAction<UserAction> {
  return async (dispatch) => {
    dispatch({ type: DELETE_USER_REQUESTED });
    try {
      await usersApi.deleteUser(organizationCode, userId);
      dispatch({ id: userId, type: DELETE_USER_REQUEST_SUCCESSFUL });
      dispatch(alertEnqueued(
        `${user.firstName} ${user.lastName} has been permanently deleted.`, { variant: 'success' }
      ));
      for (const callbackOnSuccess of callbacksOnSuccess) {
        callbackOnSuccess();
      }
    } catch (err) {
      showError(err, dispatch);
      dispatch({ type: DELETE_USER_REQUEST_FAILED });
    }
  };
}

/* eslint-disable sort-keys */
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

const colorMap = {
  application: {
    backgroundColor: '#6A4D57 ',
    color: '#FFF',
  },
  system: {
    backgroundColor: '#54798C',
    color: '#FFF',
  },
  organization: {},
  unspecified: {}
};

const styles = makeStyles((theme: Theme) => ({
  ...colorMap,
  smallBadge: {
    fontSize: 10,
    height: 18,
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(-0.5),
    width: 18
  },
}));

export default styles;
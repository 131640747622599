import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

const styles = makeStyles((theme: Theme) => ({
  code: {
    fontSize: 16
  },
  header: {
    fontSize: 45,
    marginBottom: theme.spacing(1.5)
  },
  organizationMetaContainer: {
    display: 'inline-flex',
    flexDirection: 'column'
  }
}));

export default styles;
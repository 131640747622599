import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

const styles = makeStyles((theme: Theme) => ({
  baseBreadcrumb: {
    textDecoration: 'underline'
  },
  breadcrumbs: {
    color: theme.palette.grey[600],
    fontSize: '12px'
  },
  buttonSpacing: {
    marginRight: theme.spacing(1)
  },
  codeSkeleton: {
    marginTop: theme.spacing(0.5)
  },
  disabled: {
    '&.Mui-disabled&:hover': {
      borderColor: 'transparent'
    },
    color: '#000'
  },
  editControls: {
    marginTop: theme.spacing(0.5),
    width: 'auto'
  },
  editControlsHidden: {
    visibility: 'hidden'
  },
  iconButton: {
    border: '0.75px solid #DCDCDC',
    borderRadius: '3px',
    padding: 0
  },
  input: {
    '& + .MuiOutlinedInput-notchedOutline': {
      border: 'none'
    },
    '&:placeholder-shown': {
      '& + .MuiInputAdornment-positionEnd': {
        visibility: 'hidden'
      },
    },
    padding: 0
  },
  inputField: {
    '&:hover': {
      border: `1px solid ${theme.palette.primary.light}`
    },
    border: '1px solid transparent',
  },
  nameField: {
    fontSize: 30
  },
  oktaField: {
    fontSize: 20
  },
  notchedOutline: {
    border: 0
  },
  policyContainer: {
    background: `${theme.palette.grey[100]}50`,
    borderRadius: 4,
    margin: theme.spacing(4, 0),
    padding: theme.spacing(2)
  },
  policyModel: {
    marginLeft: theme.spacing(0.5),
    whiteSpace: 'pre-wrap'
  },
  subtitle: {
    fontWeight: 600,
    marginBottom: theme.spacing(0.5)
  }
}));

export default styles;
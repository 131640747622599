import React, { useEffect, useState } from 'react';
import {
  Box,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Paper,
  TablePagination,
  Tooltip,
  Skeleton,
  Typography
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import FilterListItem from './FilterListItem';
import SearchInput from '../SearchInput/SearchInput';
import { User } from '../../store/Users/UserModels';
import { Role } from '../../store/Roles/RoleModels';
import { Group } from '../../store/Groups/GroupModels';
import { Organization, OrganizationDomain } from '../../store/Organizations/OrganizationModels';
import { Application, Permission } from '../../store/Applications/ApplicationModels';
import {
  findOrCreateKey,
  normalizeStrings,
  singularizeResource,
  toApplicationMask,
  toGroupMask,
  toOrganizationMask,
  toRoleMask,
  toTitleCase,
  toUserMask
} from '../../utility';
import { Id, ResourceNames, Resource } from '../../types/types';
import useStyles from './styles';
import { PolicyRequest } from '../../store/My/MyModels';
import If from '../If/If';
import { useCasbin } from '../../hooks/useCasbin';
import { PAGE_SIZE_OPTIONS } from '../../constants/pageSizeConstants';

interface ActionItemProps {
  // If resource action is immutable, the button is hidden
  immutable?: boolean;

  // Required Casbin policy
  requiredPolicy?: PolicyRequest;

  // Casbin policy function
  policyFunction?: (...args: any) => PolicyRequest;

  // Params to be used with function above
  policyFunctionParams?: Id[];
}

interface AddItemProps extends ActionItemProps {
  handleAddClick: () => void;
}

interface RemoveItemProps extends ActionItemProps {
  handleDeleteClick: (id: Id) => void;
}

interface EditItemProps extends ActionItemProps {
  handleEditClick: (id: Id) => void;
}

interface ReadItemProps {
  // Required casbin policy
  requiredPolicy?: PolicyRequest;

  // Casbin policy function
  policyFunction?: (...args: any) => PolicyRequest;

  // Params for the function
  policyFunctionParams?: Id[];
}

interface FilterListProps {
  addItemProps: AddItemProps;
  parentResourceName: ResourceNames;
  isLoading: boolean;
  listResourceName: ResourceNames;
  listResources: Resource[];
  onCreateResourcePage?: boolean;
  removeItemProps: RemoveItemProps;
  editItemProps?: EditItemProps;
  readItemProps?: ReadItemProps;
  rowsPerPageInput?: number;
  rowsPerPageOptionInput?: number[];
}

function FilterList({
  addItemProps,
  parentResourceName,
  isLoading = false,
  listResourceName,
  listResources = [],
  onCreateResourcePage = false,
  removeItemProps,
  editItemProps,
  readItemProps,
  rowsPerPageInput,
  rowsPerPageOptionInput
}: FilterListProps) {
  const classes = useStyles();
  const [filterText, setFilterText] = useState<string>('');
  const [filteredResources, setFilteredResources] =
    useState<Resource[]>(listResources);
  const [page, setPage] = useState<number>(0);

  const [rowsPerPage, setRowsPerPage] = useState<number>(rowsPerPageInput ?? 50);
  const rowsPerPageOptions = rowsPerPageOptionInput ?? PAGE_SIZE_OPTIONS;
  const { handleAddClick } = addItemProps;

  useEffect(() => {
    if (filterText.length === 0) {
      setFilteredResources(listResources);
    } else {
      switch (listResourceName) {
        case 'users':
          setFilteredResources((listResources as User[]).filter((user: User) => toUserMask(user).includes(filterText)));
          break;
        case 'roles':
          setFilteredResources((listResources as Role[]).filter((role: Role) => toRoleMask(role).includes(filterText)));
          break;
        case 'groups':
          setFilteredResources((listResources as Group[]).filter((group: Group) => toGroupMask(group).includes(filterText)));
          break;
        case 'organizations':
          setFilteredResources((listResources as Organization[]).filter((organization: Organization) => toOrganizationMask(organization).includes(filterText)));
          break;
        case 'applications':
          setFilteredResources((listResources as Application[]).filter((application: Application) => toApplicationMask(application).includes(filterText)));
          break;
        case 'permissions':
          setFilteredResources((listResources as Permission[]).filter((permission: Permission) => permission.includes(filterText)));
          break;
        case 'domains':
          setFilteredResources((listResources as OrganizationDomain[]).filter((domain: OrganizationDomain) => domain.name.includes(filterText)));
          break;
      }
    }
  }, [listResourceName, listResources, filterText]);

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => setFilterText(normalizeStrings(event.target.value));

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const addPermitted = useCasbin(addItemProps.requiredPolicy);

  const renderResults = () => {
    const singularResource = singularizeResource(parentResourceName);
    // Create page state
    if (onCreateResourcePage) {
      return (
        <ListItem disableGutters divider className={classes.noAssignedResources}>
          <Typography variant="body2" noWrap>{`Create ${singularResource} first prior to assigning ${listResourceName}`}</Typography>
        </ListItem>
      );
    }
    // Loading state
    if (filterText === '' && isLoading) {
      return (
        <>
          <ListItem disableGutters divider className={classes.listItem}>
            <Skeleton role="loading-skeleton" width="100%" height={60} animation="wave" />
          </ListItem>
          <ListItem disableGutters divider className={classes.listItem}>
            <Skeleton role="loading-skeleton" width="100%" height={60} animation="wave" />
          </ListItem>
          <ListItem disableGutters divider className={classes.listItem}>
            <Skeleton role="loading-skeleton" width="100%" height={60} animation="wave" />
          </ListItem>
        </>
      );

      // Currently no resources assigned
    } else if (filteredResources.length === 0 && filterText === '' && !isLoading) {
      // Able to assign if user meets permission requirements and resource isn't deemed immutable
      const ableToAssignResource = !addItemProps.immutable && addPermitted;
      return (
        <ListItem disableGutters divider className={classes.noAssignedResources}>
          <Typography variant="body2" role="message">
            {
              ableToAssignResource ? `Start assigning ${listResourceName} to ${singularResource}`
                : `There are currently no ${listResourceName} assigned to the ${singularResource}`
            }
          </Typography>
        </ListItem>
      );

      // No results from search filter state
    } else if (filteredResources.length === 0 && filterText !== '') {
      return (
        <ListItem disableGutters divider className={classes.noResults}>
          <Typography noWrap role="message">{`No ${listResourceName} matching "${filterText}"`}</Typography>
        </ListItem>
      );

      // Show assigned resources
    } else {
      return (
        (filteredResources as (Resource)[])
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((resource: Resource) => {
            const key = findOrCreateKey(resource, listResourceName);
            return (
              <FilterListItem
                key={key}
                resourceId={key}
                listResourceName={listResourceName}
                resource={resource}
                removeItemProps={removeItemProps}
                editItemProps={editItemProps}
                readItemProps={readItemProps}
              />
            );
          })
      );
    }
  };

  const assignedResources = Boolean(listResources.length) && !isLoading;
  return (
    <Paper className={classes.filterList}>
      <List
        classes={{
          padding: classes.listPadding
        }}
      >
        <ListSubheader
          classes={{
            gutters: classes.subheaderGutters,
            root: classes.titleSubheader
          }}
        >
          <Grid item container alignItems="center" justifyContent="center">
            <Grid item container alignItems="center" xs={6}>
              <Typography aria-label="list-title" variant="h6" display="inline" className={classes.title}>
                {toTitleCase(listResourceName)}
              </Typography>
              <Typography aria-label="list-count" variant="body2" display="inline">
                {(isLoading || onCreateResourcePage) ? '(--)' : `(${listResources.length})`}
              </Typography>
            </Grid>
            <Grid xs={6} item container alignItems="center" justifyContent="flex-end">
              <If condition={addPermitted && !addItemProps.immutable}>
                <Tooltip title={`Assign ${listResourceName}`} placement="bottom-end">
                  <> {/* Have to have a fragment here to make mui happy w/out breaking styles */}
                    <IconButton
                      aria-label="add-resource-button"
                      color="primary"
                      onClick={handleAddClick}
                      size="small"
                      disabled={isLoading || onCreateResourcePage}
                      style={{ padding: 0 }}
                    >
                      <AddIcon />
                    </IconButton>
                  </>
                </Tooltip>
              </If>
            </Grid>
          </Grid>
        </ListSubheader>
        <Divider />
        {/* Only display search when there are assigned resources */}
        <If condition={assignedResources}>
          <ListSubheader
            classes={{
              gutters: classes.subheaderGutters,
              root: classes.searchSubheader
            }}
          >
            <Box py={2.5}>
              <SearchInput
                onChange={handleFilterChange}
                onReset={() => setFilterText('')}
                placeholder={`Search ${toTitleCase(listResourceName)}`}
              />
            </Box>
          </ListSubheader>
          <Divider />
        </If>
        {renderResults()}
        {/* Only display pagination when there are assigned resources */}
        <If condition={assignedResources}>
          <ListItemText>
            <TablePagination
              component="div"
              count={filteredResources.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={rowsPerPageOptions}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </ListItemText>
        </If>
      </List>
    </Paper>
  );
}

export default FilterList;
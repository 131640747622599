import React from 'react';
import { Container } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import useStyles from './styles';

export const Unauthorized = () => {
  const classes = useStyles();
  return (
    <Container className={classes.container}>
      <div className={classes.ellipse}>
        <LockIcon style={{ fill: '#013A6F' }} fontSize="inherit" className={classes.icon} />
      </div>
      <div className={classes.title}>Unauthorized User</div>
      <div className={classes.body}>
				You currently don&apos;t have the permissions to access this part of the application.<br />
				If you feel this is a mistake, please contact support so that you can get assigned the necessary roles.
      </div>
    </Container>
  );
};
export default Unauthorized;
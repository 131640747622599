import { AppThunkAction } from '../rootReducer';
import { GroupAction, Group } from './GroupModels';
import { groupsApi } from '../../api';
import {
  findNonUbiquitousIds,
  normalizeGroupList,
  normalizeRoleList,
  normalizeUserList,
  showError,
  toFullName
} from '../../utility';
import { User, UserAction } from '../Users/UserModels';
import { alertEnqueued } from '../Alerts/AlertActions';
import { Role, RoleAction } from '../Roles/RoleModels';
import { Id } from '../../types/types';
import history from '../../history';
import { GET_ROLES_REQUEST_SUCCESSFUL } from '../Roles/RoleActions';
import { GET_USERS_REQUEST_SUCCESSFUL } from '../Users/UserActions';

// Action Types

export const GET_GROUP_BY_ID_REQUESTED: string = 'GET_GROUP_BY_ID_REQUESTED';
export const GET_GROUP_BY_ID_REQUEST_SUCCESSFUL: string = 'GET_GROUP_BY_ID_REQUEST_SUCCESSFUL';
export const GET_GROUP_BY_ID_REQUEST_FAILED: string = 'GET_GROUP_BY_ID_REQUEST_FAILED';

export const GET_GROUPS_REQUESTED: string = 'GET_GROUPS_REQUESTED';
export const GET_GROUPS_REQUEST_SUCCESSFUL: string = 'GET_GROUPS_REQUEST_SUCCESSFUL';
export const GET_GROUPS_REQUEST_FAILED: string = 'GET_GROUPS_REQUEST_FAILED';

export const GET_GROUPS_WITH_USERS_AND_ROLES_REQUESTED: string = 'GET_GROUPS_WITH_USERS_AND_ROLES_REQUESTED';
export const GET_GROUPS_WITH_USERS_AND_ROLES_REQUEST_SUCCESSFUL: string = 'GET_GROUPS_WITH_USERS_AND_ROLES_REQUEST_SUCCESSFUL';
export const GET_GROUPS_DETAILS_REQUEST_FAILED: string = 'GET_GROUPS_WITH_USERS_AND_ROLES_REQUEST_FAILED';

export const DELETE_GROUPS_REQUESTED: string = 'DELETE_GROUPS_REQUESTED';
export const DELETE_GROUPS_REQUEST_SUCCESSFUL: string = 'DELETE_GROUPS_REQUEST_SUCCESSFUL';
export const DELETE_GROUPS_REQUEST_FAILED: string = 'DELETE_GROUPS_REQUEST_FAILED';

export const ADD_ROLES_TO_GROUPS_REQUESTED: string = 'ADD_ROLES_TO_GROUPS_REQUESTED';
export const ADD_ROLES_TO_GROUPS_REQUEST_SUCCESSFUL: string = 'ADD_ROLES_TO_GROUPS_REQUEST_SUCCESSFUL';
export const ADD_ROLES_TO_GROUPS_REQUEST_FAILED: string = 'ADD_ROLES_TO_GROUPS_REQUEST_FAILED';

export const ADD_USERS_TO_GROUPS_REQUESTED: string = 'ADD_USERS_TO_GROUPS_REQUESTED';
export const ADD_USERS_TO_GROUPS_REQUEST_SUCCESSFUL: string = 'ADD_USERS_TO_GROUPS_REQUEST_SUCCESSFUL';
export const ADD_USERS_TO_GROUPS_REQUEST_FAILED: string = 'ADD_USERS_TO_GROUPS_REQUEST_FAILED';

export const REMOVE_ROLES_FROM_GROUP_REQUESTED: string = 'REMOVE_ROLES_FROM_GROUP_REQUESTED';
export const REMOVE_ROLES_FROM_GROUP_REQUEST_SUCCESSFUL: string = 'REMOVE_ROLES_FROM_GROUP_REQUEST_SUCCESSFUL';
export const REMOVE_ROLES_FROM_GROUP_REQUEST_FAILED: string = 'REMOVE_ROLES_FROM_GROUP_REQUEST_FAILED';

export const REMOVE_USERS_FROM_GROUP_REQUESTED: string = 'REMOVE_USERS_FROM_GROUP_REQUESTED';
export const REMOVE_USERS_FROM_GROUP_REQUEST_SUCCESSFUL: string = 'REMOVE_USERS_FROM_GROUP_REQUEST_SUCCESSFUL';
export const REMOVE_USERS_FROM_GROUP_REQUEST_FAILED: string = 'REMOVE_USERS_FROM_GROUP_REQUEST_FAILED';

export const CREATE_GROUP_REQUESTED: string = 'CREATE_GROUP_REQUESTED';
export const CREATE_GROUP_REQUEST_SUCCESSFUL: string = 'CREATE_GROUP_REQUEST_SUCCESSFUL';
export const CREATE_GROUP_REQUEST_FAILED: string = 'CREATE_GROUP_REQUEST_FAILED';

export const UPDATE_GROUP_NAME_REQUESTED: string = 'UPDATE_GROUP_NAME_REQUESTED';
export const UPDATE_GROUP_NAME_REQUEST_SUCCESSFUL: string = 'UPDATE_GROUP_NAME_REQUEST_SUCCESSFUL';
export const UPDATE_GROUP_NAME_REQUEST_FAILED: string = 'UPDATE_GROUP_NAME_REQUEST_FAILED';

// Action Creators

/**
 * Get a single group's detail by id from the associated organization which includes users/roles
 * @param {String} organizationCode - organization code used get group details
 * @param {string} groupId - the group id
 * @return {Function} - dispatches function response based on api results
 */
export function getGroupWithUsersAndRolesById(organizationCode: string, groupId: Id): AppThunkAction<GroupAction | UserAction | RoleAction> {
  return async (dispatch) => {
    dispatch({ id: groupId, type: GET_GROUP_BY_ID_REQUESTED });
    try {
      const groupWithUsersAndRolesApiResponse = await groupsApi.getWithUsersAndRolesById(organizationCode, groupId);
      const { roles, users } = groupWithUsersAndRolesApiResponse;
      groupWithUsersAndRolesApiResponse.roleIds = groupWithUsersAndRolesApiResponse.roles?.map(r => r.id) || [];
      groupWithUsersAndRolesApiResponse.userIds = groupWithUsersAndRolesApiResponse.users?.map(u => u.id) || [];

      const normalizedGroup = normalizeGroupList([groupWithUsersAndRolesApiResponse]);
      dispatch({ byId: normalizedGroup.byId, id: groupId, type: GET_GROUP_BY_ID_REQUEST_SUCCESSFUL });

      if (roles) dispatch({ ...normalizeRoleList(roles), type: GET_ROLES_REQUEST_SUCCESSFUL });

      if (users) dispatch({ ...normalizeUserList(users), type: GET_USERS_REQUEST_SUCCESSFUL });

    } catch (err) {
      showError(err, dispatch);
      dispatch({ id: groupId, type: GET_GROUP_BY_ID_REQUEST_FAILED });
    }
  };
}

/**
 * Gets groups from the associated organization without associated users/roles
 * @param {String} organizationCode - organization code used get group details
 * @return {Function} - dispatches function response based on api results
 */
export function getGroups(organizationCode: string, ...callbacksOnSuccess: (() => void)[]): AppThunkAction<GroupAction> {
  return async (dispatch) => {
    dispatch({ type: GET_GROUPS_REQUESTED });
    try {
      const groupsApiResponse = await groupsApi.getByOrg(organizationCode);
      const normalizedGroups = normalizeGroupList(groupsApiResponse);
      dispatch({ allIds: normalizedGroups.allIds, byId: normalizedGroups.byId, type: GET_GROUPS_REQUEST_SUCCESSFUL });
      for (const callbackOnSuccess of callbacksOnSuccess) {
        callbackOnSuccess();
      }
    } catch (err) {
      showError(err, dispatch);
      dispatch({ type: GET_GROUPS_REQUEST_FAILED });
    }
  };
}

/**
 * Gets groups details from the associated organization which includes users/roles
 * @param {String} organizationCode - organization code used get group details
 * @return {Function} - dispatches function response based on api results
 */
export function getGroupsByOrgWithUsersAndRoles(organizationCode: string): AppThunkAction<GroupAction | UserAction | RoleAction> {
  return async (dispatch) => {
    dispatch({ type: GET_GROUPS_WITH_USERS_AND_ROLES_REQUESTED });
    try {
      const groupsWithUsersAndRolesApiResponse = (await groupsApi.getByOrgWithUsersAndRoles(organizationCode))
        .map(g => {
          g.roleIds = g.roles?.map(r => r.id) || [];
          g.userIds = g.users?.map(u => u.id) || [];
          return g;
        });

      const normalizedGroups = normalizeGroupList(groupsWithUsersAndRolesApiResponse);
      dispatch({ allIds: normalizedGroups.allIds, byId: normalizedGroups.byId, type: GET_GROUPS_WITH_USERS_AND_ROLES_REQUEST_SUCCESSFUL });

      const roles = groupsWithUsersAndRolesApiResponse.reduce<Role[]>((acc, g) => [...acc, ...g.roles || []], []);
      dispatch({ ...normalizeRoleList(roles), type: GET_ROLES_REQUEST_SUCCESSFUL });

      const users = groupsWithUsersAndRolesApiResponse.reduce<User[]>((acc, g) => [...acc, ...g.users || []], []);
      dispatch({ ...normalizeUserList(users), type: GET_USERS_REQUEST_SUCCESSFUL });
    } catch (err) {
      showError(err, dispatch);
      dispatch({ type: GET_GROUPS_DETAILS_REQUEST_FAILED });
    }
  };
}

/**
 * Creates a single group for an organization
 * @param {String} organizationCode - organization code used to create group
 * @param {Partial<Group>} group - the name of the group to save
 * @return {Function} - dispatches function response based on api results
 */
export function createGroup(organizationCode: string, group: Partial<Group>): AppThunkAction<GroupAction> {
  return async (dispatch, getState) => {
    dispatch({ type: CREATE_GROUP_REQUESTED });
    try {
      group.organizationId = getState().organizations.byId[organizationCode].id;
      const result = await groupsApi.create(organizationCode, group);
      const { allIds, byId } = normalizeGroupList([result]);
      dispatch({ allIds, byId, type: CREATE_GROUP_REQUEST_SUCCESSFUL });
      dispatch(alertEnqueued('Group has been created.', { variant: 'success' }));
      history.push(`/groups/${result.id}`);
    } catch (err) {
      showError(err, dispatch);
      dispatch({ type: CREATE_GROUP_REQUEST_FAILED });
    }
  };
}

/** 
 * Updates a group name
 * @param {String} organizationCode - organization code used to save group
 * @param {Partial<Group>} group - the group to update
 * @param {...Function} callbacksOnSuccess - callbacks when api call was successful
 * @return {Function} - dispatches function response based on api results
 */
export function updateGroup(
  organizationCode: string,
  group: Partial<Group>,
  ...callbacksOnSuccess: (() => void)[]
): AppThunkAction<GroupAction> {
  return async (dispatch, getState) => {
    const groupId = group.id;
    dispatch({ id: groupId, type: UPDATE_GROUP_NAME_REQUESTED });
    try {
      group.organizationId = getState().organizations.byId[organizationCode].id;
      const result = await groupsApi.update(organizationCode, group);
      dispatch({ id: groupId, name: result.name, type: UPDATE_GROUP_NAME_REQUEST_SUCCESSFUL });
      dispatch(alertEnqueued('Group has been updated.', { variant: 'success' }));
      for (const callbackOnSuccess of callbacksOnSuccess) {
        callbackOnSuccess();
      }
    } catch (err) {
      showError(err, dispatch);
      dispatch({ id: groupId, type: UPDATE_GROUP_NAME_REQUEST_FAILED });
    }
  };
}

/**
 * Deletes single/multiple groups from an organization
 * @param {String} organizationCode - organization code used to delete groups
 * @param {Id[]} groupIds - an array of group ids to delete
 * @param {...Function} callbacksOnSuccess - callbacks when api call was successful
 * @return {Function} - dispatches function response based on api results
 */
export function deleteGroups(organizationCode: string, groupIds: Id[], ...callbacksOnSuccess: (() => void)[]): AppThunkAction<GroupAction> {
  return async (dispatch) => {
    dispatch({ type: DELETE_GROUPS_REQUESTED });
    try {
      const promises = groupIds.map((groupId) => groupsApi.deleteGroup(organizationCode, groupId));
      await Promise.all(promises);
      dispatch({ groupIds, type: DELETE_GROUPS_REQUEST_SUCCESSFUL });
      dispatch(alertEnqueued(`
				Group${groupIds.length > 1 ? 's have ' : ' has '} been deleted.
			`, { variant: 'success' }));
      for (const callbackOnSuccess of callbacksOnSuccess) {
        callbackOnSuccess();
      }
    } catch (err) {
      showError(err, dispatch);
      dispatch({ type: DELETE_GROUPS_REQUEST_FAILED });
    }
  };
}

/**
 * Adds single/multiple role(s) to single/multiple group(s) from an organization
 * @param {String} organizationCode - organization code used to add users to groups
 * @param {Id[]} groupIds - an array of group ids
 * @param {Id[]} roleIds - an array of role ids
 * @param {...Function} callbacksOnSuccess - callbacks when api call was successful
 * @return {Function} - dispatches function response based on api results
 */
export function addRolesToGroups(
  organizationCode: string,
  groupIds: Id[],
  roleIds: Id[],
  ...callbacksOnSuccess: (() => void)[]
): AppThunkAction<GroupAction> {
  return async (dispatch, getState) => {
    dispatch({ type: ADD_ROLES_TO_GROUPS_REQUESTED });
    try {
      const groupsById = getState().groups.byId;
      const rolesById = getState().roles.byId;
      const promises: Promise<GroupAction>[] = [];
      groupIds.forEach((groupId) => promises.push(groupsApi.addRoles(organizationCode, groupId, roleIds)));
      await Promise.all(promises);

      const newGroupIds = findNonUbiquitousIds(groupIds, roleIds.map((id) => rolesById[id].groupIds ?? []));
      const newRoleIds = findNonUbiquitousIds(roleIds, groupIds.map((id) => groupsById[id].roleIds ?? []));

      // Form singular/plural message strings
      const rolesMessage = newRoleIds.length > 1 ? `${newRoleIds.length} roles` : `${rolesById[newRoleIds[0]].name} role`;
      const groupsMessage = newGroupIds.length > 1 ? `${newGroupIds.length} groups` : `${groupsById[newGroupIds[0]].name || 'group'}`;

      dispatch({ groupIds, roleIds, type: ADD_ROLES_TO_GROUPS_REQUEST_SUCCESSFUL });
      dispatch(alertEnqueued(
        `${newRoleIds.length > 1 ?
          `${rolesMessage} have been added to ${groupsMessage}.` :
          `${rolesMessage} has been added to ${groupsMessage}.`
        }`, { variant: 'success' }));
      for (const callbackOnSuccess of callbacksOnSuccess) {
        callbackOnSuccess();
      }
    } catch (err) {
      showError(err, dispatch);
      dispatch({ type: ADD_ROLES_TO_GROUPS_REQUEST_FAILED });
    }
  };
}

/**
 * Removes a single/multiple roles from a group affiliated with an organization
 * @param {String} organizationCode - organization code used to remove a role from a group
 * @param {Id} groupId - a group id
 * @param {Id[]} roleIds - an array of role ids
 * @param {...Function} callbacksOnSuccess - callbacks when api call was successful
 * @return {Function} - dispatches function response based on api results
 */
export function removeRolesFromGroup(
  organizationCode: string,
  groupId: Id,
  roleIds: Id[],
  ...callbacksOnSuccess: (() => void)[]
): AppThunkAction<GroupAction> {
  return async (dispatch, getState) => {
    dispatch({ type: REMOVE_ROLES_FROM_GROUP_REQUESTED });
    try {
      await groupsApi.removeRoles(organizationCode, groupId, roleIds);
      dispatch({ groupId, roleIds, type: REMOVE_ROLES_FROM_GROUP_REQUEST_SUCCESSFUL });

      // Form singular/plural message strings
      const rolesMessage = roleIds.length > 1 ? `${roleIds.length} roles` : `${getState().roles.byId[roleIds[0]].name} role`;
      const groupsMessage = getState().groups.byId[groupId].name ?? 'group';
      dispatch(alertEnqueued(
        `${roleIds.length > 1 ?
          `${rolesMessage} have been removed from ${groupsMessage}.` :
          `${rolesMessage} has been removed from ${groupsMessage}.`
        }`, { variant: 'success' }));
      for (const callbackOnSuccess of callbacksOnSuccess) {
        callbackOnSuccess();
      }
    } catch (err) {
      showError(err, dispatch);
      dispatch({ type: REMOVE_ROLES_FROM_GROUP_REQUEST_FAILED });
    }
  };
}

/**
 * Adds single/multiple user(s) to single/multiple group(s) from an organization
 * @param {String} organizationCode - organization code used to add users to groups
 * @param {Id[]} groupIds - an array of group ids
 * @param {Id[]} userIds - an array of user ids
 * @param {...Function} callbacksOnSuccess - callbacks when api call was successful
 * @return {Function} - dispatches function response based on api results
 */
export function addUsersToGroups(
  organizationCode: string,
  groupIds: Id[],
  userIds: Id[],
  ...callbacksOnSuccess: (() => void)[]
): AppThunkAction<GroupAction> {
  return async (dispatch, getState) => {
    dispatch({ type: ADD_USERS_TO_GROUPS_REQUESTED });
    try {
      const groupsById = getState().groups.byId;
      const usersById = getState().users.byId;
      const promises: Promise<GroupAction>[] = [];
      groupIds.forEach((groupId) => promises.push(groupsApi.addUsers(organizationCode, groupId, userIds)));
      await Promise.all(promises);

      const newGroupIds = findNonUbiquitousIds(groupIds, userIds.map((id) => usersById[id].groupIds ?? []));
      const newUserIds = findNonUbiquitousIds(userIds, groupIds.map((id) => groupsById[id].userIds ?? []));

      // Form singular/plural message strings
      const usersMessage = newUserIds.length > 1 ? `${newUserIds.length} users` : `${toFullName(usersById[newUserIds[0]]) || 'User'}`;
      const groupsMessage = newGroupIds.length > 1 ? `${newGroupIds.length} groups` : `${groupsById[newGroupIds[0]].name || 'group'}`;
      dispatch({ groupIds, type: ADD_USERS_TO_GROUPS_REQUEST_SUCCESSFUL, userIds });
      dispatch(alertEnqueued(
        `${newUserIds.length > 1 ?
          `${usersMessage} have been added to ${groupsMessage}.` :
          `${usersMessage} has been added to ${groupsMessage}.`
        }`, { variant: 'success' }));
      for (const callbackOnSuccess of callbacksOnSuccess) {
        callbackOnSuccess();
      }
    } catch (err) {
      showError(err, dispatch);
      dispatch({ type: ADD_USERS_TO_GROUPS_REQUEST_FAILED });
    }
  };
}

/**
 * Removes single/multiple user(s) from a group affiliated with an organization
 * @param {String} organizationCode - organization code used to remove users a group
 * @param {Id} groupId - a group id
 * @param {Id[]} userIds - an array of user ids
 * @param {...Function} callbacksOnSuccess - callbacks when api call was successful
 * @return {Function} - dispatches function response based on api results
 */
export function removeUsersFromGroup(
  organizationCode: string,
  groupId: Id,
  userIds: Id[],
  ...callbacksOnSuccess: (() => void)[]
): AppThunkAction<GroupAction> {
  return async (dispatch, getState) => {
    dispatch({ type: REMOVE_USERS_FROM_GROUP_REQUESTED });
    try {
      await groupsApi.removeUsers(organizationCode, groupId, userIds);
      dispatch({ groupId, type: REMOVE_USERS_FROM_GROUP_REQUEST_SUCCESSFUL, userIds });

      // Form singular/plural message strings
      const usersMessage = userIds.length > 1 ? `${userIds.length} users` : `${toFullName(getState().users.byId[userIds[0]]) || 'User'}`;
      const groupsMessage = getState().groups.byId[groupId].name ?? 'group';
      dispatch(alertEnqueued(
        `${userIds.length > 1 ?
          `${usersMessage} have been removed from ${groupsMessage}.` :
          `${usersMessage} has been removed from ${groupsMessage}.`
        }`, { variant: 'success' }));
      for (const callbackOnSuccess of callbacksOnSuccess) {
        callbackOnSuccess();
      }
    } catch (err) {
      showError(err, dispatch);
      dispatch({ type: REMOVE_USERS_FROM_GROUP_REQUEST_FAILED });
    }
  };
}

import { Application } from '../store/Applications/ApplicationModels';
import { Client } from '../store/Clients/ClientModels';
import { Group } from '../store/Groups/GroupModels';
import { Organization } from '../store/Organizations/OrganizationModels';
import { Role } from '../store/Roles/RoleModels';
import { User } from '../store/Users/UserModels';
import { ById, CollectionState, Id } from '../types/types';

/**
 * Return allId/byId for list.
 * @param {T[]} list - an array of items
 * @param {string} idKey - the name of the property to treat as an id
 * @return {CollectionState<T>} an object containing fields for allIds/byId
 */
export function normalizeList<T, K extends keyof T>(list: T[], idKey: K): CollectionState<T> {
  const allIds: Id[] = [];
  const byId: ById<T> = {};
  list.forEach((item: T) => {
    // @ts-ignore
    allIds.push(item[idKey]);
    // @ts-ignore
    byId[item[idKey]] = item;
  });
  return { allIds, byId };
}

/**
 * Returns `Client`s normalized into allId/byId format.
 * @param {Client[]} list an array of `Client`s
 * @return {CollectionState<Client>} an object containing `Client`s using allIds/byId format
 */
export function normalizeClientList(list: Client[]): CollectionState<Client> {
  return normalizeList(list, 'code');
}

/**
 * Returns `Group`s normalized into allId/byId format.
 * @param {Group[]} list an array of `Group`s
 * @return {CollectionState<Group> } an object containing `Group`s using allIds/byId format
 */
export function normalizeGroupList(list: Group[]): CollectionState<Group> {
  return normalizeList(list, 'id');
}

/**
 * Returns `Organization`s normalized into allId/byId format.
 * @param {Organization[]} list an array of `Organization`s
 * @return {CollectionState<Organization>} an object containing `Organization`s using allIds/byId format
 */
export function normalizeOrganizationList(list: Organization[]): CollectionState<Organization> {
  return normalizeList(list, 'code');
}

/**
 * Returns `Application`s normalized into allId/byId format.
 * @param {Application[]} list an array of `Application`s
 * @return {CollectionState<Application>} an object containing `Application`s using allIds/byId format
 */
export function normalizeApplicationList(list: Application[]): CollectionState<Application> {
  return normalizeList(list, 'code');
}

/**
 * Returns `Role`s normalized into allId/byId format.
 * @param {Role[]} list an array of `Role`s
 * @return {CollectionState<Role>} an object containing `Role`s using allIds/byId format
 */
export function normalizeRoleList(list: Role[]): CollectionState<Role> {
  return normalizeList(list, 'id');
}

/**
 * Returns `User`s normalized into allId/byId format.
 * @param {User[]} list an array of `User`s
 * @return {CollectionState<User>} an object containing `User`s using allIds/byId format
 */
export function normalizeUserList(list: User[]): CollectionState<User> {
  return normalizeList(list, 'id');
}

import { organizationsApi } from '../../api';
import { OrganizationAction, OrganizationDomain, Organization } from './OrganizationModels';
import { AppThunkAction } from '../rootReducer';
import { ApplicationAction } from '../Applications/ApplicationModels';
import { GET_APPLICATIONS_REQUEST_SUCCESSFUL } from '../Applications/ApplicationActions';
import { FILTER_ORGANIZATION_ROLES } from '../Roles/RoleActions';
import {
  findNonUbiquitousIds,
  normalizeApplicationList,
  normalizeOrganizationList,
  showError
} from '../../utility';
import { alertEnqueued } from '../Alerts/AlertActions';
import { Id } from '../../types/types';
import { ClientAction } from '../Clients/ClientModels';

// Action Types
export const GET_ORGANIZATIONS_REQUESTED: string = 'GET_ORGANIZATIONS_REQUESTED';
export const GET_ORGANIZATIONS_REQUEST_SUCCESSFUL: string = 'GET_ORGANIZATIONS_REQUEST_SUCCESSFUL';
export const GET_ORGANIZATIONS_REQUEST_FAILED: string = 'GET_ORGANIZATIONS_REQUEST_FAILED';

export const GET_ORGANIZATIONS_WITH_CLIENTS_REQUESTED: string = 'GET_ORGANIZATIONS_WITH_CLIENTS_REQUESTED';
export const GET_ORGANIZATIONS_WITH_CLIENTS_REQUEST_SUCCESSFUL: string = 'GET_ORGANIZATIONS_WITH_CLIENTS_REQUEST_SUCCESSFUL';
export const GET_ORGANIZATIONS_WITH_CLIENTS_REQUEST_FAILED: string = 'GET_ORGANIZATIONS_WITH_CLIENTS_REQUEST_FAILED';

export const GET_ORGANIZATION_BY_CODE_REQUESTED: string = 'GET_ORGANIZATION_BY_CODE_REQUESTED';
export const GET_ORGANIZATION_BY_CODE_REQUEST_SUCCESSFUL: string = 'GET_ORGANIZATION_BY_CODE_REQUEST_SUCCESSFUL';
export const GET_ORGANIZATION_BY_CODE_REQUEST_FAILED: string = 'GET_ORGANIZATION_BY_CODE_REQUEST_FAILED';

export const GET_APPLICATIONS_FOR_ORGANIZATION_BY_CODE_REQUESTED: string = 'GET_APPLICATIONS_FOR_ORGANIZATION_BY_CODE_REQUESTED';
export const GET_APPLICATIONS_FOR_ORGANIZATION_BY_CODE_REQUEST_SUCCESSFUL: string = 'GET_APPLICATIONS_FOR_ORGANIZATION_BY_CODE_REQUEST_SUCCESSFUL';
export const GET_APPLICATIONS_FOR_ORGANIZATION_BY_CODE_REQUEST_FAILED: string = 'GET_APPLICATIONS_FOR_ORGANIZATION_BY_CODE_REQUEST_FAILED';

export const ADD_APPLICATIONS_TO_ORGANIZATION_REQUESTED: string = 'ADD_APPLICATIONS_TO_ORGANIZATION_REQUESTED';
export const ADD_APPLICATIONS_TO_ORGANIZATION_REQUEST_SUCCESSFUL: string = 'ADD_APPLICATIONS_TO_ORGANIZATION_REQUEST_SUCCESSFUL';
export const ADD_APPLICATIONS_TO_ORGANIZATION_REQUEST_FAILED: string = 'ADD_APPLICATIONS_TO_ORGANIZATION_REQUEST_FAILED';

export const REMOVE_APPLICATIONS_FROM_ORGANIZATION_REQUESTED: string = 'REMOVE_APPLICATIONS_FROM_ORGANIZATION_REQUESTED';
export const REMOVE_APPLICATIONS_FROM_ORGANIZATION_REQUEST_SUCCESSFUL: string = 'REMOVE_APPLICATIONS_FROM_ORGANIZATION_REQUEST_SUCCESSFUL';
export const REMOVE_APPLICATIONS_FROM_ORGANIZATION_REQUEST_FAILED: string = 'REMOVE_APPLICATIONS_FROM_ORGANIZATION_REQUEST_FAILED';

export const ADD_DOMAIN_TO_ORGANIZATION_REQUESTED: string = 'ADD_DOMAIN_TO_ORGANIZATION_REQUESTED';
export const ADD_DOMAIN_TO_ORGANIZATION_REQUEST_SUCCESSFUL: string = 'ADD_DOMAIN_TO_ORGANIZATION_REQUEST_SUCCESSFUL';
export const ADD_DOMAIN_TO_ORGANIZATION_REQUEST_FAILED: string = 'ADD_DOMAIN_TO_ORGANIZATION_REQUEST_FAILED';

export const REMOVE_DOMAIN_FROM_ORGANIZATION_REQUESTED: string = 'REMOVE_DOMAIN_FROM_ORGANIZATION_REQUESTED';
export const REMOVE_DOMAIN_FROM_ORGANIZATION_REQUEST_SUCCESSFUL: string = 'REMOVE_DOMAIN_FROM_ORGANIZATION_REQUEST_SUCCESSFUL';
export const REMOVE_DOMAIN_FROM_ORGANIZATION_REQUEST_FAILED: string = 'REMOVE_DOMAIN_FROM_ORGANIZATION_REQUEST_FAILED';

export const UPDATE_ORGANIZATION_REQUESTED: string = 'UPDATE_ORGANIZATION_REQUESTED';
export const UPDATE_ORGANIZATION_REQUEST_SUCCESSFUL: string = 'UPDATE_ORGANIZATION_REQUEST_SUCCESSFUL';
export const UPDATE_ORGANIZATION_REQUEST_FAILED: string = 'UPDATE_ORGANIZATION_REQUEST_FAILED';

// Action Creators

/**
 * Gets all organizations for the authenticated user
 * @return {Function} - dispatches function response based on api results
 */
export function getOrganizations(...callbacksOnSuccess: (() => void)[]): AppThunkAction<OrganizationAction> {
  return async (dispatch) => {
    dispatch({ type: GET_ORGANIZATIONS_REQUESTED });
    try {
      const organizationsApiResponse = await organizationsApi.getAll();
      const { allIds, byId } = normalizeOrganizationList(organizationsApiResponse);
      dispatch({ allIds, byId, type: GET_ORGANIZATIONS_REQUEST_SUCCESSFUL });
      for (const callbackOnSuccess of callbacksOnSuccess) {
        callbackOnSuccess();
      }
    } catch (err) {
      showError(err, dispatch);
      dispatch({ type: GET_ORGANIZATIONS_REQUEST_FAILED });
    }
  };
}

/**
 * Gets all organizations for the authenticated user
 * @return {Function} - dispatches function response based on api results
 */
export function getOrganizationsWithClients(...callbacksOnSuccess: (() => void)[]): AppThunkAction<OrganizationAction | ClientAction> {
  return async (dispatch) => {
    dispatch({ type: GET_ORGANIZATIONS_WITH_CLIENTS_REQUESTED });
    try {
      const organizationsApiResponse = await organizationsApi.getAllWithClients();
      const { allIds, byId } = normalizeOrganizationList(organizationsApiResponse);

      dispatch({ allIds, byId, type: GET_ORGANIZATIONS_REQUEST_SUCCESSFUL });
      for (const callbackOnSuccess of callbacksOnSuccess) {
        callbackOnSuccess();
      }
    } catch (err) {
      showError(err, dispatch);
      dispatch({ type: GET_ORGANIZATIONS_WITH_CLIENTS_REQUEST_FAILED });
    }
  };
}

/**
 * Gets a single organization by code
 * @param {Id} organizationCode - organization code
 * @return {Function} - dispatches function response based on api results
 */
export function getOrganizationByCode(organizationCode: Id): AppThunkAction<OrganizationAction> {
  return async (dispatch) => {
    dispatch({ id: organizationCode, type: GET_ORGANIZATION_BY_CODE_REQUESTED });
    try {
      const organizationByCodeApiResponse = await organizationsApi.getByCode(organizationCode);
      const { byId } = normalizeOrganizationList([organizationByCodeApiResponse]);
      dispatch({ byId, id: organizationCode, type: GET_ORGANIZATION_BY_CODE_REQUEST_SUCCESSFUL });
    } catch (err) {
      showError(err, dispatch);
      dispatch({ id: organizationCode, type: GET_ORGANIZATION_BY_CODE_REQUEST_FAILED });
    }
  };
}

/**
 * Gets a single organization by code with its related domains.
 * @param {Id} organizationCode - organization code
 * @return {Function} - dispatches function response based on api results
 */
export function getOrganizationByCodeWithDomains(organizationCode: Id): AppThunkAction<OrganizationAction> {
  return async (dispatch) => {
    dispatch({ id: organizationCode, type: GET_ORGANIZATION_BY_CODE_REQUESTED });
    try {
      const organizationByCodeApiResponse = await organizationsApi.getByCodeWithDomains(organizationCode);
      const { byId } = normalizeOrganizationList([organizationByCodeApiResponse]);
      dispatch({ byId, id: organizationCode, type: GET_ORGANIZATION_BY_CODE_REQUEST_SUCCESSFUL });
    } catch (err) {
      showError(err, dispatch);
      dispatch({ id: organizationCode, type: GET_ORGANIZATION_BY_CODE_REQUEST_FAILED });
    }
  };
}

/**
 * Get all the applications associated with an organization
 * @param {Id} organizationCode - organization code string
 * @return {Function} - dispatches function response based on api results
 */
export function getOrganizationApplications(organizationCode: Id): AppThunkAction<ApplicationAction | OrganizationAction> {
  return async (dispatch) => {
    dispatch({ id: organizationCode, type: GET_APPLICATIONS_FOR_ORGANIZATION_BY_CODE_REQUESTED });
    try {
      const organizationApplicationsApiResponse = await organizationsApi.getApplications(organizationCode);
      const { allIds, byId } = normalizeApplicationList(organizationApplicationsApiResponse);
      dispatch({ allIds, byId, type: GET_APPLICATIONS_REQUEST_SUCCESSFUL });
      dispatch({ applicationCodes: allIds, id: organizationCode, type: GET_APPLICATIONS_FOR_ORGANIZATION_BY_CODE_REQUEST_SUCCESSFUL });
    } catch (err) {
      showError(err, dispatch);
      dispatch({ id: organizationCode, type: GET_APPLICATIONS_FOR_ORGANIZATION_BY_CODE_REQUEST_FAILED });
    }
  };
}

/**
 * Adds single/multiple applications(s) to an organization
 * @param {String} organizationCode - organization code used to add applications
 * @param {Id[]} applicationCodes - an array of application codes
 * @param {...Function} callbacksOnSuccess - callbacks when api call was successful
 * @return {Function} - dispatches function response based on api results
 */
export function addApplicationsToOrganization(
  organizationCode: string,
  applicationCodes: Id[],
  ...callbacksOnSuccess: (() => void)[]
): AppThunkAction<OrganizationAction> {
  return async (dispatch, getState) => {
    dispatch({ type: ADD_APPLICATIONS_TO_ORGANIZATION_REQUESTED });
    try {
      const applicationsById = getState().applications.byId;
      const organizationsById = getState().organizations.byId;
      await organizationsApi.addApplications(organizationCode, applicationCodes);

      const newApplicationCodes = findNonUbiquitousIds(applicationCodes, [organizationsById[organizationCode].applicationCodes ?? []]);

      // Form singular/plural message strings
      const applicationsMessage = newApplicationCodes.length > 1
        ? `${newApplicationCodes.length} applications` : `${applicationsById[newApplicationCodes[0]].name} application`;
      const organizationsMessage = organizationsById[organizationCode].name || 'organization';

      dispatch({ applicationCodes, id: organizationCode, type: ADD_APPLICATIONS_TO_ORGANIZATION_REQUEST_SUCCESSFUL });
      dispatch(alertEnqueued(
        `${newApplicationCodes.length > 1 ?
          `${applicationsMessage} have been added to ${organizationsMessage}.` :
          `${applicationsMessage} has been added to ${organizationsMessage}.`
        }`, { variant: 'success' }));
      for (const callbackOnSuccess of callbacksOnSuccess) {
        callbackOnSuccess();
      }
    } catch (err) {
      showError(err, dispatch);
      dispatch({ type: ADD_APPLICATIONS_TO_ORGANIZATION_REQUEST_FAILED });
    }
  };
}

/**
 * Removes single/multiple applications(s) from an organization
 * @param {String} organizationCode - organization code used to remove applications
 * @param {Id[]} applicationCodes - an array of application codes
 * @param {...Function} callbacksOnSuccess - callbacks when api call was successful
 * @return {Function} - dispatches function response based on api results
 */
export function removeApplicationsFromOrganization(
  organizationCode: string,
  applicationCodes: Id[],
  ...callbacksOnSuccess: (() => void)[]
): AppThunkAction<OrganizationAction> {
  return async (dispatch, getState) => {
    dispatch({ type: REMOVE_APPLICATIONS_FROM_ORGANIZATION_REQUESTED });
    try {
      await organizationsApi.removeApplications(organizationCode, applicationCodes);
      dispatch({ applicationCodes, id: organizationCode, type: REMOVE_APPLICATIONS_FROM_ORGANIZATION_REQUEST_SUCCESSFUL });
      /* Remove application roles to ensure old associated application roles are not accessible inside the context of the organization */
      dispatch({ type: FILTER_ORGANIZATION_ROLES });

      // Form singular/plural message strings
      const applicationsMessage = applicationCodes.length > 1
        ? `${applicationCodes.length} applications` : `${getState().applications.byId[applicationCodes[0]].name} application`;
      const organizationsMessage = getState().organizations.byId[organizationCode].name || 'organization';
      dispatch(alertEnqueued(
        `${applicationCodes.length > 1 ?
          `${applicationsMessage} have been removed from ${organizationsMessage}.` :
          `${applicationsMessage} has been removed from ${organizationsMessage}.`
        }`, { variant: 'success' }));
      for (const callbackOnSuccess of callbacksOnSuccess) {
        callbackOnSuccess();
      }
    } catch (err) {
      showError(err, dispatch);
      dispatch({ type: REMOVE_APPLICATIONS_FROM_ORGANIZATION_REQUEST_FAILED });
    }
  };
}

/**
 * Adds a domain to an organization
 * @param {String} organizationCode - organization code of the organization to add the domain to
 * @param {OrganizationDomain} domain - the domain to add to the organization
 * @param {...Function} callbacksOnSuccess - callbacks when api call was successful
 * @return {Function} - dispatches function response based on api results
 */
export function addDomainToOrganization(
  organizationCode: string,
  domain: Partial<OrganizationDomain>,
  ...callbacksOnSuccess: (() => void)[]
): AppThunkAction<OrganizationAction> {
  return async (dispatch, getState) => {
    dispatch({ type: ADD_DOMAIN_TO_ORGANIZATION_REQUESTED });
    try {
      const newDomain: OrganizationDomain = await organizationsApi.addDomain(organizationCode, domain);
      dispatch({ id: organizationCode, domain: newDomain, type: ADD_DOMAIN_TO_ORGANIZATION_REQUEST_SUCCESSFUL });

      const organizationName = getState().organizations.byId[organizationCode].name || 'organization';
      dispatch(alertEnqueued(
        `${domain.name} has been added to ${organizationName}.`
        , { variant: 'success' }));
      for (const callbackOnSuccess of callbacksOnSuccess) {
        callbackOnSuccess();
      }
    } catch (err) {
      showError(err, dispatch);
      dispatch({ id: organizationCode, type: ADD_DOMAIN_TO_ORGANIZATION_REQUEST_FAILED });
    }
  };
}

/**
 * Adds a domain to an organization
 * @param {String} organizationCode - organization code of the organization to remove the domain from
 * @param {OrganizationDomain} domain - the domain to remove from the organization
 * @param {...Function} callbacksOnSuccess - callbacks when api call was successful
 * @return {Function} - dispatches function response based on api results
 */
export function removeDomainFromOrganization(
  organizationCode: string,
  domain: OrganizationDomain,
  ...callbacksOnSuccess: (() => void)[]
): AppThunkAction<OrganizationAction> {
  return async (dispatch, getState) => {
    dispatch({ type: REMOVE_DOMAIN_FROM_ORGANIZATION_REQUESTED });
    try {
      await organizationsApi.removeDomain(organizationCode, domain.id);

      dispatch({ id: organizationCode, domain, type: REMOVE_DOMAIN_FROM_ORGANIZATION_REQUEST_SUCCESSFUL });

      const organizationName = getState().organizations.byId[organizationCode].name || 'organization';
      const domainName = domain?.name ?? domain;
      dispatch(alertEnqueued(
        `${domainName} has been removed from ${organizationName}.`
        , { variant: 'success' }));
      for (const callbackOnSuccess of callbacksOnSuccess) {
        callbackOnSuccess();
      }
    } catch (err) {
      showError(err, dispatch);
      dispatch({ id: organizationCode, type: REMOVE_DOMAIN_FROM_ORGANIZATION_REQUEST_FAILED });
    }
  };
}

/**
 * Updates an existing organization
 * @param {string} organizationCode - the code of the organization to update
 * @param {Partial<Organization>} organization - the updated organization object
 * @param {Function[]} callbacksOnSuccess - Optional methods to call on success
 * @return {Function} - dispatches function response based on api results
 */
export function updateOrganizationStrictMode(organizationCode: string, organization: Partial<Organization>, ...callbacksOnSuccess: Function[])
  : AppThunkAction<OrganizationAction> {
  return async (dispatch) => {
    dispatch({ id: organizationCode, type: UPDATE_ORGANIZATION_REQUESTED });
    try {
      const result = await organizationsApi.updateStrictMode(organizationCode, organization);
      const { byId } = normalizeOrganizationList([result]);
      dispatch({ byId, id: organizationCode, type: UPDATE_ORGANIZATION_REQUEST_SUCCESSFUL });
      dispatch(alertEnqueued(`${organization.name} has been updated.`, { variant: 'success' }));
      callbacksOnSuccess.forEach((callbackOnSuccess: Function) => {
        callbackOnSuccess();
      });
    } catch (err) {
      showError(err, dispatch);
      dispatch({ type: UPDATE_ORGANIZATION_REQUEST_FAILED });
    }
  };
}
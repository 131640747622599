/* eslint-disable sort-keys */
import React from 'react';
import clsx from 'clsx';
import {
  Badge,
  Chip,
  Tooltip
} from '@mui/material';

import { UserStatus } from '../../store/Users/UserModels';
import { ActiveStatus } from '../../store/Organizations/OrganizationModels';
import useStyles from './styles';

type Status = UserStatus | ActiveStatus;

type StatusChipProps = {
	status: Status;
}

const StatusChip = ({ status }: StatusChipProps) => {
  const classes = useStyles();
  const statusLabel = status.split(/(?=[A-Z])/).join(' ');

  if (status === 'updated') {
    return (
      <Tooltip title="Refresh for new status" placement="bottom" arrow>
        <Badge
          classes={{
            anchorOriginBottomRightCircular: classes.anchorOriginBottomRightCircle,
            badge: classes.badge
          }}
          overlap="circular"
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'bottom',
          }}
          variant="dot"
        >
          <Chip
            className={clsx(classes.root, classes[status])}
            label={statusLabel}
            variant="outlined"
            size="small"
          />
        </Badge>
      </Tooltip>
    );
  } else {
    return (
      <Chip
        className={clsx(classes.root, classes[status])}
        label={statusLabel}
        variant="outlined"
        size="small"
      />
    );
  }
};

export default StatusChip;
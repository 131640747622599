import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

const styles = makeStyles((theme: Theme) => ({
  activeState: {
    backgroundColor: '#00000010'
  },
  avatar: {
    fontSize: 14,
    height: 32,
    width: 32,
  },
  menuRoot: {
    marginTop: theme.spacing(0.5)
  },
  root: {
    '& .MuiDataGrid-row.Mui-selected': {
      '&:hover': {
        backgroundColor: '#00000003'
      },
      backgroundColor: 'transparent',
    },
    height: 736,
    width: '100%'
  },
  selectedResultsDisplay: {
    color: theme.palette.grey[600]
  },
  tableMenuButton: {
    marginLeft: theme.spacing(0.5)
  },
  vertDropDownIcon: {
    padding: theme.spacing(0.75, 1)
  },
  link: {
    textDecoration: 'none',
    color: '#323232'
  }
}));

export default styles;
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

const closedDrawerWidth = 69;
const drawerWidth = 249;

const styles = makeStyles((theme: Theme) => {
  return ({
    content: {
      maxWidth: 1200,
      flexGrow: 1,
      marginLeft: closedDrawerWidth,
      padding: theme.spacing(3),
      transition: theme.transitions.create('margin', {
        duration: theme.transitions.duration.leavingScreen,
        easing: theme.transitions.easing.sharp,
      }),
    },
    contentShift: {
      marginLeft: drawerWidth,
      transition: theme.transitions.create('margin', {
        duration: theme.transitions.duration.enteringScreen,
        easing: theme.transitions.easing.easeOut,
      }),
    },
    root: {
      display: 'flex',
    },
    toolbar: {
      minHeight: 60,
    },
  });
});

export default styles;
import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';

import useStyles from './styles';

type DeleteModalProps = {
  onClose: () => void;
  onLeave: () => void;
  open?: boolean;
}

function LeavePageModal({
  onClose,
  onLeave,
  open = false,
}: DeleteModalProps
) {
  const classes = useStyles();

  const handleLeaveClick = () => onLeave();
  const handleCloseModal = () => onClose();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="leave-page-dialog-title"
      classes={{
        paper: classes.dialogPaper
      }}
    >
      <DialogTitle id="leave-page-dialog-title" classes={{ root: classes.titleContainer }}>
        Leave page
      </DialogTitle>
      <DialogContent classes={{ root: classes.contentContainer }}>
        <DialogContentText color="initial" classes={{ root: classes.contentContainer }}>
          All progress will be lost. Are you sure you want to leave this page?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseModal} color="primary" variant="outlined">
          Cancel
        </Button>
        <Button color="error" variant="contained" onClick={handleLeaveClick}>
          Leave page
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default LeavePageModal;

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

const styles = makeStyles((theme: Theme) => ({
  actionsContainer: {
    padding: theme.spacing(2.5, 3, 2)
  },
  content: {
    color: theme.palette.grey[800]
  },
  contentContainer: {
    '&:first-child': {
      paddingTop: theme.spacing(3.5)
    },
    padding: theme.spacing(3.5, 5),
  },
  dialogPaper: {
    width: 500
  },
  helperText: {
    color: theme.palette.grey[500],
    fontSize: 12,
    marginLeft: theme.spacing(1)
  },
  noneSelected: {
    color: theme.palette.grey[600]
  },
  selectRoot: {
    '& .MuiOutlinedInput-root': {
      '&.Mui-disabled': {
        background: theme.palette.grey[400]
      }
    },
  },
  subtitle: {
    color: theme.palette.grey[600],
    letterSpacing: 0
  },
  titleContainer: {
    padding: theme.spacing(2.5, 5, 2)
  },
}));

export default styles;
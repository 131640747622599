import { Application } from '../store/Applications/ApplicationModels';
import { Client } from '../store/Clients/ClientModels';
import { Group } from '../store/Groups/GroupModels';
import { Organization } from '../store/Organizations/OrganizationModels';
import { Role } from '../store/Roles/RoleModels';
import { User } from '../store/Users/UserModels';

/**
 * Transform strings to a single lowercase string without spaces
 * @param {string[]} strings - strings to be transformed
 * @return {string} A lowercase string without spaces
 */
export const normalizeStrings = (...strings: string[]): string => strings.reduce((acc, curr) => acc + curr, '').replace(/\s/g, '').toLowerCase();

/**
  * Transform searchable `User` fields into a normalized mask for searching and filtering
  * @param {User} user - the `User` to be transformed
  * @return {string} a normalized string
  */
export const toUserMask = ({ firstName, lastName, email = '', userName = '', status }: User): string => {
  return normalizeStrings(firstName, lastName, email, userName, status);
};
/**
  * Transform searchable `Role` fields into a normalized mask for searching and filtering
  * @param {Role} role - the `Role` to be transformed
  * @return {string} a normalized string
  */
export const toRoleMask = ({ name, roleType }: Role): string => {
  return normalizeStrings(name, roleType);
};

/**
  * Transform searchable `Group` fields into a normalized mask for searching and filtering
  * @param {Group} group - the `Group` to be transformed
  * @return {string} a normalized string
  */
export const toGroupMask = ({ name }: Group): string => {
  return normalizeStrings(name);
};

/**
  * Transform searchable `Organization` fields into a normalized mask for searching and filtering
  * @param {Organization} organization - the `Organization` to be transformed
  * @return {string} a normalized string
  */
export const toOrganizationMask = ({ name, code }: Organization): string => {
  return normalizeStrings(name, code);
};

/**
  * Transform searchable `Client` fields into a normalized mask for searching and filtering
  * @param {Client} client - the `Organization` to be transformed
  * @return {string} a normalized string
  */
export const toClientMask = ({ name, code }: Client): string => {
  return normalizeStrings(name, code);
};

/**
  * Transform searchable `Application` fields into a normalized mask for searching and filtering
  * @param {Application} application - the `Application` to be transformed
  * @return {string} a normalized string
  */
export const toApplicationMask = ({ name, code }: Application): string => {
  return normalizeStrings(name, code);
};

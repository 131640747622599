import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Controller } from 'react-hook-form';
import {
  Autocomplete,
  CircularProgress,
  TextField
} from '@mui/material';

import { UMAReduxState } from '../../store/rootReducer';
import { Client, WILDCARD, ALL_CLIENTS } from '../../store/Clients/ClientModels';
import { getClientCodesSelector } from '../../selectors/getClients';
import { ById, Id } from '../../types/types';

interface ClientFieldProps {
  label?: string;
  control: any;
}


const setWildcardForClients = (initClientsById: ById<Client>): ById<Client> => {
  if (initClientsById == null || Object.keys(initClientsById).length === 0) {
    return initClientsById;
  }
  const orgId = Object.values(initClientsById)[0].organizationId;
  const wildCardValues = { id: '0', organizationId: orgId, code: WILDCARD, name: ALL_CLIENTS, isActive: true } as Client;
  const wildCardObj = { [WILDCARD]: wildCardValues };
  return { ...wildCardObj, ...initClientsById };
};


const setWildcardForCodes = (codes: string[]): string[] => {
  if (codes == null || Object.keys(codes).length === 0) {
    return codes;
  }
  return [WILDCARD].concat(codes);
};

const ClientField = (props: ClientFieldProps) => {
  const isLoading = useSelector((state: UMAReduxState) => state.clients.isLoading);

  const codes = setWildcardForCodes(useSelector(getClientCodesSelector));
  const clientsById = setWildcardForClients(useSelector((state: UMAReduxState) => state.clients.byId));

  const [isOpen, setIsOpen] = useState(false);

  return (
    <Controller
      name="clientCode"
      control={props.control}
      defaultValue={null}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          data-testid="client-autocomplete"
          {...field}
          open={isOpen}
          onOpen={() => {
            setIsOpen(true);
          }}
          onClose={() => {
            setIsOpen(false);
          }}
          options={codes}
          getOptionLabel={(option: Id) => clientsById[option]?.name ?? ''}
          onChange={(event, option) => {
            field.onChange(option);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label={props.label}
              fullWidth
              FormHelperTextProps={{
                'aria-label': 'client-field-helper-text'
              }}
              inputProps={{
                ...params.inputProps,
                'aria-label': 'client-field',
                autoComplete: 'disabled', // disable autocomplete and autofill
              }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
              error={Boolean(error)}
              helperText={error?.message ?? ''}
            />
          )}
        />
      )}
    />
  );
};

export default ClientField;
import React from 'react';
import { connect, useSelector } from 'react-redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';

import {
  singularizeResource,
  toTitleCase
} from '../../utility';
import { Id, ResourceNames } from '../../types/types';
import { deleteGroups } from '../../store/Groups/GroupActions';
import { deleteRoles } from '../../store/Roles/RoleActions';
import { deleteUser } from '../../store/Users/UserActions';
import { Group } from '../../store/Groups/GroupModels';
import { Role } from '../../store/Roles/RoleModels';
import { User } from '../../store/Users/UserModels';
import { UMAReduxState } from '../../store/rootReducer';
import useStyles from './styles';
import { Application } from '../../store/Applications/ApplicationModels';
import { deleteApplication } from '../../store/Applications/ApplicationActions';
import { OrganizationDomain } from '../../store/Organizations/OrganizationModels';

interface StateFromProps {
  organizationCode: string;
}

interface DispatchFromProps {
  deleteGroups: (orgCode: string, groupIds: Id[], callbackOnSuccess: () => void) => void;
  deleteRoles: (orgCode: string, role: Role[], callbackOnSuccess: () => void) => void;
  deleteUser: (organizationCode: Id, userId: Id, user: User, callbacksOnSuccess: () => void) => void;
  deleteApplication: (application: Application, callbackOnSuccess: () => void) => void;
}

interface DeleteModalProps {
  isDeleting: boolean;
  onClose: () => void;
  onDelete?: () => void;
  open?: boolean;
  resourceName: ResourceNames;
  resources: Group[] | Role[] | User[] | Application[] | OrganizationDomain[];
}

type Props = StateFromProps & DispatchFromProps & DeleteModalProps;

function DeleteModal({
  deleteGroups,
  deleteRoles,
  deleteUser,
  deleteApplication,
  isDeleting,
  onClose,
  onDelete,
  open = false,
  organizationCode,
  resourceName,
  resources
}: Props) {
  const classes = useStyles();
  const orgCode = useSelector((store: UMAReduxState) => store.context.organizationCode);

  const handleDeleteClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    switch (resourceName) {
      case 'groups':
        deleteGroups(organizationCode, (resources as Group[]).map(({ id }) => id), onDeleteSuccessful);
        break;
      case 'roles':
        deleteRoles(organizationCode, (resources as Role[]), onDeleteSuccessful);
        break;
      case 'users':
        deleteUser(orgCode, (resources as User[])[0].id, (resources as User[])[0], onDeleteSuccessful);
        break;
      case 'applications':
        deleteApplication((resources as Application[])[0], onDeleteSuccessful);
        break;
    }
  };

  const onDeleteSuccessful = () => {
    onClose();
    if (onDelete) {
      onDelete();
    }
  };

  const handleCloseModal = () => {
    onClose();
  };

  const renderConfirmationMessage = () => {
    // Check if User resource
    if (resourceName === 'users') {
      const user = (resources as User[])[0];
      return `${user?.firstName} ${user?.lastName} will be permanently deleted. This action cannot be undone.`;

      // Check if Application or System Role resources
    } else if (resourceName === 'roles' && (resources as Role[]).filter(({ roleType }) => roleType === 'application').length) {
      return resources.length > 1 ? (
        `${toTitleCase(resourceName)} (${resources.length}) will be permanently removed.\n There is at least one
				application role involved which will have significant repercussions, and this action cannot be undone.
				Are you sure you want to PERMANENTLY delete these roles?`
      ) : (
        `${toTitleCase(singularResource)} will be permanently removed.\n This is an application role
				which will have significant repercussions, and this action cannot be undone.
				Are you sure you want to PERMANENTLY delete this role?`
      );

      // Catch all for Groups and Organization Roles
    } else {
      return resources.length > 1 ?
        `${toTitleCase(resourceName)} (${resources.length}) will be permanently removed.\nThis action cannot be undone.`
        : `${toTitleCase(singularResource)} will be permanently removed.\nThis action cannot be undone.`;
    }
  };

  const singularResource = singularizeResource(resourceName);
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby={`delete-${resourceName}-dialog-title`}
      classes={{
        paper: classes.dialogPaper
      }}
    >
      <DialogTitle id={`delete-${resourceName}-dialog-title`} classes={{ root: classes.titleContainer }}>
        Delete
      </DialogTitle>
      <DialogContent classes={{ root: classes.contentContainer }}>
        <DialogContentText color="initial" classes={{ root: classes.contentContainer }}>
          {renderConfirmationMessage()}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-around' }}>
        <Button onClick={handleCloseModal} color="primary" variant="outlined">
          Cancel
        </Button>
        <Button color="error" variant="contained" onClick={handleDeleteClick} disabled={isDeleting}>
          {isDeleting ? 'Deleting' : 'Delete'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const mapStateToProps = (state: UMAReduxState): StateFromProps => ({
  organizationCode: state.context.organizationCode
});

const mapDispatchToProps: DispatchFromProps = {
  deleteGroups,
  deleteRoles,
  deleteUser,
  deleteApplication,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteModal);

import React, { useRef, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Skeleton,
  Typography
} from '@mui/material';
import { connect } from 'react-redux';
import { UMAReduxState } from '../../store/rootReducer';
import useStyles from './styles';
import { Id } from '../../types/types';
import { Role } from '../../store/Roles/RoleModels';
import { importRoles, resetRoleImport } from '../../store/RoleImports/RoleImportActions';
import { FailedImportResource, RoleImportApiResponse } from '../../store/RoleImports/RoleImportModels';
import If from '../If/If';
import { PolicyRule } from '../../store/PolicyRules/PolicyRuleModels';
import RoleTypeBadge from '../RoleTypeBadge/RoleTypeBadge';

interface StateFromProps {
  organizationCode: string;
  isImporting: boolean;
  importResult: RoleImportApiResponse | undefined;
}

interface DispatchFromProps {
  importRoles: (organizationCode: Id, roles: Partial<Role>[]) => void;
  resetRoleImport: () => void;
}

interface DeleteModalProps {
  onClose: () => void;
  open: boolean;
}

type Props = StateFromProps & DispatchFromProps & DeleteModalProps;

function ImportRoleModal({
  organizationCode,
  isImporting,
  onClose,
  open,
  importResult,
  importRoles,
  resetRoleImport,
}: Props) {
  const classes = useStyles();
  const [importReady, setImportReady] = useState<boolean>(false);
  const [rolesToImport, setRolesToImport] = useState<Partial<Role>[]>([]);
  const [filename, setFilename] = useState<string>('');
  const fileInputRef = useRef<HTMLInputElement>(null);

  const policiesByRole = importResult?.policies?.reduce((acc: any, p) => {
    acc[p.roleId] = acc[p.roleId] ? [...acc[p.roleId], p] : [p];
    return acc;
  }, {}) ?? {};

  const handleImportClick = () => {
    importRoles(organizationCode, rolesToImport);
  };

  const handleCloseModal = () => {
    onClose();
    resetRoleImport();
    clearFileInput();
  };

  const clearFileInput = () => {
    if (!fileInputRef?.current) return;
    fileInputRef.current.files = null;

    setFilename('');
    setImportReady(false);
  };

  const handleFileChange = (event: any) => {
    const file: File = event.target.files[0];
    setFilename(file?.name ?? '');

    if (!file) {
      setImportReady(false);
      return;
    }

    const fileReader = new FileReader();
    fileReader.addEventListener('load', handleFileRead);
    fileReader.readAsText(file);
  };

  const handleFileRead = (event: any) => {
    const roles = JSON.parse(event.target.result);
    setRolesToImport(roles);
    setImportReady(true);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="import-role-dialog-title"
      classes={{
        paper: classes.dialogPaper
      }}
    >
      <DialogTitle id="import-role-dialog-title" classes={{ root: classes.titleContainer }}>
        Import Roles
      </DialogTitle>
      <DialogContent classes={{ root: classes.contentContainer }}>
        <Typography color="initial" classes={{ root: classes.contentContainer }}>
          <Button
            variant='contained'
            component='label'
            disabled={isImporting || !!importResult}
            sx={{ mr: 2 }}
          >
            Select a File
            <input
              type="file"
              ref={fileInputRef}
              hidden={true}
              accept='.json'
              multiple={false}
              onChange={handleFileChange}
            ></input>
          </Button>
          {filename}
        </Typography>

        <Divider />

        <If condition={!!importResult}>
          <Box sx={{ mt: 2 }}>
            <Typography sx={{ fontSize: 20, fontWeight: 600 }}>
              Import Result
            </Typography>
            <If condition={!!importResult?.roles?.length}>
              <Box sx={{ mt: 2 }}>
                <Typography sx={{ fontSize: 16, fontWeight: 600 }} >
                  {(importResult?.roles?.length ?? 0) + (importResult?.policies?.length ?? 0)} Created Resources: </Typography>
                <List>
                  <Divider />
                  {
                    importResult?.roles.map(r => (
                      <React.Fragment key={r.id}>
                        <ListItem>
                          <ListItemIcon>
                            <RoleTypeBadge roleType={r.roleType} smallBadge />
                          </ListItemIcon>
                          <ListItemText>{r.name}</ListItemText>
                        </ListItem>
                        <Divider />
                        {
                          policiesByRole[r.id]?.map((p: PolicyRule) => (
                            <React.Fragment key={p.id}>
                              <ListItem>
                                <ListItemText inset>
                                  <code>{p.applicationCode}, {p.tag}, {p.permission}, {p.organizationCode}, {p.clientCode}, {p.effect}</code>
                                </ListItemText>
                              </ListItem>
                              <Divider />
                            </React.Fragment>
                          ))
                        }
                      </React.Fragment>))
                  }
                </List>
              </Box>
            </If>
            <If condition={!!importResult?.failedResources?.length}>
              <Box sx={{ mt: 2 }}>
                <Typography sx={{ fontSize: 16, fontWeight: 600 }} >
                  {importResult?.failedResources?.length} Errors:
                </Typography>
                <List>
                  <Divider />
                  {
                    importResult?.failedResources?.map((r: FailedImportResource) => (
                      <React.Fragment key={r.name}>
                        <ListItem>
                          <ListItemText>
                            <Typography>
                              {r.resourceType === 'policyRule' ?
                                <code>{r.name}</code> :
                                <>{r.name}</>
                              }
                            </Typography>
                            <Typography>
                              {r.error}
                            </Typography>
                          </ListItemText>
                        </ListItem>
                        <Divider />
                      </React.Fragment>
                    ))
                  }
                </List>
              </Box>
            </If>

          </Box>
        </If>

        <If condition={isImporting}>
          <Box sx={{ mt: 2 }}>
            <Skeleton sx={{ fontSize: '20pt' }}></Skeleton>
            <Skeleton variant='rectangular' height={100}></Skeleton>
          </Box>
        </If>

      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-around' }}>
        <Button onClick={handleCloseModal} color="primary" variant="outlined">
          {importResult ? 'Close' : 'Cancel'}
        </Button>
        <Button variant="contained" onClick={handleImportClick} disabled={isImporting || !importReady || !!importResult}>
          {isImporting ? 'Importing' : 'Import'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state: UMAReduxState): StateFromProps => ({
  organizationCode: state.context.organizationCode,
  isImporting: state.roleImport.isImporting,
  importResult: state.roleImport.importResult,
});

const mapDispatchToProps: DispatchFromProps = {
  importRoles,
  resetRoleImport
};

export default connect(mapStateToProps, mapDispatchToProps)(ImportRoleModal);
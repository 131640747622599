/* eslint-disable sort-keys */
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

const colorMap = {
  active: {
    backgroundColor: '#E0FFE1',
    borderColor: '#4CAF50',
    color: '#4CAF50',
  },
  deprovisioned: {
    backgroundColor: '#FFEDED',
    borderColor: '#B71C1C',
    color: '#B71C1C',
  },
  inactive: {
    backgroundColor: '#FFEDED',
    borderColor: '#B71C1C',
    color: '#B71C1C',
  },
  locked: {
    backgroundColor: '#F4F4F4',
    borderColor: '#9E9E9E',
    color: '#9E9E9E',
  },
  passwordExpired: {
    backgroundColor: '#FCE0F0',
    borderColor: '#9e0059',
    color: '#9e0059'
  },
  pending: {
    backgroundColor: '#F7F5E1',
    borderColor: '#827717',
    color: '#827717',
  },
  provisioned: {
    backgroundColor: '#D8EBEE',
    borderColor: '#087f8c',
    color: '#087f8c',
  },
  recovery: {
    backgroundColor: '#F8EDFF',
    borderColor: '#6A1B9A',
    color: '#6A1B9A',
  },
  staged: {
    backgroundColor: '#E0F5FF',
    borderColor: '#03A9F4',
    color: '#03A9F4',
  },
  suspended: {
    backgroundColor: '#FFEDD6',
    borderColor: '#FF8F00',
    color: '#FF8F00',
  },
  unspecified: {},
  updated: {}
};

const styles = makeStyles((theme: Theme) => ({
  ...colorMap,
  anchorOriginBottomRightCircle: {
    bottom: '10%',
    right: '5%'
  },
  badge: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.light,
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '\'\'',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
  root: {
    borderRadius: 3,
    letterSpacing: 0.65,
    textTransform: 'capitalize'
  }
}));

export default styles;
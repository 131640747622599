import policyConstants from '../constants/policyConstants';
import { PolicyRequest } from '../store/My/MyModels';
import { Role } from '../store/Roles/RoleModels';
import { Id } from '../types/types';

export function getDeleteRolePolicy(role: Role, organizationCode: Id, applicationCode?: string): PolicyRequest | undefined {
  if (!role) return undefined;

  let accessRequest: PolicyRequest;
  switch (role.roleType) {
    case 'application':
      accessRequest = policyConstants.roles.deleteApplication(applicationCode ?? '', role.id);
      break;
    case 'organization':
      accessRequest = policyConstants.roles.deleteOrganization(organizationCode, role.id);
      break;
    default:
      return undefined;
  }

  return accessRequest;
}

export function getUpdateRolePolicy(role: Role, organizationCode: Id, applicationCode?: Id, allowUpdateApplicationAny: boolean = false)
  : PolicyRequest | undefined {
  if (!role) return undefined;

  let accessRequest: PolicyRequest;
  switch (role.roleType) {
    case 'application':
      if (allowUpdateApplicationAny) {
        accessRequest = policyConstants.roles.updateApplicationAny();
      } else if (!allowUpdateApplicationAny && applicationCode) {
        accessRequest = policyConstants.roles.updateApplication(applicationCode, role.id);
      } else {
        return undefined;
      }
      break;
    case 'organization':
      accessRequest = policyConstants.roles.updateOrganization(organizationCode, role.id);
      break;
    default:
      return undefined;
  }

  return accessRequest;
}

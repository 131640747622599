import React from 'react';
import {
  Grid,
  Skeleton
} from '@mui/material';

function InputSkeleton() {
  return (
    <Grid role="input-skeleton" container item justifyContent="center" alignItems="center">
      <Skeleton width="100%" style={{ padding: 8 }} />
    </Grid>
  );
}

export default React.forwardRef<any, any>(InputSkeleton);
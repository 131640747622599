import { without } from 'lodash';

import { Id } from '../types/types';

/**
 * Parse through a jagged array (array of arrays) of ids to find and return all that do NOT exist in every array.
 * @param {Id[]} ids - the array of ids to check for ubiquitousness
 * @param {Array<Id[]>} existingIdsForEachResource - an array containing arrays of resource ids
 * @return {Id[]} an array of non-ubiquitous ids
 */
export default function findNonUbiquitousIds(ids: Id[], existingIdsForEachResource: Id[][]): Id[] {
  // Return all ids if an empty array is passed for second argument
  if (!existingIdsForEachResource.length) return ids;
  const idsInEveryArray: Id[] = [];
  ids.forEach((id) => {
    // Store ids that exist in every array
    existingIdsForEachResource.every((idArray) => idArray.includes(id)) && idsInEveryArray.push(id);
  });
  // Return all ids that aren't ubiquitous across all arrays
  return without(ids, ...idsInEveryArray);
}
import { AnyAction } from 'redux';
import { ContextState } from './ContextModels';
import {
  RESTORE_CONTEXT_REQUESTED,
  RESTORE_CONTEXT_REQUEST_SUCCESSFUL,
  RESTORE_CONTEXT_REQUEST_FAILED,
  SET_PRIMARY_ORG_CODE_REQUESTED,
  SET_PRIMARY_ORG_CODE_REQUEST_SUCCESSFUL,
  SET_PRIMARY_ORG_CODE_REQUEST_FAILED,
  SWITCH_CONTEXT_REQUESTED,
  SWITCH_CONTEXT_REQUEST_SUCCESSFUL,
  SWITCH_CONTEXT_REQUEST_FAILED
} from './ContextActions';

export const initialState: ContextState = {
  isLoading: true,
  isSwitched: false,
  organizationCode: '',
  userPrimaryOrganizationCode: ''
};

function context(state: ContextState = initialState, action: AnyAction): ContextState {
  switch (action.type) {
    case SWITCH_CONTEXT_REQUESTED:
      return {
        ...state,
        isLoading: true
      };

    case SWITCH_CONTEXT_REQUEST_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        isSwitched: state.userPrimaryOrganizationCode !== action.organizationCode,
        organizationCode: action.organizationCode
      };

    case SWITCH_CONTEXT_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false
      };

    case RESTORE_CONTEXT_REQUESTED:
      return {
        ...state,
        isLoading: true
      };

    case RESTORE_CONTEXT_REQUEST_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        isSwitched: false,
        organizationCode: action.organizationCode
      };

    case RESTORE_CONTEXT_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false
      };

    case SET_PRIMARY_ORG_CODE_REQUESTED:
      return {
        ...state,
        isLoading: true
      };

    case SET_PRIMARY_ORG_CODE_REQUEST_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        isSwitched: false,
        organizationCode: action.organizationCode,
        userPrimaryOrganizationCode: action.organizationCode
      };

    case SET_PRIMARY_ORG_CODE_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false
      };

    default:
      return state;
  }
}

export default context;
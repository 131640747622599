import React from 'react';
import {
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  TextFieldProps
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/ClearRounded';

import useStyles from './styles';

interface SearchInputProps {
	autoFocus?: boolean;
	onChange: TextFieldProps['onChange'];
	onReset: () => void;
	placeholder?: string;
}

export default function SearchInput({ autoFocus = false, onChange, onReset, placeholder = 'Search' }: SearchInputProps) {
  const classes = useStyles();

  return (
    <form onReset={onReset} onSubmit={(e) => e.preventDefault()}>
      <TextField
        autoFocus={autoFocus}
        onChange={onChange}
        fullWidth
        placeholder={placeholder}
        inputProps={{
          className: classes.input,
          role: 'search'
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip title="clear" placement="top" arrow>
                <IconButton type="reset" size="small" className={classes.clear}><ClearIcon /></IconButton>
              </Tooltip>
            </InputAdornment>
          ),
          startAdornment: <InputAdornment position="start"><SearchIcon className={classes.searchIcon} /></InputAdornment>
        }}
      />
    </form>
  );
}

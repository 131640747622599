import React from 'react';
import { Controller } from 'react-hook-form';
import { MenuItem, TextField, TextFieldProps } from '@mui/material';

import useStyles from './styles';

interface EffectFieldProps {
  control: any;
}

type Props = EffectFieldProps & TextFieldProps;

const EffectField = ({ control, ...props }: Props) => {
  const classes = useStyles();

  return (
    <Controller
      name="effect"
      control={control}
      defaultValue=""
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...props}
          {...field}
          fullWidth
          select
          label={props.label}
          classes={{
            root: classes.selectRoot
          }}
          FormHelperTextProps={{
            'aria-label': 'effect-field-helper-text',
          }}
          inputProps={{
            'aria-label': 'effect-dropdown',
            'data-testid': 'effect-field'
          }}
          error={Boolean(error)}
          helperText={error?.message ?? ''}
        >
          <MenuItem key='allow' value='allow' data-testid="effect-option">Allow</MenuItem>
          <MenuItem key='deny' value='deny' data-testid="effect-option">Deny</MenuItem>
        </TextField>
      )}
    />
  );
};

export default EffectField;
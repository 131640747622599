/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Link,
  List,
  ListItem,
  Skeleton,
  Typography
} from '@mui/material';
import { isEqual, sortBy, union } from 'lodash';

import ListItemCheckbox from './ListItemCheckbox';
import SearchInput from '../SearchInput/SearchInput';
import { User } from '../../store/Users/UserModels';
import { getUsers } from '../../store/Users/UserActions';
import { Role } from '../../store/Roles/RoleModels';
import { getRoles } from '../../store/Roles/RoleActions';
import { Group } from '../../store/Groups/GroupModels';
import { addRolesToGroups, addUsersToGroups, getGroups, } from '../../store/Groups/GroupActions';
import { Application } from '../../store/Applications/ApplicationModels';
import { addOrganizationsToApplication, getApplications } from '../../store/Applications/ApplicationActions';
import { Organization } from '../../store/Organizations/OrganizationModels';
import { addApplicationsToOrganization, getOrganizations } from '../../store/Organizations/OrganizationActions';
import { UMAReduxState } from '../../store/rootReducer';
import { Id, ResourceNames } from '../../types/types';
import {
  collectionSpread,
  findOrCreateKey,
  normalizeStrings,
  toApplicationMask,
  toFullName,
  toGroupMask,
  toOrganizationMask,
  toRoleMask,
  toTitleCase,
  toUserMask
} from '../../utility';
import useStyles from './styles';

interface StateFromProps {
  assignableResources: User[] | Role[] | Group[] | Organization[] | Application[];
  isLoading: boolean;
  organizationCode: string;
}

interface DispatchFromProps {
  addApplicationsToOrganization: (organizationCode: string, applicationCodes: Id[], callbacksOnSuccess: () => void) => void;
  addOrganizationsToApplication: (applicationCode: string, organizationCodes: Id[], callbacksOnSuccess: () => void) => void;
  addRolesToGroups: (orgCode: string, groupIds: Id[], roleIds: Id[], callbacksOnSuccess: () => void) => void;
  addUsersToGroups: (orgCode: string, groupIds: Id[], userIds: Id[], callbacksOnSuccess: () => void) => void;
  getApplications: (callbacksOnSuccess: () => void) => void;
  getUsers: (orgCode: string, callbacksOnSuccess: () => void) => void;
  getRoles: (orgCode: string, callbacksOnSuccess: () => void) => void;
  getGroups: (orgCode: string, callbacksOnSuccess: () => void) => void;
  getOrganizations: (callbacksOnSuccess: () => void) => void;
}

interface AssignModalProps {
  assignResourceName: ResourceNames;
  parentResourceName: ResourceNames;
  parentResource: Group[] | User[] | Role[] | Application[] | Organization[];
  parentResourceLoading?: boolean;
  isAssigning: boolean;
  onClose: () => void;
  open?: boolean;
}

type Props = StateFromProps & DispatchFromProps & AssignModalProps;

function AssignModal({
  addApplicationsToOrganization,
  addOrganizationsToApplication,
  addRolesToGroups,
  addUsersToGroups,
  assignableResources,
  assignResourceName,
  parentResource,
  parentResourceName,
  getApplications,
  getGroups,
  getOrganizations,
  getRoles,
  getUsers,
  isAssigning,
  isLoading,
  onClose,
  open = false,
  organizationCode
}: Props) {
  /*
    Consolidate initially selected resources and filter out
    `User` resource if the user is deactivated (deprovisioned)
    and hasn't been previously assigned
    */
  const initialSelected: Id[] = union((parentResource as (User | Group | Role | Application | Organization)[])
    .flatMap((resource: (User | Group | Role | Application | Organization)) => {
      if (assignResourceName === 'users') {
        return (resource as Group)?.userIds ?? [];
      } else if (assignResourceName === 'roles') {
        return (resource as Group | Application)?.roleIds ?? [];
      } else if (assignResourceName === 'groups') {
        return (resource as User | Role)?.groupIds ?? [];
      } else if (assignResourceName === 'organizations') {
        return (resource as Application)?.organizationCodes ?? [];
      } else if (assignResourceName === 'applications') {
        return (resource as Organization)?.applicationCodes ?? [];
      } else {
        return [];
      }
    })
  );
  const initialAssignableResources: User[] | Role[] | Group[] | Organization[] | Application[] = (assignableResources as [])
    .filter((resource: (User | Group | Role | Organization | Application)) => {
      if (assignResourceName === 'users') {
        return (resource as User)?.status !== 'deprovisioned' || initialSelected.includes((resource as User)?.id);
      }
      return (resource as Group | Role | Organization | Application);
    });

  const [selected, setSelected] = useState<Id[]>([]);
  const [filteredResources, setFilteredResources] = useState<User[] | Role[] | Group[] | Organization[] | Application[]>([]);
  const [filterText, setFilterText] = useState<string>('');
  const [resourceLoaded, setResourceLoaded] = useState<boolean>(false);
  const classes = useStyles({ selected: selected.length });

  useEffect(() => {
    if (!isAssigning) {
      setSelected(initialSelected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentResource]);

  /* Makes initial call for assignable resources */
  useEffect(() => {
    /* don't retrieve data until the modal is opened */
    if (!open || resourceLoaded) {
      return;
    }

    if (assignResourceName === 'users') {
      getUsers(organizationCode, () => {
        setResourceLoaded(true);
      });
    }
    if (assignResourceName === 'roles' && parentResourceName !== 'applications') {
      getRoles(organizationCode, () => {
        setResourceLoaded(true);
      });
    }
    if (assignResourceName === 'groups') {
      getGroups(organizationCode, () => {
        setResourceLoaded(true);
      });
    }
    if (assignResourceName === 'organizations') {
      getOrganizations(() => {
        setResourceLoaded(true);
      });
    }
    if (assignResourceName === 'applications') {
      getApplications(() => {
        setResourceLoaded(true);
      });
    }
  }, [
    open, resourceLoaded, assignResourceName, getUsers, getRoles, getGroups, getOrganizations, organizationCode, getApplications, parentResourceName
  ]);

  /* Filter logic */
  useEffect(() => {
    if (assignResourceName === 'users' && filterText.length > 0) {
      setFilteredResources((initialAssignableResources as User[]).filter((user) => toUserMask(user).includes(filterText)));
    } else if (assignResourceName === 'roles' && filterText.length > 0) {
      setFilteredResources((initialAssignableResources as Role[]).filter((role: Role) => toRoleMask(role).includes(filterText)));
    } else if (assignResourceName === 'groups' && filterText.length > 0) {
      setFilteredResources((initialAssignableResources as Group[]).filter((group: Group) => toGroupMask(group).includes(filterText)));
    } else if (assignResourceName === 'organizations' && filterText.length > 0) {
      setFilteredResources((initialAssignableResources as Organization[])
        .filter((organization: Organization) => toOrganizationMask(organization).includes(filterText)));
    } else if (assignResourceName === 'applications' && filterText.length > 0) {
      setFilteredResources((initialAssignableResources as Application[])
        .filter((application: Application) => toApplicationMask(application).includes(filterText)));
    } else {
      setFilteredResources(initialAssignableResources);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignResourceName, assignableResources, filterText]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => setFilterText(normalizeStrings(event.target.value));

  const handleAssignClick = () => {
    if (assignResourceName === 'users' && parentResourceName === 'groups') {
      addUsersToGroups(
        organizationCode, (parentResource as Group[]).map(({ id }) => id), selected, () => {
          handleCloseModal();
        });
      return;
    }
    if (assignResourceName === 'groups' && parentResourceName === 'users') {
      addUsersToGroups(
        organizationCode, selected, (parentResource as User[]).map(({ id }) => id), () => {
          handleCloseModal();
        });
      return;
    }
    if (assignResourceName === 'roles' && parentResourceName === 'groups') {
      addRolesToGroups(
        organizationCode, (parentResource as Group[]).map(({ id }) => id), selected, () => {
          handleCloseModal();
        });
      return;
    }
    if (assignResourceName === 'groups' && parentResourceName === 'roles') {
      addRolesToGroups(
        organizationCode, selected, (parentResource as Role[]).map(({ id }) => id), () => {
          handleCloseModal();
        });
      return;
    }
    if (assignResourceName === 'organizations' && parentResourceName === 'applications') {
      addOrganizationsToApplication(
        (parentResource as Application[])[0].code, selected, () => {
          handleCloseModal();
        });
      return;
    }
    if (assignResourceName === 'applications' && parentResourceName === 'organizations') {
      addApplicationsToOrganization(
        (parentResource as Organization[])[0].code, selected, () => {
          handleCloseModal();
        });
      return;
    }
  };

  const handleClearAllClick = () => {
    setSelected(initialSelected);
  };

  const handleCloseModal = () => {
    setSelected(initialSelected);
    setFilterText('');
    onClose();
  };

  const renderAssignToSubtitle = (items: (User | Group | Role | Application | Organization)[]): string => {
    let names: string[] = [];
    if (parentResourceName === 'users') {
      names = (items as User[]).map((i) => toFullName(i)).sort();
    } else {
      names = (items as (Group | Role | Application | Organization)[]).map((i) => i.name).sort();
    }
    let stringLength = 0;
    let itemList = '';
    for (let i = 0; i < names?.length; ++i) {
      if (stringLength + names[i]?.length <= 50) {
        itemList += `${names[i]}${i + 1 < items.length ? ', ' : ''}`;
        stringLength += names[i].length;
      } else {
        itemList += `+${items.length - i}`;
        break;
      }
    }
    return itemList;
  };

  const handleToggle = (id: Id) => () => {
    const currentIndex = selected.indexOf(id);
    const newSelected = [...selected];

    if (currentIndex === -1) {
      newSelected.push(id);
    } else {
      newSelected.splice(currentIndex, 1);
    }
    setSelected(newSelected);
  };

  const renderResults = () => {
    // Loading state
    if (isLoading) {
      return (
        <>
          <ListItem disableGutters className={classes.listItem}>
            <Skeleton width={500} height={60} animation="wave" />
          </ListItem>
          <ListItem disableGutters className={classes.listItem}>
            <Skeleton width={500} height={60} animation="wave" />
          </ListItem>
          <ListItem disableGutters className={classes.listItem}>
            <Skeleton width={500} height={60} animation="wave" />
          </ListItem>
        </>
      );

      // Zero results in redux state
    } else if (filteredResources.length === 0 && filterText === '' && !isLoading) {
      return (
        <ListItem disableGutters className={classes.noResults}>
          <Typography noWrap>{`There are currently no ${assignResourceName} to assign`}</Typography>
        </ListItem>
      );

      // No results from search filter state
    } else if (filteredResources.length === 0 && filterText !== '') {
      return (
        <ListItem disableGutters className={classes.noResults}>
          <Typography noWrap>{`No ${assignResourceName} matching "${filterText}"`}</Typography>
        </ListItem>
      );

      // Show assignable options
    } else {
      return (
        (filteredResources as (User | Role | Group | Organization | Application)[]).map((resource) => {
          const key = findOrCreateKey(resource, assignResourceName);
          return (
            <ListItemCheckbox
              assignableResource={resource}
              assignResourceName={assignResourceName}
              parentResources={parentResource}
              parentResourceName={parentResourceName}
              key={key}
              onClick={handleToggle(key)}
              selected={selected}
            />
          );
        })
      );
    }
  };

  const showClearButton = !isEqual(sortBy(selected), sortBy(initialSelected));
  return (
    <Dialog
      open={open}
      onClose={handleCloseModal}
      aria-labelledby="assign-modal-dialog"
      classes={{
        paper: classes.dialogPaper
      }}
    >
      <DialogTitle id="assign-modal-dialog" className={classes.titleContainer}>
        <Typography sx={{ fontSize: 20, fontWeight: 600 }}>Assign {toTitleCase(assignResourceName)}</Typography>
        {
          isLoading ? <Skeleton width={300} height={30} /> : (
            <Typography variant="body2" className={classes.subtitle}>
              To {renderAssignToSubtitle(parentResource)}
            </Typography>
          )
        }
      </DialogTitle>

      <DialogContent dividers className={classes.contentContainer}>
        <Box px={5}>
          <SearchInput
            autoFocus
            onChange={handleChange}
            onReset={() => setFilterText('')}
          />
        </Box>

        {/*
					Toolbar for number of selected and clear all
					button. Display when at least one assignable resource selected.
					*/}
        <Grid container className={classes.toolbar}>
          {
            selected.length > 0 &&
            <>
              <Grid xs={6} container item alignItems="flex-end">
                <Typography variant="body2" className={classes.numberSelected}>{selected.length} selected</Typography>
              </Grid>
              <Grid xs={6} container item alignItems="center" justifyContent="flex-end">
                {
                  showClearButton &&
                  <Link
                    component="button"
                    variant="body2"
                    onClick={() => handleClearAllClick()}
                    className={classes.clearAll}
                  >
                    Clear all
                  </Link>
                }
              </Grid>
            </>
          }
        </Grid>

        {/*
					Render various ListItem displays dependent on state
					*/}
        <List disablePadding>
          {renderResults()}
        </List>
      </DialogContent>

      <DialogActions className={classes.actionsContainer}>
        <Button onClick={handleCloseModal} color="primary" variant="outlined">
          Cancel
        </Button>
        <Button
          color="primary"
          size="large"
          variant="contained"
          onClick={handleAssignClick}
          disabled={selected.length === initialSelected.length || isAssigning}
          sx={{ ml: 1 }}
        >
          {isAssigning ? 'Assigning' : 'Assign'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const mapStateToProps = (state: UMAReduxState, props: AssignModalProps): StateFromProps => {
  let assignableResources;
  /*
    isLoading state is set to true if the assignTo resource
    or assignable resources are loading
  */
  let isLoading = props.parentResourceLoading;
  switch (props.assignResourceName) {
    case 'roles':
      if (props.parentResourceName === 'applications') {
        assignableResources = collectionSpread(state.roles).filter((role) => role.roleType === 'application');
      } else {
        assignableResources = collectionSpread(state.roles);
      }
      isLoading = isLoading || state.roles.isLoading;
      break;

    case 'users':
      assignableResources = collectionSpread(state.users);
      isLoading = isLoading || state.users.isLoading;
      break;

    case 'groups':
      assignableResources = collectionSpread(state.groups);
      isLoading = isLoading || state.groups.isLoading;
      break;

    case 'organizations':
      assignableResources = collectionSpread(state.organizations);
      isLoading = isLoading || state.organizations.isLoading;
      break;

    case 'applications':
      assignableResources = collectionSpread(state.applications);
      isLoading = isLoading || state.applications.isLoading;
      break;

    /*
    The assignableResource cannot be undefined so
    users will be assigned the default. This should never
    happen since assignResourceName is a required prop
  */
    default:
      assignableResources = collectionSpread(state.users);
      isLoading = isLoading || state.users.isLoading;
  }
  return ({
    assignableResources,
    isLoading,
    organizationCode: state.context.organizationCode,
  });
};

const mapDispatchToProps: DispatchFromProps = {
  addApplicationsToOrganization,
  addOrganizationsToApplication,
  addRolesToGroups,
  addUsersToGroups,
  getApplications,
  getGroups,
  getOrganizations,
  getRoles,
  getUsers,
};

export default connect(
  mapStateToProps, mapDispatchToProps
)(AssignModal);
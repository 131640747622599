import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import {
  Box,
  Breadcrumbs,
  Button,
  Grid,
  Link,
  Typography
} from '@mui/material';

import { UMAReduxState } from '../../../store/rootReducer';
import { Id } from '../../../types/types';
import { GroupsState } from '../../../store/Groups/GroupModels';
import { Role } from '../../../store/Roles/RoleModels';
import { removeRolesFromGroup } from '../../../store/Groups/GroupActions';
import { deleteRoles, exportRoles, getRoleDetailsById, updateRole } from '../../../store/Roles/RoleActions';
import AssignGroupsModal from '../../AssignModal/AssignModal';
import DeleteRolesModal from '../../DeleteModal/DeleteModal';
import GroupsList from '../../FilterList/FilterList';
import PolicyRulesDataGrid from '../../PolicyRulesDataGrid/PolicyRulesDataGrid';
import RoleForm from './EditRoleForm';
import useStyles from './styles';
import { Application } from '../../../store/Applications/ApplicationModels';
import policyConstants from '../../../constants/policyConstants';
import { useCasbin } from '../../../hooks/useCasbin';
import If from '../../If/If';
import { getDeleteRolePolicy, getUpdateRolePolicy } from '../../../utility/rolePermissionHelper';
import Can from '../../Can/Can';


type StateToProps = {
  role: Role,
  application?: Application;
  isAssigning: boolean;
  isDeleting: boolean;
  isLoading: boolean;
  isUpdatingDescription: boolean;
  isUpdatingName: boolean;
  groupsState: GroupsState;
  organizationCode: string;
};

type DispatchToProps = {
  exportRoles: (organizationCode: Id, roleIds: Id[]) => void;
  deleteRoles: (orgCode: string, role: Role[]) => void;
  getRoleDetailsById: (organizationCode: string, roleId: Id) => void;
  removeRolesFromGroup: (orgCode: string, groupId: Id, roleIds: Id[]) => void;
  updateRole: (organizationCode: string, applicationCode: string, role: Partial<Role>, callbacksOnSuccess: () => void) => void;
};

type RoleDetailPageProps = StateToProps & DispatchToProps;

function RoleDetailPage({
  application,
  groupsState,
  isAssigning,
  isDeleting,
  isLoading,
  isUpdatingDescription,
  isUpdatingName,
  role,
  organizationCode,
  getRoleDetailsById,
  removeRolesFromGroup,
  exportRoles,
}: RoleDetailPageProps) {
  const classes = useStyles();
  const history = useHistory();

  const [assignGroupsModalOpen, setAssignGroupsModalOpen] = useState(false);
  const [deleteRolesModalOpen, setDeleteRolesModalOpen] = useState(false);

  useEffect(() => {
    getRoleDetailsById(organizationCode, role.id);
  }, [getRoleDetailsById, organizationCode, role.id]);

  const handleAssignGroupsModalClose = () => setAssignGroupsModalOpen(false);
  const handleAssignGroupsModalOpen = () => setAssignGroupsModalOpen(true);

  const handleGroupDeleteClick = (groupId: Id) => removeRolesFromGroup(organizationCode, groupId, [role.id]);

  const handleBreadcrumbClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    history.push('/roles');
  };

  const handleExportRoleClick = () => exportRoles(organizationCode, [role.id]);
  const handleDeleteRoleClick = () => setDeleteRolesModalOpen(true);
  const handleDeleteRolesModalClose = () => setDeleteRolesModalOpen(false);

  const organizationRoleType = role.roleType === 'organization';

  const canUpdateRolePolicyRequest = getUpdateRolePolicy(role, organizationCode, application?.code);
  const canDeleteRolePolicyRequest = getDeleteRolePolicy(role, organizationCode, role.application?.code ?? '');
  const canUpdateRole = useCasbin(canUpdateRolePolicyRequest);
  const canDeleteRole = useCasbin(canDeleteRolePolicyRequest);

  return (
    <div>
      <Grid container justifyContent="space-between" className={classes.header}>
        <Grid item>
          <Breadcrumbs className={classes.breadcrumbs} separator="›" aria-label="breadcrumb">
            <Link
              className={classes.baseBreadcrumb}
              color="inherit"
              href="/roles"
              onClick={handleBreadcrumbClick}
              variant="body2"
            >
              Roles
            </Link>
            <Typography variant="body2">Role Details</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <Can requiredPolicy={policyConstants.roles.exportRoles(organizationCode)}>
            <Button
              color="primary"
              size="large"
              variant="contained"
              onClick={handleExportRoleClick}
              disabled={isLoading}
              style={{ marginRight: '36px' }}
            >
              Export Role
            </Button>
          </Can>
          <If condition={canDeleteRole}>
            <Button
              color="error"
              size="large"
              onClick={handleDeleteRoleClick}
              disabled={isLoading}
              variant="outlined"
            >
              Delete Role
            </Button>
          </If>
        </Grid>
      </Grid>
      <Box marginBottom={2}>
        <If condition={canUpdateRole}>
          <RoleForm
            isLoading={isLoading}
            isUpdatingDescription={isUpdatingDescription}
            isUpdatingName={isUpdatingName}
            organizationCode={organizationCode}
            role={role}
          />
        </If>
        <If condition={!canUpdateRole}>
          <Typography variant="h3" marginBottom={2}>{role.name}</Typography>
          <If condition={!!role.description}><Typography variant="h6">Description</Typography></If>
          <Typography>{role.description}</Typography>
        </If>
      </Box>
      <Grid container spacing={3}>
        <Can requiredPolicy={policyConstants.roles.getById(organizationCode, role.id)}>
          <Grid xs={12} md={12} item>
            <GroupsList
              parentResourceName="roles"
              addItemProps={{
                handleAddClick: handleAssignGroupsModalOpen,
                requiredPolicy: policyConstants.groups.addRoleAny(organizationCode)
              }}
              removeItemProps={{
                handleDeleteClick: handleGroupDeleteClick,
                policyFunction: policyConstants.groups.removeRoles,
                policyFunctionParams: [organizationCode]
              }}
              readItemProps={{
                policyFunction: policyConstants.groups.getById,
                policyFunctionParams: [organizationCode]
              }}
              isLoading={isLoading}
              listResourceName="groups"
              listResources={role.groupIds !== undefined ? role.groupIds.map((id) => groupsState.byId[id]) : []}
            />
          </Grid>
        </Can>
        <If condition={organizationRoleType}>
          <Can requiredPolicy={policyConstants.policyRules.getByRole(organizationCode, role.id)}>
            <Grid xs={12} md={12} item>
              <PolicyRulesDataGrid role={role} />
            </Grid>
          </Can>
        </If>
      </Grid>
      <AssignGroupsModal
        parentResourceLoading={isLoading}
        assignResourceName="groups"
        parentResource={[role]}
        parentResourceName="roles"
        isAssigning={isAssigning}
        onClose={handleAssignGroupsModalClose}
        open={assignGroupsModalOpen}
      />
      <DeleteRolesModal
        resourceName="roles"
        isDeleting={isDeleting}
        onClose={handleDeleteRolesModalClose}
        onDelete={() => history.goBack()}
        open={deleteRolesModalOpen}
        resources={[role]}
      />
    </div >
  );
}

function mapStateToProps(state: UMAReduxState, ownProps: any): StateToProps {
  const roleId = ownProps.match.params.roleId;
  const defaultRole = { applicationId: null, description: '', groupIds: [], id: roleId, name: '' };
  const role = state.roles.byId[roleId] ?? defaultRole;

  const application = role.applicationId ? Object.values(state.applications.byId).find((app) => app.id === role.applicationId) : undefined;

  return {
    role,
    application,
    isAssigning: state.groups.isAssigning,
    isDeleting: state.roles.isDeleting,
    isLoading: state.roles.byId[roleId]?.isLoading ?? true,
    isUpdatingDescription: state.roles.byId[roleId]?.isUpdatingDescription ?? false,
    isUpdatingName: state.roles.byId[roleId]?.isUpdatingName ?? false,
    groupsState: state.groups,
    organizationCode: state.context.organizationCode,
  };
}

const mapDispatchToProps: DispatchToProps = {
  exportRoles,
  deleteRoles,
  getRoleDetailsById,
  removeRolesFromGroup,
  updateRole
};

export default connect(mapStateToProps, mapDispatchToProps)(RoleDetailPage);

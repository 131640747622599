import { AnyAction } from 'redux';
import { merge, without, union, omit } from 'lodash';

import {
  Application,
  ApplicationsState
} from './ApplicationModels';
import { CONTEXT_RESET } from '../Context/ContextActions';
import { FILTER_ORGANIZATION_ROLES } from '../Roles/RoleActions';
import {
  GET_APPLICATIONS_REQUESTED,
  GET_APPLICATIONS_REQUEST_SUCCESSFUL,
  GET_APPLICATIONS_REQUEST_FAILED,
  GET_APPLICATION_BY_CODE_REQUESTED,
  GET_APPLICATION_BY_CODE_REQUEST_SUCCESSFUL,
  GET_APPLICATION_BY_CODE_REQUEST_FAILED,
  GET_APPLICATION_ORGANIZATIONS_BY_APP_CODE_REQUESTED,
  GET_APPLICATION_ORGANIZATIONS_BY_APP_CODE_REQUEST_SUCCESSFUL,
  GET_APPLICATION_ORGANIZATIONS_BY_APP_CODE_REQUEST_FAILED,
  GET_APPLICATION_ROLES_BY_APP_CODE_REQUESTED,
  GET_APPLICATION_ROLES_BY_APP_CODE_REQUEST_SUCCESSFUL,
  GET_APPLICATION_ROLES_BY_APP_CODE_REQUEST_FAILED,
  CREATE_APPLICATION_REQUESTED,
  CREATE_APPLICATION_REQUEST_SUCCESSFUL,
  CREATE_APPLICATION_REQUEST_FAILED,
  ADD_PERMISSIONS_TO_APPLICATION_REQUESTED,
  ADD_PERMISSIONS_TO_APPLICATION_REQUEST_SUCCESSFUL,
  ADD_PERMISSIONS_TO_APPLICATION_REQUEST_FAILED,
  ADD_ORGANIZATIONS_TO_APPLICATION_REQUESTED,
  ADD_ORGANIZATIONS_TO_APPLICATION_REQUEST_SUCCESSFUL,
  ADD_ORGANIZATIONS_TO_APPLICATION_REQUEST_FAILED,
  ADD_ROLES_TO_APPLICATION_REQUESTED,
  ADD_ROLES_TO_APPLICATION_REQUEST_SUCCESSFUL,
  ADD_ROLES_TO_APPLICATION_REQUEST_FAILED,
  REMOVE_PERMISSIONS_FROM_APPLICATION_REQUEST_SUCCESSFUL,
  REMOVE_ORGANIZATIONS_FROM_APPLICATION_REQUEST_SUCCESSFUL,
  REMOVE_ROLES_FROM_APPLICATION_REQUEST_SUCCESSFUL,
  UPDATE_APPLICATION_REQUEST_SUCCESSFUL,
  UPDATE_APPLICATION_REQUEST_FAILED,
  UPDATE_APPLICATION_REQUESTED,
  DELETE_APPLICATION_REQUESTED,
  DELETE_APPLICATION_REQUEST_FAILED,
  DELETE_APPLICATION_REQUEST_SUCCESSFUL
} from './ApplicationActions';
import {
  ADD_APPLICATIONS_TO_ORGANIZATION_REQUEST_SUCCESSFUL,
  REMOVE_APPLICATIONS_FROM_ORGANIZATION_REQUEST_SUCCESSFUL
} from '../Organizations/OrganizationActions';
import { ById, Id } from '../../types/types';

export const initialState: ApplicationsState = {
  allIds: [],
  byId: {},
  isAssigning: false,
  isCreating: false,
  isLoading: false,
  isDeleting: false,
};

function applications(state: ApplicationsState = initialState, action: AnyAction): ApplicationsState {
  let byId: ById<Application>;
  let allIds: Id[];
  switch (action.type) {
    case GET_APPLICATIONS_REQUESTED:
      return {
        ...state,
        isLoading: true
      };

    case GET_APPLICATIONS_REQUEST_SUCCESSFUL:
      byId = { ...state.byId };
      action.allIds.forEach((id: string) => {
        byId[id] = merge({}, state.byId[id], action.byId[id]);
      });
      return {
        ...state,
        allIds: union(state.allIds.slice(), action.allIds),
        byId,
        isLoading: false,
      };

    case GET_APPLICATIONS_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false
      };

    case GET_APPLICATION_BY_CODE_REQUESTED:
      allIds = state.allIds.slice();
      byId = { ...state.byId };
      if (!allIds.includes(action.id)) {
        allIds = allIds.concat(action.id);
      }
      if (!byId[action.id]) {
        (byId[action.id] as Partial<Application>) = {};
      }
      allIds.forEach((id) => {
        if (id !== action.id) {
          byId[id] = { ...state.byId[id] };
          return;
        }

        byId[id] = {
          ...state.byId[id],
          isLoading: true
        };
      });
      return {
        ...state,
        allIds,
        byId
      };

    case GET_APPLICATION_BY_CODE_REQUEST_SUCCESSFUL:
      byId = {};
      state.allIds.forEach((id) => {
        if (id !== action.id) {
          byId[id] = { ...state.byId[id] };
          return;
        }

        action.byId[id].isLoading = false;
        byId[id] = merge({}, state.byId[id], action.byId[id]);
      });
      return {
        ...state,
        byId
      };

    case GET_APPLICATION_BY_CODE_REQUEST_FAILED:
      byId = {};
      allIds = state.allIds.slice();
      state.allIds.forEach((id) => {
        if (id !== action.id) {
          byId[id] = { ...state.byId[id] };
          return;
        }

        byId[id] = { ...state.byId[id], isLoading: false };
      });
      if (!byId[action.id]?.id) {
        delete byId[action.id];
        allIds = without(state.allIds, action.id);
      }
      return {
        ...state,
        allIds,
        byId
      };

    case GET_APPLICATION_ORGANIZATIONS_BY_APP_CODE_REQUESTED:
      allIds = state.allIds.slice();
      byId = { ...state.byId };
      if (!allIds.includes(action.id)) {
        allIds = allIds.concat(action.id);
      }
      if (!byId[action.id]) {
        (byId[action.id] as Partial<Application>) = {};
      }
      allIds.forEach((id) => {
        if (id !== action.id) {
          byId[id] = { ...state.byId[id] };
          return;
        }

        byId[id] = {
          ...state.byId[id],
          organizationsLoading: true
        };
      });
      return {
        ...state,
        allIds,
        byId
      };

    case GET_APPLICATION_ORGANIZATIONS_BY_APP_CODE_REQUEST_SUCCESSFUL:
      byId = {};
      state.allIds.forEach((id) => {
        if (id !== action.id) {
          byId[id] = { ...state.byId[id] };
          return;
        }

        byId[id] = {
          ...state.byId[id],
          organizationCodes: union(state.byId[id].organizationCodes, action.organizationCodes),
          organizationsLoading: false,
        };
      });
      return {
        ...state,
        byId
      };

    case GET_APPLICATION_ORGANIZATIONS_BY_APP_CODE_REQUEST_FAILED:
      byId = {};
      allIds = state.allIds.slice();
      state.allIds.forEach((id) => {
        if (id !== action.id) {
          byId[id] = { ...state.byId[id] };
          return;
        }

        byId[id] = { ...state.byId[id], organizationsLoading: false };
      });
      if (!byId[action.id]?.id) {
        delete byId[action.id];
        allIds = without(state.allIds, action.id);
      }
      return {
        ...state,
        allIds,
        byId
      };

    case GET_APPLICATION_ROLES_BY_APP_CODE_REQUESTED:
      allIds = state.allIds.slice();
      byId = { ...state.byId };
      if (!allIds.includes(action.id)) {
        allIds = allIds.concat(action.id);
      }
      if (!byId[action.id]) {
        (byId[action.id] as Partial<Application>) = {};
      }
      allIds.forEach((id) => {
        if (id !== action.id) {
          byId[id] = { ...state.byId[id] };
          return;
        }

        byId[id] = {
          ...state.byId[id],
          rolesLoading: true
        };
      });
      return {
        ...state,
        allIds,
        byId
      };

    case GET_APPLICATION_ROLES_BY_APP_CODE_REQUEST_SUCCESSFUL:
      byId = {};
      state.allIds.forEach((id) => {
        if (id !== action.id) {
          byId[id] = { ...state.byId[id] };
          return;
        }

        byId[id] = {
          ...state.byId[id],
          roleIds: union(state.byId[id].roleIds, action.roleIds),
          rolesLoading: false,
        };
      });
      return {
        ...state,
        byId
      };

    case GET_APPLICATION_ROLES_BY_APP_CODE_REQUEST_FAILED:
      byId = {};
      allIds = state.allIds.slice();
      state.allIds.forEach((id) => {
        if (id !== action.id) {
          byId[id] = { ...state.byId[id] };
          return;
        }

        byId[id] = { ...state.byId[id], rolesLoading: false };
      });
      if (!byId[action.id]?.id) {
        delete byId[action.id];
        allIds = without(state.allIds, action.id);
      }
      return {
        ...state,
        allIds,
        byId
      };

    case CREATE_APPLICATION_REQUESTED:
      return {
        ...state,
        isCreating: true
      };

    case CREATE_APPLICATION_REQUEST_SUCCESSFUL:
      return {
        ...state,
        allIds: union(state.allIds.slice(), action.allIds),
        byId: merge({}, state.byId, action.byId),
        isCreating: false,
      };

    case CREATE_APPLICATION_REQUEST_FAILED:
      return {
        ...state,
        isCreating: false
      };

    case UPDATE_APPLICATION_REQUESTED:
      allIds = [...state.allIds];
      byId = { ...state.byId };
      if (!allIds.includes(action.id)) {
        allIds = allIds.concat(action.id);
      }
      if (!byId[action.id]) {
        (byId[action.id] as Partial<Application>) = {};
      }
      allIds.forEach((id) => {
        if (id !== action.id) {
          byId[id] = { ...state.byId[id] };
          return;
        }

        byId[id] = {
          ...state.byId[id],
          isUpdating: true
        };
      });
      return {
        ...state,
        allIds,
        byId
      };

    case UPDATE_APPLICATION_REQUEST_SUCCESSFUL:
      byId = {};
      state.allIds.forEach((id) => {
        if (id !== action.id) {
          byId[id] = { ...state.byId[id] };
          return;
        }

        byId[id] = merge({}, state.byId[id], action.byId[id], { isUpdating: false });
      });
      return {
        ...state,
        byId
      };

    case UPDATE_APPLICATION_REQUEST_FAILED:
      byId = {};
      allIds = [...state.allIds];
      state.allIds.forEach((id) => {
        if (id !== action.id) {
          byId[id] = { ...state.byId[id] };
          return;
        }

        byId[id] = { ...state.byId[id], isUpdating: false };
      });
      if (!byId[action.id]?.code) {
        delete byId[action.id];
        allIds = without(state.allIds, action.id);
      }
      return {
        ...state,
        allIds,
        byId
      };

    case DELETE_APPLICATION_REQUESTED:
      return {
        ...state,
        isDeleting: true
      };

    case DELETE_APPLICATION_REQUEST_SUCCESSFUL:
      return {
        ...state,
        allIds: without(state.allIds, action.id),
        byId: omit(state.byId, action.id),
        isDeleting: false
      };

    case DELETE_APPLICATION_REQUEST_FAILED:
      return {
        ...state,
        isDeleting: false
      };

    case ADD_PERMISSIONS_TO_APPLICATION_REQUESTED:
      return {
        ...state,
        isAssigning: true
      };

    case ADD_PERMISSIONS_TO_APPLICATION_REQUEST_SUCCESSFUL:
      byId = { ...state.byId };
      byId[action.id] = {
        ...byId[action.id],
        permissions: union(byId[action.id].permissions, action.permissions)
      };
      return {
        ...state,
        byId,
        isAssigning: false,
      };

    case ADD_PERMISSIONS_TO_APPLICATION_REQUEST_FAILED:
      return {
        ...state,
        isAssigning: false
      };

    case REMOVE_PERMISSIONS_FROM_APPLICATION_REQUEST_SUCCESSFUL:
      byId = { ...state.byId };
      byId[action.id] = {
        ...byId[action.id],
        permissions: without(byId[action.id].permissions, ...action.permissions)
      };
      return {
        ...state,
        byId
      };

    case ADD_ORGANIZATIONS_TO_APPLICATION_REQUESTED:
      return {
        ...state,
        isAssigning: true
      };

    case ADD_ORGANIZATIONS_TO_APPLICATION_REQUEST_SUCCESSFUL:
      byId = { ...state.byId };
      byId[action.id] = {
        ...byId[action.id],
        organizationCodes: union(byId[action.id].organizationCodes, action.organizationCodes)
      };
      return {
        ...state,
        byId,
        isAssigning: false,
      };

    case ADD_ORGANIZATIONS_TO_APPLICATION_REQUEST_FAILED:
      return {
        ...state,
        isAssigning: false
      };

    case REMOVE_ORGANIZATIONS_FROM_APPLICATION_REQUEST_SUCCESSFUL:
      byId = { ...state.byId };
      byId[action.id] = {
        ...byId[action.id],
        organizationCodes: without(byId[action.id].organizationCodes, ...action.organizationCodes)
      };
      return {
        ...state,
        byId
      };

    case ADD_ROLES_TO_APPLICATION_REQUESTED:
      return {
        ...state,
        isAssigning: true
      };

    case ADD_ROLES_TO_APPLICATION_REQUEST_SUCCESSFUL:
      byId = { ...state.byId };
      byId[action.id] = {
        ...byId[action.id],
        roleIds: union(byId[action.id].roleIds, action.roleIds)
      };
      return {
        ...state,
        byId,
        isAssigning: false,
      };

    case ADD_ROLES_TO_APPLICATION_REQUEST_FAILED:
      return {
        ...state,
        isAssigning: false
      };

    case REMOVE_ROLES_FROM_APPLICATION_REQUEST_SUCCESSFUL:
      byId = { ...state.byId };
      byId[action.id] = {
        ...byId[action.id],
        roleIds: without(byId[action.id].roleIds, ...action.roleIds)
      };
      return {
        ...state,
        byId
      };

    // Context Reset
    case FILTER_ORGANIZATION_ROLES:
    case CONTEXT_RESET:
      /*
        Remove Application role ids when context switches or roles reducer is filtered to organization roles
        to prevent mapping through Role objects that don't exist.
        */
      allIds = state.allIds.slice();
      byId = { ...state.byId };
      allIds.map((id) => byId[id].roleIds = []);
      return {
        ...initialState,
        allIds,
        byId
      };

    case ADD_APPLICATIONS_TO_ORGANIZATION_REQUEST_SUCCESSFUL:
      allIds = state.allIds.slice();
      byId = { ...state.byId };
      allIds.forEach((id) => {
        if (action.applicationCodes.includes(id)) {
          byId[id] = {
            ...state.byId[id],
            organizationCodes: union(byId[id].organizationCodes, action.id)
          };
        } else {
          byId[id] = { ...state.byId[id] };
        }
      });
      return {
        ...state,
        byId
      };

    case REMOVE_APPLICATIONS_FROM_ORGANIZATION_REQUEST_SUCCESSFUL:
      allIds = state.allIds.slice();
      byId = { ...state.byId };
      allIds.forEach((id) => {
        if (action.applicationCodes.includes(id)) {
          byId[id] = {
            ...state.byId[id],
            organizationCodes: without(byId[id].organizationCodes, action.id)
          };
        } else {
          byId[id] = { ...state.byId[id] };
        }
      });
      return {
        ...state,
        byId
      };

    default:
      return state;
  }
}

export default applications;
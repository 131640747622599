import environment from '../environment';
import { Id } from '../types/types';
import { PolicyRule } from '../store/PolicyRules/PolicyRuleModels';
import {
  handleErrors,
  retrieveAccessToken
} from '../utility';

const { apiBasePath } = environment;

export function getPolicyRulesByRole(organizationCode: Id, roleId: Id): Promise<PolicyRule[]> {
  return fetch(`${apiBasePath}/organization/${organizationCode}/role/${roleId}/policy`, {
    headers: {
      'Authorization': `Bearer ${retrieveAccessToken()}`
    }
  }).then(handleErrors).then((response) => response.json() as Promise<PolicyRule[]>);
}

export function createPolicyRule(policyRule: PolicyRule): Promise<PolicyRule> {
  const { organizationCode, roleId } = policyRule;
  return fetch(`${apiBasePath}/organization/${organizationCode}/role/${roleId}/policy`, {
    body: JSON.stringify(policyRule),
    headers: {
      'Authorization': `Bearer ${retrieveAccessToken()}`,
      'Content-Type': 'application/json'
    },
    method: 'POST',
  }).then(handleErrors).then((response) => response.json());
}

export function updatePolicyRule(policyRule: Partial<PolicyRule>): Promise<PolicyRule> {
  const { organizationCode, roleId, id } = policyRule;
  return fetch(`${apiBasePath}/organization/${organizationCode}/role/${roleId}/policy/${id}`, {
    body: JSON.stringify(policyRule),
    headers: {
      'Authorization': `Bearer ${retrieveAccessToken()}`,
      'Content-Type': 'application/json'
    },
    method: 'PUT',
  }).then(handleErrors).then((response) => response.json());
}

export function deletePolicyRule(organizationCode: Id, roleId: Id, id: Id) {
  return fetch(`${apiBasePath}/organization/${organizationCode}/role/${roleId}/policy/${id}`, {
    headers: {
      'Authorization': `Bearer ${retrieveAccessToken()}`,
      'Content-Type': 'application/json'
    },
    method: 'DELETE'
  }).then(handleErrors).then((response) => response);
}
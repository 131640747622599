import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import {
  Button,
  Chip,
  Grid,
  Toolbar,
  Typography
} from '@mui/material';
import clsx from 'clsx';

import { UMAReduxState } from '../../store/rootReducer';
import { restoreContext } from '../../store/Context/ContextActions';
import useStyles from './styles';

export interface ContextBannerStyleProps {
	contentShift: boolean;
	shiftWidth: number;
}

interface ContextBannerProps {
	restoreContext: () => void;
	isAlternativeContext: boolean;
	organizationCode: string;
	organizationName: string;
}

type Props = ContextBannerProps & ContextBannerStyleProps;

function ContextBanner({
  contentShift,
  isAlternativeContext = false,
  organizationCode,
  organizationName = '',
  shiftWidth,
  restoreContext
}: Props) {
  const classes = useStyles({ contentShift, shiftWidth });
  const contextLoading = useSelector((state: UMAReduxState) => state.context.isLoading);
  const usersLoading = useSelector((state: UMAReduxState) => state.users.isLoading);
  const groupsLoading = useSelector((state: UMAReduxState) => state.groups.isLoading);
  const clientsLoading = useSelector((state: UMAReduxState) => state.clients.isLoading);
  const rolesLoading = useSelector((state: UMAReduxState) => state.roles.isLoading);
  const policyRulesLoading = useSelector((state: UMAReduxState) => state.policyRules.isLoading);

  const [collapsed, setCollapsed] = useState<boolean>(!isAlternativeContext);

  const handleExitButtonClick = () => {
    restoreContext();
    setCollapsed(true);
  };

  useEffect(() => {
    setCollapsed(!isAlternativeContext);
  }, [isAlternativeContext]);

  const disabled = usersLoading || groupsLoading || clientsLoading || rolesLoading || policyRulesLoading || contextLoading;
  return (
    <React.Fragment>
      <Toolbar className={clsx(classes.root, {
        [classes.contentShift]: contentShift,
        [classes.collapsed]: collapsed
      })}>
        {
          contextLoading ? (
            <Typography variant="body2" className={classes.contextMessage}>You are now switching contexts...</Typography>
          ) : (
            <Grid>
              <Chip
                className={classes.chipRoot}
                label={organizationCode}
                variant="outlined"
                size="small"
              />
              <Typography variant="body2" className={classes.contextMessage}>
								You are now viewing &quot;{organizationName}&quot;
              </Typography>
            </Grid>
          )
        }
        <Button
          className={classes.button}
          onClick={handleExitButtonClick}
          disabled={disabled}
        >
					EXIT
        </Button>
      </Toolbar>
    </React.Fragment>);
}

const mapStateToProps = (state: UMAReduxState,) => {
  const organizationCode = state.context.organizationCode;
  return ({
    isAlternativeContext: state.context ? state.context.isSwitched : false,
    organizationCode,
    organizationName: state.organizations.byId[organizationCode]?.name ?? ''
  });
};

const mapDispatchToProps = {
  restoreContext
};

export default connect(mapStateToProps, mapDispatchToProps)(ContextBanner);


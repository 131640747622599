import React from 'react';
import { Controller, ControllerProps } from 'react-hook-form';
import {
  Autocomplete,
  TextField
} from '@mui/material';

import { CreateRoleFormValues, RoleType } from '../../../store/Roles/RoleModels';
import { toTitleCase } from '../../../utility';

interface RoleTypeFieldProps {
  onChange?: () => void;
  roleTypes: RoleType[];
}

type Props = Partial<ControllerProps<CreateRoleFormValues>> & RoleTypeFieldProps

const RoleTypeField = ({ control, roleTypes, onChange }: Props) => {

  return (
    <Controller
      name="roleType"
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          {...field}
          options={roleTypes}
          getOptionLabel={(option: RoleType) => toTitleCase(option)}
          onChange={(event, option) => {
            onChange?.();
            field.onChange(option);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label=""
              variant="outlined"
              fullWidth
              inputProps={{
                ...params.inputProps,
                autoComplete: 'disabled' // disable autocomplete and autofill
              }}
              error={Boolean(error)}
              helperText={error?.message ?? ''}
            />
          )}
        />
      )}
    />
  );
};

export default RoleTypeField;
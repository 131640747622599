import React from 'react';
import {
  Box,
  Skeleton,
} from '@mui/material';

function TextAreaInputSkeleton() {
  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Skeleton width="100%" />
      <Skeleton width="100%" />
      <Skeleton width="100%" />
    </Box>
  );
}

export default React.forwardRef<any, any>(TextAreaInputSkeleton);
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Controller } from 'react-hook-form';
import { MenuItem, TextField, TextFieldProps } from '@mui/material';

import { getApplicationPermissions } from '../../selectors/getApplicationPermissions';
import { getApplicationByCodeInOrg } from '../../store/Applications/ApplicationActions';
import { Permission } from '../../store/Applications/ApplicationModels';
import { UMAReduxState } from '../../store/rootReducer';
import { toTitleCase } from '../../utility';
import useStyles from './styles';

interface PermissionFieldProps {
	applicationCode: string;
	control: any;
}

type Props = PermissionFieldProps & TextFieldProps;

const PermissionField = ({ applicationCode, control, ...props }: Props) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const permissions: Permission[] = useSelector((state: UMAReduxState) => getApplicationPermissions(state, { applicationCode }));
  const isLoading: boolean = useSelector((state: UMAReduxState) => state.applications.byId[applicationCode]?.isLoading ?? true);

  const organizationCode = useSelector((state: UMAReduxState) => state.context.organizationCode);

  useEffect(() => {
    if (applicationCode) {
      dispatch(getApplicationByCodeInOrg(organizationCode, applicationCode));
    }
  }, [applicationCode, organizationCode, dispatch]);

  const noOptions = !isLoading && !permissions.length;
  return (
    <Controller
      name="permission"
      control={control}
      defaultValue=""
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...props}
          {...field}
          fullWidth
          select
          label={props.label}
          classes={{
            root: classes.selectRoot
          }}
          FormHelperTextProps={{
            'aria-label': 'permission-field-helper-text',
          }}
          inputProps={{
            'aria-label': 'permission-dropdown',
            'data-testid': 'permission-field'
          }}
          error={Boolean(error)}
          helperText={error?.message ?? ''}
        >
          <MenuItem className={classes.noneSelected} data-testid="permission-option" value=""><em>{noOptions ? 'No Options' : 'None'}</em></MenuItem>
          {permissions.map((permission) => (
            <MenuItem key={permission} value={permission} data-testid="permission-option">{toTitleCase(permission)}</MenuItem>
          ))}
        </TextField>
      )}
    />
  );
};

export default PermissionField;
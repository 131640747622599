import environment from '../environment';
import { Id } from '../types/types';
import { User } from '../store/Users/UserModels';
import { Group } from '../store/Groups/GroupModels';
import { handleErrors, retrieveAccessToken } from '../utility';

const { apiBasePath } = environment;

export function getUsers(organizationCode: Id): Promise<User[]> {
  return fetch(`${apiBasePath}/organization/${organizationCode}/user`, {
    headers: {
      'Authorization': `Bearer ${retrieveAccessToken()}`
    }
  }).then(handleErrors).then((response) => response.json() as Promise<User[]>);
}

export function getUsersWithGroupIds(organizationCode: Id): Promise<User[]> {
  return fetch(`${apiBasePath}/organization/${organizationCode}/user/groups`, {
    headers: {
      'Authorization': `Bearer ${retrieveAccessToken()}`
    }
  }).then(handleErrors).then((response) => response.json() as Promise<User[]>);
}

export function getById(organizationCode: Id, userId: Id): Promise<User> {
  return fetch(`${apiBasePath}/organization/${organizationCode}/user/${userId}`, {
    headers: {
      'Authorization': `Bearer ${retrieveAccessToken()}`
    }
  }).then(handleErrors).then((response) => response.json() as Promise<User>);
}

export function getUserGroupsById(organizationCode: Id, userId: Id): Promise<Group[]> {
  return fetch(`${apiBasePath}/organization/${organizationCode}/user/${userId}/groups`, {
    headers: {
      'Authorization': `Bearer ${retrieveAccessToken()}`
    }
  }).then(handleErrors).then((response) => response.json() as Promise<Group[]>);
}

export function getDeprovisionedUsers(organizationCode: Id): Promise<User[]> {
  return fetch(`${apiBasePath}/organization/${organizationCode}/user/deprovisioned`, {
    headers: {
      'Authorization': `Bearer ${retrieveAccessToken()}`
    }
  }).then(handleErrors).then((response) => response.json() as Promise<User[]>);
}

export function importAllOktaUsers(): Promise<User[]> {
  return fetch(`${apiBasePath}/user/import`, {
    headers: {
      'Authorization': `Bearer ${retrieveAccessToken()}`,
      'Content-Type': 'application/json'
    },
    method: 'POST',
  }).then(handleErrors).then((response) => response.json());
}

export function importOktaUsersByOrg(organizationCode: Id): Promise<User[]> {
  return fetch(`${apiBasePath}/organization/${organizationCode}/user/import`, {
    headers: {
      'Authorization': `Bearer ${retrieveAccessToken()}`,
      'Content-Type': 'application/json'
    },
    method: 'POST',
  }).then(handleErrors).then((response) => response.json());
}

export function create(organizationCode: Id, user: Partial<User>) {
  return fetch(`${apiBasePath}/organization/${organizationCode}/user`, {
    body: JSON.stringify(user),
    headers: {
      'Authorization': `Bearer ${retrieveAccessToken()}`,
      'Content-Type': 'application/json'
    },
    method: 'POST',
  }).then(handleErrors).then((response) => response.json());
}

export function update(organizationCode: Id, user: Partial<User>) {
  return fetch(`${apiBasePath}/organization/${organizationCode}/user/${user.id}`, {
    body: JSON.stringify(user),
    headers: {
      'Authorization': `Bearer ${retrieveAccessToken()}`,
      'Content-Type': 'application/json'
    },
    method: 'PUT',
  }).then(handleErrors).then((response) => response.json());
}

export function deleteUser(organizationCode: Id, userId: Id) {
  return fetch(`${apiBasePath}/organization/${organizationCode}/user/${userId}`, {
    headers: {
      'Authorization': `Bearer ${retrieveAccessToken()}`
    },
    method: 'DELETE',
  }).then(handleErrors).then((response) => response);
}

/* User Status Changes */

export function activate(orgCode: Id, userId: Id) {
  return fetch(`${apiBasePath}/organization/${orgCode}/user/${userId}/activate`, {
    headers: {
      'Authorization': `Bearer ${retrieveAccessToken()}`,
      'Content-Type': 'application/json'
    },
    method: 'POST',
  }).then(handleErrors).then((response) => response);
}

export function deactivate(orgCode: Id, userId: Id) {
  return fetch(`${apiBasePath}/organization/${orgCode}/user/${userId}/deactivate`, {
    headers: {
      'Authorization': `Bearer ${retrieveAccessToken()}`,
      'Content-Type': 'application/json'
    },
    method: 'POST',
  }).then(handleErrors).then((response) => response);
}

export function reactivate(orgCode: Id, userId: Id) {
  return fetch(`${apiBasePath}/organization/${orgCode}/user/${userId}/reactivate`, {
    headers: {
      'Authorization': `Bearer ${retrieveAccessToken()}`,
      'Content-Type': 'application/json'
    },
    method: 'POST',
  }).then(handleErrors).then((response) => response);
}

export function suspend(orgCode: Id, userId: Id) {
  return fetch(`${apiBasePath}/organization/${orgCode}/user/${userId}/suspend`, {
    headers: {
      'Authorization': `Bearer ${retrieveAccessToken()}`,
      'Content-Type': 'application/json'
    },
    method: 'POST',
  }).then(handleErrors).then((response) => response);
}

export function unsuspend(orgCode: Id, userId: Id) {
  return fetch(`${apiBasePath}/organization/${orgCode}/user/${userId}/unsuspend`, {
    headers: {
      'Authorization': `Bearer ${retrieveAccessToken()}`,
      'Content-Type': 'application/json'
    },
    method: 'POST',
  }).then(handleErrors).then((response) => response);
}

export function unlock(orgCode: Id, userId: Id) {
  return fetch(`${apiBasePath}/organization/${orgCode}/user/${userId}/unlock`, {
    headers: {
      'Authorization': `Bearer ${retrieveAccessToken()}`,
      'Content-Type': 'application/json'
    },
    method: 'POST',
  }).then(handleErrors).then((response) => response);
}

export function resetpassword(orgCode: Id, userId: Id) {
  return fetch(`${apiBasePath}/organization/${orgCode}/user/${userId}/resetpassword`, {
    headers: {
      'Authorization': `Bearer ${retrieveAccessToken()}`,
      'Content-Type': 'application/json'
    },
    method: 'POST',
  }).then(handleErrors).then((response) => response);
}
import React, { useEffect } from 'react';
import { usePopupState, bindTrigger, bindPopper, } from 'material-ui-popup-state/hooks';
import { Button } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { connect, useSelector } from 'react-redux';

import ContextManagementPopperMenu from './ContextManagementPopperMenu';
import useStyles from './styles';
import { UMAReduxState } from '../../store/rootReducer';
import If from '../If/If';
import { getOrganizationsWithClients } from '../../store/Organizations/OrganizationActions';
import policyConstants from '../../constants/policyConstants';
import { useCasbin } from '../../hooks/useCasbin';

interface DispatchToProps {
  getOrganizationsWithClients: () => void;
}

type Props = DispatchToProps;

export function SwitchContext({
  getOrganizationsWithClients
}: Props) {
  const classes = useStyles({});
  const currentOrg = useSelector((state: UMAReduxState) => state.organizations.byId[state.context.userPrimaryOrganizationCode]?.name);
  const popupState = usePopupState({ popupId: 'switchContextMenu', variant: 'popover' });
  const canChangeContext = useCasbin(policyConstants.organizations.getAny());

  const allOrganizationsLoaded = useSelector((state: UMAReduxState) => state.organizations.allOrganizationsLoaded);

  useEffect(() => {
    if (!allOrganizationsLoaded && canChangeContext) {
      getOrganizationsWithClients();
    }
  }, [getOrganizationsWithClients, allOrganizationsLoaded, canChangeContext]);


  return (<>
    <If condition={canChangeContext}>
      <Button
        aria-label="switch-context-button"
        className={classes.switchContextButton}
        endIcon={<ArrowDropDownIcon />}
        {...bindTrigger(popupState)}
      >
        {currentOrg}
      </Button>
      <ContextManagementPopperMenu
        bindPopper={bindPopper}
        popupState={popupState}
      />
    </If>
    <If condition={!canChangeContext}>
      <span className={classes.contextLabel}>{currentOrg}</span>
    </If>
  </>);
};

const mapDispatchToProps: DispatchToProps = {
  getOrganizationsWithClients
};

export default connect(null, mapDispatchToProps)(SwitchContext);

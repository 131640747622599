import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

const styles = makeStyles((theme: Theme) => ({
  content: {
    color: theme.palette.grey[800]
  },
  contentContainer: {
    padding: theme.spacing(2, 5),
  },
  dialogPaper: {
    width: 600
  },
  titleContainer: {
    padding: theme.spacing(2.5, 5, 2)
  },
  actionsContainer: {
    padding: theme.spacing(2.5, 3, 2)
  },
  cancelButtonContainer: {
    flexGrow: 1
  }
}));

export default styles;
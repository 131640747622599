import React, { useEffect, useMemo, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import {
  Box,
  Breadcrumbs,
  Button,
  Grid,
  Link,
  TextField,
  Typography
} from '@mui/material';

import { UMAReduxState } from '../../../store/rootReducer';
import {
  Role,
  createRoleFormSchema,
  CreateRoleFormValues,
  RoleType
} from '../../../store/Roles/RoleModels';
import { createRole } from '../../../store/Roles/RoleActions';
import ApplicationField from '../../ApplicationField/ApplicationField';
import GroupsList from '../../FilterList/FilterList';
import LeavePageModal from '../../LeavePageModal/LeavePageModal';
import PolicyRulesPlaceholder from './PolicyRulesPlaceholder';
import RoleTypeField from './RoleTypeField';
import useStyles from './styles';
import If from '../../If/If';
import { useCasbin } from '../../../hooks/useCasbin';
import policyConstants from '../../../constants/policyConstants';
import { Id } from '../../../types/types';

const FORM_ID = 'create-role-form';

type StateToProps = {
  isCreating: boolean;
  organizationCode: string
};

type DispatchToProps = {
  createRole: (organizationCode: string, applicationCode: string, role: Partial<Role>) => void
};

type GroupCreatePageProps = StateToProps & DispatchToProps;

function GroupCreatePage({
  createRole,
  isCreating,
  organizationCode
}: GroupCreatePageProps) {
  const classes = useStyles({});
  const history = useHistory();
  const organizationId: Id = useSelector((state: UMAReduxState) => state.organizations.byId[state.context.organizationCode]?.id);
  const applicationsById = useSelector((state: UMAReduxState) => state.applications.byId);

  // Getting available role types has to happen here so that we can set the correct default for the controlled RoleTypeField form.
  const canCreateOrgRole = useCasbin(policyConstants.roles.createOrganization(organizationCode));
  const canCreateAnyAppRole = useCasbin(policyConstants.roles.createAnyApplication());

  const allowedRoleTypes: RoleType[] = useMemo(() => [] as RoleType[], []);

  const { control, clearErrors, formState: { isDirty, isValid }, handleSubmit, watch, setValue } = useForm<CreateRoleFormValues>({
    defaultValues: { roleType: 'organization' },
    mode: 'onChange',
    resolver: yupResolver(createRoleFormSchema)
  });

  useEffect(() => {
    if (canCreateOrgRole) allowedRoleTypes.push('organization');
    if (canCreateAnyAppRole) allowedRoleTypes.push('application');
    setValue('roleType', allowedRoleTypes[0] ?? '');
  }, [allowedRoleTypes, setValue, canCreateOrgRole, canCreateAnyAppRole]);

  const [leavePageModalOpen, setLeavePageModalOpen] = useState<boolean>(false);

  const handleBreadcrumbClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    history.push('/roles');
  };

  const onSubmit = (values: CreateRoleFormValues) => {
    if (!isDirty) return;

    const createPayload: Partial<Role> = {
      applicationId: values.roleType === 'application' && values.applicationCode ? applicationsById[values.applicationCode].id : null,
      description: values.description,
      name: values.name,
      roleType: values.roleType || 'organization',
      organizationId: values.roleType === 'organization' ? organizationId : undefined
    };
    createRole(organizationCode, values.applicationCode, createPayload);
  };

  const handleLeavePageModalOpened = () => setLeavePageModalOpen(true);
  const handleLeavePageModalClosed = () => setLeavePageModalOpen(false);
  const handleLeavePageClick = () => history.push('/roles');

  const roleType = watch('roleType');
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} id={FORM_ID}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Breadcrumbs className={classes.breadcrumbs} separator="›" aria-label="breadcrumb">
              <Link
                className={classes.baseBreadcrumb}
                color="inherit"
                href="/roles"
                onClick={handleBreadcrumbClick}
                variant="body2"
              >
                Roles
              </Link>
              <Typography variant="body2">Create Role</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item>
            <Button color="primary" size="large" variant="outlined" onClick={handleLeavePageModalOpened}>
              Cancel
            </Button>
            <Button
              disabled={isCreating || !isDirty || !isValid}
              color="primary"
              size="large"
              variant="contained"
              type="submit"
              form={FORM_ID}
              sx={{ ml: 1 }}
            >
              {isCreating ? 'Saving' : 'Save'}
            </Button>
          </Grid>
        </Grid>
        <Box mt={2} mb={2}>
          <Grid container item xs={6} spacing={2}>
            <Grid item xs={12}>
              <Controller
                name="name"
                control={control}
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    autoFocus
                    placeholder="Enter role name"
                    required
                    inputProps={{
                      className: classes.input
                    }}
                    InputProps={{
                      classes: {
                        root: clsx(classes.inputField, classes.nameField)
                      }
                    }}
                    fullWidth
                    error={Boolean(error)}
                    helperText={error?.message ?? ''}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Box marginBottom={1}>
                <Typography variant="h6">Role Type</Typography>
              </Box>
              <RoleTypeField control={control} roleTypes={allowedRoleTypes} onChange={() => clearErrors(['applicationCode', 'description'])} />
            </Grid>
            <Grid item xs={6}>
              <If condition={roleType === 'application'}>
                <Typography variant="h6" sx={{ mb: 1 }}>Application</Typography>
                <ApplicationField label="" control={control} />
              </If>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" sx={{ mb: 1 }}>Description</Typography>
              <Controller
                name="description"
                control={control}
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    autoFocus
                    placeholder="Enter description"
                    multiline
                    fullWidth
                    minRows={3}
                    maxRows={5}
                    InputProps={{
                      classes: {
                        root: classes.descriptionField
                      }
                    }}
                    inputProps={{
                      className: classes.input
                    }}
                    error={Boolean(error)}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Box>
        <Grid container spacing={7}>
          <Grid xs={12} item>
            <GroupsList
              onCreateResourcePage={true}
              parentResourceName="roles"
              addItemProps={{
                handleAddClick: () => { }
              }}
              removeItemProps={{
                handleDeleteClick: () => { }
              }}
              isLoading={false}
              listResourceName="groups"
              listResources={[]}
            />
          </Grid>
          <Grid xs={12} item>
            <PolicyRulesPlaceholder />
          </Grid>
        </Grid>
      </form>
      <LeavePageModal
        open={leavePageModalOpen}
        onClose={handleLeavePageModalClosed}
        onLeave={handleLeavePageClick}
      />
    </div>
  );
}

const mapStateToProps = (state: UMAReduxState) => ({
  isCreating: state.roles.isCreating,
  organizationCode: state.context.organizationCode
});

const mapDispatchToProps = {
  createRole
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupCreatePage);

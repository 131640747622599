import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

const styles = makeStyles((theme: Theme) => ({
  content: {
    color: theme.palette.grey[800]
  },
  contentContainer: {
    padding: theme.spacing(2, 0)
  },
  dialogPaper: {
    padding: theme.spacing(5),
    width: 800,
    height: 600,
  },
  titleContainer: {
    marginBottom: theme.spacing(1.5),
    padding: 0
  }
}));

export default styles;
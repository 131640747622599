import React from 'react';
import {
  Avatar,
  Checkbox,
  Grid,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';

import RoleTypeBadge from '../RoleTypeBadge/RoleTypeBadge';
import UserAvatar from '../UserAvatar/UserAvatar';
import { Id, ResourceNames } from '../../types/types';
import { Application } from '../../store/Applications/ApplicationModels';
import { Group } from '../../store/Groups/GroupModels';
import { Organization } from '../../store/Organizations/OrganizationModels';
import { Role } from '../../store/Roles/RoleModels';
import { User } from '../../store/Users/UserModels';
import {
  singularizeResource,
  toFullName
} from '../../utility';
import useStyles from './styles';

interface ListItemCheckboxProps {
  assignResourceName: ResourceNames;
  parentResources: Group[] | User[] | Role[] | Application[] | Organization[];
  parentResourceName: ResourceNames;
  assignableResource: User | Role | Group | Organization | Application;
  onClick: () => void;
  selected: Id[];
}

function ListItemCheckbox({
  assignResourceName,
  parentResources,
  parentResourceName,
  assignableResource,
  onClick,
  selected
}: ListItemCheckboxProps) {
  const classes = useStyles({ selected: selected.length });

  /*
    Logic to determine if the ListItem mapped from the assignable resources is disabled (already assigned),
    if the ListItem is currently checked and assign the ListItem an Aria Label.
  */
  const alreadyAssignedTo: Id[] = [];
  let disabled = false;
  let checked = false;
  let ariaLabel = '';

  // Assign Users
  if (assignResourceName === 'users') {
    (parentResources as Group[]).forEach((parentResource) => {
      if (parentResource.userIds?.includes((assignableResource as User).id)) {
        alreadyAssignedTo.push(parentResource.name);
        disabled = true;
      }
    });
    checked = selected.indexOf((assignableResource as User).id) !== -1;
    ariaLabel = `checkbox-list-label-${(assignableResource as User).userName ?? (assignableResource as User).id}`;

    // Assign GROUPS
  } else if (assignResourceName === 'groups') {
    (parentResources as (User | Role)[]).forEach((parentResource) => {
      if (parentResource.groupIds?.includes((assignableResource as Group).id)) {
        if (parentResourceName === 'users') {
          alreadyAssignedTo.push((parentResource as User).lastName);
        }
        if (parentResourceName === 'roles') {
          alreadyAssignedTo.push((parentResource as Role).name);
        }
        disabled = true;
      }
    });
    checked = selected.indexOf((assignableResource as Group).id) !== -1;
    ariaLabel = `checkbox-list-label-${(assignableResource as Group).name ?? (assignableResource as Group).id}`;

    // Assign ROLES
  } else if (assignResourceName === 'roles') {
    (parentResources as (Group | Application)[]).forEach((parentResource) => {
      if (parentResource.roleIds?.includes((assignableResource as Role).id)) {
        alreadyAssignedTo.push(parentResource.name);
        disabled = true;
      }
    });
    checked = selected.indexOf((assignableResource as Role).id) !== -1;
    ariaLabel = `checkbox-list-label-${(assignableResource as Role).name ?? (assignableResource as Role).id}`;

    // Assign ORGANIZATIONS
  } else if (assignResourceName === 'organizations') {
    (parentResources as Application[]).forEach((parentResource) => {
      if (parentResource.organizationCodes?.includes((assignableResource as Organization).code)) {
        alreadyAssignedTo.push(parentResource.name);
        disabled = true;
      }
    });
    checked = selected.indexOf((assignableResource as Organization).code) !== -1;
    ariaLabel = `checkbox-list-label-${(assignableResource as Organization).name ?? (assignableResource as Organization).code}`;

    // Assign APPLICATIONS
  } else if (assignResourceName === 'applications') {
    (parentResources as Organization[]).forEach((parentResource) => {
      if (parentResource.applicationCodes?.includes((assignableResource as Application).code)) {
        alreadyAssignedTo.push(parentResource.name);
        disabled = true;
      }
    });
    checked = selected.indexOf((assignableResource as Application).code) !== -1;
    ariaLabel = `checkbox-list-label-${(assignableResource as Application).name ?? (assignableResource as Application).code}`;
  }

  const renderListItemBody = () => {
    if (assignResourceName === 'users') {
      return (
        <React.Fragment>
          <ListItemAvatar><UserAvatar user={assignableResource as User} smallBadge /></ListItemAvatar>
          <ListItemText
            primary={toFullName(assignableResource as User)}
            secondary={(assignableResource as User).email}
            primaryTypographyProps={{
              noWrap: true
            }}
            secondaryTypographyProps={{
              noWrap: true
            }}
          />
        </React.Fragment>
      );
    } else if (assignResourceName === 'roles') {
      /* Add Role-Type Badge for Application and System Roles */
      const nameDisplay = (assignableResource as Role).roleType === 'organization' ? (assignableResource as Role).name : (
        <Grid container alignItems="center">
          {(assignableResource as Role).name} <RoleTypeBadge roleType={(assignableResource as Role).roleType} smallBadge />
        </Grid>
      );

      return (
        <ListItemText
          primary={nameDisplay}
          primaryTypographyProps={{
            noWrap: true
          }}
        />
      );
    } else if (assignResourceName === 'groups') {
      return (
        <ListItemText
          primary={(assignableResource as Group).name}
          primaryTypographyProps={{
            noWrap: true
          }}
        />
      );
    } else if (assignResourceName === 'organizations' || assignResourceName === 'applications') {
      return (
        <ListItemText
          primary={(assignableResource as Organization | Application).name}
          secondary={(assignableResource as Organization | Application).code?.toUpperCase()}
          primaryTypographyProps={{
            noWrap: true
          }}
          secondaryTypographyProps={{
            noWrap: true
          }}
        />
      );
    }
  };

  const assignedToAllGroups = alreadyAssignedTo.length === parentResources.length;
  const multipleParentResources = parentResources.length > 1;
  return (
    <ListItemButton
      className={classes.listItem}
      disabled={disabled}
      disableGutters
      onClick={onClick}
    >
      <ListItemIcon className={classes.listItemIcon}>
        <Checkbox
          edge="start"
          checked={checked}
          tabIndex={-1}
          disableRipple
          inputProps={{ 'aria-labelledby': ariaLabel }}
        />
      </ListItemIcon>
      {renderListItemBody()}
      {
        disabled &&
        <Grid>
          <Typography variant="body2" display="inline" className={classes.alreadyAssigned}>
            {
              assignedToAllGroups && multipleParentResources
                ? `Already in all ${parentResourceName}`
                : `Already in ${alreadyAssignedTo.length > 1 ? parentResourceName : singularizeResource(parentResourceName)}`
            }
          </Typography>
          {
            !assignedToAllGroups && multipleParentResources &&
            <Avatar className={classes.alreadyAssignedAvatar}>{`+${alreadyAssignedTo.length}`}</Avatar>
          }
        </Grid>
      }
    </ListItemButton>
  );
}

export default ListItemCheckbox;
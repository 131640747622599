import React from 'react';
import { useSelector } from 'react-redux';
import {
  Grid,
  Typography
} from '@mui/material';

import { UMAReduxState } from '../../store/rootReducer';
import { Organization, OrganizationsState } from '../../store/Organizations/OrganizationModels';
import ContextManagementOrganizationListItem from './ContextManagementOrganizationListItem';
import useStyles from './styles';
import policyConstants from '../../constants/policyConstants';
import { useEnforcer } from '../../hooks/useCasbin';

interface ContextManagementOrganizationListProps {
  filterText: string;
  onListItemClick: (event: React.MouseEvent<HTMLLIElement, MouseEvent>, organization: Organization) => void;
  filteredOrganizations: Organization[];
  organizationsState: OrganizationsState;
}

export default function ContextManagementOrganizationList({
  filterText,
  onListItemClick,
  filteredOrganizations,
  organizationsState
}: ContextManagementOrganizationListProps) {
  const classes = useStyles({});
  const userPrimaryOrgCode = useSelector((state: UMAReduxState) => state.my.profile.primaryOrganizationCode);
  const isLoading = organizationsState.isLoading;
  const noOrganizations = organizationsState.allIds.length === 0 && filterText === '' && !isLoading;
  const noFilteredResults = filteredOrganizations.length === 0 && filterText !== '';

  const enforcer = useEnforcer();

  if (isLoading) {
    return (
      <Grid container item justifyContent="center" alignItems="center" className={classes.messageContainer}>
        <Typography aria-label="loading" role="message" variant="body2" className={classes.message}>
          Loading Organizations...
        </Typography>
      </Grid>
    );
  }

  if (noOrganizations) {
    return (
      <Grid container item justifyContent="center" alignItems="center" className={classes.messageContainer}>
        <Typography role="message" variant="body2" className={classes.message}>
          You currently do not have any other organizations to switch to.
        </Typography>
      </Grid>
    );
  }

  if (noFilteredResults) {
    return (
      <Typography role="message" variant="body2" className={classes.message} noWrap>
        No results matching &quot;{filterText}&quot;
      </Typography>
    );
  }

  return (
    <>
      {filteredOrganizations
        .filter(org => {
          const request = policyConstants.organizations.getById(org.code);
          return enforcer?.enforceSync(request.obj, request.act) ?? false;
        })
        .sort((a) => a.code === userPrimaryOrgCode ? -1 : 1)
        .map((organization: Organization) => (
          <ContextManagementOrganizationListItem
            key={organization.code}
            onListItemClick={(e: React.MouseEvent<HTMLLIElement, MouseEvent>) => onListItemClick(e, organization)}
            organization={organization}
          />
        ))}
    </>
  );
}

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

const styles = makeStyles((theme: Theme) => ({
  baseBreadcrumb: {
    textDecoration: 'underline'
  },
  breadcrumbs: {
    color: theme.palette.grey[600],
    fontSize: '12px'
  },
  editControls: {
    marginTop: theme.spacing(0.5)
  },
  editControlsHidden: {
    visibility: 'hidden'
  },
  editableItem: {
    '&:active': {
      border: 'none',
      outline: `1px solid ${theme.palette.primary.light}`
    },
    '&:focus': {
      border: 'none',
      outline: `1px solid ${theme.palette.primary.light}`
    }
  },
  iconButton: {
    border: '0.75px solid #DCDCDC',
    borderRadius: '3px',
    padding: 0
  },
  input: {
    '& + .MuiOutlinedInput-notchedOutline': {
      border: 'none'
    },
    '&:placeholder-shown': {
      '& + .MuiInputAdornment-positionEnd': {
        visibility: 'hidden'
      },
    },
    padding: 0
  },
  inputField: {
    '&:hover': {
      border: `1px solid ${theme.palette.primary.light}`
    },
    border: '1px solid transparent',
  },
  nameField: {
    fontSize: 30
  },
  notchedOutline: {
    border: 0
  }
}));

export default styles;
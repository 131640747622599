import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Controller } from 'react-hook-form';
import {
  Autocomplete,
  CircularProgress,
  TextField
} from '@mui/material';

import { UMAReduxState } from '../../store/rootReducer';
import { getOrganizationApplications } from '../../store/Organizations/OrganizationActions';
import { ById, Id } from '../../types/types';
import If from '../If/If';
import { useEnforcer } from '../../hooks/useCasbin';
import policyConstants from '../../constants/policyConstants';
import { Application } from '../../store/Applications/ApplicationModels';

interface ApplicationFieldProps {
  control: any;
  label?: string;
  onChange?: () => void;
}

const ApplicationField = (props: ApplicationFieldProps) => {
  const dispatch = useDispatch();
  const orgCode = useSelector((state: UMAReduxState) => state.context.organizationCode);
  const isLoading = useSelector((state: UMAReduxState) => state.organizations.byId[orgCode]?.applicationsLoading ?? false);
  const codes = useSelector((state: UMAReduxState) => state.organizations.byId[orgCode]?.applicationCodes ?? []);
  const applicationsById = useSelector((state: UMAReduxState) => state.applications.byId);
  const applicationsForOrgLoaded = useSelector((state: UMAReduxState) => state.organizations.byId[orgCode]?.applicationsLoaded ?? false);
  const enforcer = useEnforcer();

  const allowedCodes: Id[] = codes.filter((code: Id) => {
    const policyRequest = policyConstants.applications.getByCodeInOrganization(orgCode, code);
    return enforcer?.enforceSync(policyRequest.obj, policyRequest.act) ?? false;
  });

  const allowedApplicationsById: ById<Application> = allowedCodes.reduce((acc: ById<Application>, code: Id) =>
    ({ ...acc, [code]: applicationsById[code] }), {});

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (isOpen && !applicationsForOrgLoaded) {
      dispatch(getOrganizationApplications(orgCode));
    }
  }, [isOpen, orgCode, applicationsForOrgLoaded, dispatch]);

  return (
    <Controller
      name="applicationCode"
      control={props.control}
      defaultValue={null}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          data-testid="application-autocomplete"
          {...field}
          open={isOpen}
          onOpen={() => {
            setIsOpen(true);
          }}
          onClose={() => {
            setIsOpen(false);
          }}
          loading={applicationsForOrgLoaded}
          options={allowedCodes}
          getOptionLabel={(option: Id) => allowedApplicationsById[option]?.name ?? ''}
          onChange={(event, option) => {
            props.onChange?.();
            field.onChange(option);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={props.label}
              variant="outlined"
              fullWidth
              FormHelperTextProps={{
                'aria-label': 'application-field-helper-text'
              }}
              inputProps={{
                ...params.inputProps,
                'aria-label': 'application-field',
                autoComplete: 'disabled' // disable autocomplete and autofill
              }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    <If condition={isLoading}>
                      <CircularProgress color="inherit" size={20} />
                    </If>
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
              error={Boolean(error)}
              helperText={error?.message ?? ''}
            />
          )}
        />
      )}
    />
  );
};

export default ApplicationField;
import React from 'react';
import { useSelector } from 'react-redux';
import {
  ListItemButton,
  ListItemText,
} from '@mui/material';
import CheckMarkIcon from '@mui/icons-material/Done';

import { Organization } from '../../store/Organizations/OrganizationModels';
import useStyles from './styles';
import { UMAReduxState } from '../../store/rootReducer';

interface ContextManagementOrganizationListItemProps {
  onListItemClick: (e: any) => void;
  organization: Organization;
}

export default function ContextManagementOrganizationListItem({ onListItemClick, organization }: ContextManagementOrganizationListItemProps) {
  const currentContext = useSelector((state: UMAReduxState) => state.context.organizationCode);
  const organizationSelected = currentContext === organization.code;
  const classes = useStyles({ organizationSelected });
  // Resource loading states
  const contextLoading = useSelector((state: UMAReduxState) => state.context.isLoading);
  const usersLoading = useSelector((state: UMAReduxState) => state.users.isLoading);
  const groupsLoading = useSelector((state: UMAReduxState) => state.groups.isLoading);
  const clientsLoading = useSelector((state: UMAReduxState) => state.clients.isLoading);
  const rolesLoading = useSelector((state: UMAReduxState) => state.roles.isLoading);
  const policyRulesLoading = useSelector((state: UMAReduxState) => state.policyRules.isLoading);

  const disabled = Boolean(usersLoading || groupsLoading || clientsLoading || rolesLoading || policyRulesLoading || contextLoading);


  const listOrganizationDetails = (organization: Organization) => (
    <div className={classes.row}>
      <span>{organization.name}</span>
      <span>{organization.code?.toUpperCase()}</span>
    </div>
  );

  const listClients = (organization: Organization) => (
    <div className={classes.column}>
      {organization.clients?.map(c => (
        <div key={c.id} className={classes.row}>
          <span>{c.name}</span>
          <span>{c.code?.toUpperCase()}</span>
        </div>))}
    </div>
  );

  return (
    <ListItemButton
      aria-label={organization.code}
      aria-selected={organizationSelected}
      role="organization-list-item"
      onClick={onListItemClick}
      className={classes.menuItem}
      disabled={disabled}
    >
      <ListItemText
        primary={listOrganizationDetails(organization)}
        secondary={listClients(organization)}
        classes={{
          primary: classes.listItemTextPrimary
        }}
        primaryTypographyProps={{
          noWrap: true
        }}
        secondaryTypographyProps={{
          noWrap: true
        }}
      />
      {organizationSelected ? <CheckMarkIcon style={{ marginLeft: '15px' }} aria-label="selected-icon" /> : ''}
    </ListItemButton>
  );
}
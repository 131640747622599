import environment from '../environment';
import { Id } from '../types/types';
import { User } from '../store/Users/UserModels';
import {
  handleErrors,
  retrieveAccessToken
} from '../utility';

const { apiBasePath } = environment;

export function getMyUserProfile(userId: Id): Promise<User> {
  return fetch(`${apiBasePath}/user/${userId}`, {
    headers: {
      'Authorization': `Bearer ${retrieveAccessToken()}`
    }
  }).then(handleErrors).then((response) => response.json() as Promise<User>);
}
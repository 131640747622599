import { CollectionState, Id, ReceiveCollectionAction } from '../../types/types';

export interface Client {
  code: string;
  name: string;
  id: Id;
  organizationId: Id;
}

export interface ClientsState extends CollectionState<Client> {
  isLoading: boolean;
}

export interface ClientRow extends Client {
  id: Id;
}

export interface RequestClientsAction {
  type: string;
}

export type ClientAction = RequestClientsAction | ReceiveCollectionAction<Client>;

// Constants
export const WILDCARD: string = '*';
export const ALL_CLIENTS: string = 'All Clients';
import environment from '../environment';
import { Id } from '../types/types';
import { Application } from '../store/Applications/ApplicationModels';
import {
  handleErrors,
  retrieveAccessToken
} from '../utility';

const { apiBasePath } = environment;

/* Applications */
export function getAll(): Promise<Application[]> {
  return fetch(`${apiBasePath}/application`, {
    headers: {
      'Authorization': `Bearer ${retrieveAccessToken()}`
    }
  }).then(handleErrors).then((response) => response.json() as Promise<Application[]>);
}

export function getByCode(applicationCode: Id): Promise<Application> {
  return fetch(`${apiBasePath}/application/${applicationCode}/permissions`, {
    headers: {
      'Authorization': `Bearer ${retrieveAccessToken()}`
    }
  }).then(handleErrors).then((response) => response.json() as Promise<Application>);
}

export function getByCodeInOrganization(organizationCode: Id, applicationCode: Id): Promise<Application> {
  return fetch(`${apiBasePath}/organization/${organizationCode}/applications/${applicationCode}/permissions`, {
    headers: {
      'Authorization': `Bearer ${retrieveAccessToken()}`
    }
  }).then(handleErrors).then((response) => response.json() as Promise<Application>);
}

export function create(organizationCode: string, application: Partial<Application>) {
  return fetch(`${apiBasePath}/organization/${organizationCode}/application`, {
    body: JSON.stringify(application),
    headers: {
      'Authorization': `Bearer ${retrieveAccessToken()}`,
      'Content-Type': 'application/json'
    },
    method: 'POST',
  }).then(handleErrors).then((response) => response.json());
}

export function update(applicationCode: string, application: Partial<Application>) {
  return fetch(`${apiBasePath}/application/${applicationCode}`, {
    body: JSON.stringify(application),
    headers: {
      'Authorization': `Bearer ${retrieveAccessToken()}`,
      'Content-Type': 'application/json'
    },
    method: 'PUT',
  }).then(handleErrors).then((response) => response.json());
}

export function deleteApplication(applicationCode: string): Promise<void> {
  return fetch(`${apiBasePath}/application/${applicationCode}`, {
    headers: {
      'Authorization': `Bearer ${retrieveAccessToken()}`,
      'Content-Type': 'application/json'
    },
    method: 'DELETE',
  }).then(handleErrors).then((response) => response);
}

export function addPermissions(applicationCode: Id, permissions: string[]) {
  return fetch(`${apiBasePath}/application/${applicationCode}/permissions`, {
    body: JSON.stringify(permissions),
    headers: {
      'Authorization': `Bearer ${retrieveAccessToken()}`,
      'Content-Type': 'application/json'
    },
    method: 'PUT'
  }).then(handleErrors).then((response) => response);
}

export function removePermissions(applicationCode: Id, permissions: string[]) {
  return fetch(`${apiBasePath}/application/${applicationCode}/permissions`, {
    body: JSON.stringify(permissions),
    headers: {
      'Authorization': `Bearer ${retrieveAccessToken()}`,
      'Content-Type': 'application/json'
    },
    method: 'DELETE'
  }).then(handleErrors).then((response) => response);
}

export function getRoles(applicationCode: Id) {
  return fetch(`${apiBasePath}/application/${applicationCode}/role`, {
    headers: {
      'Authorization': `Bearer ${retrieveAccessToken()}`,
    },
  }).then(handleErrors).then((response) => response.json());
}

export function getAppRolesAndOrgRolesForAppByPolicy(applicationCode: Id, organizationCode: string) {
  return fetch(`${apiBasePath}/application/${applicationCode}/organization/${organizationCode}/roles`, {
    headers: {
      'Authorization': `Bearer ${retrieveAccessToken()}`,
    },
  }).then(handleErrors).then((response) => response.json());
}

export function getOrganizations(applicationCode: Id) {
  return fetch(`${apiBasePath}/application/${applicationCode}/organizations`, {
    headers: {
      'Authorization': `Bearer ${retrieveAccessToken()}`,
    },
  }).then(handleErrors).then((response) => response.json());
}

export function addOrganizations(applicationCode: Id, organizationCodes: Id[]) {
  return fetch(`${apiBasePath}/application/${applicationCode}/organizations`, {
    body: JSON.stringify(organizationCodes),
    headers: {
      'Authorization': `Bearer ${retrieveAccessToken()}`,
      'Content-Type': 'application/json'
    },
    method: 'PUT'
  }).then(handleErrors).then((response) => response);
}

export function removeOrganizations(applicationCode: Id, organizationCodes: Id[]) {
  return fetch(`${apiBasePath}/application/${applicationCode}/organizations`, {
    body: JSON.stringify(organizationCodes),
    headers: {
      'Authorization': `Bearer ${retrieveAccessToken()}`,
      'Content-Type': 'application/json'
    },
    method: 'DELETE'
  }).then(handleErrors).then((response) => response);
}
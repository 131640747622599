import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

const styles = makeStyles((theme: Theme) => ({
  body: {
    color: '#BEBEBE',
    font: 'normal normal normal 16px/24px Roboto, Helvetica, Arial, sans-serif',
    height: 47,
    letterSpacing: 0.32,
    opacity: 1,
    paddingTop: 30,
    textAlign: 'center'
  },
  container: {
    marginTop: theme.spacing(6)
  },
  ellipse: {
    alignItems: 'center',
    background: '#F0F0F0 0 0 no-repeat padding-box',
    borderRadius: '50%',
    display: 'flex',
    fontSize: 70,
    height: 137,
    left: 730,
    margin: 'auto',
    opacity: 1,
    width: 137
  },
  icon: {
    display: 'block',
    fill: '#013A6F',
    margin: 'auto'
  },
  title: {
    color: '#323232',
    font: 'normal normal 300 32px/35px Saira, Helvetica, Arial, sans-serif',
    height: 50,
    letterSpacing: 0,
    opacity: 1,
    paddingTop: 30,
    textAlign: 'center'
  }
}));

export default styles;
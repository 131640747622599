import React from 'react';
import { Route, RouteProps } from 'react-router';
import {
  Grid
} from '@mui/material';

const UnauthenticatedRoute = (props: RouteProps) => {
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      style={{ minHeight: '100vh' }}
    >
      <Route {...props} />
    </Grid>
  );
};

export default UnauthenticatedRoute;
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import {
  Box,
  Breadcrumbs,
  Button,
  Grid,
  Link,
  Typography
} from '@mui/material';

import { UMAReduxState } from '../../../store/rootReducer';
import { Role, RolesState } from '../../../store/Roles/RoleModels';
import { User, UsersState } from '../../../store/Users/UserModels';
import { Group } from '../../../store/Groups/GroupModels';
import {
  deleteGroups,
  getGroupWithUsersAndRolesById,
  removeRolesFromGroup,
  removeUsersFromGroup,
} from '../../../store/Groups/GroupActions';
import { Id } from '../../../types/types';
import AssignUsersModal from '../../AssignModal/AssignModal';
import AssignRolesModal from '../../AssignModal/AssignModal';
import DeleteGroupsModal from '../../DeleteModal/DeleteModal';
import GroupForm from './EditGroupForm';
import UsersList from '../../FilterList/FilterList';
import RolesList from '../../FilterList/FilterList';
import FederatedGroupsList from '../../FilterList/FilterList';
import useStyles from './styles';
import { FederatedGroupMapping, FederatedGroupMappingState } from '../../../store/FederatedGroupMappings/FederatedGroupMappingModels';
import { deleteFederatedGroupMapping, getFederatedGroupMappingsByGroup } from '../../../store/FederatedGroupMappings/FederatedGroupMappingActions';
import AddFederatedGroupMappingModal from '../../FederatedGroupMappingModals/AddFederatedGroupMappingModal/AddFederatedGroupMappingModal';
import EditFederatedGroupMappingModal from '../../FederatedGroupMappingModals/EditFederatedGroupMappingModal/EditFederatedGroupMappingModal';
import policyConstants from '../../../constants/policyConstants';
import { useCasbin } from '../../../hooks/useCasbin';
import If from '../../If/If';
import Can from '../../Can/Can';

type GroupToProps = Required<Pick<Group, 'id' | 'roleIds' | 'name' | 'userIds'>>;

type StateToProps = GroupToProps & {
  isAssigning: boolean,
  isDeleting: boolean,
  isLoading: boolean,
  isUpdating: boolean,
  organizationCode: string,
  rolesState: RolesState,
  usersState: UsersState,
  federatedGroupMappingState: FederatedGroupMappingState
};

type DispatchToProps = {
  deleteGroups: (orgCode: string, groupIds: Id[]) => void,
  getGroupWithUsersAndRolesById: (organizationCode: string, groupId: Id) => void,
  removeRolesFromGroup: (orgCode: string, groupId: Id, roleIds: Id[]) => void,
  removeUsersFromGroup: (orgCode: string, groupId: Id, userIds: Id[]) => void,
  getFederatedGroupMappingsByGroup: (organizationCode: string, groupId: Id) => void,
  deleteFederatedGroupMapping: (organizationCode: string, groupId: Id, federatedGruopMappingId: Id) => void
};

type GroupDetailPageProps = StateToProps & DispatchToProps;

function GroupDetailPage({
  getGroupWithUsersAndRolesById,
  isAssigning,
  isDeleting,
  isLoading,
  isUpdating,
  id: groupId,
  name,
  organizationCode,
  removeUsersFromGroup,
  removeRolesFromGroup,
  roleIds,
  rolesState,
  userIds,
  usersState,
  federatedGroupMappingState,
  getFederatedGroupMappingsByGroup,
  deleteFederatedGroupMapping
}: GroupDetailPageProps) {
  const classes = useStyles();
  const history = useHistory();

  const [assignUsersModalOpen, setAssignUsersModalOpen] = useState(false);
  const [assignRolesModalOpen, setAssignRolesModalOpen] = useState(false);
  const [deleteGroupsModalOpen, setDeleteGroupsModalOpen] = useState(false);
  const [addFederatedGroupsModalOpen, setAddFederatedGroupsModalOpen] = useState(false);
  const [federatedGroupMappingBeingEdited, setFederatedGroupMappingBeingEdited] = useState<FederatedGroupMapping | null>();
  const canEditGroup = useCasbin(policyConstants.groups.update(organizationCode, groupId));

  useEffect(() => {
    getGroupWithUsersAndRolesById(organizationCode, groupId);
  }, [getGroupWithUsersAndRolesById, organizationCode, groupId]);

  // Retrieve federated group mappings and get them ready to pass to the table.
  useEffect(() => {
    getFederatedGroupMappingsByGroup(organizationCode, groupId);
  }, [groupId, getFederatedGroupMappingsByGroup, organizationCode]);

  const [groupMappings, setGroupMappings] = useState<FederatedGroupMapping[]>([]);
  const [groupMappingsLoading, setGroupMappingsLoading] = useState(false);
  useEffect(() => {
    setGroupMappings(federatedGroupMappingState.mappings.filter((x) => x.groupId === groupId));
    setGroupMappingsLoading(!!~federatedGroupMappingState.groupsLoading.findIndex((x) => x === groupId));
  }, [groupId, federatedGroupMappingState]);

  const handleAssignRolesModalClose = () => setAssignRolesModalOpen(false);
  const handleAssignRolesModalOpen = () => setAssignRolesModalOpen(true);
  const handleAssignUsersModalClose = () => setAssignUsersModalOpen(false);
  const handleAssignUsersModalOpen = () => setAssignUsersModalOpen(true);

  const getUserList = (): User[] => {
    return userIds.reduce((acc: User[], id: Id) => !!usersState.byId[id] ? [usersState.byId[id], ...acc] : acc, []);
  };

  const getRoleList = (): Role[] => {
    return roleIds.reduce((acc: Role[], id: Id) => !!rolesState.byId[id] ? [rolesState.byId[id], ...acc] : acc, []);
  };

  const handleUserDeleteClick = (userId: Id) => {
    removeUsersFromGroup(
      organizationCode,
      groupId,
      [userId]
    );
  };

  const handleRoleDeleteClick = (roleId: Id) => {
    removeRolesFromGroup(
      organizationCode,
      groupId,
      [roleId]
    );
  };

  const handleBreadcrumbClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    history.push('/groups');
  };

  const handleDeleteGroupClick = () => {
    setDeleteGroupsModalOpen(true);
  };
  const handleDeleteGroupsModalClose = () => setDeleteGroupsModalOpen(false);

  const group = {
    isUpdating,
    id: groupId,
    name,
    roleIds,
    userIds
  };
  return (
    <div>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Breadcrumbs className={classes.breadcrumbs} separator="›" aria-label="breadcrumb">
            <Link
              className={classes.baseBreadcrumb}
              color="inherit"
              href="/groups"
              onClick={handleBreadcrumbClick}
              variant="body2"
            >
              Groups
            </Link>
            <Typography variant="body2">Group Details</Typography>
          </Breadcrumbs>
        </Grid>
        <Can requiredPolicy={policyConstants.groups.delete(organizationCode, groupId)}>
          <Grid item>
            <Button
              color="error"
              size="large"
              onClick={handleDeleteGroupClick}
              disabled={isLoading}
              variant="outlined"
            >
              Delete Group
            </Button>
          </Grid>
        </Can>
      </Grid>
      <Box mt={2} mb={1.5}>
        <If condition={canEditGroup}>
          <GroupForm
            group={group}
            isLoading={isLoading}
            organizationCode={organizationCode}
          />
        </If>
        <If condition={!canEditGroup}>
          <Typography variant="h3">{group.name}</Typography>
        </If>
      </Box>
      <Grid container spacing={3}>
        <Can requiredPolicy={policyConstants.groups.getById(organizationCode, groupId)}>
          <Grid xs={12} md={6} item>
            <UsersList
              parentResourceName="groups"
              addItemProps={{
                handleAddClick: handleAssignUsersModalOpen,
                requiredPolicy: policyConstants.groups.addUsers(organizationCode, groupId)
              }}
              removeItemProps={{
                handleDeleteClick: handleUserDeleteClick,
                requiredPolicy: policyConstants.groups.removeUsers(organizationCode, groupId)
              }}
              readItemProps={{
                policyFunction: policyConstants.users.getById,
                policyFunctionParams: [organizationCode]
              }}
              isLoading={isLoading || usersState.isLoading}
              listResourceName="users"
              listResources={getUserList()}
            />
          </Grid>
        </Can>
        <Can requiredPolicy={policyConstants.groups.getById(organizationCode, groupId)}>
          <Grid xs={12} md={6} item>
            <RolesList
              parentResourceName="groups"
              addItemProps={{
                handleAddClick: handleAssignRolesModalOpen,
                requiredPolicy: policyConstants.groups.addRoles(organizationCode, groupId)
              }}
              removeItemProps={{
                handleDeleteClick: handleRoleDeleteClick,
                requiredPolicy: policyConstants.groups.removeRoles(organizationCode, groupId)
              }}
              readItemProps={{
                policyFunction: policyConstants.roles.getById,
                policyFunctionParams: [organizationCode]
              }}
              isLoading={isLoading || rolesState.isLoading}
              listResourceName="roles"
              listResources={getRoleList()}
            />
          </Grid>
        </Can>
        <Can requiredPolicy={policyConstants.federatedGroupMappings.getByGroup(organizationCode, groupId)}>
          <Grid xs={12} md={6} item>
            <FederatedGroupsList
              parentResourceName="groups"
              addItemProps={{
                handleAddClick: () => setAddFederatedGroupsModalOpen(true),
                requiredPolicy: policyConstants.federatedGroupMappings.create(organizationCode, groupId)
              }}
              removeItemProps={{
                handleDeleteClick: (federatedGroupMappingId) => deleteFederatedGroupMapping(organizationCode, groupId, federatedGroupMappingId),
                policyFunction: policyConstants.federatedGroupMappings.delete,
                policyFunctionParams: [organizationCode, groupId]
              }}
              editItemProps={{
                handleEditClick: (id) => setFederatedGroupMappingBeingEdited(federatedGroupMappingState.mappings.find(m => m.id === id)),
                policyFunction: policyConstants.federatedGroupMappings.update,
                policyFunctionParams: [organizationCode, groupId]
              }}
              isLoading={groupMappingsLoading ||
                federatedGroupMappingState.isCreating ||
                federatedGroupMappingState.isDeleting ||
                federatedGroupMappingState.isUpdating
              }
              listResourceName="federated group mappings"
              listResources={groupMappings}
            />
          </Grid>
        </Can>
      </Grid>
      <AssignUsersModal
        parentResourceLoading={isLoading}
        assignResourceName="users"
        parentResource={[group]}
        parentResourceName="groups"
        isAssigning={isAssigning}
        onClose={handleAssignUsersModalClose}
        open={assignUsersModalOpen}
      />
      <AssignRolesModal
        parentResourceLoading={isLoading}
        assignResourceName="roles"
        parentResource={[group]}
        parentResourceName="groups"
        isAssigning={isAssigning}
        onClose={handleAssignRolesModalClose}
        open={assignRolesModalOpen}
      />
      <DeleteGroupsModal
        resourceName="groups"
        isDeleting={isDeleting}
        onClose={handleDeleteGroupsModalClose}
        onDelete={() => history.goBack()}
        open={deleteGroupsModalOpen}
        resources={[{
          id: groupId,
          name,
          roleIds,
          userIds
        }]}
      />
      <AddFederatedGroupMappingModal
        isOpen={addFederatedGroupsModalOpen}
        onClose={() => setAddFederatedGroupsModalOpen(false)}
        groupId={groupId}
        organizationCode={organizationCode}
      />
      <EditFederatedGroupMappingModal
        isOpen={!!federatedGroupMappingBeingEdited}
        onClose={() => setFederatedGroupMappingBeingEdited(null)}
        organizationCode={organizationCode}
        mapping={federatedGroupMappingBeingEdited as FederatedGroupMapping}
      />
    </div>
  );
}

function mapStateToProps(state: UMAReduxState, ownProps: any): StateToProps {
  const groupId = ownProps.match.params.groupId;
  const defaultGroup = { id: groupId, name: '', roleIds: [], userIds: [] };
  const { id = groupId, name = '', roleIds = [], userIds = [] } = state.groups.byId[groupId] ?? defaultGroup;
  return {
    isAssigning: state.groups.isAssigning,
    isDeleting: state.groups.isDeleting,
    isLoading: state.groups.byId[id]?.isLoading ?? true,
    isUpdating: state.groups.byId[id]?.isUpdating ?? false,
    id,
    name,
    organizationCode: state.context.organizationCode,
    roleIds,
    rolesState: state.roles,
    userIds,
    usersState: state.users,
    federatedGroupMappingState: state.federatedGroupMappings
  };
}

const mapDispatchToProps: DispatchToProps = {
  deleteGroups,
  getGroupWithUsersAndRolesById,
  removeRolesFromGroup,
  removeUsersFromGroup,
  getFederatedGroupMappingsByGroup,
  deleteFederatedGroupMapping,
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupDetailPage);

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

const styles = makeStyles((theme: Theme) => ({
  clear: {
    '&:hover, &.Mui-focusVisible': {
      color: theme.palette.grey[900],
    },
    color: theme.palette.grey[600]
  },
  input: {
    '&:placeholder-shown': {
      '& + .MuiInputAdornment-positionEnd': {
        visibility: 'hidden'
      },
    }
  },
  searchIcon: {
    color: theme.palette.grey[500]
  }
}));

export default styles;
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

const styles = makeStyles((theme: Theme) => ({
  buttonContainer: {
    marginTop: theme.spacing(4)
  },
  circle: {
    alignItems: 'center',
    backgroundColor: '#FAD6D2',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(6)
  },
  container: {
    background: '#00000005',
    borderRadius: 4,
  },
  detailsContainer: {
    padding: theme.spacing(5, 3.5)
  },
  header: {
    color: theme.palette.grey[800],
    fontFamily: '\'Saira\', sans-serif',
    fontSize: 40,
    padding: theme.spacing(1, 0, 0.5),
    textAlign: 'center',
    width: 650
  },
  icon: {
    color: theme.palette.error.dark,
    height: 200,
    width: 200
  },
  subheader: {
    color: theme.palette.grey[600],
    fontSize: 16,
    lineHeight: 1.5,
    marginTop: theme.spacing(1),
    textAlign: 'center',
    width: 650
  }
}));

export default styles;
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { SecureRoute, useOktaAuth } from '@okta/okta-react';
import { RouteProps } from 'react-router';
import {
  Toolbar,
} from '@mui/material';
import clsx from 'clsx';

import ContextBanner from '../ContextBanner/ContextBanner';
import ContentLoading from '../ContentLoading/ContentLoading';
import Header from '../Header/Header';
import SideNavigation from '../SideNavigation/SideNavigation';
import Unauthorized from '../Unauthorized/Unauthorized';
import { UMAReduxState } from '../../store/rootReducer';
import useStyles from './styles';
import { useCasbinSome } from '../../hooks/useCasbin';
import { PolicyRequest } from '../../store/My/MyModels';

const drawerWidth = 249;

interface StateToProps {
  contextLoading: boolean;
  receivedPermissions: boolean;
}

interface AuthenticatedRouteProps {
  partialPermissions?: boolean;
  requiredPermissions?: string[];
  requiredPolicies?: PolicyRequest[];
}

type Props = AuthenticatedRouteProps & RouteProps & StateToProps;

const AuthenticatedRoute = ({
  component,
  exact = false,
  contextLoading,
  path,
  requiredPolicies = [],
  render,
}: Props) => {
  const classes = useStyles();
  const [contentShift, setContentShift] = useState<boolean>(true);
  const onSideNavigationToggle = (isOpen: boolean) => setContentShift(isOpen);
  const isPermitted = useCasbinSome(requiredPolicies);

  const { authState } = useOktaAuth();

  const isLoading = !authState || contextLoading;

  const renderComponent = () => {
    /* Loading State */
    if (isLoading) {
      return ContentLoading;

      /*
        Show Unauthorized component if user doesn't have permissions to access the route.
        Defaults to zero required permissions (requiredPermissions = []).
      */
    } else if (!isPermitted && requiredPolicies.length > 0) {
      return Unauthorized;

      /* Render component defined in AppRouter */
    } else {
      return component;
    }
  };

  return (
    <React.Fragment>
      <Header />
      <SideNavigation onToggle={onSideNavigationToggle} />
      <Toolbar className={classes.toolbar} />
      <ContextBanner
        contentShift={contentShift}
        shiftWidth={drawerWidth}
      />
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: contentShift,
        })}
      >
        <SecureRoute path={path} exact={exact} component={renderComponent()} render={render} />
      </main>
    </React.Fragment>
  );
};

function mapStateToProps(state: UMAReduxState): StateToProps {
  return {
    contextLoading: state.context.isLoading,
    receivedPermissions: state.my.receivedPermissions
  };
}

export default connect(mapStateToProps)(AuthenticatedRoute);
import environment from '../environment';
import { Id } from '../types/types';
import { Group, } from '../store/Groups/GroupModels';
import { handleErrors, retrieveAccessToken } from '../utility';

const { apiBasePath } = environment;

export function getByOrg(organizationCode: Id): Promise<Group[]> {
  return fetch(`${apiBasePath}/organization/${organizationCode}/group`, {
    headers: {
      'Authorization': `Bearer ${retrieveAccessToken()}`
    }
  }).then(handleErrors).then((response) => response.json() as Promise<Group[]>);
}

export function getWithUsersAndRolesById(organizationCode: Id, groupId: Id): Promise<Group> {
  return fetch(`${apiBasePath}/organization/${organizationCode}/group/${groupId}?users=true&roles=true`, {
    headers: {
      'Authorization': `Bearer ${retrieveAccessToken()}`
    }
  }).then(handleErrors).then((response) => response.json() as Promise<Group>);
}

export function getByOrgWithUsersAndRoles(organizationCode: Id): Promise<Group[]> {
  return fetch(`${apiBasePath}/organization/${organizationCode}/group?users=true&roles=true`, {
    headers: {
      'Authorization': `Bearer ${retrieveAccessToken()}`
    }
  }).then(handleErrors).then((response) => response.json() as Promise<Group[]>);
}

export function create(organizationCode: Id, group: Partial<Group>) {
  return fetch(`${apiBasePath}/organization/${organizationCode}/group`, {
    body: JSON.stringify(group),
    headers: {
      'Authorization': `Bearer ${retrieveAccessToken()}`,
      'Content-Type': 'application/json'
    },
    method: 'POST',
  }).then(handleErrors).then((response) => response.json());
}

export function update(organizationCode: Id, group: Partial<Group>) {
  return fetch(`${apiBasePath}/organization/${organizationCode}/group/${group.id}`, {
    body: JSON.stringify(group),
    headers: {
      'Authorization': `Bearer ${retrieveAccessToken()}`,
      'Content-Type': 'application/json'
    },
    method: 'PUT',
  }).then(handleErrors).then((response) => response.json());
}

export function deleteGroup(organizationCode: Id, groupId: Id) {
  return fetch(`${apiBasePath}/organization/${organizationCode}/group/${groupId}`, {
    headers: {
      'Authorization': `Bearer ${retrieveAccessToken()}`
    },
    method: 'DELETE',
  }).then(handleErrors).then((response) => response);
}

export function addRoles(organizationCode: Id, groupId: Id, roleIds: Id[]) {
  return fetch(`${apiBasePath}/organization/${organizationCode}/group/${groupId}/role`, {
    body: JSON.stringify(roleIds),
    headers: {
      'Authorization': `Bearer ${retrieveAccessToken()}`,
      'Content-Type': 'application/json'
    },
    method: 'PUT'
  }).then(handleErrors).then((response) => response);
}

export function addUsers(organizationCode: Id, groupId: Id, userIds: Id[]) {
  return fetch(`${apiBasePath}/organization/${organizationCode}/group/${groupId}/user`, {
    body: JSON.stringify(userIds),
    headers: {
      'Authorization': `Bearer ${retrieveAccessToken()}`,
      'Content-Type': 'application/json'
    },
    method: 'PUT'
  }).then(handleErrors).then((response) => response);
}

export function removeRoles(organizationCode: Id, groupId: Id, roleIds: Id[]) {
  return fetch(`${apiBasePath}/organization/${organizationCode}/group/${groupId}/role`, {
    body: JSON.stringify(roleIds),
    headers: {
      'Authorization': `Bearer ${retrieveAccessToken()}`,
      'Content-Type': 'application/json'
    },
    method: 'DELETE'
  }).then(handleErrors).then((response) => response);
}

export function removeUsers(organizationCode: Id, groupId: Id, userIds: Id[]) {
  return fetch(`${apiBasePath}/organization/${organizationCode}/group/${groupId}/user`, {
    body: JSON.stringify(userIds),
    headers: {
      'Authorization': `Bearer ${retrieveAccessToken()}`,
      'Content-Type': 'application/json'
    },
    method: 'DELETE'
  }).then(handleErrors).then((response) => response);
}
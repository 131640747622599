import { AnyAction } from 'redux';
import { omit, union, without } from 'lodash';

import {
  PolicyRule,
  PolicyRulesState
} from './PolicyRuleModels';
import {
  GET_POLICY_RULES_REQUESTED,
  GET_POLICY_RULES_REQUEST_SUCCESSFUL,
  GET_POLICY_RULES_REQUEST_FAILED,
  CREATE_POLICY_RULE_REQUESTED,
  CREATE_POLICY_RULE_REQUEST_SUCCESSFUL,
  CREATE_POLICY_RULE_REQUEST_FAILED,
  DELETE_POLICY_RULES_REQUESTED,
  UPDATE_POLICY_RULE_REQUESTED,
  UPDATE_POLICY_RULE_REQUEST_SUCCESSFUL,
  UPDATE_POLICY_RULE_REQUEST_FAILED,
  DELETE_POLICY_RULES_REQUEST_SUCCESSFUL,
  DELETE_POLICY_RULES_REQUEST_FAILED
} from './PolicyRuleActions';
import {
  CONTEXT_RESET
} from '../Context/ContextActions';
import { normalizeList } from '../../utility';

export const initialState: PolicyRulesState = {
  allIds: [],
  byId: {},
  isCreating: false,
  isDeleting: false,
  isLoading: false,
  isUpdating: false
};

function policyRules(state: PolicyRulesState = initialState, action: AnyAction): PolicyRulesState {
  switch (action.type) {
    case GET_POLICY_RULES_REQUESTED:
      return {
        ...state,
        isLoading: true
      };

    case GET_POLICY_RULES_REQUEST_SUCCESSFUL:
      const { allIds, byId } = normalizeList<PolicyRule, 'id'>(action.policyRules, 'id');
      return {
        ...state,
        allIds: [
          ...state.allIds.filter(prId => !allIds.includes(prId)),
          ...allIds
        ],
        byId: { ...state.byId, ...byId },
        isLoading: false
      };

    case GET_POLICY_RULES_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false
      };

    case CREATE_POLICY_RULE_REQUESTED:
      return {
        ...state,
        isCreating: true
      };

    case CREATE_POLICY_RULE_REQUEST_SUCCESSFUL:
      return {
        ...state,
        allIds: union(state.allIds.slice(), [action.policyRule.id]),
        byId: { ...state.byId, [action.policyRule.id]: action.policyRule },
        isCreating: false
      };

    case CREATE_POLICY_RULE_REQUEST_FAILED:
      return {
        ...state,
        isCreating: false
      };

    case UPDATE_POLICY_RULE_REQUESTED:
      return {
        ...state,
        isUpdating: true
      };

    case UPDATE_POLICY_RULE_REQUEST_SUCCESSFUL:
      return {
        ...state,
        byId: { ...state.byId, [action.policyRule.id]: action.policyRule },
        isUpdating: false
      };

    case UPDATE_POLICY_RULE_REQUEST_FAILED:
      return {
        ...state,
        isUpdating: false
      };

    case DELETE_POLICY_RULES_REQUESTED:
      return {
        ...state,
        isDeleting: true
      };

    case DELETE_POLICY_RULES_REQUEST_SUCCESSFUL:
      return {
        ...state,
        allIds: without(state.allIds, ...action.policyRuleIds),
        byId: omit(state.byId, action.policyRuleIds),
        isDeleting: false
      };

    case DELETE_POLICY_RULES_REQUEST_FAILED:
      return {
        ...state,
        isDeleting: false
      };

    // Context Reset
    case CONTEXT_RESET:
      return {
        ...initialState
      };

    default:
      return state;
  }
}

export default policyRules;
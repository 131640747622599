import React from 'react';
import { connect } from 'react-redux';
import {
  Snackbar,
  SnackbarCloseReason
} from '@mui/material';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

import {
  alertClosed,
  alertDequeued
} from '../../store/Alerts/AlertActions';
import { UMAReduxState } from '../../store/rootReducer';
import { AlertState } from '../../store/Alerts/AlertModels';
import useStyles from './styles';

interface StateFromProps {
	alerts: AlertState;
}

interface DispatchFromProps {
	alertClosed: () => void;
	alertDequeued: () => void;
}

type Props = StateFromProps & DispatchFromProps;

function GlobalAlert({
  alertClosed,
  alertDequeued,
  alerts
}: Props) {
  const classes = useStyles();

  function handleClose(e: React.SyntheticEvent, reason?: SnackbarCloseReason) {
    if (reason === 'clickaway') {
      return;
    }
    alertClosed();
  }

  function handleExited() {
    alertDequeued();
  }

  function renderMessage() {
    if (currentError.variant === 'success') {
      return (
        <Snackbar
          aria-label="success-global-alert"
          anchorOrigin={{
            horizontal: 'center',
            vertical: 'bottom'
          }}
          classes={{
            root: classes.successMessage
          }}
          open={alerts.isOpen}
          onClose={handleClose}
          autoHideDuration={currentError.autoHide ? currentError.autoHideDuration : 5000}
          message={currentError.message}
          TransitionProps={{
            onExited: handleExited
          }} />
      );
    } else {
      return (
        <Snackbar
          anchorOrigin={{
            horizontal: 'center',
            vertical: 'top'
          }}
          style={{
            top: '-4px'
          }}
          open={alerts.isOpen}
          onClose={handleClose}
          autoHideDuration={currentError.autoHide ? currentError.autoHideDuration : null}
          message={currentError.message}
          TransitionProps={{
            onExited: handleExited
          }}
        >
          <div>
            <Alert
              onClose={handleClose}
              severity={currentError.variant}
            >
              {currentError.message}
            </Alert>
          </div>
        </Snackbar>
      );
    }
  }

  if (!alerts.all.length) return null;
  const currentError = alerts.all[0];

  return currentError.inForm ? null : renderMessage();
}

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function mapStateToProps(state: UMAReduxState) {
  return {
    alerts: state.alerts
  };
}

const mapDispatchToProps: DispatchFromProps = {
  alertClosed,
  alertDequeued
};

export default connect(
  mapStateToProps, mapDispatchToProps
)(GlobalAlert);
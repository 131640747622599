import * as Yup from 'yup';

import { CollectionState, Id } from '../../types/types';

// We define our schema based on the same keys in our form:
export const createApplicationFormSchema = Yup.object().shape({
  code: Yup.string().required('Application Code is required.')
    .min(4, 'Code must be at least 4 characters. ')
    .max(16, 'Code cannot exceed 16 characters. ')
    .matches(/^[a-zA-Z0-9_-]+$/i, {
      excludeEmptyString: true,
      message: 'Code can only contain letters, numbers, hyphens(-) and underscores(_).'
    }),
  name: Yup.string().required('Application Name is required.'),
  oktaGroupId: Yup.string().notRequired(),
}).required();

export const updateApplicationFormSchema = Yup.object().shape({
  name: Yup.string().required('Application Name is required.'),
  oktaGroupId: Yup.string().notRequired(),
}).required();

export type CreateApplicationFormValues = {
  code: string;
  name: string;
  oktaGroupId: string | null;
}

export type UpdateApplicationFormValues = {
  name: string;
  oktaGroupId: string;
}

export type CreatePermissionFormValues = {
  permission: string | undefined;
}

export type Application = {
  id: string;
  name: string;
  code: string;
  oktaGroupId?: string | null;
  isLoading?: boolean;
  isUpdating?: boolean;
  permissions?: Permission[];
  organizationCodes?: Id[];
  organizationsLoading?: boolean;
  roleIds?: Id[];
  rolesLoading?: boolean;
}

export type Permission = string;

export interface ApplicationsState extends CollectionState<Application> {
  isAssigning: boolean;
  isCreating: boolean;
  isLoading: boolean;
  isDeleting: boolean;
}

export interface RequestApplicationsAction {
  type: string;
}

export interface ReceiveApplicationsAction {
  allIds: Id[];
  byId: { [id in Id]: Application };
  type: string;
}

export interface ReceivePolicyModelAction {
  policyModel: string;
  type: string;
}

export interface ReceiveApplicationOrganizationsAction {
  id: Id;
  organizationCodes: Id[];
  type: string;
}

export interface ReceiveApplicationRolesAction {
  id: Id;
  roleIds: Id[];
  type: string;
}

export interface ReceiveApplicationPermissionsAction {
  id: Id;
  permissions: Permission[];
  type: string;
}

export type ApplicationAction =
  | RequestApplicationsAction
  | ReceiveApplicationsAction
  | ReceivePolicyModelAction
  | ReceiveApplicationRolesAction
  | ReceiveApplicationOrganizationsAction
  | ReceiveApplicationPermissionsAction;
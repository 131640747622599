import React, { MouseEventHandler, useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { debounce } from 'lodash';
import clsx from 'clsx';
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Skeleton,
  Toolbar,
  Tooltip,
  Typography
} from '@mui/material';
import {
  GridCellParams,
  GridColDef,
  DataGrid,
  GridSelectionModel,
  GridValueGetterParams
} from '@mui/x-data-grid';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Link } from 'react-router-dom';

import { UMAReduxState } from '../../../store/rootReducer';
import { ById, Id } from '../../../types/types';
import { changeUsersStatuses, getUsersDetails } from '../../../store/Users/UserActions';
import { Group } from '../../../store/Groups/GroupModels';
import { User, UserStatus, UserStatusActions, UserWithId } from '../../../store/Users/UserModels';
import { escapeRegExp, rowCountByPage, toFullName, toUserMask, usersDetailsSpread } from '../../../utility';
import AssignGroupsModal from '../../AssignModal/AssignModal';
import DeactivateUserModal from '../../DeactivateUserModal/DeactivateUserModal';
import NoSearchResultsOverlay from '../../NoSearchResultsOverlay/NoSearchResultsOverlay';
import SearchInput from '../../SearchInput/SearchInput';
import UserAvatar from '../../UserAvatar/UserAvatar';
import UserStatusChip from '../../StatusChip/StatusChip';
import environment from '../../../environment';
import useStyles from './styles';
import { getGroups } from '../../../store/Groups/GroupActions';
import policyConstants from '../../../constants/policyConstants';
import { useCasbin } from '../../../hooks/useCasbin';
import If from '../../If/If';
import Can from '../../Can/Can';
import { INITIAL_PAGE_SIZE, PAGE_SIZE_OPTIONS } from '../../../constants/pageSizeConstants';
import ImportUserModal from '../../ImportUserModal/ImportUserModal';


type HandlerMap = {
  [action in UserStatusActions]: MouseEventHandler;
}

type UsersPageProps = {
  byId: ById<User>;
  getUsersDetails: (organizationCode: string) => void;
  changeUsersStatuses: (status: UserStatusActions, userIds: Id[], organizationCode: Id, callbacksOnSuccess: () => void) => void;
  getGroups: (organizationCode: string) => void;
  isAssigning: boolean;
  isLoading: boolean;
  organizationCode: string;
  users: UserWithId[];
}

function UsersPage({
  byId,
  changeUsersStatuses,
  getUsersDetails,
  getGroups,
  isAssigning,
  isLoading,
  organizationCode,
  users
}: UsersPageProps) {
  const history = useHistory();
  const currentAuthenticatedUserId = useSelector((state: UMAReduxState) => state.my.profile.id);
  const { debounceDelayMS } = environment;

  const [selected, setSelected] = useState<Id[]>([]);
  const [filterText, setFilterText] = useState<string>('');
  const [rows, setRows] = useState<UserWithId[]>(users);
  const [selectedActionUsers, setSelectedActionUsers] = useState<User[]>([]);
  const [actionOverflowMenuAnchorEl, setActionOverflowMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [buttonGroupMenuAnchorEl, setButtonGroupMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [assignGroupsModalOpen, setAssignGroupsModalOpen] = useState(false);
  const [deactivateUserModalOpen, setDeactivateUserModalOpen] = useState(false);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(INITIAL_PAGE_SIZE);
  const [importModalOpen, setImportModalOpen] = useState<boolean>(false);
  const classes = useStyles();

  const debouncedGetUsersDetails = debounce((organizationCode) => {
    getUsersDetails(organizationCode);
  }, debounceDelayMS);

  useEffect(() => {
    // Initial display of users based on search bar filter
    handleFilterChange(filterText);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);

  /*
    Using a debounced call to prevent unnecessary calls to OKTA if a user is clicking around navigation.
    Debounce delay is set as an env variable (defaults to 0 ms).
    */
  useEffect(() => {
    debouncedGetUsersDetails(organizationCode);
    return () => {
      debouncedGetUsersDetails.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationCode]);

  // TODO group data should be included in user detail call. Remove once UMA-455 is complete
  useEffect(() => {
    getGroups(organizationCode);
  }, [getGroups, organizationCode]);

  // Data Grid Actions
  const onPageChange = (page: number) => setPage(page);
  const onPageSizeChange = (pageSize: number) => setPageSize(pageSize);
  const onSelectionModelChange = (selectionModel: GridSelectionModel) => setSelected(selectionModel);
  const handleFilterChange = (filterText: string) => {
    setFilterText(filterText);
    const searchRegex = new RegExp(escapeRegExp(filterText), 'i');
    const filteredRows = users.filter((user) => {
      return searchRegex.test(toUserMask(user));
    });
    setRows(filteredRows);
  };

  // import user button
  const handleUserImportButtonClick = () => {
    setImportModalOpen(true);
  };

  // Button Group Menu
  const handleButtonGroupMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setButtonGroupMenuAnchorEl(event.currentTarget);
  };
  const handleButtonGroupMenuClose = () => {
    setButtonGroupMenuAnchorEl(null);
  };

  // Action Overflow Menu
  const handleActionOverflowMenuClick = (event: React.MouseEvent<HTMLButtonElement>, cellParams?: GridCellParams) => {
    if (cellParams !== undefined) {
      setSelectedActionUsers([byId[cellParams.row.id]]);
    } else {
      setSelectedActionUsers(selected.map((id) => byId[id]));
    }
    setActionOverflowMenuAnchorEl(event.currentTarget);
  };
  const handleActionOverflowMenuClose = () => setActionOverflowMenuAnchorEl(null);

  // Assign Modal
  const handleAssignGroupsModalOpen = () => {
    setAssignGroupsModalOpen(true);
    setActionOverflowMenuAnchorEl(null);
  };
  const handleAssignGroupsModalClose = () => setAssignGroupsModalOpen(false);

  // Deactivate User Modal
  const handleDeactivateUserModalOpen = () => {
    setDeactivateUserModalOpen(true);
    setActionOverflowMenuAnchorEl(null);
  };
  const handleDeactivateUserModalClose = () => setDeactivateUserModalOpen(false);

  // Edit User - Route to User Detail
  const handleEditUserClick: MouseEventHandler = () => {
    const userId = selectedActionUsers[0].id;
    history.push(`${history.location.pathname}/${userId}`);
  };

  // Create User
  const handleCreateUserClick: MouseEventHandler = () => {
    history.push('/users/create');
  };

  // Deprovisioned User
  const handleDeprovisionedUsersClick: MouseEventHandler = () => {
    history.push('/users/deprovisioned');
  };

  // Menu Handlers
  const handleChangeUsersStatuses = (statusAction: UserStatusActions) => {
    changeUsersStatuses(statusAction, selectedActionUsers.map((user) => user.id), organizationCode, handleActionOverflowMenuClose);
  };
  const handleUserAssignToGroupClick = () => handleAssignGroupsModalOpen();

  const handlerMap: Partial<HandlerMap> = {
    'activate': () => handleChangeUsersStatuses('activate'),
    'assignToGroup': handleUserAssignToGroupClick,
    'deactivate': handleDeactivateUserModalOpen,
    'edit': handleEditUserClick,
    'reactivate': () => handleChangeUsersStatuses('reactivate'),
    'resetPassword': () => handleChangeUsersStatuses('resetPassword'),
    'suspend': () => handleChangeUsersStatuses('suspend'),
    'unlock': () => handleChangeUsersStatuses('unlock'),
    'unsuspend': () => handleChangeUsersStatuses('unsuspend')
  };

  // Checking for writes.
  // Action overflow menu checks.
  const updateAnyUserPermitted = useCasbin(policyConstants.users.updateAny(organizationCode));
  const addUserToGroupsAny = useCasbin(policyConstants.groups.addUsersAny(organizationCode));
  // Create button.
  const createUserIsPermitted = useCasbin(policyConstants.users.create(organizationCode));
  // The actions for each user status transition
  const activateAnyPermitted = useCasbin(policyConstants.users.activateAny(organizationCode));
  const deactivateAnyPermitted = useCasbin(policyConstants.users.deactivateAny(organizationCode));
  const reactivateAnyPermitted = useCasbin(policyConstants.users.reactivateAny(organizationCode));
  const suspendAnyPermitted = useCasbin(policyConstants.users.suspendAny(organizationCode));
  const unsuspendAnyPermitted = useCasbin(policyConstants.users.unsuspendAny(organizationCode));
  const unlockAnyPermitted = useCasbin(policyConstants.users.unlockAny(organizationCode));
  const resetPasswordAnyPermitted = useCasbin(policyConstants.users.resetPasswordAny(organizationCode));

  // Checking for reads.
  // Action dropdown near create with deprovisioned link.
  const getDeprovisionedUsersPermitted = useCasbin(policyConstants.users.getDeprovisioned(organizationCode));
  // Checking if we can link out to detail pages.
  const getAnyUser = useCasbin(policyConstants.users.getAny(organizationCode));
  // Checking if we can import users from Okta.
  const canImportByOrg = useCasbin(policyConstants.users.importByOrganization(organizationCode));
  const canImportAll = useCasbin(policyConstants.users.importAll());

  const getMenuItems = (selectedActionUsers: User[], handlerMap: Partial<HandlerMap>, currentUserId: Id | undefined) => {
    if (selectedActionUsers.length) {
      const isCurrentUser = currentUserId === selectedActionUsers[0].id;

      if (selectedActionUsers.length > 1) {
        return (
          <If condition={addUserToGroupsAny}>
            <MenuItem onClick={handlerMap.assignToGroup}>Assign to group</MenuItem>
          </If>
        );
      }

      if (isCurrentUser) {
        return (
          <React.Fragment>
            <If condition={addUserToGroupsAny}>
              <MenuItem onClick={handlerMap.assignToGroup}>Assign to group</MenuItem>
            </If>
            <If condition={updateAnyUserPermitted}>
              <MenuItem onClick={handlerMap.edit}>Edit user</MenuItem>
            </If>
          </React.Fragment>
        );
      }
      return getMenuItemsByStatus(selectedActionUsers[0], handlerMap);
    }
  };

  const menuEnabled = (status: UserStatus) => {
    switch (status) {
      case 'active':
        return addUserToGroupsAny || updateAnyUserPermitted || resetPasswordAnyPermitted || suspendAnyPermitted || deactivateAnyPermitted;
      case 'locked':
        return addUserToGroupsAny || updateAnyUserPermitted || unlockAnyPermitted || resetPasswordAnyPermitted || deactivateAnyPermitted;
      case 'deprovisioned':
        return activateAnyPermitted;
      case 'staged':
        return addUserToGroupsAny || updateAnyUserPermitted || activateAnyPermitted || resetPasswordAnyPermitted || deactivateAnyPermitted;
      case 'provisioned':
        return addUserToGroupsAny || updateAnyUserPermitted || reactivateAnyPermitted || deactivateAnyPermitted;
      case 'suspended':
        return addUserToGroupsAny || updateAnyUserPermitted || unsuspendAnyPermitted || deactivateAnyPermitted;
      case 'updated':
        return updateAnyUserPermitted;
      default:
        return addUserToGroupsAny || updateAnyUserPermitted || resetPasswordAnyPermitted || deactivateAnyPermitted;
    }
  };

  const getMenuItemsByStatus = (user: User, handlerMap: Partial<HandlerMap>) => {
    switch (user.status) {
      case 'active':
        return (
          <React.Fragment>
            <If condition={addUserToGroupsAny}>
              <MenuItem onClick={handlerMap.assignToGroup}>Assign to group</MenuItem>
            </If>
            <Can requiredPolicy={policyConstants.users.update(organizationCode, user.id)}>
              <MenuItem onClick={handlerMap.edit}>Edit user</MenuItem>
            </Can>
            <Can requiredPolicy={policyConstants.users.resetPassword(organizationCode, user.id)}>
              <MenuItem onClick={handlerMap.resetPassword}>Reset password</MenuItem>
            </Can>
            <Can requiredPolicy={policyConstants.users.suspend(organizationCode, user.id)}>
              <MenuItem onClick={handlerMap.suspend}>Suspend</MenuItem>
            </Can>
            <Can requiredPolicy={policyConstants.users.deactivate(organizationCode, user.id)}>
              <MenuItem onClick={handlerMap.deactivate}>Deactivate</MenuItem>
            </Can>
          </React.Fragment>
        );
      case 'locked':
        return (
          <React.Fragment>
            <Can requiredPolicy={policyConstants.users.unlock(organizationCode, user.id)}>
              <MenuItem onClick={handlerMap.unlock}>Unlock</MenuItem>
            </Can>
            <If condition={addUserToGroupsAny}>
              <MenuItem onClick={handlerMap.assignToGroup}>Assign to group</MenuItem>
            </If>
            <Can requiredPolicy={policyConstants.users.update(organizationCode, user.id)}>
              <MenuItem onClick={handlerMap.edit}>Edit user</MenuItem>
            </Can>
            <Can requiredPolicy={policyConstants.users.resetPassword(organizationCode, user.id)}>
              <MenuItem onClick={handlerMap.resetPassword}>Reset password</MenuItem>
            </Can>
            <Can requiredPolicy={policyConstants.users.deactivate(organizationCode, user.id)}>
              <MenuItem onClick={handlerMap.deactivate}>Deactivate</MenuItem>
            </Can>
          </React.Fragment>
        );
      case 'deprovisioned':
        return (
          <Can requiredPolicy={policyConstants.users.activate(organizationCode, user.id)}>
            <MenuItem onClick={handlerMap.activate}>Activate</MenuItem>
          </Can>
        );
      case 'staged':
        return (
          <React.Fragment>
            <Can requiredPolicy={policyConstants.users.activate(organizationCode, user.id)}>
              <MenuItem onClick={handlerMap.activate}>Activate</MenuItem>
            </Can>
            <If condition={addUserToGroupsAny}>
              <MenuItem onClick={handlerMap.assignToGroup}>Assign to group</MenuItem>
            </If>
            <Can requiredPolicy={policyConstants.users.update(organizationCode, user.id)}>
              <MenuItem onClick={handlerMap.edit}>Edit user</MenuItem>
            </Can>
            <Can requiredPolicy={policyConstants.users.resetPassword(organizationCode, user.id)}>
              <MenuItem onClick={handlerMap.resetPassword}>Reset password</MenuItem>
            </Can>
            <Can requiredPolicy={policyConstants.users.deactivate(organizationCode, user.id)}>
              <MenuItem onClick={handlerMap.deactivate}>Deactivate</MenuItem>
            </Can>
          </React.Fragment>
        );
      case 'provisioned':
        return (
          <React.Fragment>
            <Can requiredPolicy={policyConstants.users.reactivate(organizationCode, user.id)}>
              <MenuItem onClick={handlerMap.reactivate}>Reactivate</MenuItem>
            </Can>
            <If condition={addUserToGroupsAny}>
              <MenuItem onClick={handlerMap.assignToGroup}>Assign to group</MenuItem>
            </If>
            <Can requiredPolicy={policyConstants.users.update(organizationCode, user.id)}>
              <MenuItem onClick={handlerMap.edit}>Edit user</MenuItem>
            </Can>
            <Can requiredPolicy={policyConstants.users.deactivate(organizationCode, user.id)}>
              <MenuItem onClick={handlerMap.deactivate}>Deactivate</MenuItem>
            </Can>
          </React.Fragment>
        );
      case 'suspended':
        return (
          <React.Fragment>
            <Can requiredPolicy={policyConstants.users.unsuspend(organizationCode, user.id)}>
              <MenuItem onClick={handlerMap.unsuspend}>Unsuspend</MenuItem>
            </Can>
            <If condition={addUserToGroupsAny}>
              <MenuItem onClick={handlerMap.assignToGroup}>Assign to group</MenuItem>
            </If>
            <Can requiredPolicy={policyConstants.users.update(organizationCode, user.id)}>
              <MenuItem onClick={handlerMap.edit}>Edit user</MenuItem>
            </Can>
            <Can requiredPolicy={policyConstants.users.deactivate(organizationCode, user.id)}>
              <MenuItem onClick={handlerMap.deactivate}>Deactivate</MenuItem>
            </Can>
          </React.Fragment>
        );
      case 'updated':
        return (
          <React.Fragment>
            <Can requiredPolicy={policyConstants.users.update(organizationCode, user.id)}>
              <MenuItem onClick={handlerMap.edit}>Edit user</MenuItem>
            </Can>
          </React.Fragment>
        );

      case 'pending':
      case 'recovery':
      case 'passwordExpired':
      case 'unspecified':
      default:
        return (
          <React.Fragment>
            <If condition={addUserToGroupsAny}>
              <MenuItem onClick={handlerMap.assignToGroup}>Assign to group</MenuItem>
            </If>
            <Can requiredPolicy={policyConstants.users.update(organizationCode, user.id)}>
              <MenuItem onClick={handlerMap.edit}>Edit user</MenuItem>
            </Can>
            <Can requiredPolicy={policyConstants.users.resetPassword(organizationCode, user.id)}>
              <MenuItem onClick={handlerMap.resetPassword}>Reset password</MenuItem>
            </Can>
            <Can requiredPolicy={policyConstants.users.deactivate(organizationCode, user.id)}>
              <MenuItem onClick={handlerMap.deactivate}>Deactivate</MenuItem>
            </Can>
          </React.Fragment>
        );
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      flex: 1,
      headerName: 'NAME',
      minWidth: 200,
      renderCell(params: GridCellParams) {
        const row: unknown = params.row;
        return (
          <Tooltip placement='bottom-end' title={`${params.getValue(params.id, 'name')}`}>
            <>
              <If condition={getAnyUser}>
                <Link to={`/users/${params.row.id}`} className={classes.link}>
                  <Grid
                    container
                    spacing={1}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    flexWrap="nowrap">
                    <Grid item><UserAvatar className={classes.avatar} user={row as User} smallBadge /></Grid>
                    <Grid item style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}> {`${params.getValue(params.id, 'name')}`}</Grid>
                  </Grid>
                </Link>
              </If>
              <If condition={!getAnyUser}>
                <Grid
                  container
                  spacing={1}
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  flexWrap="nowrap"
                  style={{ cursor: 'default' }}>
                  <Grid item><UserAvatar className={classes.avatar} user={row as User} smallBadge /></Grid>
                  <Grid item style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}> {`${params.getValue(params.id, 'name')}`}</Grid>
                </Grid>
              </If>
            </>
          </Tooltip >
        );
      },
      valueGetter: (params: GridValueGetterParams) => toFullName(params.row),
    },
    {
      field: 'email',
      flex: 1,
      headerName: 'EMAIL',
      minWidth: 100
    },
    {
      field: 'status',
      headerName: 'STATUS',
      minWidth: 155,
      renderCell(params: GridCellParams) {
        if (params.row.statusUpdating) {
          return <Skeleton width={155} height={35} />;
        } else {
          return <UserStatusChip status={params.row.status} />;
        }
      },
    },
    {
      field: 'groups',
      flex: 1,
      headerName: 'GROUP',
      minWidth: 200,
      sortComparator: (v1, v2) => (v2 as [])?.length - (v1 as [])?.length,
      valueGetter: (params: GridValueGetterParams) => params.row.groups.map((group: Group) => group?.name ?? '').join(', '),
    },
    {
      align: 'right',
      field: '',
      renderCell(params: GridCellParams) {
        const id = `${params.row.id}-menu-button`;
        return (
          <IconButton
            aria-label="Actions"
            id={id}
            className={`${clsx({ [classes.activeState]: actionOverflowMenuAnchorEl?.id === id })}`}
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleActionOverflowMenuClick(e, params)}
            size="small"
            disabled={isLoading || !menuEnabled(params.row.status)}
            sx={{ mr: 2 }}
          >
            <MoreVertIcon />
          </IconButton>
        );
      },
      sortable: false
    }
  ];

  const tableMenuId = 'table-menu-button';
  const tableMenuOpen = actionOverflowMenuAnchorEl?.id === tableMenuId;
  return (
    <div className={classes.root}>
      <Grid container>
        <Grid xs={2} container item alignItems="center">
          <Typography variant="h3">Users</Typography>
        </Grid>
        <Grid xs={10} container item alignItems="center" justifyContent="flex-end">
          <Box my={.5}>
            <SearchInput
              onChange={(e) => handleFilterChange(e.target.value)}
              onReset={() => handleFilterChange('')}
              placeholder="Search Users, Email, Status"
            />
          </Box>
          <If condition={canImportAll || canImportByOrg}>
            <Box ml={4.5}>
              <Button
                variant="contained"
                onClick={handleUserImportButtonClick}
                disabled={isLoading}
              >
                Import Okta Users
              </Button>
            </Box>
          </If>
          <Box ml={4.5}>
            <ButtonGroup>
              <Button
                color="primary"
                size="large"
                variant="contained"
                onClick={handleCreateUserClick}
                disabled={!createUserIsPermitted}
              >
                Create User
              </Button>
              <Button
                color="primary"
                size="small"
                variant="contained"
                onClick={handleButtonGroupMenuClick}
                className={classes.vertDropDownIcon}
                disabled={!getDeprovisionedUsersPermitted}
              >
                <MoreVertIcon />
              </Button>
            </ButtonGroup>
          </Box>
        </Grid>
      </Grid>
      <Toolbar disableGutters>
        <If condition={!!selected.length}>
          <Typography variant="body2" className={classes.selectedResultsDisplay}>
            {selected.length} selected
          </Typography>
          <IconButton
            id={tableMenuId}
            className={`${clsx({ [classes.activeState]: tableMenuOpen })} ${classes.tableMenuButton}`}
            onClick={handleActionOverflowMenuClick}
            size="small"
            disabled={isLoading || !addUserToGroupsAny}
          >
            <MoreVertIcon />
          </IconButton>
        </If>
        <If condition={!selected.length}>
          <Typography variant="body2" className={classes.selectedResultsDisplay}>
            {rowCountByPage(page, pageSize, rows.length)} of {rows.length} results
          </Typography>
        </If>
      </Toolbar>
      <Divider />
      <DataGrid
        checkboxSelection={updateAnyUserPermitted || addUserToGroupsAny}
        columnBuffer={0}
        columns={columns}
        components={{
          NoRowsOverlay: NoSearchResultsOverlay
        }}
        componentsProps={{
          noRowsOverlay: {
            isFiltering: filterText.length,
            noRowsLabel: 'No users have been created.'
          }
        }}
        disableColumnFilter
        disableColumnMenu
        disableSelectionOnClick
        hideFooterSelectedRowCount
        loading={isLoading}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        onSelectionModelChange={onSelectionModelChange}
        pageSize={pageSize}
        rows={rows}
        rowsPerPageOptions={PAGE_SIZE_OPTIONS}
      />

      {/* User Actions Dropdown Menu */}
      <Menu
        anchorEl={actionOverflowMenuAnchorEl}
        anchorOrigin={{
          horizontal: tableMenuOpen ? 'left' : 'right',
          vertical: 'bottom',
        }}
        transformOrigin={{
          horizontal: tableMenuOpen ? 'left' : 'right',
          vertical: 'top',
        }}
        PopoverClasses={{
          root: classes.menuRoot
        }}
        keepMounted
        open={Boolean(actionOverflowMenuAnchorEl)}
        onClose={handleActionOverflowMenuClose}
      >
        <div>
          {getMenuItems(selectedActionUsers, handlerMap, currentAuthenticatedUserId)}
        </div>
      </Menu>

      {/* Create User Dropdown Menu */}
      <Menu
        anchorEl={buttonGroupMenuAnchorEl}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        PopoverClasses={{
          root: classes.menuRoot
        }}
        keepMounted
        open={Boolean(buttonGroupMenuAnchorEl)}
        onClose={handleButtonGroupMenuClose}
      >
        <div>
          <MenuItem onClick={handleDeprovisionedUsersClick}>Deprovisioned Users</MenuItem>
        </div>
      </Menu>
      <AssignGroupsModal
        assignResourceName="groups"
        parentResource={selectedActionUsers}
        parentResourceName="users"
        isAssigning={isAssigning}
        onClose={handleAssignGroupsModalClose}
        open={assignGroupsModalOpen}
      />
      <DeactivateUserModal
        onClose={handleDeactivateUserModalClose}
        onDeactivate={() => {
          setSelected([]);
          setSelectedActionUsers([]);
        }}
        open={deactivateUserModalOpen}
        user={selectedActionUsers[0]}
      />

      {/* User Import Scope Modal */}
      <ImportUserModal
        isLoading={isLoading}
        onClose={() => setImportModalOpen(false)}
        open={importModalOpen}
      />
    </div>
  );
}

const mapStateToProps = (state: UMAReduxState) => ({
  byId: state.users.byId,
  isAssigning: state.groups.isAssigning,
  isLoading: state.users.isLoading,
  organizationCode: state.context.organizationCode,
  users: usersDetailsSpread(state.users, state.groups).filter((user) => user.status !== 'deprovisioned')
    .sort((a, b) => toFullName(b).toLowerCase() > toFullName(a).toLowerCase() ? -1 : 1),
});

const mapDispatchToProps = {
  changeUsersStatuses,
  getGroups,
  getUsersDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersPage);
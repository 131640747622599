import React, { MouseEventHandler, useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import clsx from 'clsx';
import {
  Box,
  Breadcrumbs,
  Divider,
  Grid,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Toolbar,
  Typography
} from '@mui/material';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridRowModel,
  GridSelectionModel,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { UMAReduxState } from '../../../store/rootReducer';
import { ById, Id } from '../../../types/types';
import { changeUsersStatuses, getDeprovisionedUsers } from '../../../store/Users/UserActions';
import { User, UserStatusActions, UserWithId } from '../../../store/Users/UserModels';
import {
  escapeRegExp,
  rowCountByPage,
  toFullName,
  toUserMask,
  usersDetailsSpread
} from '../../../utility';
import DeleteUserModal from '../../DeleteModal/DeleteModal';
import NoSearchResultsOverlay from '../../NoSearchResultsOverlay/NoSearchResultsOverlay';
import SearchInput from '../../SearchInput/SearchInput';
import UserAvatar from '../../UserAvatar/UserAvatar';
import useStyles from './styles';
import policyConstants from '../../../constants/policyConstants';
import { useCasbin } from '../../../hooks/useCasbin';
import If from '../../If/If';
import { INITIAL_PAGE_SIZE, PAGE_SIZE_OPTIONS } from '../../../constants/pageSizeConstants';


type HandlerMap = {
  [action in UserStatusActions]: MouseEventHandler;
}

type UserDeprovisionedPageProps = {
  byId: ById<User>;
  getDeprovisionedUsers: (organizationCode: string) => void;
  changeUsersStatuses: (status: UserStatusActions, userIds: Id[], organizationCode: Id, callbacksOnSuccess: () => void) => void;
  isDeleting: boolean;
  isLoading: boolean;
  organizationCode: string;
  users: UserWithId[];
}

function UserDeprovisionedPage({
  byId,
  changeUsersStatuses,
  getDeprovisionedUsers,
  isDeleting,
  isLoading,
  organizationCode,
  users
}: UserDeprovisionedPageProps) {
  const history = useHistory();
  const currentAuthenticatedUserId = useSelector((state: UMAReduxState) => state.my.profile.id);

  const [selected, setSelected] = useState<Id[]>([]);
  const [filterText, setFilterText] = useState<string>('');
  const [rows, setRows] = useState<UserWithId[]>(users);
  const [selectedActionUsers, setSelectedActionUsers] = useState<User[]>([]);
  const [actionOverflowMenuAnchorEl, setActionOverflowMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [deleteUserModalOpen, setDeleteUserModalOpen] = useState(false);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(INITIAL_PAGE_SIZE);
  const classes = useStyles();

  const canGetUserAny = useCasbin(policyConstants.users.getAny(organizationCode));
  const canActivateAny = useCasbin(policyConstants.users.activateAny(organizationCode));
  const canUpdateAny = useCasbin(policyConstants.users.updateAny(organizationCode));
  const canDeleteAny = useCasbin(policyConstants.users.deleteAny(organizationCode));

  useEffect(() => {
    getDeprovisionedUsers(organizationCode);
  }, [getDeprovisionedUsers, organizationCode]);

  useEffect(() => {
    // Initial display of users based on search bar filter
    handleFilterChange(filterText);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);

  // Data Grid Actions
  const onPageChange = (page: number) => setPage(page);
  const onPageSizeChange = (pageSize: number) => setPageSize(pageSize);
  const onSelectionModelChange = (selectionModel: GridSelectionModel) => setSelected(selectionModel);
  const handleFilterChange = (filterText: string) => {
    setFilterText(filterText);
    const searchRegex = new RegExp(escapeRegExp(filterText), 'i');
    const filteredRows = users.filter((user) => {
      return searchRegex.test(toUserMask(user));
    });
    setRows(filteredRows);
  };

  // Action Overflow Menu
  const handleActionOverflowMenuClick = (event: React.MouseEvent<HTMLButtonElement>, cellParams?: GridCellParams) => {
    if (cellParams !== undefined) {
      setSelectedActionUsers([byId[cellParams.row.id]]);
    } else {
      setSelectedActionUsers(selected.map((id) => byId[id]));
    }
    setActionOverflowMenuAnchorEl(event.currentTarget);
  };
  const handleActionOverflowMenuClose = () => setActionOverflowMenuAnchorEl(null);

  // Edit User - Route to User Detail
  const handleEditUserClick: MouseEventHandler = () => {
    const userId = selectedActionUsers[0].id;
    history.push(`/users/${userId}`);
  };

  // Delete User Modal
  const handleDeleteUserModalOpen = () => {
    setDeleteUserModalOpen(true);
    setActionOverflowMenuAnchorEl(null);
  };
  const handleDeleteUserModalClose = () => setDeleteUserModalOpen(false);

  // Menu Handlers
  const handleChangeUsersStatuses = (statusAction: UserStatusActions) => {
    changeUsersStatuses(statusAction, selectedActionUsers.map((user) => user.id), organizationCode, handleActionOverflowMenuClose);
  };

  // Breadcrumbs
  const handleBreadcrumbClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    history.push('/users');
  };

  // Edit Menus
  const getMenuItems = (selectedActionUsers: User[], handlerMap: Partial<HandlerMap>, currentUserId: Id | undefined) => {
    if (selectedActionUsers.length) {
      const isCurrentUser = currentUserId === selectedActionUsers[0].id;

      if (selectedActionUsers.length > 1) {
        return (
          <If condition={canActivateAny}>
            <MenuItem onClick={handlerMap.activate}>Activate</MenuItem>
          </If>
        );
      }

      if (isCurrentUser) {
        return (
          <If condition={canUpdateAny}>
            <MenuItem onClick={handlerMap.edit}>Edit User</MenuItem>
          </If>
        );
      }

      return (
        <>
          <If condition={canActivateAny}>
            <MenuItem onClick={handlerMap.activate}>Activate</MenuItem>
          </If>
          <If condition={canUpdateAny}>
            <MenuItem onClick={handlerMap.edit}>Edit User</MenuItem>
          </If>
          <If condition={canDeleteAny}>
            <MenuItem onClick={handlerMap.delete}>Delete</MenuItem>
          </If>
        </>
      );
    }
  };

  const handlerMap: Partial<HandlerMap> = {
    'activate': () => handleChangeUsersStatuses('activate'),
    'delete': handleDeleteUserModalOpen,
    'edit': handleEditUserClick
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      flex: 1,
      headerName: 'NAME',
      minWidth: 100,
      renderCell(params: GridCellParams) {
        const row: GridRowModel = params.row;
        return (
          <Grid
            container
            spacing={1}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            flexWrap="nowrap"
            onClick={() => canGetUserAny && history.push(`/users/${params.row.id}`)}
            style={{ cursor: canGetUserAny ? 'pointer' : 'default' }}>
            <Grid item><UserAvatar className={classes.avatar} user={row as User} smallBadge /></Grid>
            <Grid item>{`${params.getValue(params.id, 'name')}`}</Grid>
          </Grid>
        );
      },
      valueGetter: (params: GridValueGetterParams) => toFullName(params.row),
    },
    {
      field: 'email',
      flex: 1,
      headerName: 'EMAIL',
      minWidth: 100
    },
    {
      field: 'userId',
      flex: 1,
      headerName: 'ID',
      minWidth: 100
    },
    {
      align: 'right',
      field: '',
      flex: 1,
      renderCell(params: GridCellParams) {
        const id = `${params.row.id}-menu-button`;
        return (
          <If condition={canUpdateAny || canActivateAny || canDeleteAny}>
            <IconButton
              aria-label="Actions"
              id={id}
              className={`${clsx({ [classes.activeState]: actionOverflowMenuAnchorEl?.id === id })}`}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleActionOverflowMenuClick(e, params)}
              size="small"
              disabled={isLoading}
            >
              <MoreVertIcon />
            </IconButton>
          </If>
        );
      },
      sortable: false
    }
  ];

  const tableMenuId = 'table-menu-button';
  const tableMenuOpen = actionOverflowMenuAnchorEl?.id === tableMenuId;
  return (
    <div className={classes.root}>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Breadcrumbs className={classes.breadcrumbs} separator="›" aria-label="breadcrumb">
            <Link
              className={classes.baseBreadcrumb}
              color="inherit"
              href="/users"
              onClick={handleBreadcrumbClick}
              variant="body2"
            >
              Users
            </Link>
            <Typography variant="body2">Deprovisioned</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid xs={6} container item alignItems="center" justifyContent="flex-end">
          <Box mr={4.5}>
            <SearchInput
              onChange={(e) => handleFilterChange(e.target.value)}
              onReset={() => handleFilterChange('')}
              placeholder="Search Users"
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container>
        <Grid xs={6} container item alignItems="center">
          <Typography variant="h3">Deprovisioned Users</Typography>
        </Grid>
      </Grid>
      <Toolbar disableGutters>
        {selected.length > 0 ? (
          <React.Fragment>
            <Typography variant="body2" className={classes.selectedResultsDisplay}>
              {selected.length} selected
            </Typography>
            <IconButton
              id={tableMenuId}
              className={`${clsx({ [classes.activeState]: tableMenuOpen })} ${classes.tableMenuButton}`}
              onClick={handleActionOverflowMenuClick}
              size="small"
              disabled={isLoading}
            >
              <MoreVertIcon />
            </IconButton>
          </React.Fragment>
        ) : (
          <Typography variant="body2" className={classes.selectedResultsDisplay}>
            {rowCountByPage(page, pageSize, rows.length)} of {rows.length} results
          </Typography>
        )}
      </Toolbar>
      <Divider />
      <DataGrid
        checkboxSelection={canActivateAny}
        columnBuffer={0}
        columns={columns}
        components={{
          NoRowsOverlay: NoSearchResultsOverlay
        }}
        componentsProps={{
          noRowsOverlay: {
            isFiltering: filterText.length,
            noRowsLabel: 'There are currently no deprovisioned users.'
          }
        }}
        disableColumnFilter
        disableColumnMenu
        disableSelectionOnClick
        hideFooterSelectedRowCount
        loading={isLoading}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        onSelectionModelChange={onSelectionModelChange}
        pageSize={pageSize}
        rows={rows}
        rowsPerPageOptions={PAGE_SIZE_OPTIONS}
      />
      <Menu
        anchorEl={actionOverflowMenuAnchorEl}
        anchorOrigin={{
          horizontal: tableMenuOpen ? 'left' : 'right',
          vertical: 'bottom',
        }}
        transformOrigin={{
          horizontal: tableMenuOpen ? 'left' : 'right',
          vertical: 'top',
        }}
        PopoverClasses={{
          root: classes.menuRoot
        }}
        keepMounted
        open={Boolean(actionOverflowMenuAnchorEl)}
        onClose={handleActionOverflowMenuClose}
      >
        <div>
          {getMenuItems(selectedActionUsers, handlerMap, currentAuthenticatedUserId)}
        </div>
      </Menu>
      <DeleteUserModal
        resourceName="users"
        isDeleting={isDeleting}
        onClose={handleDeleteUserModalClose}
        onDelete={() => {
          setSelected([]);
          setSelectedActionUsers([]);
        }}
        open={deleteUserModalOpen}
        resources={selectedActionUsers}
      />
    </div>
  );
}

const mapStateToProps = (state: UMAReduxState) => ({
  byId: state.users.byId,
  isDeleting: state.users.isDeleting,
  isLoading: state.users.isLoading,
  organizationCode: state.context.organizationCode,
  users: usersDetailsSpread(state.users, state.groups).filter((user) => user.status === 'deprovisioned')
    .sort((a, b) => toFullName(b).toLowerCase() > toFullName(a).toLowerCase() ? -1 : 1),
});

const mapDispatchToProps = {
  changeUsersStatuses,
  getDeprovisionedUsers
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDeprovisionedPage);
import React from 'react';
import {
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  ListSubheader,
  Paper,
  Typography
} from '@mui/material';

import useStyles from './styles';

function FilterList() {
  const classes = useStyles();

  return (
    <Paper className={classes.filterList}>
      <List
        classes={{
          padding: classes.listPadding
        }}
      >
        <ListSubheader
          classes={{
            gutters: classes.subheaderGutters,
            root: classes.titleSubheader
          }}
        >
          <Grid item container alignItems="center" justifyContent="center">
            <Grid item container alignItems="center" xs={6}>
              <Typography variant="h6" display="inline" className={classes.title}>Policy Rules</Typography>
            </Grid>
            <Grid xs={6} item container alignItems="center" justifyContent="flex-end">
              <Button
                disabled={true}
                color="primary"
                size="large"
                variant="contained"
              >
								Create Policy Rule
              </Button>
            </Grid>
          </Grid>
        </ListSubheader>
        <Divider />
        <ListItem disableGutters divider className={classes.listItem}>
          <Typography variant="body2" noWrap>Create role first prior to creating policy rules</Typography>
        </ListItem>
      </List>
    </Paper>
  );
}

export default FilterList;
import { applicationsApi } from '../../api';
import { ApplicationAction, Application } from './ApplicationModels';
import { AppThunkAction } from '../rootReducer';
import {
  findNonUbiquitousIds,
  normalizeApplicationList,
  normalizeOrganizationList,
  normalizeRoleList,
  showError,
  toTitleCase
} from '../../utility';
import { alertEnqueued } from '../Alerts/AlertActions';
import { GET_ORGANIZATIONS_REQUEST_SUCCESSFUL } from '../Organizations/OrganizationActions';
import { GET_ROLES_REQUEST_SUCCESSFUL } from '../Roles/RoleActions';
import { OrganizationAction } from '../Organizations/OrganizationModels';
import { RoleAction } from '../Roles/RoleModels';
import { Id } from '../../types/types';
import history from '../../history';

// Action Types
export const GET_APPLICATIONS_REQUESTED: string = 'GET_APPLICATIONS_REQUESTED';
export const GET_APPLICATIONS_REQUEST_SUCCESSFUL: string = 'GET_APPLICATIONS_REQUEST_SUCCESSFUL';
export const GET_APPLICATIONS_REQUEST_FAILED: string = 'GET_APPLICATIONS_REQUEST_FAILED';

export const GET_APPLICATION_BY_CODE_REQUESTED: string = 'GET_APPLICATION_BY_CODE_REQUESTED';
export const GET_APPLICATION_BY_CODE_REQUEST_SUCCESSFUL: string = 'GET_APPLICATION_BY_CODE_REQUEST_SUCCESSFUL';
export const GET_APPLICATION_BY_CODE_REQUEST_FAILED: string = 'GET_APPLICATION_BY_CODE_REQUEST_FAILED';

export const GET_APPLICATION_ORGANIZATIONS_BY_APP_CODE_REQUESTED: string = 'GET_APPLICATION_ORGANIZATIONS_BY_APP_CODE_REQUESTED';
export const GET_APPLICATION_ORGANIZATIONS_BY_APP_CODE_REQUEST_SUCCESSFUL: string = 'GET_APPLICATION_ORGANIZATIONS_BY_APP_CODE_REQUEST_SUCCESSFUL';
export const GET_APPLICATION_ORGANIZATIONS_BY_APP_CODE_REQUEST_FAILED: string = 'GET_APPLICATION_ORGANIZATIONS_BY_APP_CODE_REQUEST_FAILED';

export const GET_APPLICATION_ROLES_BY_APP_CODE_REQUESTED: string = 'GET_APPLICATION_ROLES_BY_APP_CODE_REQUESTED';
export const GET_APPLICATION_ROLES_BY_APP_CODE_REQUEST_SUCCESSFUL: string = 'GET_APPLICATION_ROLES_BY_APP_CODE_REQUEST_SUCCESSFUL';
export const GET_APPLICATION_ROLES_BY_APP_CODE_REQUEST_FAILED: string = 'GET_APPLICATION_ROLES_BY_APP_CODE_REQUEST_FAILED';

export const CREATE_APPLICATION_REQUESTED: string = 'CREATE_APPLICATION_REQUESTED';
export const CREATE_APPLICATION_REQUEST_SUCCESSFUL: string = 'CREATE_APPLICATION_REQUEST_SUCCESSFUL';
export const CREATE_APPLICATION_REQUEST_FAILED: string = 'CREATE_APPLICATION_REQUEST_FAILED';

export const UPDATE_APPLICATION_REQUESTED: string = 'UPDATE_APPLICATION_REQUESTED';
export const UPDATE_APPLICATION_REQUEST_SUCCESSFUL: string = 'UPDATE_APPLICATION_REQUEST_SUCCESSFUL';
export const UPDATE_APPLICATION_REQUEST_FAILED: string = 'UPDATE_APPLICATION_REQUEST_FAILED';

export const DELETE_APPLICATION_REQUESTED: string = 'DELETE_APPLICATION_REQUESTED';
export const DELETE_APPLICATION_REQUEST_SUCCESSFUL: string = 'DELETE_APPLICATION_REQUEST_SUCCESSFUL';
export const DELETE_APPLICATION_REQUEST_FAILED: string = 'DELETE_APPLICATION_REQUEST_FAILED';

export const ADD_PERMISSIONS_TO_APPLICATION_REQUESTED: string = 'ADD_PERMISSIONS_TO_APPLICATION_REQUESTED';
export const ADD_PERMISSIONS_TO_APPLICATION_REQUEST_SUCCESSFUL: string = 'ADD_PERMISSIONS_TO_APPLICATION_REQUEST_SUCCESSFUL';
export const ADD_PERMISSIONS_TO_APPLICATION_REQUEST_FAILED: string = 'ADD_PERMISSIONS_TO_APPLICATION_REQUEST_FAILED';

export const REMOVE_PERMISSIONS_FROM_APPLICATION_REQUESTED: string = 'REMOVE_PERMISSIONS_FROM_APPLICATION_REQUESTED';
export const REMOVE_PERMISSIONS_FROM_APPLICATION_REQUEST_SUCCESSFUL: string = 'REMOVE_PERMISSIONS_FROM_APPLICATION_REQUEST_SUCCESSFUL';
export const REMOVE_PERMISSIONS_FROM_APPLICATION_REQUEST_FAILED: string = 'REMOVE_PERMISSIONS_FROM_APPLICATION_REQUEST_FAILED';

export const ADD_ORGANIZATIONS_TO_APPLICATION_REQUESTED: string = 'ADD_ORGANIZATIONS_TO_APPLICATION_REQUESTED';
export const ADD_ORGANIZATIONS_TO_APPLICATION_REQUEST_SUCCESSFUL: string = 'ADD_ORGANIZATIONS_TO_APPLICATION_REQUEST_SUCCESSFUL';
export const ADD_ORGANIZATIONS_TO_APPLICATION_REQUEST_FAILED: string = 'ADD_ORGANIZATIONS_TO_APPLICATION_REQUEST_FAILED';

export const REMOVE_ORGANIZATIONS_FROM_APPLICATION_REQUESTED: string = 'REMOVE_ORGANIZATIONS_FROM_APPLICATION_REQUESTED';
export const REMOVE_ORGANIZATIONS_FROM_APPLICATION_REQUEST_SUCCESSFUL: string = 'REMOVE_ORGANIZATIONS_FROM_APPLICATION_REQUEST_SUCCESSFUL';
export const REMOVE_ORGANIZATIONS_FROM_APPLICATION_REQUEST_FAILED: string = 'REMOVE_ORGANIZATIONS_FROM_APPLICATION_REQUEST_FAILED';

export const ADD_ROLES_TO_APPLICATION_REQUESTED: string = 'ADD_ROLES_TO_APPLICATION_REQUESTED';
export const ADD_ROLES_TO_APPLICATION_REQUEST_SUCCESSFUL: string = 'ADD_ROLES_TO_APPLICATION_REQUEST_SUCCESSFUL';
export const ADD_ROLES_TO_APPLICATION_REQUEST_FAILED: string = 'ADD_ROLES_TO_APPLICATION_REQUEST_FAILED';

export const REMOVE_ROLES_FROM_APPLICATION_REQUESTED: string = 'REMOVE_ROLES_FROM_APPLICATION_REQUESTED';
export const REMOVE_ROLES_FROM_APPLICATION_REQUEST_SUCCESSFUL: string = 'REMOVE_ROLES_FROM_APPLICATION_REQUEST_SUCCESSFUL';
export const REMOVE_ROLES_FROM_APPLICATION_REQUEST_FAILED: string = 'REMOVE_ROLES_FROM_APPLICATION_REQUEST_FAILED';

// Action Creators

/**
 * Get all applications
 * @return {Function} - dispatches function response based on api results
 */
export function getApplications(...callbacksOnSuccess: (() => void)[]): AppThunkAction<ApplicationAction> {
  return async (dispatch) => {
    dispatch({ type: GET_APPLICATIONS_REQUESTED });
    try {
      const applications = await applicationsApi.getAll();
      const { allIds, byId } = normalizeApplicationList(applications);
      dispatch({ allIds, byId, type: GET_APPLICATIONS_REQUEST_SUCCESSFUL });
      for (const callbackOnSuccess of callbacksOnSuccess) {
        callbackOnSuccess();
      }
    } catch (err) {
      showError(err, dispatch);
      dispatch({ type: GET_APPLICATIONS_REQUEST_FAILED });
    }
  };
}

/**
 * Get a single application's details by code which includes permissions
 * @param {string} code - application code string
 * @return {Function} - dispatches function response based on api results
 */
export function getApplicationByCode(code: string): AppThunkAction<ApplicationAction> {
  return async (dispatch) => {
    dispatch({ id: code, type: GET_APPLICATION_BY_CODE_REQUESTED });
    try {
      const applicationApiResponse = await applicationsApi.getByCode(code);
      const { byId } = normalizeApplicationList([applicationApiResponse]);
      dispatch({ byId, id: code, type: GET_APPLICATION_BY_CODE_REQUEST_SUCCESSFUL });
    } catch (err) {
      showError(err, dispatch);
      dispatch({ id: code, type: GET_APPLICATION_BY_CODE_REQUEST_FAILED });
    }
  };
}

/**
 * Get a single application's details by code which includes permissions in the context of an organization.
 * @param {string} orgCode - organization code string
 * @param {string} appCode - application code string
 * @return {Function} - dispatches function response based on api results
 */
export function getApplicationByCodeInOrg(orgCode: string, appCode: string): AppThunkAction<ApplicationAction> {
  return async (dispatch) => {
    dispatch({ id: appCode, type: GET_APPLICATION_BY_CODE_REQUESTED });
    try {
      const applicationApiResponse = await applicationsApi.getByCodeInOrganization(orgCode, appCode);
      const { byId } = normalizeApplicationList([applicationApiResponse]);
      dispatch({ byId, id: appCode, type: GET_APPLICATION_BY_CODE_REQUEST_SUCCESSFUL });
    } catch (err) {
      showError(err, dispatch);
      dispatch({ id: appCode, type: GET_APPLICATION_BY_CODE_REQUEST_FAILED });
    }
  };
}

/**
 * Adds single/multiple permission(s) to an application
 * @param {string} applicationCode - application code string
 * @param {string[]} permissions - array of permissions to add
 * @param {...Function} callbacksOnSuccess - callbacks when api call was successful
 * @return {Function} - dispatches function response based on api results
 */
export function addPermissionsToApplication(
  applicationCode: string,
  permissions: string[],
  ...callbacksOnSuccess: (() => void)[]
): AppThunkAction<ApplicationAction> {
  return async (dispatch, getState) => {
    dispatch({ id: applicationCode, type: ADD_PERMISSIONS_TO_APPLICATION_REQUESTED });
    try {
      await applicationsApi.addPermissions(applicationCode, permissions);
      dispatch({ id: applicationCode, permissions, type: ADD_PERMISSIONS_TO_APPLICATION_REQUEST_SUCCESSFUL });

      const permissionsMessage = permissions.length > 1 ? `${permissions.length} permissions` : `${toTitleCase(permissions[0])} permission`;
      const applicationsMessage = getState().applications.byId[applicationCode].name || 'application';
      dispatch(alertEnqueued(
        `${permissions.length > 1 ?
          `${permissionsMessage} have been added to ${applicationsMessage}.` :
          `${permissionsMessage} has been added to ${applicationsMessage}.`
        }`, { variant: 'success' }));
      for (const callbackOnSuccess of callbacksOnSuccess) {
        callbackOnSuccess();
      }
    } catch (err) {
      showError(err, dispatch);
      dispatch({ id: applicationCode, type: ADD_PERMISSIONS_TO_APPLICATION_REQUEST_FAILED });
    }
  };
}

/**
 * Removes single/multiple permission(s) from an application
 * @param {string} applicationCode - application code string
 * @param {string[]} permissions - array of permissions to remove
 * @return {Function} - dispatches function response based on api results
 */
export function removePermissionsFromApplication(applicationCode: string, permissions: string[]): AppThunkAction<ApplicationAction> {
  return async (dispatch, getState) => {
    dispatch({ id: applicationCode, type: REMOVE_PERMISSIONS_FROM_APPLICATION_REQUESTED });
    try {
      await applicationsApi.removePermissions(applicationCode, permissions);
      dispatch({ id: applicationCode, permissions, type: REMOVE_PERMISSIONS_FROM_APPLICATION_REQUEST_SUCCESSFUL });

      const permissionsMessage = permissions.length > 1 ? `${permissions.length} permissions` : `${toTitleCase(permissions[0])} permission`;
      const applicationsMessage = getState().applications.byId[applicationCode].name || 'application';
      dispatch(alertEnqueued(
        `${permissions.length > 1 ?
          `${permissionsMessage} have been removed from ${applicationsMessage}.` :
          `${permissionsMessage} has been removed from ${applicationsMessage}.`
        }`, { variant: 'success' }));
    } catch (err) {
      showError(err, dispatch);
      dispatch({ id: applicationCode, type: REMOVE_PERMISSIONS_FROM_APPLICATION_REQUEST_FAILED });
    }
  };
}

/**
 * Get all the organizations associated with an application
 * @param {string} code - application code string
 * @return {Function} - dispatches function response based on api results
 */
export function getOrganizationsForApplication(code: string): AppThunkAction<ApplicationAction | OrganizationAction> {
  return async (dispatch) => {
    dispatch({ id: code, type: GET_APPLICATION_ORGANIZATIONS_BY_APP_CODE_REQUESTED });
    try {
      const applicationOrganizationsApiResponse = await applicationsApi.getOrganizations(code);
      const { allIds, byId } = normalizeOrganizationList(applicationOrganizationsApiResponse);
      dispatch({ allIds, byId, type: GET_ORGANIZATIONS_REQUEST_SUCCESSFUL });
      dispatch({ id: code, organizationCodes: allIds, type: GET_APPLICATION_ORGANIZATIONS_BY_APP_CODE_REQUEST_SUCCESSFUL });
    } catch (err) {
      showError(err, dispatch);
      dispatch({ id: code, type: GET_APPLICATION_ORGANIZATIONS_BY_APP_CODE_REQUEST_FAILED });
    }
  };
}

/**
 * Get all the roles associated with an application
 * @param {string} code - application code string
 * @return {Function} - dispatches function response based on api results
 */
export function getRolesForApplication(code: string): AppThunkAction<ApplicationAction | RoleAction> {
  return async (dispatch) => {
    dispatch({ id: code, type: GET_APPLICATION_ROLES_BY_APP_CODE_REQUESTED });
    try {
      const applicationRolesApiResponse = await applicationsApi.getRoles(code);
      const { allIds, byId } = normalizeRoleList(applicationRolesApiResponse);
      dispatch({ allIds, byId, type: GET_ROLES_REQUEST_SUCCESSFUL });
      dispatch({ id: code, roleIds: allIds, type: GET_APPLICATION_ROLES_BY_APP_CODE_REQUEST_SUCCESSFUL });
    } catch (err) {
      showError(err, dispatch);
      dispatch({ id: code, type: GET_APPLICATION_ROLES_BY_APP_CODE_REQUEST_FAILED });
    }
  };
}

/**
 * Get all the roles associated with an application
 * and org roles associated to an application by policy
 * @param {String} applicationCode - application code
 * @param {String} organizationCode - organization code
 * @return {Function} - dispatches function response based on api results
 */
export function getAppAndOrgRoles(applicationCode: string, organizationCode: string): AppThunkAction<ApplicationAction | RoleAction> {
  return async (dispatch) => {
    dispatch({ id: applicationCode, type: GET_APPLICATION_ROLES_BY_APP_CODE_REQUESTED });
    try {
      const rolesApiResponse = await applicationsApi.getAppRolesAndOrgRolesForAppByPolicy(applicationCode, organizationCode);
      const { allIds, byId } = normalizeRoleList(rolesApiResponse);
      dispatch({ allIds, byId, type: GET_ROLES_REQUEST_SUCCESSFUL });
      dispatch({ id: applicationCode, roleIds: allIds, type: GET_APPLICATION_ROLES_BY_APP_CODE_REQUEST_SUCCESSFUL });
    } catch (err) {
      showError(err, dispatch);
      dispatch({ id: applicationCode, type: GET_APPLICATION_ROLES_BY_APP_CODE_REQUEST_FAILED });
    }
  };
}

/**
 * Creates a new application
 * @param {string} organizationCode - the organization to tie the application to
 * @param {Partial<Application>} application - the application to create
 * @return {Function} - dispatches function response based on api results
 */
export function createApplication(organizationCode: string, application: Partial<Application>): AppThunkAction<ApplicationAction> {
  return async (dispatch) => {
    dispatch({ type: CREATE_APPLICATION_REQUESTED });
    try {
      const result = await applicationsApi.create(organizationCode, application);
      const { allIds, byId } = normalizeApplicationList([result]);
      dispatch({ allIds, byId, type: CREATE_APPLICATION_REQUEST_SUCCESSFUL });
      dispatch(alertEnqueued('Application has been created.', { variant: 'success' }));
      history.push(`/applications/${result.code}`);
    } catch (err) {
      showError(err, dispatch);
      dispatch({ type: CREATE_APPLICATION_REQUEST_FAILED });
    }
  };
}

/**
 * Updates an existing application
 * @param {string} applicationCode - the code of the application to update
 * @param {Partial<Application>} application - the updated application object
 * @param {Function[]} callbacksOnSuccess - Optional methods to call on success
 * @return {Function} - dispatches function response based on api results
 */
export function updateApplication(applicationCode: string, application: Partial<Application>, ...callbacksOnSuccess: Function[])
  : AppThunkAction<ApplicationAction> {
  return async (dispatch) => {
    dispatch({ id: applicationCode, type: UPDATE_APPLICATION_REQUESTED });
    try {
      const result = await applicationsApi.update(applicationCode, application);
      const { byId } = normalizeApplicationList([result]);
      dispatch({ byId, id: applicationCode, type: UPDATE_APPLICATION_REQUEST_SUCCESSFUL });
      dispatch(alertEnqueued(`${application.name} has been updated.`, { variant: 'success' }));
      callbacksOnSuccess.forEach((callbackOnSuccess: Function) => {
        callbackOnSuccess();
      });
    } catch (err) {
      showError(err, dispatch);
      dispatch({ type: UPDATE_APPLICATION_REQUEST_FAILED });
    }
  };
}

/**
 * Deletes an existing application
 * @param {Application} application - the application to delete
 * @param {Function[]} callbacksOnSuccess - Optional methods to call on success
 * @return {Function} - dispatches function response based on api results
 */
export function deleteApplication(application: Application, ...callbacksOnSuccess: Function[]): AppThunkAction<ApplicationAction> {
  return async (dispatch) => {
    dispatch({ id: application.code, type: DELETE_APPLICATION_REQUESTED });
    try {
      await applicationsApi.deleteApplication(application.code);
      dispatch({ id: application.code, type: DELETE_APPLICATION_REQUEST_SUCCESSFUL });
      dispatch(alertEnqueued(`${application.name} has been deleted.`, { variant: 'success' }));
      callbacksOnSuccess.forEach((callbackOnSuccess: Function) => {
        callbackOnSuccess();
      });
    } catch (err) {
      showError(err, dispatch);
      dispatch({ type: DELETE_APPLICATION_REQUEST_FAILED });
    }
  };
}

/**
 * Adds single/multiple organization(s) to an application
 * @param {String} applicationCode - application code used to add organizations
 * @param {Id[]} organizationCodes - an array of organization codes
 * @param {...Function} callbacksOnSuccess - callbacks when api call was successful
 * @return {Function} - dispatches function response based on api results
 */
export function addOrganizationsToApplication(
  applicationCode: string,
  organizationCodes: Id[],
  ...callbacksOnSuccess: (() => void)[]
): AppThunkAction<ApplicationAction> {
  return async (dispatch, getState) => {
    dispatch({ type: ADD_ORGANIZATIONS_TO_APPLICATION_REQUESTED });
    try {
      const applicationsById = getState().applications.byId;
      const organizationsById = getState().organizations.byId;
      await applicationsApi.addOrganizations(applicationCode, organizationCodes);

      const newOrganizationCodes = findNonUbiquitousIds(organizationCodes, [applicationsById[applicationCode].organizationCodes ?? []]);

      // Form singular/plural message strings
      const organizationsMessage = newOrganizationCodes.length > 1
        ? `${newOrganizationCodes.length} organizations` : `${organizationsById[newOrganizationCodes[0]].name} organization`;
      const applicationsMessage = applicationsById[applicationCode].name || 'application';

      dispatch({ id: applicationCode, organizationCodes, type: ADD_ORGANIZATIONS_TO_APPLICATION_REQUEST_SUCCESSFUL });
      dispatch(alertEnqueued(
        `${newOrganizationCodes.length > 1 ?
          `${organizationsMessage} have been added to ${applicationsMessage}.` :
          `${organizationsMessage} has been added to ${applicationsMessage}.`
        }`, { variant: 'success' }));
      for (const callbackOnSuccess of callbacksOnSuccess) {
        callbackOnSuccess();
      }
    } catch (err) {
      showError(err, dispatch);
      dispatch({ type: ADD_ORGANIZATIONS_TO_APPLICATION_REQUEST_FAILED });
    }
  };
}

/**
 * Removes single/multiple organization(s) from an application
 * @param {String} applicationCode - application code used to remove organizations
 * @param {Id[]} organizationCodes - an array of organization codes
 * @param {...Function} callbacksOnSuccess - callbacks when api call was successful
 * @return {Function} - dispatches function response based on api results
 */
export function removeOrganizationsFromApplication(
  applicationCode: string,
  organizationCodes: Id[],
  ...callbacksOnSuccess: (() => void)[]
): AppThunkAction<ApplicationAction> {
  return async (dispatch, getState) => {
    dispatch({ type: REMOVE_ORGANIZATIONS_FROM_APPLICATION_REQUESTED });
    try {
      await applicationsApi.removeOrganizations(applicationCode, organizationCodes);
      dispatch({ id: applicationCode, organizationCodes, type: REMOVE_ORGANIZATIONS_FROM_APPLICATION_REQUEST_SUCCESSFUL });

      // Form singular/plural message strings
      const organizationsMessage = organizationCodes.length > 1
        ? `${organizationCodes.length} organizations` : `${getState().organizations.byId[organizationCodes[0]].name} organization`;
      const applicationsMessage = getState().applications.byId[applicationCode].name || 'application';
      dispatch(alertEnqueued(
        `${organizationCodes.length > 1 ?
          `${organizationsMessage} have been removed from ${applicationsMessage}.` :
          `${organizationsMessage} has been removed from ${applicationsMessage}.`
        }`, { variant: 'success' }));
      for (const callbackOnSuccess of callbacksOnSuccess) {
        callbackOnSuccess();
      }
    } catch (err) {
      showError(err, dispatch);
      dispatch({ type: REMOVE_ORGANIZATIONS_FROM_APPLICATION_REQUEST_FAILED });
    }
  };
}
import { AnyAction } from 'redux';
import {
  Alert,
  AlertState
} from './AlertModels';
import {
  ALERT_ENQUEUED,
  ALERT_DEQUEUED,
  ALERT_CLOSED
} from './AlertActions';

export const initialState: AlertState = {
  all: [],
  isOpen: false
};

function alerts(state: AlertState = initialState, action: AnyAction): AlertState {
  switch (action.type) {
    case ALERT_ENQUEUED:
      if (state.all.find((alert: Alert) => alert.message === action.message)) {
        return state;
      }
      return {
        ...state,
        all: state.all.concat({
          autoHide: action.options.autoHide,
          autoHideDuration: action.options.autoHideDuration,
          inForm: action.options.inForm,
          message: action.message,
          variant: action.options.variant,
        }),
        isOpen: true
      };

    case ALERT_DEQUEUED:
      return {
        ...state,
        all: state.all.slice(1),
        isOpen: !!state.all.slice(1).length
      };

    case ALERT_CLOSED:
      return {
        ...state,
        isOpen: false
      };

    default:
      return state;
  }
}

export default alerts;
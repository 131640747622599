import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

const styles = makeStyles((theme: Theme) => ({
  activeState: {
    backgroundColor: '#00000010'
  },
  deleteTooltip: {
    backgroundColor: '#f5f5f9',
    border: '1px solid #dadde9',
    color: '#00000087',
    maxWidth: 220
  },
  menuRoot: {
    marginTop: theme.spacing(0.5)
  },
  root: {
    '& .MuiDataGrid-row.Mui-selected': {
      '&:hover': {
        backgroundColor: '#00000003'
      },
      backgroundColor: 'transparent',
    },
    height: 736,
    width: '100%'
  },
  selectedResultsDisplay: {
    color: theme.palette.grey[600]
  },
  tableMenuButton: {
    marginLeft: theme.spacing(0.5)
  }
}));

export default styles;
import * as Yup from 'yup';

import { CollectionState, Id, ReceiveCollectionAction } from '../../types/types';
import { Application } from '../Applications/ApplicationModels';
import { Group } from '../Groups/GroupModels';

// We define our schema based on the same keys in our form:
export const createRoleFormSchema = Yup.object().shape({
  applicationCode: Yup.string().nullable(true).when('roleType', {
    is: 'application',
    then: Yup.string().nullable(true).required('Application is required with current role type.')
  }),
  name: Yup.string().required('Role Name is required.'),
  description: Yup.string().notRequired(),
  roleType: Yup.string().required('Role Type is required.').nullable(true)
}).required();

export const updateRoleFormSchema = Yup.object().shape({
  name: Yup.string().required('Role Name is required.'),
  description: Yup.string().notRequired(),
  roleType: Yup.string().required('Role Type is required.').nullable(true)
}).required();

export type CreateRoleFormValues = {
  applicationCode: string;
  description: string;
  name: string;
  roleType: RoleType;
}

export type UpdateRoleFormValues = {
  description: string | null;
  name: string;
  roleType: RoleType;
}

export type RoleType = 'unspecified' | 'organization' | 'application';

export interface Role {
  application?: Application;
  applicationId?: string | null;
  created?: string;
  description?: string | null;
  groupIds?: Id[];
  isLoading?: boolean;
  organizationId?: Id;
  id: Id;
  name: string;
  roleType: RoleType;
  isUpdatingName?: boolean;
  isUpdatingDescription?: boolean;
}

export interface RoleRow extends Role {
  id: Id;
}

export interface RoleDetailsApiResponse extends Role {
  groups?: Group[];
}

export interface RolesState extends CollectionState<Role> {
  isCreating: boolean;
  isDeleting: boolean;
  isLoading: boolean;
  isExporting: boolean;
}

interface GetRolesAction {
  type: string;
}

interface DeleteRolesAction {
  type: string;
  roles: Role[];
}

interface RequestRolesAction {
  type: string;
}

interface RequestRoleByIdAction {
  type: string;
  role: Partial<Role>;
  id: Id;
}

export type RoleAction = RequestRolesAction | ReceiveCollectionAction<Role> | GetRolesAction | DeleteRolesAction | RequestRoleByIdAction;
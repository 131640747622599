import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { debounce } from 'lodash';
import clsx from 'clsx';
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography
} from '@mui/material';
import {
  GridCellParams,
  GridColDef,
  DataGrid,
  GridSelectionModel,
  GridValueGetterParams
} from '@mui/x-data-grid';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Link } from 'react-router-dom';

import { UMAReduxState } from '../../../store/rootReducer';
import { ById } from '../../../types/types';
import { getGroupsByOrgWithUsersAndRoles } from '../../../store/Groups/GroupActions';
import { Group } from '../../../store/Groups/GroupModels';
import { Role } from '../../../store/Roles/RoleModels';
import { escapeRegExp, groupsDetailsSpread, roleNameComparator, rowCountByPage, toGroupMask } from '../../../utility';
import AssignRolesModal from '../../AssignModal/AssignModal';
import AssignUsersModal from '../../AssignModal/AssignModal';
import DeleteGroupsModal from '../../DeleteModal/DeleteModal';
import NoSearchResultsOverlay from '../../NoSearchResultsOverlay/NoSearchResultsOverlay';
import SearchInput from '../../SearchInput/SearchInput';
import environment from '../../../environment';
import useStyles from './styles';
import If from '../../If/If';
import policyConstants from '../../../constants/policyConstants';
import { useCasbin } from '../../../hooks/useCasbin';
import Can from '../../Can/Can';
import { INITIAL_PAGE_SIZE, PAGE_SIZE_OPTIONS } from '../../../constants/pageSizeConstants';


type GroupsPageProps = {
  byId: ById<Group>;
  getGroupsByOrgWithUsersAndRoles: (organizationCode: string) => void;
  isAssigning: boolean,
  isDeleting: boolean,
  isLoading: boolean;
  organizationCode: string;
  groups: Group[];
}

function GroupsPage({
  byId,
  getGroupsByOrgWithUsersAndRoles,
  isAssigning,
  isDeleting,
  isLoading,
  organizationCode,
  groups
}: GroupsPageProps) {
  const history = useHistory();
  const { debounceDelayMS } = environment;

  const [selected, setSelected] = useState<(string | number)[]>([]);
  const [filterText, setFilterText] = useState<string>('');
  const [rows, setRows] = useState<Group[]>(groups);
  const [selectedActionGroups, setSelectedActionGroups] = useState<Group[]>([]);
  const [actionOverflowMenuAnchorEl, setActionOverflowMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [assignRolesToGroupsModalOpen, setAssignRolesToGroupsModalOpen] = useState(false);
  const [assignUsersToGroupsModalOpen, setAssignUsersToGroupsModalOpen] = useState(false);
  const [deleteGroupsModalOpen, setDeleteGroupsModalOpen] = useState(false);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(INITIAL_PAGE_SIZE);
  const classes = useStyles();
  const canEditGroups = useCasbin(policyConstants.groups.updateAny(organizationCode));

  const debouncedGetGroupsByOrgWithUsersAndRoles = debounce((organizationCode) => {
    getGroupsByOrgWithUsersAndRoles(organizationCode);
  }, debounceDelayMS);

  useEffect(() => {
    // Initial display of groups based on search bar filter
    handleFilterChange(filterText);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groups]);

  /*
    Using a debounced call to prevent unnecessary calls to OKTA if a user is clicking around navigation.
    Debounce delay is set as an env variable (defaults to 0 ms).
    */
  useEffect(() => {
    debouncedGetGroupsByOrgWithUsersAndRoles(organizationCode);
    return () => {
      debouncedGetGroupsByOrgWithUsersAndRoles.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationCode]);

  // Data Grid Actions
  const onPageChange = (page: number) => setPage(page);
  const onPageSizeChange = (pageSize: number) => setPageSize(pageSize);
  const onSelectionModelChange = (selectionModel: GridSelectionModel) => setSelected(selectionModel);
  const handleFilterChange = (filterText: string) => {
    setFilterText(filterText);
    const searchRegex = new RegExp(escapeRegExp(filterText), 'i');
    const filteredRows = groups.filter((group) => {
      return searchRegex.test(toGroupMask(group));
    });
    setRows(filteredRows);
  };

  // Action Overflow Menu
  const handleActionOverflowMenuClick = (event: React.MouseEvent<HTMLButtonElement>, cellParams?: GridCellParams) => {
    if (cellParams !== undefined) {
      setSelectedActionGroups([byId[cellParams.row.id]]);
    } else {
      setSelectedActionGroups(selected.map((id) => byId[id]));
    }
    setActionOverflowMenuAnchorEl(event.currentTarget);
  };
  const handleActionOverflowMenuClose = () => {
    setActionOverflowMenuAnchorEl(null);
  };

  // Edit Group - Route to Group Detail
  const handleEditGroupClick = () => {
    const groupId = selectedActionGroups[0].id;
    history.push(`${history.location.pathname}/${groupId}`);
  };

  // Assign Users Modal
  const handleAssignUsersModalOpen = () => {
    setAssignUsersToGroupsModalOpen(true);
    setActionOverflowMenuAnchorEl(null);
  };
  const handleAssignUsersModalClose = () => setAssignUsersToGroupsModalOpen(false);

  // Assign Roles Modal
  const handleAssignRolesModalOpen = () => {
    setAssignRolesToGroupsModalOpen(true);
    setActionOverflowMenuAnchorEl(null);
  };
  const handleAssignRolesModalClose = () => setAssignRolesToGroupsModalOpen(false);

  // Create/Delete Group Actions
  const handleCreateGroupClick = () => history.push(`${history.location.pathname}/create`);
  const handleDeleteGroupsModalOpen = () => {
    setDeleteGroupsModalOpen(true);
    setActionOverflowMenuAnchorEl(null);
  };
  const handleDeleteGroupsModalClose = () => setDeleteGroupsModalOpen(false);

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'NAME',
      minWidth: 300,
      renderCell(params: GridCellParams) {
        return <Link to={`/groups/${params.row.id}`} style={{ textDecoration: 'none' }}>
          <Typography>
            {params.row.name}
          </Typography>
        </Link>;
      },
    },
    {
      field: 'users',
      headerName: 'USERS',
      minWidth: 100,
      sortComparator: (v1, v2) => (v2 as number) > (v1 as number) ? -1 : 1,
      valueGetter: (params: GridValueGetterParams) => params.row?.userIds?.length || 0, // TODO switch to users w/ UMA-455
    },
    {
      field: 'roles',
      flex: 1,
      headerName: 'ROLES',
      minWidth: 200,
      valueGetter: (params: GridValueGetterParams) => params.row.roles?.sort(roleNameComparator)?.map((role: Role) => role.name)?.join(', ') ?? '',
    },
    {
      align: 'right',
      field: '',
      renderCell(params: GridCellParams) {
        const id = `${params.row.id}-menu-button`;
        return (
          <If condition={canEditGroups}>
            <IconButton
              aria-label="Actions"
              id={id}
              className={`${clsx({ [classes.activeState]: actionOverflowMenuAnchorEl?.id === id })}`}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleActionOverflowMenuClick(e, params)}
              size="small"
              disabled={isLoading}
              sx={{ mr: 2 }}
            >
              <MoreVertIcon />
            </IconButton>
          </If>
        );
      },
      sortable: false
    }
  ];

  const tableMenuId = 'table-menu-button';
  const tableMenuOpen = actionOverflowMenuAnchorEl?.id === tableMenuId;
  return (
    <div className={classes.root}>
      <Grid container>
        <Grid xs={2} container item alignItems="center">
          <Typography variant="h3">Groups</Typography>
        </Grid>
        <Grid xs={10} container item alignItems="center" justifyContent="flex-end">
          <Box my={.5}>
            <SearchInput
              onChange={(e) => handleFilterChange(e.target.value)}
              onReset={() => handleFilterChange('')}
              placeholder="Search Groups"
            />
          </Box>
          <Can requiredPolicy={policyConstants.groups.create(organizationCode)}>
            <Box ml={4.5}>
              <Button
                color="primary"
                size="large"
                variant="contained"
                onClick={handleCreateGroupClick}
              >
                Create Group
              </Button>
            </Box>
          </Can>
        </Grid>
      </Grid>
      <Toolbar disableGutters>
        {selected.length > 0 ? (
          <React.Fragment>
            <Typography variant="body2" className={classes.selectedResultsDisplay}>
              {selected.length} selected
            </Typography>
            <IconButton
              id={tableMenuId}
              className={`${clsx({ [classes.activeState]: tableMenuOpen })} ${classes.tableMenuButton}`}
              onClick={handleActionOverflowMenuClick}
              size="small"
              disabled={isLoading}
            >
              <MoreVertIcon />
            </IconButton>
          </React.Fragment>
        ) : (
          <Typography variant="body2" className={classes.selectedResultsDisplay}>
            {rowCountByPage(page, pageSize, rows.length)} of {rows.length} results
          </Typography>
        )}
      </Toolbar>
      <Divider />
      <DataGrid
        checkboxSelection={canEditGroups}
        columnBuffer={0}
        columns={columns}
        components={{
          NoRowsOverlay: NoSearchResultsOverlay
        }}
        componentsProps={{
          noRowsOverlay: {
            isFiltering: filterText.length,
            noRowsLabel: 'No groups have been created.'
          }
        }}
        disableColumnFilter
        disableColumnMenu
        disableSelectionOnClick
        hideFooterSelectedRowCount
        loading={isLoading}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        onSelectionModelChange={onSelectionModelChange}
        pageSize={pageSize}
        rows={rows}
        rowsPerPageOptions={PAGE_SIZE_OPTIONS}
      />
      <Menu
        anchorEl={actionOverflowMenuAnchorEl}
        anchorOrigin={{
          horizontal: tableMenuOpen ? 'left' : 'right',
          vertical: 'bottom',
        }}
        transformOrigin={{
          horizontal: tableMenuOpen ? 'left' : 'right',
          vertical: 'top',
        }}
        PopoverClasses={{
          root: classes.menuRoot
        }}
        keepMounted
        open={Boolean(actionOverflowMenuAnchorEl)}
        onClose={handleActionOverflowMenuClose}
      >
        <div>
          {selectedActionGroups.length > 1 ? (
            <>
              <MenuItem onClick={handleAssignUsersModalOpen}>Assign users</MenuItem>
              <MenuItem onClick={handleDeleteGroupsModalOpen}>Delete</MenuItem>
            </>
          ) : (
            <>
              <MenuItem onClick={handleEditGroupClick}>Edit group</MenuItem>
              <MenuItem onClick={handleAssignUsersModalOpen}>Assign users</MenuItem>
              <MenuItem onClick={handleAssignRolesModalOpen}>Assign roles</MenuItem>
              <MenuItem onClick={handleDeleteGroupsModalOpen}>Delete</MenuItem>
            </>
          )}
        </div>
      </Menu>
      <AssignUsersModal
        assignResourceName="users"
        parentResource={selectedActionGroups}
        parentResourceName="groups"
        isAssigning={isAssigning}
        onClose={handleAssignUsersModalClose}
        open={assignUsersToGroupsModalOpen}
      />
      <AssignRolesModal
        assignResourceName="roles"
        parentResource={selectedActionGroups}
        parentResourceName="groups"
        isAssigning={isAssigning}
        onClose={handleAssignRolesModalClose}
        open={assignRolesToGroupsModalOpen}
      />
      <DeleteGroupsModal
        resourceName="groups"
        isDeleting={isDeleting}
        onClose={handleDeleteGroupsModalClose}
        onDelete={() => {
          setSelected([]);
          setSelectedActionGroups([]);
        }}
        open={deleteGroupsModalOpen}
        resources={selectedActionGroups}
      />
    </div>
  );
}

const mapStateToProps = (state: UMAReduxState) => ({
  byId: state.groups.byId,
  groups: groupsDetailsSpread(state.groups, state.users, state.roles).sort((a, b) => b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 1),
  isAssigning: state.groups.isAssigning,
  isDeleting: state.groups.isDeleting,
  isLoading: state.groups.isLoading,
  organizationCode: state.context.organizationCode,
});

const mapDispatchToProps = {
  getGroupsByOrgWithUsersAndRoles,
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupsPage);

import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { UMAReduxState } from '../../store/rootReducer';
import { PolicyRequest } from '../../store/My/MyModels';
import { useCasbin } from '../../hooks/useCasbin';

interface StateToProps {
  actualPermissions: string[];
  receivedPermissions: boolean;
}

interface CanProps {
  children: any;
  partialPermissions?: boolean;
  redirectTo?: string;
  requiredPermissions?: string[];
  requiredPolicy?: PolicyRequest;
}

type Props = CanProps & StateToProps;

function Can({
  children,
  redirectTo,
  requiredPolicy
}: Props) {

  const hasPermission = useCasbin(requiredPolicy);

  if (hasPermission) {
    return children;
  } else if (!hasPermission && redirectTo) {
    return <Redirect to={redirectTo} />;
  } else {
    return <React.Fragment />;
  }
}

function mapStateToProps(state: UMAReduxState): StateToProps {
  return {
    actualPermissions: state.my.permissions,
    receivedPermissions: state.my.receivedPermissions
  };
}

export default connect(mapStateToProps)(Can);
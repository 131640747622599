import {
  Alert,
  AlertAction
} from './AlertModels';

// Action Types
export const ALERT_ENQUEUED: string = 'ALERT_ENQUEUED';
export const ALERT_DEQUEUED: string = 'ALERT_DEQUEUED';
export const ALERT_CLOSED: string = 'ALERT_CLOSED';

// Action Creators

/**
 * Remove an alert from the queue
 * @return {Object} - redux type
 */
export const alertDequeued = (): AlertAction => ({ type: ALERT_DEQUEUED });

/**
 * Closes the GlobalAlert modal component
 * @return {Object} - redux type
 */
export const alertClosed = (): AlertAction => ({ type: ALERT_CLOSED });

/**
 * Adds an alert to the queue to be displayed - defaults to error
 * @param {String} message - the message to display
 * @param {Object} [options] - alert display options
 * @param {boolean} [options.autoHide] - whether to auto hide the alert
 * @param {number} [options.autoHideDuration] - number of milliseconds til alert hides (only if autoHide is true)
 * @param {boolean} [options.inForm] - (error specific) whether error came from a form
 * @param {string} [options.variant] - type of alert message
 * @return {Object} - message, alert options and redux type
 */
export function alertEnqueued(message: string = 'An unknown error occurred', options: Partial<Alert> = {}): AlertAction {
  const defaultOptions: Alert = {
    autoHide: false,
    autoHideDuration: null,
    inForm: false,
    variant: 'error'
  };
  const mergedOptions: Alert = Object.assign({}, defaultOptions, options);

  if (mergedOptions.autoHide && !mergedOptions.autoHideDuration) {
    // Set a sensible duration (in milliseconds) based on the number of
    // words in the message, plus a little buffer.
    mergedOptions.autoHideDuration = ((message.split(' ').length * 1000) / 2) + 500;
  }

  return {
    message,
    options: mergedOptions,
    type: ALERT_ENQUEUED
  };
}
import { Role } from '../store/Roles/RoleModels';

/**
 * A comparator for the name property of `Role`s
 * @param {Role} role1 - A `Role`
 * @param {Role} role2 - Another `Role`
 * @return {Number} a number indicating whether a reference role's name comes before or after the given string in sort order.
 */
export default function roleNameComparator(role1: Role, role2: Role): Number {
  return role1.name.localeCompare(role2.name);
};
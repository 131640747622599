import React from 'react';
import {
  Avatar,
  AvatarProps,
  Badge,
  Tooltip
} from '@mui/material';
import clsx from 'clsx';

import { User } from '../../store/Users/UserModels';
import { toInitials, toFullName } from '../../utility';
import useStyles from './styles';

interface UserAvatarProps extends AvatarProps {
	disableBadge?: boolean;
	smallBadge?: boolean;
	user: Partial<User>;
}

export default function UserAvatar({
  disableBadge = false,
  smallBadge = false,
  user,
  ...rest
}: UserAvatarProps) {
  const classes = useStyles({ smallBadge, users: 0 });

  const showBadge = !disableBadge;
  const deactivated = user.status === 'deprovisioned';
  const federatedUser = user.isFederated;
  if (deactivated && showBadge) {
    return (
      <Badge
        classes={{
          anchorOriginBottomRightCircular: classes.anchorOriginBottomRightCircle,
          badge: clsx(classes.badge, classes.deactivated)
        }}
        color="primary"
        overlap="circular"
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        badgeContent={
          <Tooltip
            title={`${federatedUser ? 'Deactivated/Federated' : 'Deactivated'} User`}
            placement="bottom-start"
          >
            <div className={classes.tooltipArea}>{federatedUser ? 'F' : ''}</div>
          </Tooltip>
        }
      >
        <Avatar alt={toFullName(user)} {...rest}>{toInitials(user)}</Avatar>
      </Badge>
    );
  }
  if (federatedUser && showBadge) {
    return (
      <Badge
        classes={{
          anchorOriginBottomRightCircular: classes.anchorOriginBottomRightCircle,
          badge: classes.badge
        }}
        color="primary"
        overlap="circular"
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        badgeContent={<Tooltip title="Federated User" placement="bottom-start"><div className={classes.tooltipArea}>F</div></Tooltip>}
      >
        <Avatar alt={toFullName(user)} {...rest}>{toInitials(user)}</Avatar>
      </Badge>
    );
  } else {
    return <Avatar alt={toFullName(user)} {...rest}>{toInitials(user)}</Avatar>;
  }
};

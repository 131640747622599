import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

const styles = makeStyles((theme: Theme) => ({
  baseBreadcrumb: {
    textDecoration: 'underline'
  },
  breadcrumbs: {
    color: theme.palette.grey[600],
    fontSize: '12px'
  },
  buttonSpacing: {
    marginRight: theme.spacing(1)
  },
  descriptionField: {
    '&:hover': {
      border: `1px solid ${theme.palette.primary.light}`
    },
    border: '1px solid transparent',
    borderRadius: 4,
    fontSize: 13,
    height: 'auto',
    padding: theme.spacing(1, 0.5),
  },
  disabled: {
    '&$disabled&:hover': {
      borderColor: 'transparent'
    },
    color: '#000'
  },
  editControls: {
    marginTop: theme.spacing(0.5),
    width: 'auto'
  },
  editControlsHidden: {
    visibility: 'hidden'
  },
  header: {
    minHeight: theme.spacing(4.75)
  },
  iconButton: {
    border: '0.75px solid #DCDCDC',
    borderRadius: '3px',
    padding: 0
  },
  input: {
    '& + .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '&:placeholder-shown': {
      '& + .MuiInputAdornment-positionEnd': {
        visibility: 'hidden'
      },
    },
    padding: 0
  },
  nameField: {
    '&:hover': {
      border: `1px solid ${theme.palette.primary.light}`
    },
    border: '1px solid transparent',
    fontSize: 30,
  },
  notchedOutline: {
    border: 0
  },
  roleTypeBadge: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(0.5)
  }
}));

export default styles;
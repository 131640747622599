import { Role, RoleDetailsApiResponse } from '../store/Roles/RoleModels';
import { ById, CollectionState, Id } from '../types/types';

/**
 * Returns `Role`s with array of `User`s ids normalized into allId/byId format.
 * @param {RoleDetailsApiResponse[]} list an array of `Role`s
 * @return {CollectionState<Role>} an object containing `Role`s with `User`s using allIds/byId format
 */
export default function normalizeRolesDetailsApiResponse(list: RoleDetailsApiResponse[]): CollectionState<Role> {
  const idKey: keyof Role = 'id';
  const allIds: Id[] = [];
  const byId: ById<Role> = {};
  list.forEach((item: RoleDetailsApiResponse) => {
    allIds.push(item[idKey]);
    const groupIds = item.groups?.map((group) => group.id) ?? [];
    delete item.groups;
    byId[item[idKey]] = { ...item, groupIds };
  });
  return { allIds, byId };
}
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import clsx from 'clsx';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography
} from '@mui/material';
import { DataGrid, GridCellParams, GridColDef, GridRowId, GridSelectionModel, } from '@mui/x-data-grid';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Link } from 'react-router-dom';

import { UMAReduxState } from '../../../store/rootReducer';
import { ById, Id } from '../../../types/types';
import { exportRoles, getRoles } from '../../../store/Roles/RoleActions';
import { Role, RoleRow, RolesState } from '../../../store/Roles/RoleModels';
import { escapeRegExp, rowCountByPage, toRoleMask, toTitleCase } from '../../../utility';
import DeleteRolesModal from '../../DeleteModal/DeleteModal';
import NoSearchResultsOverlay from '../../NoSearchResultsOverlay/NoSearchResultsOverlay';
import SearchInput from '../../SearchInput/SearchInput';
import useStyles from './styles';
import policyConstants from '../../../constants/policyConstants';
import { useEnforcer, useCasbinSome, useCasbin } from '../../../hooks/useCasbin';
import If from '../../If/If';
import { getDeleteRolePolicy, getUpdateRolePolicy } from '../../../utility/rolePermissionHelper';
import { INITIAL_PAGE_SIZE, PAGE_SIZE_OPTIONS } from '../../../constants/pageSizeConstants';
import Can from '../../Can/Can';
import RoleImportModal from '../../RoleImportModal/RoleImportModal';
import { getOrganizationApplications } from '../../../store/Organizations/OrganizationActions';
import { Application } from '../../../store/Applications/ApplicationModels';


type StateToProps = {
  byId: ById<Role>;
  isDeleting: boolean,
  isLoading: boolean;
  isExporting: boolean;
  organizationCode: string;
  roles: RoleRow[];
  applications: ById<Application>;
}

type DispatchToProps = {
  getRoles: (organizationCode: string) => void;
  getOrganizationApplications: (code: Id) => void;
  exportRoles: (organizationCode: Id, roleIds: Id[]) => void;
}

type RolesPageProps = StateToProps & DispatchToProps;

function RolesPage({
  byId,
  getRoles,
  getOrganizationApplications,
  exportRoles,
  isDeleting,
  isLoading,
  isExporting,
  organizationCode,
  roles,
  applications
}: RolesPageProps) {
  const history = useHistory();
  const [selected, setSelected] = useState<(string | number)[]>([]);
  const [filterText, setFilterText] = useState<string>('');
  const [rows, setRows] = useState<RoleRow[]>(roles);
  const [selectedActionRoles, setSelectedActionRoles] = useState<Role[]>([]);
  const [actionOverflowMenuAnchorEl, setActionOverflowMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [deleteRolesModalOpen, setDeleteRolesModalOpen] = useState(false);
  const [importRolesModalOpen, setImportRolesModalOpen] = useState(false);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(INITIAL_PAGE_SIZE);
  const classes = useStyles();

  const enforcer = useEnforcer();
  const canCreateRoles = useCasbinSome([
    policyConstants.roles.createAnyApplication(),
    policyConstants.roles.createOrganization(organizationCode)]);

  const canExportRoles = useCasbin(policyConstants.roles.exportRoles(organizationCode));
  const canGetApplications = useCasbin(policyConstants.organizations.getApplications(organizationCode));

  useEffect(() => {
    getRoles(organizationCode);
    if (canGetApplications) getOrganizationApplications(organizationCode);

  }, [getRoles, getOrganizationApplications, canGetApplications, organizationCode]);

  useEffect(() => {
    // Initial display of roles based on search bar filter
    handleFilterChange(filterText);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roles]);

  // Data Grid Actions
  const onPageChange = (page: number) => setPage(page);
  const onPageSizeChange = (pageSize: number) => setPageSize(pageSize);
  const onSelectionModelChange = (selectionModel: GridSelectionModel) => setSelected(selectionModel);
  const handleFilterChange = (filterText: string) => {
    setFilterText(filterText);
    const searchRegex = new RegExp(escapeRegExp(filterText), 'i');
    const filteredRows = roles.filter((role) => {
      return searchRegex.test(toRoleMask(role));
    });
    setRows(filteredRows);
  };

  // Action Overflow Menu
  const handleActionOverflowMenuClick = (event: React.MouseEvent<HTMLButtonElement>, cellParams?: GridCellParams) => {
    if (cellParams !== undefined) {
      setSelectedActionRoles([byId[cellParams.row.id]]);
    } else {
      setSelectedActionRoles(selected.map((id) => byId[id]));
    }
    setActionOverflowMenuAnchorEl(event.currentTarget);
  };
  const handleActionOverflowMenuClose = () => {
    setActionOverflowMenuAnchorEl(null);
  };

  // Edit Role - Route to Role Detail
  const handleEditRoleClick = () => {
    const roleId = selectedActionRoles[0].id;
    history.push(`${history.location.pathname}/${roleId}`);
  };

  // Create/Delete Role Actions
  const handleCreateRoleClick = () => history.push('/roles/create');
  const handleDeleteRolesModalClose = () => setDeleteRolesModalOpen(false);
  const handleDeleteRolesModalOpen = () => {
    setDeleteRolesModalOpen(true);
    setActionOverflowMenuAnchorEl(null);
  };
  const handleExportRoleClick = () => {
    const selectedRoleIds: Id[] = selectedActionRoles.map(r => r.id);
    exportRoles(organizationCode, selectedRoleIds);
  };
  const handleImportRolesModalOpen = () => {
    setImportRolesModalOpen(true);
  };
  const handleImportRolesModalClose = () => {
    setImportRolesModalOpen(false);
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'NAME',
      minWidth: 300,
      renderCell(params: GridCellParams) {
        return (
          <Link to={`/roles/${params.row.id}`} style={{ textDecoration: 'none' }}>
            <Typography>{params.row.name}</Typography>
          </Link>
        );
      },
    },
    {
      field: 'roleType',
      headerName: 'TYPE',
      minWidth: 200,
      renderCell(params: GridCellParams) {
        return <>{toTitleCase(params.row.roleType)}</>;
      },
    },
    {
      field: 'description',
      flex: 1,
      headerName: 'DESCRIPTION',
      minWidth: 200,
    },
    {
      align: 'right',
      field: '',
      renderCell(params: GridCellParams) {
        const id = `${params.row.id}-menu-button`;
        return (
          <If condition={canUpdateRole(params.row as any) || canDeleteRole(params.row as any)}>
            <IconButton
              aria-label="Actions"
              id={id}
              className={`${clsx({ [classes.activeState]: actionOverflowMenuAnchorEl?.id === id })}`}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleActionOverflowMenuClick(e, params)}
              size="small"
              disabled={isLoading}
              sx={{ mr: 2 }}
            >
              <MoreVertIcon />
            </IconButton>
          </If>
        );
      },
      sortable: false
    }
  ];

  const canDeleteRole = (role: Role): boolean => {
    if (!role || !enforcer) return false;

    const appCode = Object.values(applications)?.find(a => a.id === role.applicationId)?.code; // TODO there has got to be a better place to do this!!!
    const accessRequest = getDeleteRolePolicy(role, organizationCode, appCode);
    if (!accessRequest) return false;

    return enforcer.enforceSync(accessRequest.obj, accessRequest.act);
  };

  const canUpdateRole = (role: Role): boolean => {
    if (!role || !enforcer) return false;

    // Since we don't have access to app roles app code, just check for updateAny
    const accessRequest = getUpdateRolePolicy(role, organizationCode, undefined, true);
    if (!accessRequest) return false;

    return enforcer.enforceSync(accessRequest.obj, accessRequest.act);
  };

  const canDeleteSelectedRoles = (): boolean => {
    return selected.every((id: GridRowId) => !!byId[id] ? canDeleteRole(byId[id]) : false);
  };

  const tableMenuId = 'table-menu-button';
  const tableMenuOpen = actionOverflowMenuAnchorEl?.id === tableMenuId;
  return (
    <div className={classes.root}>
      <Grid container>
        <Grid xs={2} container item alignItems="center">
          <Typography variant="h3">Roles</Typography>
        </Grid>
        <Grid xs={10} container item alignItems="center" justifyContent="flex-end">
          <Box my={.5}>
            <SearchInput
              onChange={(e) => handleFilterChange(e.target.value)}
              onReset={() => handleFilterChange('')}
              placeholder="Search Roles"
            />
          </Box>
          <Can requiredPolicy={policyConstants.roles.importRoles(organizationCode)}>
            <Box ml={4.5}>
              <Button
                color="primary"
                size="large"
                variant="contained"
                onClick={handleImportRolesModalOpen}
              >
                Import Roles
              </Button>
            </Box>
          </Can>

          <If condition={canCreateRoles}>
            <Box ml={4.5}>
              <Button
                color="primary"
                size="large"
                variant="contained"
                onClick={handleCreateRoleClick}
              >
                Create Role
              </Button>
            </Box>
          </If>
        </Grid>
      </Grid>
      <Toolbar disableGutters>
        <If condition={!!selected.length}>
          <Typography variant="body2" className={classes.selectedResultsDisplay}>
            {selected.length} selected
          </Typography>
          <IconButton
            id={tableMenuId}
            className={`${clsx({ [classes.activeState]: tableMenuOpen })} ${classes.tableMenuButton}`}
            onClick={handleActionOverflowMenuClick}
            size="small"
            disabled={isLoading}
          >
            <MoreVertIcon />
          </IconButton>
        </If>
        <If condition={!selected.length}>
          <Typography variant="body2" className={classes.selectedResultsDisplay}>
            {rowCountByPage(page, pageSize, rows.length)} of {rows.length} results
          </Typography>
        </If>
      </Toolbar>
      <Divider />
      <DataGrid
        checkboxSelection
        columnBuffer={0}
        columns={columns}
        components={{
          NoRowsOverlay: NoSearchResultsOverlay
        }}
        componentsProps={{
          noRowsOverlay: {
            isFiltering: filterText.length,
            noRowsLabel: 'No roles have been created.'
          }
        }}
        disableColumnFilter
        disableColumnMenu
        disableSelectionOnClick
        hideFooterSelectedRowCount
        loading={isLoading}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        onSelectionModelChange={onSelectionModelChange}
        pageSize={pageSize}
        rows={rows}
        rowsPerPageOptions={PAGE_SIZE_OPTIONS}
      />
      <Menu
        anchorEl={actionOverflowMenuAnchorEl}
        anchorOrigin={{
          horizontal: tableMenuOpen ? 'left' : 'right',
          vertical: 'bottom',
        }}
        transformOrigin={{
          horizontal: tableMenuOpen ? 'left' : 'right',
          vertical: 'top',
        }}
        PopoverClasses={{
          root: classes.menuRoot
        }}
        keepMounted
        open={Boolean(actionOverflowMenuAnchorEl)}
        onClose={handleActionOverflowMenuClose}
      >
        <div>
          <If condition={canExportRoles}>
            <MenuItem onClick={handleExportRoleClick}>
              Export
              <If condition={isExporting}>
                <CircularProgress sx={{ ml: 1 }} size="1rem"></CircularProgress>
              </If>
            </MenuItem>
          </If>
          <If condition={selectedActionRoles.length === 1 && canUpdateRole(selectedActionRoles[0])}>
            <MenuItem onClick={handleEditRoleClick}>Edit role</MenuItem>
          </If>
          <If condition={canDeleteSelectedRoles()}>
            <MenuItem onClick={handleDeleteRolesModalOpen}>Delete</MenuItem>
          </If>
        </div>
      </Menu>
      <DeleteRolesModal
        resourceName="roles"
        isDeleting={isDeleting}
        onClose={handleDeleteRolesModalClose}
        onDelete={() => {
          setSelected([]);
          setSelectedActionRoles([]);
        }}
        open={deleteRolesModalOpen}
        resources={selectedActionRoles}
      />
      <RoleImportModal
        open={importRolesModalOpen}
        onClose={handleImportRolesModalClose}
      />
    </div>
  );
}

/**
 * Create an array of `Role`s with each role having an `id` property
 * @param {RolesState} rolesState - a `CollectionState` of `Role`s
 * @return {RoleRow[]} an array of Roles with each role having a `users` property being populated with `User`s and an ID.
 */
export function rolesSpreadWithId(rolesState: RolesState) {
  const { allIds, byId } = rolesState;
  const idKey: keyof Role = 'id';
  return allIds.map((id) => {
    const role = byId[id];
    return ({
      ...role,
      id: role[idKey]
    });
  });
}

const mapStateToProps = (state: UMAReduxState) => ({
  byId: state.roles.byId,
  isDeleting: state.roles.isDeleting,
  isLoading: state.roles.isLoading,
  isExporting: state.roles.isExporting,
  myPermissions: state.my.permissions,
  organizationCode: state.context.organizationCode,
  applications: state.applications.byId,
  roles: rolesSpreadWithId(state.roles).sort((a, b) => b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 1),
});

const mapDispatchToProps: DispatchToProps = {
  getRoles,
  getOrganizationApplications,
  exportRoles
};

export default connect(mapStateToProps, mapDispatchToProps)(RolesPage);

import { policyRulesApi } from '../../api';
import { PolicyRule, PolicyRuleAction } from './PolicyRuleModels';
import { Id } from '../../types/types';
import { AppThunkAction } from '../rootReducer';
import {
  showError
} from '../../utility';
import { alertEnqueued } from '../Alerts/AlertActions';

// Action Types
export const GET_POLICY_RULES_REQUESTED: string = 'GET_POLICY_RULES_REQUESTED';
export const GET_POLICY_RULES_REQUEST_SUCCESSFUL: string = 'GET_POLICY_RULES_REQUEST_SUCCESSFUL';
export const GET_POLICY_RULES_REQUEST_FAILED: string = 'GET_POLICY_RULES_REQUEST_FAILED';
export const CREATE_POLICY_RULE_REQUESTED: string = 'CREATE_POLICY_RULE_REQUESTED';
export const CREATE_POLICY_RULE_REQUEST_SUCCESSFUL: string = 'CREATE_POLICY_RULE_REQUEST_SUCCESSFUL';
export const CREATE_POLICY_RULE_REQUEST_FAILED: string = 'CREATE_POLICY_RULE_REQUEST_FAILED';
export const UPDATE_POLICY_RULE_REQUESTED: string = 'UPDATE_POLICY_RULE_REQUESTED';
export const UPDATE_POLICY_RULE_REQUEST_SUCCESSFUL: string = 'UPDATE_POLICY_RULE_REQUEST_SUCCESSFUL';
export const UPDATE_POLICY_RULE_REQUEST_FAILED: string = 'UPDATE_POLICY_RULE_REQUEST_FAILED';
export const DELETE_POLICY_RULES_REQUESTED: string = 'DELETE_POLICY_RULES_REQUESTED';
export const DELETE_POLICY_RULES_REQUEST_SUCCESSFUL: string = 'DELETE_POLICY_RULES_REQUEST_SUCCESSFUL';
export const DELETE_POLICY_RULES_REQUEST_FAILED: string = 'DELETE_POLICY_RULES_REQUEST_FAILED';

// Action Creators

export function getPolicyRulesByRole(organizationCode: Id, roleId: Id): AppThunkAction<PolicyRuleAction> {
  return async (dispatch) => {
    dispatch({ type: GET_POLICY_RULES_REQUESTED });
    try {
      const policyRules: PolicyRule[] = await policyRulesApi.getPolicyRulesByRole(organizationCode, roleId);
      dispatch({ policyRules, type: GET_POLICY_RULES_REQUEST_SUCCESSFUL });
    } catch (err) {
      showError(err, dispatch);
      dispatch({ type: GET_POLICY_RULES_REQUEST_FAILED });
    }
  };
}

export function createPolicyRule(policyRuleToCreate: PolicyRule, ...callbacksOnSuccess: (() => void)[]): AppThunkAction<PolicyRuleAction> {
  return async (dispatch) => {
    dispatch({ type: CREATE_POLICY_RULE_REQUESTED });
    try {
      const policyRule = await policyRulesApi.createPolicyRule(policyRuleToCreate);
      dispatch({ policyRule, type: CREATE_POLICY_RULE_REQUEST_SUCCESSFUL });
      dispatch(alertEnqueued('Policy Rule has been created.', { variant: 'success' }));
      for (const callbackOnSuccess of callbacksOnSuccess) {
        callbackOnSuccess();
      }
    } catch (err) {
      showError(err, dispatch);
      dispatch({ type: CREATE_POLICY_RULE_REQUEST_FAILED });
    }
  };
}

export function updatePolicyRule(policyRuleToUpdate: Partial<PolicyRule>, ...callbacksOnSuccess: (() => void)[]): AppThunkAction<PolicyRuleAction> {
  return async (dispatch) => {
    dispatch({ type: UPDATE_POLICY_RULE_REQUESTED });
    try {
      const policyRule = await policyRulesApi.updatePolicyRule(policyRuleToUpdate);
      dispatch({ policyRule, type: UPDATE_POLICY_RULE_REQUEST_SUCCESSFUL });
      dispatch(alertEnqueued('Policy Rule has been updated.', { variant: 'success' }));
      for (const callbackOnSuccess of callbacksOnSuccess) {
        callbackOnSuccess();
      }
    } catch (err) {
      showError(err, dispatch);
      dispatch({ type: UPDATE_POLICY_RULE_REQUEST_FAILED });
    }
  };
}

export function deletePolicyRules(
  organizationCode: Id,
  roleId: Id,
  policyRuleIds: Id[],
  ...callbacksOnSuccess: (() => void)[]
): AppThunkAction<PolicyRuleAction> {
  return async (dispatch) => {
    dispatch({ type: DELETE_POLICY_RULES_REQUESTED });
    try {
      const successfulRequests: Id[] = [];

      const promises = policyRuleIds.map(id => policyRulesApi.deletePolicyRule(organizationCode, roleId, id)
        .then(() => successfulRequests.push(id))
        .catch((err) => {
          dispatch(alertEnqueued(err.message, { variant: 'error' }));
        }));

      await Promise.all(promises);

      if (successfulRequests.length > 0) {
        dispatch({ policyRuleIds: successfulRequests, type: DELETE_POLICY_RULES_REQUEST_SUCCESSFUL });
        dispatch(alertEnqueued(`
            Policy Rule${successfulRequests.length > 1 ? 's have ' : ' has '} been deleted.
          `, { variant: 'success' }));
        for (const callbackOnSuccess of callbacksOnSuccess) {
          callbackOnSuccess();
        }
      }
    } catch (err) {
      showError(err, dispatch);
      dispatch({ type: DELETE_POLICY_RULES_REQUEST_FAILED });
    }
  };
}
import { ContextAction } from './ContextModels';
import { AppThunkAction } from '../rootReducer';
import {
  showError
} from '../../utility';
import history from '../../history';

// Action Types
export const RESTORE_CONTEXT_REQUESTED: string = 'RESTORE_CONTEXT_REQUESTED';
export const RESTORE_CONTEXT_REQUEST_SUCCESSFUL: string = 'RESTORE_CONTEXT_REQUEST_SUCCESSFUL';
export const RESTORE_CONTEXT_REQUEST_FAILED: string = 'RESTORE_CONTEXT_REQUEST_FAILED';

export const SET_PRIMARY_ORG_CODE_REQUESTED: string = 'SET_PRIMARY_ORG_CODE_REQUESTED';
export const SET_PRIMARY_ORG_CODE_REQUEST_SUCCESSFUL: string = 'SET_PRIMARY_ORG_CODE_REQUEST_SUCCESSFUL';
export const SET_PRIMARY_ORG_CODE_REQUEST_FAILED: string = 'SET_PRIMARY_ORG_CODE_REQUEST_FAILED';

export const SWITCH_CONTEXT_REQUESTED: string = 'SWITCH_CONTEXT_REQUESTED';
export const SWITCH_CONTEXT_REQUEST_SUCCESSFUL: string = 'SWITCH_CONTEXT_REQUEST_SUCCESSFUL';
export const SWITCH_CONTEXT_REQUEST_FAILED: string = 'SWITCH_CONTEXT_REQUEST_FAILED';

export const CONTEXT_RESET: string = 'CONTEXT_RESET';

// Action Creators

/**
 * Set the currently authenticated user's `primaryOrganicationCode` returned from the claims on the access token
 * @param {String} organizationCode - currently authenticated user's primary organization code
 * @return {Function} - dispatches function response based on api results
 */
export function setPrimaryOrganizationCode(organizationCode: string): AppThunkAction<ContextAction> {
  return async (dispatch) => {
    dispatch({ type: SET_PRIMARY_ORG_CODE_REQUESTED });
    try {
      dispatch({ organizationCode, type: SET_PRIMARY_ORG_CODE_REQUEST_SUCCESSFUL });
    } catch (err) {
      showError(err, dispatch);
      dispatch({ type: SET_PRIMARY_ORG_CODE_REQUEST_FAILED });
    }
  };
}

/**
 * Restore context to the users primary organization code
 * @return {Function} - dispatches function response based on api results
 */
export function restoreContext(): AppThunkAction<ContextAction> {
  return async (dispatch, getState) => {
    dispatch({ type: RESTORE_CONTEXT_REQUESTED });
    try {
      const userPrimaryOrganizationCode = getState().context.userPrimaryOrganizationCode;
      if (userPrimaryOrganizationCode) {
        setTimeout(() => {
          history.push('/overview');
          dispatch({ type: CONTEXT_RESET });
          dispatch({ organizationCode: userPrimaryOrganizationCode, type: RESTORE_CONTEXT_REQUEST_SUCCESSFUL });
        }, 1000);
      }
    } catch (err) {
      showError(err, dispatch);
      dispatch({ type: RESTORE_CONTEXT_REQUEST_FAILED });
    }
  };
}

/**
 * Switches the context to display another org's users, groups, clients, etc.
 * @param {String} organizationCode - organization code used to switch the context of the application
 * @return {Function} - dispatches function response based on api results
 */
export function switchContext(organizationCode: string): AppThunkAction<ContextAction> {
  return async (dispatch, getState) => {
    const currentContext = getState().context.organizationCode;
    /*
			Return if context hasn't changed
		*/
    if (currentContext === organizationCode) return;
    /*
			Otherwise reset groups, users, clients and roles
			and update context with the new organization code
		*/
    dispatch({ type: SWITCH_CONTEXT_REQUESTED });
    try {
      setTimeout(() => {
        history.push('/overview');
        dispatch({ type: CONTEXT_RESET });
        dispatch({ organizationCode, type: SWITCH_CONTEXT_REQUEST_SUCCESSFUL });
      }, 1000);
    } catch (err) {
      showError(err, dispatch);
      dispatch({ type: SWITCH_CONTEXT_REQUEST_FAILED });
    }
  };
}

/* eslint-disable max-len */
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

const styles = makeStyles((theme: Theme) => ({
  actionButton: {
    '&:hover, &.Mui-focusVisible': {
      opacity: 1
    },
    marginRight: theme.spacing(2.5),
    opacity: 0
  },
  filterList: {
    minWidth: 400
  },
  listItem: {
    '&.Mui-focusVisible + .MuiListItemSecondaryAction-root $actionButton,&:hover + .MuiListItemSecondaryAction-root $actionButton, &.Mui-focusVisible $actionButton, &:hover $actionButton': {
      opacity: 1
    },
    '&:hover': {
      background: '#00000010'
    },
    padding: theme.spacing(1, 2.5),
  },
  listItemIcon: {
    minWidth: 0,
    padding: theme.spacing(0, 0.5)
  },
  listPadding: {
    padding: 0
  },
  noAssignedResources: {
    color: theme.palette.grey[600],
    padding: theme.spacing(2, 2.5)
  },
  noResults: {
    color: theme.palette.grey[600],
    justifyContent: 'center',
    padding: theme.spacing(5, 5)
  },
  searchSubheader: {
    lineHeight: 0
  },
  subheaderGutters: {
    padding: theme.spacing(0, 2.5)
  },
  title: {
    marginRight: theme.spacing(1)
  },
  titleSubheader: {
    padding: theme.spacing(2, 2.5)
  },
}));

export default styles;
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

const styles = makeStyles((theme: Theme) => ({
  actionsContainer: {
    display: 'inline-block',
    justifyContent: 'normal',
    padding: theme.spacing(2.5, 3, 2)
  },
  contentContainer: {
    padding: theme.spacing(2.5, 0, 0),
  },
  dialogPaper: {
    maxHeight: 566,
    width: 500
  },
  listItem: {
    cursor: 'default',
    padding: theme.spacing(1, 5)
  },
  noResults: {
    justifyContent: 'center',
    padding: theme.spacing(5, 5)
  },
  subtitle: {
    color: theme.palette.grey[600],
    letterSpacing: 0
  },
  titleContainer: {
    padding: theme.spacing(2.5, 5, 2)
  }
}));

export default styles;
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

const styles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  rowOne: {
    height: 200
  },
  rowThree: {
    height: 170
  },
  rowTwo: {
    height: 353
  },
  skeleton: {
    borderRadius: 4
  }
}));

export default styles;
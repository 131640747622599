import { rolesApi } from '../../api';
import { Id } from '../../types/types';
import { Role, RoleAction } from './RoleModels';
import { AppThunkAction, UMAReduxState } from '../rootReducer';
import {
  normalizeApplicationList,
  normalizeGroupList,
  normalizeRoleList,
  normalizeRolesDetailsApiResponse,
  showError
} from '../../utility';
import { alertEnqueued } from '../Alerts/AlertActions';
import { GroupAction } from '../Groups/GroupModels';
import { GET_GROUPS_REQUEST_SUCCESSFUL } from '../Groups/GroupActions';
import history from '../../history';
import { GET_APPLICATIONS_REQUEST_SUCCESSFUL } from '../Applications/ApplicationActions';
import { Application, ApplicationAction } from '../Applications/ApplicationModels';

// Action Types
export const GET_ROLES_REQUESTED: string = 'GET_ROLES_REQUESTED';
export const GET_ROLES_REQUEST_SUCCESSFUL: string = 'GET_ROLES_REQUEST_SUCCESSFUL';
export const GET_ROLES_REQUEST_FAILED: string = 'GET_ROLES_REQUEST_FAILED';

export const GET_ROLE_DETAILS_BY_ID_REQUESTED: string = 'GET_ROLE_DETAILS_BY_ID_REQUESTED';
export const GET_ROLE_DETAILS_BY_ID_REQUEST_SUCCESSFUL: string = 'GET_ROLE_DETAILS_BY_ID_REQUEST_SUCCESSFUL';
export const GET_ROLE_DETAILS_BY_ID_REQUEST_FAILED: string = 'GET_ROLE_DETAILS_BY_ID_REQUEST_FAILED';

export const EXPORT_ROLES_REQUESTED: string = 'EXPORT_ROLES_REQUESTED';
export const EXPORT_ROLES_REQUEST_SUCCESSFUL: string = 'EXPORT_ROLES_REQUEST_SUCCESSFUL';
export const EXPORT_ROLES_REQUEST_FAILED: string = 'EXPORT_ROLES_REQUEST_FAILED';

export const CREATE_ROLE_REQUESTED: string = 'CREATE_ROLE_REQUESTED';
export const CREATE_ROLE_REQUEST_SUCCESSFUL: string = 'CREATE_ROLE_REQUEST_SUCCESSFUL';
export const CREATE_ROLE_REQUEST_FAILED: string = 'CREATE_ROLE_REQUEST_FAILED';

export const UPDATE_ROLE_REQUESTED: string = 'UPDATE_ROLE_REQUESTED';
export const UPDATE_ROLE_REQUEST_SUCCESSFUL: string = 'UPDATE_ROLE_REQUEST_SUCCESSFUL';
export const UPDATE_ROLE_REQUEST_FAILED: string = 'UPDATE_ROLE_REQUEST_FAILED';

export const DELETE_ROLES_REQUESTED: string = 'DELETE_ROLES_REQUESTED';
export const DELETE_ROLES_REQUEST_SUCCESSFUL: string = 'DELETE_ROLES_REQUEST_SUCCESSFUL';
export const DELETE_ROLES_REQUEST_FAILED: string = 'DELETE_ROLES_REQUEST_FAILED';

export const FILTER_ORGANIZATION_ROLES: string = 'FILTER_ORGANIZATION_ROLES';

// Action Creators

/**
 * Gets all roles for an organization
 * @param {String} organizationCode - organization code used to add users to groups
 * @return {Function} - dispatches function response based on api results
 */
export function getRoles(organizationCode: string, ...callbacksOnSuccess: (() => void)[]): AppThunkAction<RoleAction> {
  return async (dispatch) => {
    dispatch({ type: GET_ROLES_REQUESTED });
    try {
      const rolesApiResponse = await rolesApi.getRolesInOrg(organizationCode);
      const { allIds, byId } = normalizeRoleList(rolesApiResponse);
      dispatch({ allIds, byId, type: GET_ROLES_REQUEST_SUCCESSFUL });
      for (const callbackOnSuccess of callbacksOnSuccess) {
        callbackOnSuccess();
      }
    } catch (err) {
      showError(err, dispatch);
      dispatch({ type: GET_ROLES_REQUEST_FAILED });
    }
  };
}

/**
 * Get a single role's detail by id from the associated organization which includes groups
 * @param {String} organizationCode - organization code used get role details
 * @param {string} roleId - the role id
 * @return {Function} - dispatches function response based on api results
 */
export function getRoleDetailsById(organizationCode: string, roleId: Id): AppThunkAction<RoleAction | GroupAction | ApplicationAction> {
  return async (dispatch) => {
    dispatch({ id: roleId, type: GET_ROLE_DETAILS_BY_ID_REQUESTED });
    try {
      const roleDetailsApiResponse = await rolesApi.getRoleWithDetailsInOrg(organizationCode, roleId);
      const groups = roleDetailsApiResponse.groups ?? [];
      const applications = roleDetailsApiResponse.application ? [roleDetailsApiResponse.application] : [];
      const normalizedRole = normalizeRolesDetailsApiResponse([roleDetailsApiResponse]);
      const normalizedGroups = normalizeGroupList(groups);
      const normalizedApplications = normalizeApplicationList(applications);
      dispatch({ allIds: normalizedGroups.allIds, byId: normalizedGroups.byId, type: GET_GROUPS_REQUEST_SUCCESSFUL });
      dispatch({ allIds: normalizedApplications.allIds, byId: normalizedApplications.byId, type: GET_APPLICATIONS_REQUEST_SUCCESSFUL });
      dispatch({ byId: normalizedRole.byId, id: roleId, type: GET_ROLE_DETAILS_BY_ID_REQUEST_SUCCESSFUL });
    } catch (err) {
      showError(err, dispatch);
      dispatch({ id: roleId, type: GET_ROLE_DETAILS_BY_ID_REQUEST_FAILED });
    }
  };
}

/**
 * Export a list of roles. Used to import roles to different UMA environments
 * @param {Id} organizationCode - organization code used get role details
 * @param {Id[]} roleIds - the id's of the roles to be exported
 * @return {Function} - dispatches function response based on api results
 */
export function exportRoles(organizationCode: Id, roleIds: Id[]): AppThunkAction<RoleAction> {
  return async (dispatch) => {
    dispatch({ type: EXPORT_ROLES_REQUESTED });
    try {
      const filename = 'role export';
      const roles = await rolesApi.exportRoles(organizationCode, roleIds);

      // Credit here: https://stackoverflow.com/questions/19721439/download-json-object-as-a-file-from-browser
      const dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(roles));
      const downloadAnchorNode = document.createElement('a');
      downloadAnchorNode.setAttribute('href', dataStr);
      downloadAnchorNode.setAttribute('download', filename + '.json');
      document.body.appendChild(downloadAnchorNode); // required for firefox
      downloadAnchorNode.click();
      downloadAnchorNode.remove();

      dispatch({ type: EXPORT_ROLES_REQUEST_SUCCESSFUL });
    } catch (err) {
      showError(err, dispatch);
      dispatch({ type: EXPORT_ROLES_REQUEST_FAILED });
    }
  };
}

/**
 * Creates a new role
 * @param {String} organizationCode - organization code used to save role
 * @param {String} applicationCode - application code used to save role
 * @param {Partial<Role>} role - role properties
 * @return {Function} - dispatches function response based on api results
 */
export function createRole(organizationCode: string, applicationCode: string, role: Partial<Role>): AppThunkAction<RoleAction> {
  return async (dispatch) => {
    dispatch({ type: CREATE_ROLE_REQUESTED });
    try {
      let result;

      if (role.roleType === 'application') {
        result = await rolesApi.createApplicationRole(applicationCode, role);
      } else {
        result = await rolesApi.createOrganizationRole(organizationCode, role);
      }
      const { allIds, byId } = normalizeRolesDetailsApiResponse([result]);
      dispatch({ allIds, byId, type: CREATE_ROLE_REQUEST_SUCCESSFUL });
      dispatch(alertEnqueued('Role has been created.', { variant: 'success' }));
      history.push(`/roles/${result.id}`);
    } catch (err) {
      showError(err, dispatch);
      dispatch({ type: CREATE_ROLE_REQUEST_FAILED });
    }
  };
}

/**
 * Updates an existing role
 * @param {String} organizationCode - organization code used to save role
 * @param {String} applicationCode - application code used to save role
 * @param {Partial<Role>} role - role properties to update
 * @param {...Function} callbacksOnSuccess - callbacks when api call was successful
 * @return {Function} - dispatches function response based on api results
 */
export function updateRole(
  organizationCode: string,
  applicationCode: string,
  role: Partial<Role>,
  ...callbacksOnSuccess: (() => void)[]
): AppThunkAction<RoleAction> {
  return async (dispatch) => {
    dispatch({ id: role.id, role, type: UPDATE_ROLE_REQUESTED });
    try {
      let result;
      switch (role.roleType) {
        case 'application':
          result = await rolesApi.updateApplicationRole(applicationCode, role);
          break;
        case 'organization':
          result = await rolesApi.updateOrganizationRole(organizationCode, role);
          break;
        case 'unspecified':
        default:
          showError('Unable to update a role with an unspecified role type', dispatch);
          dispatch({ id: role.id, type: UPDATE_ROLE_REQUEST_FAILED });
          break;
      }

      const normalizedRole = normalizeRolesDetailsApiResponse([result]);
      dispatch({ byId: normalizedRole.byId, id: role.id, type: UPDATE_ROLE_REQUEST_SUCCESSFUL });
      dispatch(alertEnqueued('Role has been updated.', { variant: 'success' }));
      for (const callbackOnSuccess of callbacksOnSuccess) {
        callbackOnSuccess();
      }
    } catch (err) {
      showError(err, dispatch);
      dispatch({ id: role.id, type: UPDATE_ROLE_REQUEST_FAILED });
    }
  };
}

/**
 * Deletes single/multiple role(s) from an organization
 * @param {String} organizationCode - organization code used to delete organization roles
 * @param {Role[]} roles - an array of roles to delete
 * @param {...Function} callbacksOnSuccess - callbacks when api call was successful
 * @return {Function} - dispatches function response based on api results
 */
export function deleteRoles(
  organizationCode: string,
  roles: Role[],
  ...callbacksOnSuccess: (() => void)[]): AppThunkAction<RoleAction> {
  return async (dispatch, getState) => {
    dispatch({ type: DELETE_ROLES_REQUESTED });
    try {
      // call delete role for each selected role. Ignore roles with an invalid role type or missing params
      const promises: Promise<void>[] = roles.reduce((acc: Promise<void>[], role: Role) => {
        if (role.roleType === 'application' && role.applicationId) {
          const store: UMAReduxState = getState();
          const appCode = Object.values(store.applications.byId).find((app: Application) => app.id === role.applicationId)?.code;
          if (!appCode) return acc;

          return [...acc, rolesApi.deleteApplicationRole(appCode, role.id)];
        } else if (role.roleType === 'organization') {
          return [...acc, rolesApi.deleteOrganizationRole(organizationCode, role.id)];
        } else {
          return acc;
        }
      }, []);

      await Promise.all(promises);
      dispatch({ roles, type: DELETE_ROLES_REQUEST_SUCCESSFUL });
      dispatch(alertEnqueued(`
				Role${roles.length > 1 ? 's have ' : ' has '} been deleted.
			`, { variant: 'success' }));
      for (const callbackOnSuccess of callbacksOnSuccess) {
        callbackOnSuccess();
      }
    } catch (err) {
      showError(err, dispatch);
      dispatch({ type: DELETE_ROLES_REQUEST_FAILED });
    }
  };
}

/**
 * Filter out all roles except organization roles
 * @return {Function} - dispatches function response based on api results
 */
export function filterOrganizationRoles(): AppThunkAction<RoleAction> {
  return async (dispatch) => dispatch({ type: FILTER_ORGANIZATION_ROLES });
}
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import {
  Box,
  Breadcrumbs,
  Button,
  Grid,
  Link,
  Typography
} from '@mui/material';

import { UMAReduxState } from '../../../store/rootReducer';
import { GroupsState } from '../../../store/Groups/GroupModels';
import { User } from '../../../store/Users/UserModels';
import { getUserById, getUserGroupsById } from '../../../store/Users/UserActions';
import { removeUsersFromGroup } from '../../../store/Groups/GroupActions';
import AssignGroupsModal from '../../AssignModal/AssignModal';
import DeactivateUserModal from '../../DeactivateUserModal/DeactivateUserModal';
import DeleteUserModal from '../../DeleteModal/DeleteModal';
import UserDetails from '../../UserDetails/UserDetails';
import GroupsList from '../../FilterList/FilterList';
import { Id } from '../../../types/types';
import useStyles from './styles';
import { useCasbin } from '../../../hooks/useCasbin';
import policyConstants from '../../../constants/policyConstants';
import Can from '../../Can/Can';

type StateToProps = {
  groupIds: Id[];
  groupState: GroupsState;
  isDeleting: boolean;
  isLoading: boolean;
  userId: Id;
  organizationCode: string,
  user: User
};

type DispatchToProps = {
  getUserById: (organizationCode: string, groupId: Id) => void,
  getUserGroupsById: (organizationCode: string, groupId: Id) => void
  removeUsersFromGroup: (orgCode: string, groupId: Id, userIds: Id[]) => void,
};

type UserDetailPageProps = StateToProps & DispatchToProps;

function UserDetailPage({
  getUserById,
  getUserGroupsById,
  groupIds,
  groupState,
  isDeleting,
  isLoading,
  userId,
  organizationCode,
  removeUsersFromGroup,
  user
}: UserDetailPageProps) {
  const classes = useStyles();
  const history = useHistory();
  const [assignGroupsModalOpen, setAssignGroupsModalOpen] = useState(false);
  const [deactivateUserModalOpen, setDeactivateUserModalOpen] = useState(false);
  const [deleteUserModalOpen, setDeleteUserModalOpen] = useState(false);

  useEffect(() => {
    getUserById(organizationCode, userId);
    getUserGroupsById(organizationCode, userId);
  }, [getUserById, getUserGroupsById, organizationCode, userId]);

  const handleBreadcrumbClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    history.push('/users');
  };

  // Assign Modal
  const handleAssignGroupsModalOpen = () => setAssignGroupsModalOpen(true);
  const handleAssignGroupsModalClose = () => setAssignGroupsModalOpen(false);
  // Deactivate Modal
  const handleDeactivateUserModalOpen = () => setDeactivateUserModalOpen(true);
  const handleDeactivateUserModalClose = () => setDeactivateUserModalOpen(false);

  const handleRemoveUserFromGroupClick = (groupId: Id) => {
    removeUsersFromGroup(
      organizationCode,
      groupId,
      [userId]
    );
  };

  const handleDeleteUserClick = () => {
    setDeleteUserModalOpen(true);
  };
  const handleDeleteUserModalClose = () => setDeleteUserModalOpen(false);

  // Check casbin writes.
  // Delete user button.
  const deleteUserPermitted = useCasbin(policyConstants.users.delete(organizationCode, userId));

  const displayDeleteButton = user?.status === 'deprovisioned' && deleteUserPermitted;
  return (
    <>
      <Grid container justifyContent="space-between" className={classes.headerContainer}>
        <Grid item>
          <Breadcrumbs className={classes.breadcrumbs} separator="›" aria-label="breadcrumb">
            <Link
              className={classes.baseBreadcrumb}
              color="inherit"
              href="/users"
              onClick={handleBreadcrumbClick}
              variant="body2"
            >
              Users
            </Link>
            <Typography variant="body2">User Profile</Typography>
          </Breadcrumbs>
        </Grid>
        {
          displayDeleteButton && (
            <Grid item>
              <Button
                color="error"
                size="large"
                onClick={handleDeleteUserClick}
                disabled={isLoading}
                variant="outlined"
              >
                Delete User
              </Button>
            </Grid>
          )
        }
      </Grid>
      <Box mt={2} mb={3}>
        <Typography variant="h3">User Profile</Typography>
      </Box>
      <Grid container spacing={3}>
        <Grid xs={7} item className={classes.detailsContainer}>
          <UserDetails
            isLoading={isLoading}
            user={user}
            handleDeactivateUser={handleDeactivateUserModalOpen}
          />
        </Grid>
        <Can requiredPolicy={policyConstants.users.getWithGroupIdsById(organizationCode, userId)}>
          <Grid xs={5} item>
            <GroupsList
              parentResourceName="users"
              addItemProps={{
                handleAddClick: handleAssignGroupsModalOpen,
                requiredPolicy: policyConstants.groups.addUsersAny(organizationCode)
              }}
              removeItemProps={{
                handleDeleteClick: handleRemoveUserFromGroupClick,
                policyFunction: policyConstants.groups.removeUsers,
                policyFunctionParams: [organizationCode]
              }}
              readItemProps={{
                policyFunction: policyConstants.groups.getById,
                policyFunctionParams: [organizationCode]
              }}
              isLoading={isLoading || Boolean(user.groupsLoading)}
              listResourceName="groups"
              listResources={groupIds.map((id) => groupState.byId[id])}
            />
          </Grid>
        </Can>
      </Grid>
      <AssignGroupsModal
        parentResourceLoading={isLoading}
        assignResourceName="groups"
        parentResource={[user]}
        parentResourceName="users"
        isAssigning={groupState.isAssigning}
        onClose={handleAssignGroupsModalClose}
        open={assignGroupsModalOpen}
      />
      <DeactivateUserModal
        onClose={handleDeactivateUserModalClose}
        onDeactivate={() => history.push('/users')}
        open={deactivateUserModalOpen}
        user={user}
      />
      <DeleteUserModal
        resourceName="users"
        isDeleting={isDeleting}
        onClose={handleDeleteUserModalClose}
        onDelete={() => history.goBack()}
        open={deleteUserModalOpen}
        resources={[user]}
      />
    </>
  );
}

function mapStateToProps(state: UMAReduxState, ownProps: any): StateToProps {
  const userId = ownProps.match.params.userId;
  return {
    groupIds: state.users.byId[userId]?.groupIds ?? [],
    groupState: state.groups,
    isDeleting: state.users.isDeleting,
    isLoading: state.users.byId[userId]?.isLoading ?? true,
    userId,
    organizationCode: state.context.organizationCode,
    user: state.users.byId[userId]
  };
}

const mapDispatchToProps: DispatchToProps = {
  getUserById,
  getUserGroupsById,
  removeUsersFromGroup
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDetailPage);
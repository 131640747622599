import React, { FC } from 'react';

interface IfProps {
  condition: boolean;
  children: any;
}

const If: FC<IfProps> = ({ condition, children }) => {
  return condition ? children : (<></>);
};

export default If;

import React, { useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import {
  ClickAwayListener,
  Grid,
  IconButton,
  Skeleton,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import ClearIcon from '@mui/icons-material/ClearRounded';
import DoneIcon from '@mui/icons-material/Done';

import {
  Application,
  updateApplicationFormSchema,
  UpdateApplicationFormValues
} from '../../../store/Applications/ApplicationModels';
import InputSkeleton from '../../InputSkeleton/InputSkeleton';
import useStyles from './styles';
import { updateApplication } from '../../../store/Applications/ApplicationActions';

const FORM_ID = 'edit-application-form';

type DispatchToProps = {
  updateApplication: (applicationCode: string, application: Partial<Application>, callbackOnSuccess: () => void) => void
};

interface ApplicationFormProps {
  application: Application,
  applicationCode: string;
  isLoading: boolean;
  isUpdating: boolean;
}

type Props = ApplicationFormProps & DispatchToProps;

function ApplicationForm({
  application,
  applicationCode,
  isLoading,
  isUpdating,
  updateApplication,
}: Props) {
  const classes = useStyles();
  const { control, formState: { isDirty, isValid }, handleSubmit, reset, setValue } = useForm<UpdateApplicationFormValues>({
    defaultValues: useMemo(() => {
      return { name: application?.name, oktaGroupId: application?.oktaGroupId || '' };
    }, [application]),
    mode: 'onChange',
    resolver: yupResolver(updateApplicationFormSchema)
  });

  const [editing, setEditing] = useState<boolean>(false);
  const [oktaGroupIdEditing, setOktaGroupIdEditing] = useState<boolean>(false);

  // Ensures the initial value gets set once the input renders
  useEffect(() => {
    reset({ name: application?.name, oktaGroupId: application?.oktaGroupId ?? '' });
  }, [application?.name, application?.oktaGroupId, reset]);

  const onApplicationNameTextFieldFocus = () => {
    setEditing(true);
  };

  const onOktaGroupIdTextFieldFocus = () => {
    setOktaGroupIdEditing(true);
  };

  const onSubmit = (values: UpdateApplicationFormValues) => {
    if (!isDirty) return;

    const updatePayload: Partial<Application> = {
      code: applicationCode,
      id: application.id,
      name: values.name,
      oktaGroupId: values.oktaGroupId || null
    };

    updateApplication(applicationCode, updatePayload, () => {
      setEditing(false);
      setOktaGroupIdEditing(false);
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} id={FORM_ID}>
      <Grid container item xs={12} direction="row"></Grid>
      <ClickAwayListener
        mouseEvent="onMouseDown"
        touchEvent="onTouchStart"
        onClickAway={() => {
          setValue('name', application?.name, { shouldDirty: true, shouldValidate: true });
          setEditing(false);
        }}
      >
        <Grid container item xs={12} direction="column">
          <Grid item xs={12}>
            <Controller
              name="name"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  placeholder="Enter application name"
                  onFocus={onApplicationNameTextFieldFocus}
                  required
                  inputProps={{
                    className: classes.input
                  }}
                  InputProps={{
                    classes: {
                      disabled: classes.disabled,
                      notchedOutline: classes.notchedOutline,
                      root: clsx(classes.inputField, classes.nameField)
                    },
                    inputComponent: (isLoading) ? InputSkeleton : 'input'
                  }}
                  fullWidth
                  error={Boolean(error)}
                  helperText={error?.message ?? ''}
                />
              )}
            />
          </Grid>
          <Grid container xs={12} item justifyContent="space-between">
            <Grid item>
              <Typography variant="button">
                {
                  isLoading ?
                    <Skeleton height={30} classes={{ root: classes.codeSkeleton }} width={40} />
                    : application.code
                }
              </Typography>
            </Grid>
            <Grid
              container
              item
              className={clsx(classes.editControls, { [classes.editControlsHidden]: !editing })}
            >
              <Grid item>
                <Tooltip title="Save">
                  <span>
                    <IconButton
                      disabled={!isDirty || !isValid || isLoading || isUpdating}
                      className={clsx(classes.iconButton, classes.buttonSpacing)}
                      data-edit-control
                      type="submit"
                      form={FORM_ID}
                      size="large">
                      <DoneIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title="Exit">
                  <IconButton
                    className={classes.iconButton}
                    data-edit-control
                    onClick={() => {
                      setValue('name', application?.name, { shouldDirty: true, shouldValidate: true });
                      setEditing(false);
                    }}
                    size="large">
                    <ClearIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ClickAwayListener>
      <ClickAwayListener
        mouseEvent="onMouseDown"
        touchEvent="onTouchStart"
        onClickAway={() => {
          setValue('oktaGroupId', application?.oktaGroupId ?? '', { shouldDirty: true, shouldValidate: true });
          setOktaGroupIdEditing(false);
        }}
      >
        <Grid container item xs={12} direction="column">
          <Grid item xs={12}>
            <Typography variant="h6">Okta Group Id</Typography>
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="oktaGroupId"
              control={control}
              defaultValue=""
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  placeholder="Enter Okta Group Id"
                  onFocus={onOktaGroupIdTextFieldFocus}
                  inputProps={{
                    className: classes.input
                  }}
                  InputProps={{
                    classes: {
                      disabled: classes.disabled,
                      notchedOutline: classes.notchedOutline,
                      root: clsx(classes.inputField, classes.oktaField)
                    },
                    inputComponent: (isLoading) ? InputSkeleton : 'input'
                  }}
                  fullWidth
                  error={Boolean(error)}
                  helperText={error?.message ?? ''}
                />
              )}
            />
          </Grid>
          <Grid container xs={12} item justifyContent="space-between">
            <Grid item>
            </Grid>
            <Grid
              container
              item
              className={clsx(classes.editControls, { [classes.editControlsHidden]: !oktaGroupIdEditing })}
            >
              <Grid item>
                <Tooltip title="Save">
                  <span>
                    <IconButton
                      disabled={!isDirty || !isValid || isLoading || isUpdating}
                      className={clsx(classes.iconButton, classes.buttonSpacing)}
                      data-edit-control
                      type="submit"
                      form={FORM_ID}
                      size="large">
                      <DoneIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title="Exit">
                  <IconButton
                    className={classes.iconButton}
                    data-edit-control
                    onClick={() => {
                      setValue('oktaGroupId', application?.oktaGroupId ?? '', { shouldDirty: true, shouldValidate: true });
                      setOktaGroupIdEditing(false);
                    }}
                    size="large">
                    <ClearIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ClickAwayListener>
    </form>
  );
}

const mapDispatchToProps: DispatchToProps = {
  updateApplication
};

export default connect(null, mapDispatchToProps)(ApplicationForm);
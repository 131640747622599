import { AnyAction } from 'redux';

import {
  ClientsState
} from './ClientModels';
import {
  GET_CLIENTS_REQUESTED,
  GET_CLIENTS_REQUEST_SUCCESSFUL,
  GET_CLIENTS_REQUEST_FAILED
} from './ClientActions';
import {
  CONTEXT_RESET
} from '../Context/ContextActions';

export const initialState: ClientsState = {
  allIds: [],
  byId: {},
  isLoading: false
};

function clients(state: ClientsState = initialState, action: AnyAction): ClientsState {
  switch (action.type) {
    case GET_CLIENTS_REQUESTED:
      return {
        ...state,
        isLoading: true
      };

    case GET_CLIENTS_REQUEST_SUCCESSFUL:
      return {
        allIds: action.allIds,
        byId: action.byId,
        isLoading: false
      };

    case GET_CLIENTS_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false
      };

      // Context Reset
    case CONTEXT_RESET:
      return {
        ...initialState
      };

    default:
      return state;
  }
}

export default clients;
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ClickAwayListener, Grow, List, ListSubheader, Paper, Popper } from '@mui/material';
import { bindPopper, PopupState } from 'material-ui-popup-state/core';

import { UMAReduxState } from '../../store/rootReducer';
import ContextManagementOrganizationList from './ContextManagementOrganizationList';
import { Organization, OrganizationsState } from '../../store/Organizations/OrganizationModels';
import SearchInput from '../SearchInput/SearchInput';
import { switchContext } from '../../store/Context/ContextActions';
import { collectionSpread, escapeRegExp, toOrganizationMask } from '../../utility';
import useStyles from './styles';
import { toClientMask } from '../../utility/normalizeStrings';

interface StateToProps {
  organizations: Organization[];
  organizationState: OrganizationsState;
}

interface DispatchToProps {
  switchContext: (organizationCode: string) => void;
}

interface ContextManagementMenuProps {
  bindPopper: typeof bindPopper;
  popupState: PopupState;
}

type Props = ContextManagementMenuProps & StateToProps & DispatchToProps;

export function ContextManagementMenu({
  bindPopper,
  organizationState,
  organizations,
  popupState,
  switchContext
}: Props) {
  const classes = useStyles({});
  const [filteredOrganizations, setFilteredOrganizations] = useState(organizations);
  const [filterText, setFilterText] = useState<string>('');


  useEffect(() => {
    // Initial display of organizations based on search bar filter
    handleFilterChange(filterText);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizations]);

  const handleFilterChange = (filterText: string) => {
    setFilterText(filterText);
    const searchRegex = new RegExp(escapeRegExp(filterText), 'i');
    const filteredRows = organizations.filter((organization) => {
      const orgMatch = searchRegex.test(toOrganizationMask(organization));
      const clientMatch = organization?.clients?.some(c => searchRegex.test(toClientMask(c))) ?? false;

      return orgMatch || clientMatch;
    });
    setFilteredOrganizations(filteredRows);
  };

  const handleClickAway = (e: MouseEvent | TouchEvent, popupState: PopupState) => {
    if (popupState.isOpen) {
      popupState.close();
      setFilterText('');
    }
  };

  const handleListItemClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, organization: Organization) => {
    popupState.close();
    if (organization) {
      switchContext(organization.code);
      setFilteredOrganizations(organizations);
      setFilterText('');
    }
  };

  return (
    <Popper
      role="popper"
      className={classes.popover}
      placement="bottom-end"
      {...bindPopper(popupState)}
      transition
      modifiers={[
        {
          name: 'offset',
          options: {
            offset: [0, 20],
          },
        },
      ]}
    >
      {({ TransitionProps, placement }) => (
        <div>
          <ClickAwayListener onClickAway={(e) => handleClickAway(e, popupState)}>
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <div>
                <Paper className={classes.menuContainer}>
                  <List
                    subheader={
                      <ListSubheader className={classes.subHeader}>
                        <SearchInput
                          autoFocus
                          onChange={(e) => handleFilterChange(e.target.value)}
                          onReset={() => handleFilterChange('')}
                        />
                      </ListSubheader>
                    }
                  >
                    <ContextManagementOrganizationList
                      filterText={filterText}
                      onListItemClick={handleListItemClick}
                      filteredOrganizations={filteredOrganizations}
                      organizationsState={organizationState}
                    />
                  </List>
                </Paper>
              </div>
            </Grow>
          </ClickAwayListener>
        </div>
      )}
    </Popper>
  );
}

const mapStateToProps = (state: UMAReduxState): StateToProps => ({
  organizationState: state.organizations,
  organizations: collectionSpread(state.organizations)
});

const mapDispatchToProps: DispatchToProps = {
  switchContext
};

export default connect(mapStateToProps, mapDispatchToProps)(ContextManagementMenu);


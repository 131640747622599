import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import clsx from 'clsx';
import { Box, Button, Divider, Grid, IconButton, Menu, MenuItem, Toolbar, Typography } from '@mui/material';
import { GridCellParams, GridColDef, DataGrid, GridValueFormatterParams } from '@mui/x-data-grid';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Link } from 'react-router-dom';

import { UMAReduxState } from '../../../store/rootReducer';
import { ById } from '../../../types/types';
import { getApplications } from '../../../store/Applications/ApplicationActions';
import { Application } from '../../../store/Applications/ApplicationModels';
import { escapeRegExp, rowCountByPage, toApplicationMask, } from '../../../utility';
import Can from '../../Can/Can';
import NoSearchResultsOverlay from '../../NoSearchResultsOverlay/NoSearchResultsOverlay';
import SearchInput from '../../SearchInput/SearchInput';
import useStyles from './styles';
import policyConstants from '../../../constants/policyConstants';
import { INITIAL_PAGE_SIZE, PAGE_SIZE_OPTIONS } from '../../../constants/pageSizeConstants';
import DeleteApplicationModal from '../../DeleteModal/DeleteModal';

type StateToProps = {
  byId: ById<Application>;
  isLoading: boolean;
  applications: Application[];
  isDeleting: boolean;
};

type DispatchToProps = {
  getApplications: () => void;
};

type ApplicationsPageProps = StateToProps & DispatchToProps;

function ApplicationsPage({
  applications,
  byId,
  getApplications,
  isLoading,
  isDeleting,
}: ApplicationsPageProps) {
  const history = useHistory();
  const [filterText, setFilterText] = useState<string>('');
  const [rows, setRows] = useState<Application[]>(applications);
  const [selectedActionApps, setSelectedActionApps] = useState<Application[]>([]);
  const [actionOverflowMenuAnchorEl, setActionOverflowMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(INITIAL_PAGE_SIZE);
  const [deleteApplicationModalOpen, setDeleteApplicationModalOpen] = useState(false);
  const classes = useStyles();

  const handleDeleteApplicationClick = () => {
    setDeleteApplicationModalOpen(true);
    setActionOverflowMenuAnchorEl(null);
  };
  const handleDeleteApplicationModalClose = () => setDeleteApplicationModalOpen(false);

  useEffect(() => {
    getApplications();
  }, [getApplications]);

  useEffect(() => {
    // Initial display of applications based on search bar filter
    handleFilterChange(filterText);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applications]);

  // Data Grid Actions
  const onPageChange = (page: number) => setPage(page);
  const onPageSizeChange = (pageSize: number) => setPageSize(pageSize);
  const handleFilterChange = (filterText: string) => {
    setFilterText(filterText);
    const searchRegex = new RegExp(escapeRegExp(filterText), 'i');
    const filteredRows = applications.filter((application) => {
      return searchRegex.test(toApplicationMask(application));
    });
    setRows(filteredRows);
  };

  // Action Overflow Menu
  const handleActionOverflowMenuClick = (event: React.MouseEvent<HTMLButtonElement>, cellParams: GridCellParams) => {
    setSelectedActionApps([byId[cellParams.row.code]]);
    setActionOverflowMenuAnchorEl(event.currentTarget);
  };
  const handleActionOverflowMenuClose = () => {
    setActionOverflowMenuAnchorEl(null);
  };

  // Route to Create Application
  const handleCreateApplicationClick = () => history.push(`${history.location.pathname}/create`);

  // Route to Application Detail
  const handleApplicationDetailClick = () => {
    const applicationCode = selectedActionApps[0].code;
    history.push(`${history.location.pathname}/${applicationCode}`);
  };

  const columns: GridColDef[] = [
    {
      field: 'code',
      headerName: 'CODE',
      minWidth: 200,
      renderCell(params: GridCellParams) {
        return <Link to={`/applications/${params.row.code}`} style={{ textDecoration: 'none' }}>
          <Typography>
            {params.row.code}
          </Typography>
        </Link>;
      },
      valueFormatter(params: GridValueFormatterParams) {
        return typeof params.value === 'string' ? params.value.toUpperCase() : params.value;
      },
    },
    {
      field: 'name',
      headerName: 'NAME',
      minWidth: 400,
    },
    {
      align: 'right',
      field: '',
      flex: 1,
      renderCell(params: GridCellParams) {
        const id = `${params.row.id}-menu-button`;
        return (
          <IconButton
            aria-label="Actions"
            id={id}
            className={`${clsx({ [classes.activeState]: actionOverflowMenuAnchorEl?.id === id })}`}
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleActionOverflowMenuClick(e, params)}
            size="small"
            disabled={isLoading}
            sx={{ mr: 2 }}
          >
            <MoreVertIcon />
          </IconButton>
        );
      },
      sortable: false
    }
  ];

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid xs={3} container item alignItems="center">
          <Typography variant="h3">Applications</Typography>
        </Grid>
        <Grid xs={9} container item alignItems="center" justifyContent="flex-end">
          <Box mt={.5} mb={.5}>
            <SearchInput
              onChange={(e) => handleFilterChange(e.target.value)}
              onReset={() => handleFilterChange('')}
              placeholder="Search Applications"
            />
          </Box>
          <Can requiredPolicy={policyConstants.applications.create()}>
            <Box ml={4.5}>
              <Button
                color="primary"
                size="large"
                variant="contained"
                onClick={handleCreateApplicationClick}
              >
                Create Application
              </Button>
            </Box>
          </Can>
        </Grid>
      </Grid>
      <Toolbar disableGutters>
        <Typography variant="body2" className={classes.selectedResultsDisplay}>
          {rowCountByPage(page, pageSize, rows.length)} of {rows.length} results
        </Typography>
      </Toolbar>
      <Divider />
      <DataGrid
        columnBuffer={0}
        columns={columns}
        components={{
          NoRowsOverlay: NoSearchResultsOverlay
        }}
        componentsProps={{
          noRowsOverlay: {
            isFiltering: filterText.length,
            noRowsLabel: 'No applications have been created.'
          }
        }}
        disableColumnFilter
        disableColumnMenu
        disableSelectionOnClick
        hideFooterSelectedRowCount
        loading={isLoading}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        pageSize={pageSize}
        rows={rows}
        rowsPerPageOptions={PAGE_SIZE_OPTIONS}
      />
      <Menu
        anchorEl={actionOverflowMenuAnchorEl}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        PopoverClasses={{
          root: classes.menuRoot
        }}
        keepMounted
        open={Boolean(actionOverflowMenuAnchorEl)}
        onClose={handleActionOverflowMenuClose}
      >
        <div>
          <MenuItem onClick={handleApplicationDetailClick}>View details</MenuItem>
          <MenuItem onClick={handleDeleteApplicationClick}>Delete</MenuItem>
        </div>
      </Menu>
      <DeleteApplicationModal
        resourceName='applications'
        isDeleting={isDeleting}
        onClose={handleDeleteApplicationModalClose}
        onDelete={() => history.push('/applications')}
        open={deleteApplicationModalOpen}
        resources={selectedActionApps}
      />
    </div>

  );
}

const mapStateToProps = (state: UMAReduxState): StateToProps => ({
  applications: Object.values(state.applications.byId).sort((a, b) => b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 1),
  byId: state.applications.byId,
  isLoading: state.applications.isLoading,
  isDeleting: state.applications.isDeleting
});

const mapDispatchToProps: DispatchToProps = {
  getApplications
};

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationsPage);

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Box,
  Grid,
  Typography
} from '@mui/material';
import {
  GridColDef,
  DataGrid,
  GridValueFormatterParams
} from '@mui/x-data-grid';

import { UMAReduxState } from '../../../store/rootReducer';
import { getClients } from '../../../store/Clients/ClientActions';
import {
  Client,
  ClientRow,
  ClientsState
} from '../../../store/Clients/ClientModels';
import useStyles from './styles';

interface ClientsPageProps {
  isLoading: boolean;
  getClients: (organizationCode: string) => void;
  organizationCode: string;
  rows: ClientRow[];
}

const ClientsPage = ({
  isLoading,
  getClients,
  organizationCode,
  rows = []
}: ClientsPageProps) => {
  const classes = useStyles();

  useEffect(() => {
    getClients(organizationCode);
  }, [getClients, organizationCode]);

  const columns: GridColDef[] = [
    {
      field: 'code',
      flex: 1,
      headerName: 'CLIENT CODE',
      valueFormatter(params: GridValueFormatterParams) {
        return typeof params.value === 'string' ? params.value.toUpperCase() : params.value;
      },
    },
    {
      field: 'name',
      flex: 1,
      headerName: 'NAME'
    }
  ];

  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <Box marginBottom={3}>
            <Typography variant="h3">Clients</Typography>
          </Box>
        </Grid>
        <Grid item xs={6} className={classes.dataGridContainer}>
          <DataGrid
            autoHeight
            columnBuffer={0}
            columns={columns}
            disableColumnFilter
            disableColumnMenu
            disableColumnSelector
            disableSelectionOnClick
            hideFooterSelectedRowCount
            hideFooterPagination
            loading={isLoading}
            rows={rows}
            localeText={{
              noRowsLabel: 'Client setup has not been completed for this organization.'
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

/**
 * Create an array of `Client`s with each client having an `id` property
 * and an id property.
 * @param {ClientsState} clientState - a `CollectionState` of `Clients`s
 * @return {ClientRow[]} an array of Clients with each client having an id property.
 */
export function clientsSpreadWithId(clientState: ClientsState) {
  const { allIds, byId } = clientState;
  const idKey: keyof Client = 'code';
  return allIds.map((id) => {
    const client = byId[id];
    return ({
      ...client,
      id: client[idKey]
    });
  });
}

const mapStateToProps = (state: UMAReduxState) => ({
  isLoading: state.clients.isLoading,
  organizationCode: state.context.organizationCode,
  rows: clientsSpreadWithId(state.clients)
});

const mapDispatchToProps = {
  getClients
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientsPage);

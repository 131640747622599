import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

interface AvatarStyleProps {
	smallBadge?: boolean;
	users: number;
}

const styles = makeStyles((theme: Theme) => ({
  anchorOriginBottomRightCircle: {
    bottom: '10%',
    right: '5%'
  },
  avatarGroup: {
    '&:last-child': {
      backgroundColor: ({ users }: AvatarStyleProps) => users > 5 ? theme.palette.grey[700] : '#7D8AF0',
      fontSize: ({ users }: AvatarStyleProps) => users > 5 ? 12 : 14
    }
  },
  badge: {
    border: '1px solid #FFF',
    borderRadius: '50%',
    cursor: 'pointer',
    fontSize: 10,
    height: ({ smallBadge }: AvatarStyleProps) => smallBadge ? 14 : 18,
    minWidth: ({ smallBadge }: AvatarStyleProps) => smallBadge ? 14 : 18,
  },
  deactivated: {
    background: '#B71C1C'
  },
  tooltipArea: {
    alignItems: 'center',
    background: 'transparent',
    display: 'flex',
    height: 16,
    justifyContent: 'center',
    position: 'absolute',
    width: 16,
  }
}));

export default styles;
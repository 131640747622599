import { clientsApi } from '../../api';
import { ClientAction } from './ClientModels';
import { AppThunkAction } from '../rootReducer';
import {
  normalizeClientList,
  showError
} from '../../utility';

// Action Types
export const GET_CLIENTS_REQUESTED: string = 'GET_CLIENTS_REQUESTED';
export const GET_CLIENTS_REQUEST_SUCCESSFUL: string = 'GET_CLIENTS_REQUEST_SUCCESSFUL';
export const GET_CLIENTS_REQUEST_FAILED: string = 'GET_CLIENTS_REQUEST_FAILED';

// Action Creators

/**
 * Get clients by organization code
 * @param {String} organizationCode - organization code used to retrieve associated clients
 * @return {Function} - dispatches function response based on api results
 */
export function getClients(organizationCode: string): AppThunkAction<ClientAction> {
  return async (dispatch) => {
    dispatch({ type: GET_CLIENTS_REQUESTED });
    try {
      const clients = await clientsApi.getClients(organizationCode);
      const { allIds, byId } = normalizeClientList(clients);
      dispatch({ allIds, byId, type: GET_CLIENTS_REQUEST_SUCCESSFUL });
    } catch (err) {
      showError(err, dispatch);
      dispatch({ type: GET_CLIENTS_REQUEST_FAILED });
    }
  };
}

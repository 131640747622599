import { combineReducers, Reducer, ReducersMapObject } from 'redux';
import { connectRouter, RouterState } from 'connected-react-router';
import { History } from 'history';

import alertReducer from './Alerts/AlertReducer';
import applicationsReducer from './Applications/ApplicationReducer';
import clientsReducer from './Clients/ClientReducer';
import contextReducer from './Context/ContextReducer';
import groupsReducer from './Groups/GroupReducer';
import myReducer from './My/MyReducer';
import organizationsReducer from './Organizations/OrganizationReducer';
import policyRulesReducer from './PolicyRules/PolicyRuleReducer';
import roleImportReducer from './RoleImports/RoleImportReducer';
import rolesReducer from './Roles/RoleReducer';
import usersReducer from './Users/UserReducers';
import { AlertState } from './Alerts/AlertModels';
import { ApplicationsState } from './Applications/ApplicationModels';
import { ClientsState } from './Clients/ClientModels';
import { ContextState } from './Context/ContextModels';
import { GroupsState } from './Groups/GroupModels';
import { MyState } from './My/MyModels';
import { OrganizationsState } from './Organizations/OrganizationModels';
import { PolicyRulesState } from './PolicyRules/PolicyRuleModels';
import { RolesState } from './Roles/RoleModels';
import { UsersState } from './Users/UserModels';
import { FederatedGroupMappingState } from './FederatedGroupMappings/FederatedGroupMappingModels';
import federatedGroupMappingReducer from './FederatedGroupMappings/FederatedGroupMappingReducer';
import { RoleImportState } from './RoleImports/RoleImportModels';

// The top-level state object
export interface UMAReduxState {
  alerts: AlertState;
  applications: ApplicationsState;
  clients: ClientsState;
  context: ContextState;
  federatedGroupMappings: FederatedGroupMappingState;
  groups: GroupsState;
  my: MyState;
  organizations: OrganizationsState;
  policyRules: PolicyRulesState;
  roles: RolesState;
  roleImport: RoleImportState;
  router: RouterState;
  users: UsersState;
}

const rootReducer = (history: History): Reducer<UMAReduxState> => {
  const reducerMap: ReducersMapObject<UMAReduxState> = {
    alerts: alertReducer,
    applications: applicationsReducer,
    clients: clientsReducer,
    context: contextReducer,
    federatedGroupMappings: federatedGroupMappingReducer,
    groups: groupsReducer,
    my: myReducer,
    organizations: organizationsReducer,
    policyRules: policyRulesReducer,
    roles: rolesReducer,
    roleImport: roleImportReducer,
    router: connectRouter(history) as any,
    users: usersReducer
  };

  return combineReducers(reducerMap);
};

export default rootReducer;

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
  (dispatch: (action: TAction) => void, getState: () => UMAReduxState): void;
}

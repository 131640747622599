import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Box, Grid, Typography } from '@mui/material';

import { UMAReduxState } from '../../../store/rootReducer';
import { GroupsState } from '../../../store/Groups/GroupModels';
import { User } from '../../../store/Users/UserModels';
import { getMyUserProfile } from '../../../store/My/MyActions';
import { getUserGroupsById } from '../../../store/Users/UserActions';
import { removeUsersFromGroup } from '../../../store/Groups/GroupActions';
import AssignGroupsModal from '../../AssignModal/AssignModal';
import Can from '../../Can/Can';
import GroupsList from '../../FilterList/FilterList';
import UserDetails from '../../UserDetails/UserDetails';
import { Id } from '../../../types/types';
import useStyles from './styles';
import policyConstants from '../../../constants/policyConstants';
import If from '../../If/If';

type StateToProps = {
  groupIds: Id[];
  groupState: GroupsState;
  isLoading: boolean;
  userId: Id;
  organizationCode: string,
  user: User
  isSwitched: boolean;
};

type DispatchToProps = {
  getMyUserProfile: (userId: Id) => void,
  getUserGroupsById: (organizationCode: string, groupId: Id) => void
  removeUsersFromGroup: (orgCode: string, groupId: Id, userIds: Id[]) => void,
};

type ProfilePageProps = StateToProps & DispatchToProps;

function ProfilePage({
  getMyUserProfile,
  getUserGroupsById,
  groupIds,
  groupState,
  isLoading,
  userId,
  organizationCode,
  removeUsersFromGroup,
  user,
  isSwitched,
}: ProfilePageProps) {
  const classes = useStyles();
  const [assignGroupsModalOpen, setAssignGroupsModalOpen] = useState(false);

  useEffect(() => {
    if (userId && !isSwitched) {
      getMyUserProfile(userId);
      getUserGroupsById(organizationCode, userId);
    }
  }, [getMyUserProfile, getUserGroupsById, organizationCode, userId, isSwitched]);

  const handleAssignGroupsModalOpen = () => setAssignGroupsModalOpen(true);
  const handleAssignGroupsModalClose = () => setAssignGroupsModalOpen(false);

  const handleGroupDeleteClick = (groupId: Id) => {
    removeUsersFromGroup(
      organizationCode,
      groupId,
      [userId]
    );
  };

  return (
    <React.Fragment>
      <Box mt={2} mb={3}>
        <Typography variant="h3">My Profile</Typography>
      </Box>
      <Grid container spacing={3}>
        <Grid xs={7} item className={classes.detailsContainer}>
          <UserDetails user={user} isLoading={isLoading} disableUserStatusChanges />
        </Grid>
        <If condition={!isSwitched}>
          <Can requiredPolicy={policyConstants.users.getWithGroupIdsById(organizationCode, userId)}>
            <Grid xs={5} item>
              <GroupsList
                parentResourceName="users"
                addItemProps={{
                  handleAddClick: handleAssignGroupsModalOpen,
                  requiredPolicy: policyConstants.groups.addUsersAny(organizationCode)
                }}
                removeItemProps={{
                  handleDeleteClick: handleGroupDeleteClick,
                  policyFunction: policyConstants.groups.removeUsers,
                  policyFunctionParams: [organizationCode]
                }}
                readItemProps={{
                  policyFunction: policyConstants.groups.getById,
                  policyFunctionParams: [organizationCode]
                }}
                isLoading={isLoading || Boolean(user?.groupsLoading)}
                listResourceName="groups"
                listResources={groupIds.map((id) => groupState.byId[id])}
              />
            </Grid>
          </Can>
        </If>
      </Grid>
      <AssignGroupsModal
        parentResourceLoading={isLoading}
        assignResourceName="groups"
        parentResource={[user]}
        parentResourceName="users"
        isAssigning={groupState.isAssigning}
        onClose={handleAssignGroupsModalClose}
        open={assignGroupsModalOpen}
      />
    </React.Fragment>
  );
}

function mapStateToProps(state: UMAReduxState): StateToProps {
  const userId = state.my.profile.id ?? '';
  const profileLoading = state.my.profile.isLoading || !!state.users.byId[userId]?.isLoading;
  return {
    groupIds: state.users.byId[userId]?.groupIds ?? [],
    groupState: state.groups,
    isLoading: profileLoading ?? true,
    userId,
    organizationCode: state.context.organizationCode,
    user: state.my.profile,
    isSwitched: state.context.isSwitched,
  };
}

const mapDispatchToProps: DispatchToProps = {
  getMyUserProfile,
  getUserGroupsById,
  removeUsersFromGroup
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
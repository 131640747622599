import { alertEnqueued } from '../store/Alerts/AlertActions';

/**
 * Parse and display error using the alerts redux action
 * @param {Error} err - error object throw into catch block
 * @param {Function} dispatch - dispatch function to trigger alerts
 * @return {Function} - dispatch alert enqueued
 */
export default function showError(err: any, dispatch: any) {
  if (err?.body?.errors) {
    err.body.errors.forEach((error: string) => dispatch(alertEnqueued(error, { autoHide: true })));
  } else {
    dispatch(alertEnqueued(err?.message || err?.error?.message || err.toString()));
  }
}
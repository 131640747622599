import React, { useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';

import {
  Box, Grid, Skeleton, Typography,
  Checkbox, FormControlLabel
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';

import { UMAReduxState } from '../../../store/rootReducer';
import { Id } from '../../../types/types';
import { ApplicationsState } from '../../../store/Applications/ApplicationModels';
import { Organization, OrganizationDomain, UpdateOrgStrictModeFormValues } from '../../../store/Organizations/OrganizationModels';
import {
  getOrganizationApplications,
  getOrganizationByCode,
  getOrganizationByCodeWithDomains,
  removeApplicationsFromOrganization,
  removeDomainFromOrganization,
  updateOrganizationStrictMode
} from '../../../store/Organizations/OrganizationActions';
import { getClients } from '../../../store/Clients/ClientActions';
import { ClientsState } from '../../../store/Clients/ClientModels';
import ActiveStatusChip from '../../StatusChip/StatusChip';
import ApplicationsList from '../../FilterList/FilterList';
import ClientsList from '../../FilterList/FilterList';
import OrganizationDomainList from '../../FilterList/FilterList';
import AssignApplicationModal from '../../AssignModal/AssignModal';
import useStyles from './styles';
import policyConstants from '../../../constants/policyConstants';
import { useCasbin } from '../../../hooks/useCasbin';
import If from '../../If/If';
import CreateDomainModal from '../../CreateDomainModal/CreateDomainModal';

type StateToProps = {
  applicationCodes: Id[];
  applicationState: ApplicationsState;
  clientIds: Id[];
  clientState: ClientsState;
  isAssigning: boolean,
  isLoading: boolean,
  organizationCode: string,
  organization: Organization,
};

type DispatchToProps = {
  getClients: (organizationCode: string) => void;
  getOrganizationApplications: (code: Id) => void;
  getOrganizationByCode: (code: Id) => void;
  getOrganizationByCodeWithDomains: (code: Id) => void;
  removeApplicationsFromOrganization: (code: string, applicationCodes: Id[]) => void;
  removeDomainFromOrganization: (organizationCode: string, domain: OrganizationDomain) => void;
  updateOrganizationStrictMode: (organizationCode: string, organization: Partial<Organization>, ...callbacksOnSuccess: Function[]) => void;
};

type OrganizationDetailPageProps = StateToProps & DispatchToProps;

function OrganizationDetailPage({
  applicationCodes,
  applicationState,
  clientIds,
  clientState,
  getClients,
  getOrganizationApplications,
  getOrganizationByCode,
  getOrganizationByCodeWithDomains,
  isAssigning,
  isLoading,
  organization,
  organizationCode,
  removeApplicationsFromOrganization,
  removeDomainFromOrganization,
  updateOrganizationStrictMode
}: OrganizationDetailPageProps) {
  const classes = useStyles();

  const [assignApplicationsModalOpen, setAssignApplicationsModalOpen] = useState(false);

  const canGetApplications = useCasbin(policyConstants.organizations.getApplications(organizationCode));
  const canGetClients = useCasbin(policyConstants.clients.getAll(organizationCode));
  const canGetDomains = useCasbin(policyConstants.organizations.getDomains(organizationCode));
  const canGetByOrg = useCasbin(policyConstants.organizations.getById(organizationCode));
  const canSetStrictMode = useCasbin(policyConstants.organizations.canSetStrictMode(organizationCode));

  useEffect(() => {
    if (canGetClients) getClients(organizationCode);
    if (canGetApplications) getOrganizationApplications(organizationCode);

    if (canGetDomains) getOrganizationByCodeWithDomains(organizationCode);
    else getOrganizationByCode(organizationCode);
  }, [
    getClients,
    getOrganizationByCode,
    getOrganizationByCodeWithDomains,
    getOrganizationApplications,
    organizationCode,
    canGetApplications,
    canGetClients,
    canGetDomains]);

  const handleAssignApplicationsModalClose = () => setAssignApplicationsModalOpen(false);
  const handleAssignApplicationsModalOpen = () => setAssignApplicationsModalOpen(true);

  const [createDomainModalOpen, setCreateDomainModalOpen] = useState(false);

  const handleCreateDomainModalOpen = () => setCreateDomainModalOpen(true);
  const handleCreateDomainModalClose = () => setCreateDomainModalOpen(false);
  const handleDeleteDomainClick = (domainName: Id) => {
    const domain = organization.organizationDomains?.find(d => d.name === domainName);
    if (!domain) return;
    removeDomainFromOrganization(organizationCode, domain);
  };

  // Filter List Handlers
  const handleApplicationDeleteClick = (applicationCode: Id) => {
    removeApplicationsFromOrganization(
      organizationCode,
      [applicationCode]
    );
  };

  const strictMode = organization?.strictMode ?? false;

  const { control, reset } = useForm<UpdateOrgStrictModeFormValues>({
    defaultValues: useMemo(() => {
      return {
        strictMode
      };
    }, [strictMode]),
    mode: 'onChange',
  });

  useEffect(() => {
    reset({ strictMode });
  }, [strictMode, reset]);

  return (
    <div>
      <Box mt={2} mb={5}>
        <Grid container item xs={12} direction="column">
          <Grid item xs={12} md={6}>
            <Typography variant="h3" className={classes.header}>
              {isLoading ? <Grid item xs={6}><Skeleton /></Grid> : organization.name}
            </Typography>
          </Grid>
          <Grid container spacing={3}>
            <Grid item>
              <Grid container spacing={6}>
                <Grid item className={classes.organizationMetaContainer}>
                  <Typography variant="caption" color="textSecondary" gutterBottom>Code</Typography>
                  <Typography variant="body1" component="span" className={classes.code}>
                    {isLoading ? <Skeleton width={50} /> : organization.code.toUpperCase()}
                  </Typography>
                </Grid>
                <Grid item className={classes.organizationMetaContainer}>
                  <Typography variant="caption" color="textSecondary" gutterBottom>Status</Typography>
                  <Typography variant="body1" component="span">
                    {isLoading ? <Skeleton width={50} /> : (
                      <ActiveStatusChip status={organization.isActive ? 'active' : 'inactive'} />
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Grid container spacing={3}>
        <If condition={canGetApplications}>
          <Grid xs={12} md={6} item>
            <ApplicationsList
              parentResourceName="organizations"
              addItemProps={{
                handleAddClick: handleAssignApplicationsModalOpen,
                requiredPolicy: policyConstants.organizations.addApplications(organizationCode)
              }}
              removeItemProps={{
                handleDeleteClick: handleApplicationDeleteClick,
                policyFunction: policyConstants.organizations.removeApplications,
                policyFunctionParams: [organizationCode]
              }}
              readItemProps={{
                policyFunction: policyConstants.applications.getById
              }}
              isLoading={isLoading}
              listResourceName="applications"
              listResources={applicationCodes.map((id) => applicationState.byId[id]) ?? []}
              rowsPerPageInput={10}
              rowsPerPageOptionInput={[5, 10, 15]}
            />
          </Grid>
        </If>
        <If condition={canGetClients}>
          <Grid xs={12} md={6} item>
            <ClientsList
              parentResourceName="organizations"
              addItemProps={{
                handleAddClick: () => { },
                immutable: true
              }}
              removeItemProps={{
                handleDeleteClick: () => { },
                immutable: true
              }}
              isLoading={isLoading}
              listResourceName="clients"
              listResources={clientIds?.map((id) => clientState.byId[id]) ?? []}
              rowsPerPageInput={10}
              rowsPerPageOptionInput={[5, 10, 25]}
            />
          </Grid>
        </If>
        <If condition={canGetDomains}>
          <Grid xs={12} md={6} item>
            <OrganizationDomainList
              parentResourceName="organizations"
              addItemProps={{
                handleAddClick: handleCreateDomainModalOpen,
                requiredPolicy: policyConstants.organizations.addDomain(organizationCode)
              }}
              removeItemProps={{
                handleDeleteClick: handleDeleteDomainClick,
                requiredPolicy: policyConstants.organizations.deleteDomains(organizationCode)
              }}
              isLoading={isLoading}
              listResourceName="domains"
              listResources={organization?.organizationDomains ?? []}
            />
          </Grid>
        </If>
      </Grid>
      <AssignApplicationModal
        parentResourceLoading={isLoading}
        parentResourceName="organizations"
        parentResource={[organization]}
        assignResourceName="applications"
        isAssigning={isAssigning}
        onClose={handleAssignApplicationsModalClose}
        open={assignApplicationsModalOpen}
      />
      <If condition={canGetByOrg}>
        <FormControlLabel
          control={
            <Controller
              name='strictMode'
              control={control}
              render={({ field: props }) => (
                <Checkbox
                  {...props}
                  checked={props.value}
                  disabled={!canSetStrictMode || (isAssigning || !organization?.organizationDomains?.length)}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const value = e.target.checked;
                    props.onChange(value);

                    const updatePayload: Partial<Organization> = {
                      id: organization.id,
                      code: organization.code,
                      name: organization.name,
                      isActive: organization.isActive,
                      strictMode: value
                    };

                    updateOrganizationStrictMode(organizationCode, updatePayload);
                  }
                  }
                />
              )}
            />
          }
          label='Strict Mode'
        />
      </If>
      <CreateDomainModal
        isAssigning={isAssigning}
        isLoading={isLoading}
        organization={organization}
        domains={organization?.organizationDomains ?? []}
        onClose={handleCreateDomainModalClose}
        open={createDomainModalOpen}
      />
    </div>
  );
}

function mapStateToProps(state: UMAReduxState): StateToProps {
  const code = state.context.organizationCode;
  const organization = state.organizations.byId[code];

  return {
    applicationCodes: state.organizations.byId[code]?.applicationCodes ?? [],
    applicationState: state.applications,
    isAssigning: state.organizations.isAssigning,
    isLoading: (state.organizations.byId[code]?.isLoading ?? true),
    organization,
    organizationCode: code,
    clientIds: state.clients.allIds,
    clientState: state.clients
  };
}

const mapDispatchToProps: DispatchToProps = {
  getClients,
  getOrganizationApplications,
  getOrganizationByCode,
  getOrganizationByCodeWithDomains,
  removeApplicationsFromOrganization,
  removeDomainFromOrganization,
  updateOrganizationStrictMode
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationDetailPage);

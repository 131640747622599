import { createSelector } from 'reselect';

import { UMAReduxState } from '../store/rootReducer';

interface GetApplicationPermissionsProps {
	applicationCode: string;
}

export const getApplicationPermissions = createSelector(
  (state: UMAReduxState) => state.applications,
  (state: UMAReduxState, props: GetApplicationPermissionsProps) => props.applicationCode,
  (applications, applicationCode) => applications.byId[applicationCode]?.permissions ?? []
);

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

const styles = makeStyles((theme: Theme) => ({
  baseBreadcrumb: {
    textDecoration: 'underline'
  },
  breadcrumbs: {
    color: theme.palette.grey[600],
    fontSize: 12
  },
  descriptionField: {
    '&:hover': {
      border: `1px solid ${theme.palette.primary.light}`
    },
    border: '1px solid transparent',
    borderRadius: 4,
    fontSize: 13,
    height: 'auto',
    padding: theme.spacing(1, 0.5),
  },
  editControls: {
    marginTop: theme.spacing(0.5)
  },
  editControlsHidden: {
    visibility: 'hidden'
  },
  editableContainer: {
    '&:hover': {
      border: `1px solid ${theme.palette.primary.light}`
    },
    border: '1px solid transparent',
    borderRadius: 4,
  },
  editableItem: {
    '&:active': {
      border: 'none',
      outline: `1px solid ${theme.palette.primary.light}`
    },
    '&:focus': {
      border: 'none',
      outline: `1px solid ${theme.palette.primary.light}`
    }
  },
  filterList: {
    minWidth: 400
  },
  iconButton: {
    border: '0.75px solid #DCDCDC',
    borderRadius: 3,
    padding: 0
  },
  input: {
    '& + .MuiOutlinedInput-notchedOutline': {
      border: 'none'
    },
    '&:placeholder-shown': {
      '& + .MuiInputAdornment-positionEnd': {
        visibility: 'hidden'
      },
    },
    padding: 0
  },
  inputField: {
    '&:hover': {
      border: `1px solid ${theme.palette.primary.light}`
    },
    border: '1px solid transparent',
  },
  listItem: {
    color: theme.palette.grey[600],
    padding: theme.spacing(2, 2.5)
  },
  listPadding: {
    padding: 0
  },
  nameField: {
    fontSize: 30,
    padding: 0
  },
  subheaderGutters: {
    padding: theme.spacing(0, 2.5)
  },
  title: {
    marginRight: theme.spacing(1)
  },
  titleSubheader: {
    padding: theme.spacing(2, 2.5)
  },
}));

export default styles;
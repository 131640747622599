import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';

import {
  toFullName
} from '../../utility';
import { changeUsersStatuses } from '../../store/Users/UserActions';
import { User } from '../../store/Users/UserModels';
import useStyles from './styles';
import { UMAReduxState } from '../../store/rootReducer';

interface DeactivateUserModalProps {
  onClose: () => void;
  onDeactivate?: () => void;
  open?: boolean;
  user: User;
}

type Props = DeactivateUserModalProps;

function DeactivateUserModal({
  onClose,
  onDeactivate,
  open = false,
  user
}: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const organizationCode = useSelector((store: UMAReduxState) => store.context.organizationCode);

  const handleDeactivateClick = () => {
    dispatch(changeUsersStatuses('deactivate', [user.id], organizationCode, onDeactivateSuccessful));
  };
  const onDeactivateSuccessful = () => {
    onClose();
    if (onDeactivate) {
      onDeactivate();
    }
  };
  const handleCloseModal = () => {
    onClose();
  };

  const statusUpdating = user?.statusUpdating ?? false;
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="deactivate-user-dialog-modal"
      classes={{
        paper: classes.dialogPaper
      }}
    >
      <DialogTitle id="deactivate-user-dialog-title" classes={{ root: classes.titleContainer }}>
        Deactivate User
      </DialogTitle>
      <DialogContent classes={{ root: classes.contentContainer }}>
        <DialogContentText color="initial" classes={{ root: classes.contentContainer }}>
          {
            `${user ? toFullName(user) : 'User'}'s status will be moved to deprovisioned (deactivated).
						\nThis action will make the user significantly less accessible throughout the application.`
          }
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button onClick={handleCloseModal} color="primary" variant="outlined">
          Cancel
        </Button>
        <Button color="error" variant="contained" onClick={handleDeactivateClick} disabled={statusUpdating} sx={{ ml: 1 }}>
          {statusUpdating ? 'Deactivating' : 'Deactivate'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeactivateUserModal;

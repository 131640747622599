import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import {
  Box,
  Breadcrumbs,
  Button,
  ClickAwayListener,
  Grid,
  IconButton,
  Link,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import ClearIcon from '@mui/icons-material/ClearRounded';
import DoneIcon from '@mui/icons-material/Done';

import { UMAReduxState } from '../../../store/rootReducer';
import {
  Group,
  groupFormSchema,
  GroupFormValues
} from '../../../store/Groups/GroupModels';
import {
  createGroup
} from '../../../store/Groups/GroupActions';
import UsersList from '../../FilterList/FilterList';
import RolesList from '../../FilterList/FilterList';
import LeavePageModal from '../../LeavePageModal/LeavePageModal';
import useStyles from './styles';

const FORM_ID = 'create-group-form';

type StateToProps = {
  isCreating: boolean;
  organizationCode: string;
};

type DispatchToProps = {
  createGroup: (organizationCode: string, group: Partial<Group>) => void
};

type GroupCreatePageProps = StateToProps & DispatchToProps;

function GroupCreatePage({
  createGroup,
  isCreating,
  organizationCode
}: GroupCreatePageProps) {
  const classes = useStyles();
  const history = useHistory();
  const { control, formState: { isDirty, isValid }, handleSubmit, reset } = useForm<GroupFormValues>({
    mode: 'onChange',
    resolver: yupResolver(groupFormSchema)
  });

  const [editing, setEditing] = useState<boolean>(false);
  const [leavePageModalOpen, setLeavePageModalOpen] = useState<boolean>(false);

  const onFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    setEditing(true);
  };

  const handleBreadcrumbClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    history.push('/groups');
  };

  const onSubmit = (values: GroupFormValues) => {
    if (!isDirty) return;

    const createPayload: Partial<Group> = {
      name: values.name
    };
    createGroup(organizationCode, createPayload);
  };

  const handleLeavePageModalOpened = () => setLeavePageModalOpen(true);
  const handleLeavePageModalClosed = () => setLeavePageModalOpen(false);
  const handleLeavePageClick = () => history.push('/groups');

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} id={FORM_ID}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Breadcrumbs className={classes.breadcrumbs} separator="›" aria-label="breadcrumb">
              <Link
                className={classes.baseBreadcrumb}
                color="inherit"
                href="/groups"
                onClick={handleBreadcrumbClick}
                variant="body2"
              >
                Groups
              </Link>
              <Typography variant="body2">Create Group</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item>
            <Button color="primary" size="large" variant="outlined" onClick={handleLeavePageModalOpened}>
              Cancel
            </Button>
            <Button
              aria-label="submit-button"
              disabled={isCreating || !isDirty || !isValid}
              color="primary"
              size="large"
              variant="contained"
              type="submit"
              form={FORM_ID}
              sx={{ ml: 1 }}
            >
              {isCreating ? 'Saving' : 'Save'}
            </Button>
          </Grid>
        </Grid>
        <Box mt={2} mb={1.5}>
          <ClickAwayListener
            mouseEvent="onMouseDown"
            touchEvent="onTouchStart"
            onClickAway={() => {
              setEditing(false);
            }}
          >
            <Grid container item xs={6} direction="column">
              <Controller
                name="name"
                control={control}
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    placeholder="Enter group name"
                    onFocus={onFocus}
                    required
                    inputProps={{
                      'aria-label': 'group-name-input',
                      className: classes.input
                    }}
                    InputProps={{
                      classes: {
                        root: clsx(classes.inputField, classes.nameField)
                      }
                    }}
                    fullWidth
                    error={Boolean(error)}
                    helperText={error?.message ?? ''}
                  />
                )}
              />
              <Grid xs={12} item>
                <Grid
                  container
                  justifyContent="flex-end"
                  spacing={1}
                  className={clsx(classes.editControls, { [classes.editControlsHidden]: !editing })}
                >
                  <Grid item>
                    <Tooltip title="Save">
                      <span>
                        <IconButton
                          aria-label="input-submit-button"
                          disabled={!isDirty || !isValid}
                          className={classes.iconButton}
                          data-edit-control
                          type="submit"
                          form={FORM_ID}
                          size="large">
                          <DoneIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </Grid>
                  <Grid item>
                    <Tooltip title="Exit">
                      <IconButton
                        aria-label="input-clear-button"
                        className={classes.iconButton}
                        data-edit-control
                        onClick={() => {
                          reset({ name: '' });
                          setEditing(false);
                        }}
                        size="large">
                        <ClearIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </ClickAwayListener>
        </Box>
      </form>
      <Grid container spacing={3}>
        <Grid xs={12} md={6} item>
          <UsersList
            onCreateResourcePage={true}
            parentResourceName="groups"
            addItemProps={{
              handleAddClick: () => { }
            }}
            removeItemProps={{
              handleDeleteClick: () => { }
            }}
            isLoading={false}
            listResourceName="users"
            listResources={[]}
          />
        </Grid>
        <Grid xs={12} md={6} item>
          <RolesList
            onCreateResourcePage={true}
            parentResourceName="groups"
            addItemProps={{
              handleAddClick: () => { }
            }}
            removeItemProps={{
              handleDeleteClick: () => { }
            }}
            isLoading={false}
            listResourceName="roles"
            listResources={[]}
          />
        </Grid>
      </Grid>
      <LeavePageModal
        open={leavePageModalOpen}
        onClose={handleLeavePageModalClosed}
        onLeave={handleLeavePageClick}
      />
    </div>
  );
}

const mapStateToProps = (state: UMAReduxState): StateToProps => ({
  isCreating: state.groups.isCreating,
  organizationCode: state.context.organizationCode
});

const mapDispatchToProps: DispatchToProps = {
  createGroup
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupCreatePage);

type EnvironmentVariables = {
  apiBasePath: string;
  debounceDelayMS: number;
  enableTokenHookLogging: boolean;
  enableCasbinEnforcerLogging: boolean;
  environment: string;
  oktaClientId: string;
  oktaIssuer: string;
};

const environment: EnvironmentVariables = {
  apiBasePath: process.env.REACT_APP_API_BASE_PATH || 'https://localhost:6001/v2',
  debounceDelayMS: Number(process.env.REACT_APP_DEBOUNCE_DELAY_MS) || 0,
  enableTokenHookLogging: Boolean(process.env.REACT_APP_ENABLE_TOKEN_HOOK_LOGGING) || false,
  enableCasbinEnforcerLogging: Boolean(process.env.REACT_APP_ENABLE_CASBIN_ENFORCER_LOGGING) || false,
  environment: process.env.REACT_ENV || 'development',
  oktaClientId: process.env.REACT_APP_OKTA_CLIENT_ID || '',
  oktaIssuer: process.env.REACT_APP_OKTA_ISSUER || ''
};

export default environment;

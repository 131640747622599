import { AnyAction } from 'redux';
import {
  merge,
  union,
  without
} from 'lodash';

import {
  Organization,
  OrganizationsState
} from './OrganizationModels';
import {
  GET_ORGANIZATIONS_REQUESTED,
  GET_ORGANIZATIONS_REQUEST_SUCCESSFUL,
  GET_ORGANIZATIONS_REQUEST_FAILED,
  GET_ORGANIZATION_BY_CODE_REQUESTED,
  GET_ORGANIZATION_BY_CODE_REQUEST_SUCCESSFUL,
  GET_ORGANIZATION_BY_CODE_REQUEST_FAILED,
  GET_APPLICATIONS_FOR_ORGANIZATION_BY_CODE_REQUESTED,
  GET_APPLICATIONS_FOR_ORGANIZATION_BY_CODE_REQUEST_SUCCESSFUL,
  GET_APPLICATIONS_FOR_ORGANIZATION_BY_CODE_REQUEST_FAILED,
  ADD_APPLICATIONS_TO_ORGANIZATION_REQUESTED,
  ADD_APPLICATIONS_TO_ORGANIZATION_REQUEST_SUCCESSFUL,
  ADD_APPLICATIONS_TO_ORGANIZATION_REQUEST_FAILED,
  REMOVE_APPLICATIONS_FROM_ORGANIZATION_REQUEST_SUCCESSFUL,
  ADD_DOMAIN_TO_ORGANIZATION_REQUEST_SUCCESSFUL,
  ADD_DOMAIN_TO_ORGANIZATION_REQUEST_FAILED,
  ADD_DOMAIN_TO_ORGANIZATION_REQUESTED,
  GET_ORGANIZATIONS_WITH_CLIENTS_REQUESTED,
  GET_ORGANIZATIONS_WITH_CLIENTS_REQUEST_FAILED,
  REMOVE_DOMAIN_FROM_ORGANIZATION_REQUEST_SUCCESSFUL,
  UPDATE_ORGANIZATION_REQUESTED,
  UPDATE_ORGANIZATION_REQUEST_SUCCESSFUL,
  UPDATE_ORGANIZATION_REQUEST_FAILED
} from './OrganizationActions';
import {
  ADD_ORGANIZATIONS_TO_APPLICATION_REQUEST_SUCCESSFUL,
  REMOVE_ORGANIZATIONS_FROM_APPLICATION_REQUEST_SUCCESSFUL
} from '../Applications/ApplicationActions';
import { ById, Id } from '../../types/types';

export const initialState: OrganizationsState = {
  allIds: [],
  allOrganizationsLoaded: false,
  byId: {},
  isAssigning: false,
  isLoading: false
};

function organizations(state: OrganizationsState = initialState, action: AnyAction): OrganizationsState {
  let byId: ById<Organization>;
  let allIds: Id[];
  switch (action.type) {
    case GET_ORGANIZATIONS_REQUESTED:
      return {
        ...state,
        allOrganizationsLoaded: false,
        isLoading: true
      };

    case GET_ORGANIZATIONS_REQUEST_SUCCESSFUL:
      return {
        ...state,
        allIds: union(state.allIds.slice(), action.allIds),
        allOrganizationsLoaded: true,
        byId: merge({}, state.byId, action.byId),
        isLoading: false
      };

    case GET_ORGANIZATIONS_REQUEST_FAILED:
      return {
        ...state,
        allOrganizationsLoaded: false,
        isLoading: false,
      };

    case GET_ORGANIZATIONS_WITH_CLIENTS_REQUESTED:
      return {
        ...state,
        allOrganizationsLoaded: false,
        isLoading: true
      };

    case GET_ORGANIZATIONS_WITH_CLIENTS_REQUEST_FAILED:
      return {
        ...state,
        allOrganizationsLoaded: true,
        isLoading: false
      };

    case GET_ORGANIZATION_BY_CODE_REQUESTED:
      allIds = state.allIds.slice();
      byId = { ...state.byId };
      if (!allIds.includes(action.id)) {
        allIds = allIds.concat(action.id);
      }
      if (!byId[action.id]) {
        (byId[action.id] as Partial<Organization>) = {};
      }
      allIds.forEach((id) => {
        if (id !== action.id) {
          byId[id] = { ...state.byId[id] };
          return;
        }

        byId[id] = {
          ...state.byId[id],
          isLoading: true
        };
      });
      return {
        ...state,
        allIds,
        byId
      };

    case GET_ORGANIZATION_BY_CODE_REQUEST_SUCCESSFUL:
      byId = {};
      state.allIds.forEach((id) => {
        if (id !== action.id) {
          byId[id] = { ...state.byId[id] };
          return;
        }

        action.byId[id].isLoading = false;
        byId[id] = merge({}, state.byId[id], action.byId[id]);
      });
      return {
        ...state,
        byId
      };

    case GET_ORGANIZATION_BY_CODE_REQUEST_FAILED:
      byId = {};
      allIds = state.allIds.slice();
      state.allIds.forEach((id) => {
        if (id !== action.id) {
          byId[id] = { ...state.byId[id] };
          return;
        }

        byId[id] = { ...state.byId[id], isLoading: false };
      });
      if (!byId[action.id]?.id) {
        delete byId[action.id];
        allIds = without(state.allIds, action.id);
      }
      return {
        ...state,
        allIds,
        byId
      };

    case GET_APPLICATIONS_FOR_ORGANIZATION_BY_CODE_REQUESTED:
      allIds = state.allIds.slice();
      byId = { ...state.byId };
      if (!allIds.includes(action.id)) {
        allIds = allIds.concat(action.id);
      }
      if (!byId[action.id]) {
        (byId[action.id] as Partial<Organization>) = {};
      }
      allIds.forEach((id) => {
        if (id !== action.id) {
          byId[id] = { ...state.byId[id] };
          return;
        }

        byId[id] = {
          ...state.byId[id],
          applicationsLoading: true
        };
      });
      return {
        ...state,
        allIds,
        byId
      };

    case GET_APPLICATIONS_FOR_ORGANIZATION_BY_CODE_REQUEST_SUCCESSFUL:
      byId = {};
      state.allIds.forEach((id) => {
        if (id !== action.id) {
          byId[id] = { ...state.byId[id] };
          return;
        }

        byId[id] = {
          ...state.byId[id],
          applicationCodes: union(state.byId[id].applicationCodes, action.applicationCodes),
          applicationsLoaded: true,
          applicationsLoading: false,
        };
      });
      return {
        ...state,
        byId
      };

    case GET_APPLICATIONS_FOR_ORGANIZATION_BY_CODE_REQUEST_FAILED:
      byId = {};
      allIds = state.allIds.slice();
      state.allIds.forEach((id) => {
        if (id !== action.id) {
          byId[id] = { ...state.byId[id] };
          return;
        }

        byId[id] = {
          ...state.byId[id],
          applicationsLoaded: false,
          applicationsLoading: false
        };
      });
      if (!byId[action.id]?.id) {
        delete byId[action.id];
        allIds = without(state.allIds, action.id);
      }
      return {
        ...state,
        allIds,
        byId
      };

    case ADD_APPLICATIONS_TO_ORGANIZATION_REQUESTED:
      return {
        ...state,
        isAssigning: true
      };

    case ADD_APPLICATIONS_TO_ORGANIZATION_REQUEST_SUCCESSFUL:
      byId = { ...state.byId };
      byId[action.id] = {
        ...byId[action.id],
        applicationCodes: union(byId[action.id].applicationCodes, action.applicationCodes)
      };
      return {
        ...state,
        byId,
        isAssigning: false,
      };

    case ADD_APPLICATIONS_TO_ORGANIZATION_REQUEST_FAILED:
      return {
        ...state,
        isAssigning: false
      };

    case REMOVE_APPLICATIONS_FROM_ORGANIZATION_REQUEST_SUCCESSFUL:
      byId = { ...state.byId };
      byId[action.id] = {
        ...byId[action.id],
        applicationCodes: without(byId[action.id].applicationCodes, ...action.applicationCodes)
      };
      return {
        ...state,
        byId
      };

    case ADD_ORGANIZATIONS_TO_APPLICATION_REQUEST_SUCCESSFUL:
      allIds = state.allIds.slice();
      byId = { ...state.byId };
      allIds.forEach((id) => {
        if (action.organizationCodes.includes(id)) {
          byId[id] = {
            ...state.byId[id],
            applicationCodes: union(byId[id].applicationCodes, action.id)
          };
        } else {
          byId[id] = { ...state.byId[id] };
        }
      });
      return {
        ...state,
        byId
      };

    case REMOVE_ORGANIZATIONS_FROM_APPLICATION_REQUEST_SUCCESSFUL:
      allIds = state.allIds.slice();
      byId = { ...state.byId };
      allIds.forEach((id) => {
        if (action.organizationCodes.includes(id)) {
          byId[id] = {
            ...state.byId[id],
            applicationCodes: without(byId[id].applicationCodes, action.id)
          };
        } else {
          byId[id] = { ...state.byId[id] };
        }
      });
      return {
        ...state,
        byId
      };

    case ADD_DOMAIN_TO_ORGANIZATION_REQUESTED:
      return {
        ...state,
        isAssigning: true
      };
 
    case ADD_DOMAIN_TO_ORGANIZATION_REQUEST_SUCCESSFUL:
      byId = { ...state.byId };
      byId[action.id] = {
        ...byId[action.id],
        organizationDomains: union(byId[action.id].organizationDomains, [action.domain])
      };

      if ((byId[action.id].organizationDomains?.length) === 1) {
        byId[action.id] = {
          ...byId[action.id],
          strictMode: true
        };
      }

      return {
        ...state,
        isAssigning: false,
        byId
      };

    case ADD_DOMAIN_TO_ORGANIZATION_REQUEST_FAILED:
      return {
        ...state,
        isAssigning: false
      };

    case REMOVE_DOMAIN_FROM_ORGANIZATION_REQUEST_SUCCESSFUL:
      byId = { ...state.byId };
      byId[action.id] = {
        ...byId[action.id],
        organizationDomains: byId[action.id].organizationDomains?.filter(domain => domain.id !== action.domain.id)
      };

      if (!byId[action.id].organizationDomains?.length) {
        byId[action.id] = {
          ...byId[action.id],
          strictMode: false
        };
      }

      return {
        ...state,
        isAssigning: false,
        byId
      };

    case UPDATE_ORGANIZATION_REQUESTED:
      return {
        ...state,
        isAssigning: true
      };

    case UPDATE_ORGANIZATION_REQUEST_SUCCESSFUL:
      byId = {};
      state.allIds.forEach((id) => {
        if (id !== action.id) {
          byId[id] = { ...state.byId[id] };
          return;
        }

        byId[id] = merge({}, state.byId[id], action.byId[id]);
      });
      return {
        ...state,
        byId,
        isAssigning: false
      }; 

    case UPDATE_ORGANIZATION_REQUEST_FAILED:
   
      return {
        ...state,
        isAssigning: false
      };

    default:
      return state;
  }
};

export default organizations;
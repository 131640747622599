import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Box, Breadcrumbs, Button, Grid, Link, Typography } from '@mui/material';

import { UMAReduxState } from '../../../store/rootReducer';
import {
  getApplicationByCode,
  getOrganizationsForApplication,
  removeOrganizationsFromApplication,
  removePermissionsFromApplication,
  getAppAndOrgRoles
} from '../../../store/Applications/ApplicationActions';
import { Application } from '../../../store/Applications/ApplicationModels';
import { RolesState } from '../../../store/Roles/RoleModels';
import { OrganizationsState } from '../../../store/Organizations/OrganizationModels';
import ApplicationForm from './EditApplicationForm';
import AssignOrganizationsModal from '../../AssignModal/AssignModal';
import Can from '../../Can/Can';
import CreatePermissionModal from '../../CreatePermissionModal/CreatePermissionModal';
import OrganizationsList from '../../FilterList/FilterList';
import PermissionsList from '../../FilterList/FilterList';
import RolesList from '../../FilterList/FilterList';
import { Id } from '../../../types/types';
import useStyles from './styles';
import policyConstants from '../../../constants/policyConstants';
import { useCasbin } from '../../../hooks/useCasbin';
import If from '../../If/If';
import DeleteModal from '../../DeleteModal/DeleteModal';

type StateToProps = {
  application: Application;
  applicationCode: string;
  isAssigning: boolean;
  isLoading: boolean;
  isUpdating: boolean;
  isDeleting: boolean;
  organizationCodes: Id[]
  organizationState: OrganizationsState;
  permissions: string[];
  roleIds: Id[];
  roleState: RolesState;
};

type DispatchToProps = {
  getApplicationByCode: (code: string) => void;
  getOrganizationsForApplication: (code: string) => void;
  removeOrganizationsFromApplication: (code: string, organizationCodes: Id[]) => void;
  removePermissionsFromApplication: (code: string, permissions: string[]) => void;
  getAppAndOrgRoles: (applicationCode: string, organizationCode: string) => void;
};

type ApplicationDetailPageProps = StateToProps & DispatchToProps;

function ApplicationDetailPage({
  application,
  applicationCode,
  getApplicationByCode,
  getOrganizationsForApplication,
  getAppAndOrgRoles,
  isAssigning,
  isLoading,
  isUpdating,
  isDeleting,
  organizationCodes,
  organizationState,
  permissions,
  removeOrganizationsFromApplication,
  removePermissionsFromApplication,
  roleIds,
  roleState
}: ApplicationDetailPageProps) {
  const classes = useStyles();
  const history = useHistory();
  const organizationCode = useSelector((state: UMAReduxState) => state.context.organizationCode);

  const [assignOrganizationsModalOpen, setAssignOrganizationsModalOpen] = useState(false);
  const [createPermissionsModalOpen, setCreatePermissionsModalOpen] = useState(false);
  const [deleteApplicationModalOpen, setDeleteApplicationModalOpen] = useState(false);

  const handleDeleteApplicationClick = () => setDeleteApplicationModalOpen(true);
  const handleDeleteApplicationModalClose = () => setDeleteApplicationModalOpen(false);

  const canEditApplication = useCasbin(policyConstants.applications.update(applicationCode));

  useEffect(() => {
    getApplicationByCode(applicationCode);
    getOrganizationsForApplication(applicationCode);
    getAppAndOrgRoles(applicationCode, organizationCode);
  }, [
    applicationCode,
    getApplicationByCode,
    getOrganizationsForApplication,
    getAppAndOrgRoles, organizationCode
  ]);

  const handleBreadcrumbClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    history.push('/applications');
  };

  // Filter List Handlers
  const handleOrganizationDeleteClick = (orgCode: Id) => {
    removeOrganizationsFromApplication(
      applicationCode,
      [orgCode]
    );
  };

  const handlePermissionDeleteClick = (permission: Id) => {
    removePermissionsFromApplication(
      applicationCode,
      [permission.toString()]
    );
  };

  // Assign Organization Modal Handlers
  const handleAssignOrganizationsModalClose = () => setAssignOrganizationsModalOpen(false);
  const handleAssignOrganizationsModalOpen = () => setAssignOrganizationsModalOpen(true);

  // Create Permission Modal Handlers
  const handleCreatePermissionsModalClose = () => setCreatePermissionsModalOpen(false);
  const handleCreatePermissionsModalOpen = () => setCreatePermissionsModalOpen(true);

  const disableRemovingOrganization = organizationCodes.length <= 1;

  return (
    <>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Breadcrumbs className={classes.breadcrumbs} separator="›" aria-label="breadcrumb">
            <Link
              className={classes.baseBreadcrumb}
              color="inherit"
              href="/applications"
              onClick={handleBreadcrumbClick}
              variant="body2"
            >
              Applications
            </Link>
            <Typography variant="body2">Application Details</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <Can requiredPolicy={policyConstants.applications.delete(applicationCode)}>
            <Button
              color="error"
              size="large"
              onClick={handleDeleteApplicationClick}
              disabled={isLoading}
              variant="outlined"
            >
              Delete Application
            </Button>
          </Can>
        </Grid>
      </Grid>
      <Box mt={2} mb={1.5}>
        <Grid container style={{ paddingBottom: '12px' }}>
          <Grid item xs={12} md={6}>
            <If condition={canEditApplication}>
              <ApplicationForm
                application={application}
                applicationCode={applicationCode}
                isLoading={isLoading}
                isUpdating={isUpdating}
              />
            </If>
            <If condition={!canEditApplication}>
              <Typography variant="h3">{application?.name}</Typography>
              <Typography>{application?.code}</Typography>
            </If>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Can requiredPolicy={policyConstants.applications.getOrganizations(applicationCode)}>
            <Grid xs={12} md={6} item>
              <OrganizationsList
                parentResourceName="applications"
                addItemProps={{
                  handleAddClick: handleAssignOrganizationsModalOpen,
                  requiredPolicy: policyConstants.applications.addOrganization(applicationCode)
                }}
                removeItemProps={{
                  handleDeleteClick: handleOrganizationDeleteClick,
                  immutable: disableRemovingOrganization,
                  requiredPolicy: policyConstants.applications.removeOrganization(applicationCode)
                }}
                isLoading={isLoading}
                listResourceName="organizations"
                listResources={organizationCodes.map((id) => organizationState.byId[id]) ?? []}
              />
              {disableRemovingOrganization && !isLoading &&
                <Grid container item xs={12} justifyContent="flex-end" alignItems="center">
                  <Typography variant="body2" className={classes.breadcrumbs}>
                    An application must be tied to a minimum of one organization.
                  </Typography>
                </Grid>}
            </Grid>
          </Can>
          <Can requiredPolicy={policyConstants.applications.getRoles(applicationCode)}>
            <Grid xs={12} md={6} item>
              <RolesList
                parentResourceName="applications"
                addItemProps={{
                  handleAddClick: () => { },
                  immutable: true
                }}
                removeItemProps={{
                  handleDeleteClick: () => { },
                  immutable: true
                }}
                readItemProps={{
                  policyFunction: policyConstants.roles.getById,
                  policyFunctionParams: [organizationCode]
                }}
                isLoading={isLoading}
                listResourceName="roles"
                listResources={roleIds.map((id) => roleState.byId[id]) ?? []}
              />
            </Grid>
          </Can>
          <Grid xs={12} md={6} item>
            <PermissionsList
              parentResourceName="applications"
              addItemProps={{
                handleAddClick: handleCreatePermissionsModalOpen,
                requiredPolicy: policyConstants.applications.addPermission(applicationCode)
              }}
              removeItemProps={{
                handleDeleteClick: handlePermissionDeleteClick,
                requiredPolicy: policyConstants.applications.removePermission(applicationCode)
              }}
              isLoading={isLoading}
              listResourceName="permissions"
              listResources={permissions}
            />
          </Grid>
        </Grid>
      </Box>
      <AssignOrganizationsModal
        parentResourceLoading={isLoading}
        assignResourceName="organizations"
        parentResource={[application]}
        parentResourceName="applications"
        isAssigning={isAssigning}
        onClose={handleAssignOrganizationsModalClose}
        open={assignOrganizationsModalOpen}
      />
      <CreatePermissionModal
        application={application}
        applicationCode={applicationCode}
        isAssigning={isAssigning}
        isLoading={isLoading}
        permissions={permissions}
        open={createPermissionsModalOpen}
        onClose={handleCreatePermissionsModalClose}
      />
      <DeleteModal
        resourceName='applications'
        isDeleting={isDeleting}
        onClose={handleDeleteApplicationModalClose}
        onDelete={() => history.push('/applications')}
        open={deleteApplicationModalOpen}
        resources={[application]}
      />
    </>
  );
}

function mapStateToProps(state: UMAReduxState, ownProps: any): StateToProps {
  const code = ownProps.match.params.code;
  const application = state.applications.byId[code];
  return {
    application,
    applicationCode: code,
    isAssigning: state.applications.isAssigning,
    isLoading: (application?.isLoading ?? true)
      || (application?.organizationsLoading ?? true)
      || (application?.rolesLoading ?? true),
    isUpdating: (application?.isUpdating ?? false),
    isDeleting: state.applications.isDeleting,
    organizationCodes: state.applications.byId[code]?.organizationCodes ?? [],
    organizationState: state.organizations,
    permissions: application?.permissions ?? [],
    roleIds: state.applications.byId[code]?.roleIds ?? [],
    roleState: state.roles,
  };
}

const mapDispatchToProps: DispatchToProps = {
  getApplicationByCode,
  getOrganizationsForApplication,
  removeOrganizationsFromApplication,
  removePermissionsFromApplication,
  getAppAndOrgRoles
};

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationDetailPage);

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

const drawerWidth = 249;

const styles = makeStyles((theme: Theme) => ({
  drawer: {
    flexShrink: 0,
    justifyContent: 'flex-end',
    whiteSpace: 'nowrap',
    width: drawerWidth,
  },
  drawerClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.leavingScreen,
      easing: theme.transitions.easing.sharp,
    }),
    width: theme.spacing(8.75),
  },
  drawerOpen: {
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.enteringScreen,
      easing: theme.transitions.easing.sharp,
    }),
    width: drawerWidth,
  },
  grow: {
    flexGrow: 1
  },
  iconButton: {
    '&:hover': {
      background: 'transparent'
    },
    padding: theme.spacing(1),
    width: 64
  },
  shiftIcon: {
    left: 173
  },
  toggleButton: {
    height: 42,
    width: 42
  }
}));

export default styles;
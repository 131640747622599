import environment from '../environment';
import { Id } from '../types/types';
import { Client } from '../store/Clients/ClientModels';
import {
  handleErrors,
  retrieveAccessToken
} from '../utility';

const { apiBasePath } = environment;

export function getClients(organizationCode: Id): Promise<Client[]> {
  return fetch(`${apiBasePath}/organization/${organizationCode}/client`, {
    headers: {
      'Authorization': `Bearer ${retrieveAccessToken()}`
    }
  }).then(handleErrors).then((response) => response.json() as Promise<Client[]>);
}
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemText,
  Skeleton,
  TextField,
  Typography
} from '@mui/material';

import InputSkeleton from '../InputSkeleton/InputSkeleton';
import SearchInput from '../SearchInput/SearchInput';
import { addPermissionsToApplication } from '../../store/Applications/ApplicationActions';
import {
  Application,
  CreatePermissionFormValues,
  Permission
} from '../../store/Applications/ApplicationModels';
import {
  normalizeStrings
} from '../../utility';
import useStyles from './styles';

const FORM_ID = 'create-application-permission-form';

interface DispatchFromProps {
  addPermissionsToApplication: (code: string, permissions: string[], callbacksOnSuccess: () => void) => void;
}

interface CreatePermissionModalProps {
  application: Application;
  applicationCode: string;
  permissions: Permission[];
  isLoading: boolean;
  isAssigning: boolean;
  onClose: () => void;
  open?: boolean;
}

type Props = DispatchFromProps & CreatePermissionModalProps;

function CreatePermissionModal({
  addPermissionsToApplication,
  application,
  applicationCode,
  isAssigning,
  isLoading,
  onClose,
  open = false,
  permissions
}: Props) {
  const classes = useStyles();
  const { control, formState: { isValid, isDirty }, handleSubmit, reset } = useForm<CreatePermissionFormValues>({
    mode: 'onChange',
  });

  const [filteredResources, setFilteredResources] = useState<Permission[]>([]);
  const [filterText, setFilterText] = useState<string>('');

  /* Filter logic */
  useEffect(() => {
    if (filterText.length > 0) {
      setFilteredResources((permissions).filter((permission) => permission.includes(filterText)));
    } else {
      setFilteredResources(permissions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissions, filterText]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => setFilterText(normalizeStrings(event.target.value));

  const handleCloseModal = () => {
    setFilterText('');
    reset({ permission: '' });
    onClose();
  };

  const onSubmit = (values: CreatePermissionFormValues) => {
    if (!isDirty) return;

    const newPermissions = values.permission ? [values.permission?.toLowerCase()] : [];
    addPermissionsToApplication(applicationCode, newPermissions, () => {
      reset({ permission: '' });
      onClose();
    });
  };

  const renderResults = () => {
    // Loading state
    if (filteredResources.length === 0 && filterText === '' && isLoading) {
      return (
        <>
          <ListItem disableGutters className={classes.listItem}>
            <Skeleton role="loading-skeleton" width={500} height={60} animation="wave" />
          </ListItem>
          <ListItem disableGutters className={classes.listItem}>
            <Skeleton role="loading-skeleton" width={500} height={60} animation="wave" />
          </ListItem>
          <ListItem disableGutters className={classes.listItem}>
            <Skeleton role="loading-skeleton" width={500} height={60} animation="wave" />
          </ListItem>
        </>
      );

      // Zero results in redux state
    } else if (filteredResources.length === 0 && filterText === '' && !isLoading) {
      return (
        <ListItem disableGutters className={classes.noResults}>
          <Typography role="message" noWrap>There are currently no permissions assigned.</Typography>
        </ListItem>
      );

      // No results from search filter state
    } else if (filteredResources.length === 0 && filterText !== '') {
      return (
        <ListItem disableGutters className={classes.noResults}>
          <Typography role="message" noWrap>{`No permissions matching "${filterText}"`}</Typography>
        </ListItem>
      );

      // Show assignable options
    } else {
      return (
        (filteredResources as Permission[]).map((permission) => {
          return (
            <ListItem
              aria-label={permission}
              role="permission-list-item"
              button
              className={classes.listItem}
              disableGutters
              key={permission}
            >
              <ListItemText
                primary={permission}
                primaryTypographyProps={{
                  noWrap: true
                }}
              />
            </ListItem>
          );
        })
      );
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleCloseModal}
      aria-label="assign-permission-to-application-modal"
      classes={{
        paper: classes.dialogPaper
      }}
    >
      <DialogTitle className={classes.titleContainer}>
        <Typography aria-label="permission-list-title" sx={{ fontSize: 20, fontWeight: 600 }}>Assign Permissions</Typography>
        {
          isLoading ? <Skeleton aria-label="subtitle-loading-skeleton" role="loading-skeleton" width={300} height={30} /> : (
            <Typography aria-label="permission-list-subtitle" variant="body2" className={classes.subtitle}>
              To {application.name}
            </Typography>
          )
        }
      </DialogTitle>

      <DialogContent dividers className={classes.contentContainer}>
        <Box px={5}>
          <SearchInput
            onChange={handleChange}
            onReset={() => setFilterText('')}
          />
        </Box>

        {/*
					Render various ListItem displays dependent on state
					*/}
        <List>
          {renderResults()}
        </List>
      </DialogContent>

      <DialogActions classes={{ root: classes.actionsContainer }}>
        <form onSubmit={handleSubmit(onSubmit)} id={FORM_ID}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name="permission"
                control={control}
                defaultValue=""
                rules={{
                  minLength: { message: 'Permission should be 2 or more characters.', value: 2 },
                  required: { message: 'Permission is required.', value: true },
                  validate: {
                    alreadyExists: (v) => !permissions.includes(v?.toLowerCase() ?? '') || 'Permission already exists.'
                  }
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    label="Permission"
                    autoFocus
                    fullWidth
                    FormHelperTextProps={{
                      'aria-label': 'application-permission-helper-text'
                    }}
                    inputProps={{
                      'aria-label': 'application-permission-input',
                    }}
                    InputProps={{
                      inputComponent: isLoading ? InputSkeleton : 'input'
                    }}
                    error={Boolean(error)}
                    helperText={error?.message ?? ''}
                  />
                )}
              />
            </Grid>
            <Grid container item xs={12} justifyContent="flex-end">
              <Button onClick={handleCloseModal} color="primary" variant="outlined">
                {isDirty ? 'Cancel' : 'Close'}
              </Button>
              <Button
                aria-label="assign-permission-button"
                disabled={isLoading || isAssigning || !isDirty || !isValid}
                color="primary"
                variant="contained"
                type="submit"
                size="large"
                form={FORM_ID}
                sx={{ ml: 1 }}
              >
                {isAssigning ? 'Assigning' : 'Assign'}
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogActions>
    </Dialog>
  );
}

const mapDispatchToProps: DispatchFromProps = {
  addPermissionsToApplication
};

export default connect(
  null, mapDispatchToProps
)(CreatePermissionModal);
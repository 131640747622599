import { Client } from '../store/Clients/ClientModels';
import { Group } from '../store/Groups/GroupModels';
import { Application, Permission } from '../store/Applications/ApplicationModels';
import { Organization, OrganizationDomain } from '../store/Organizations/OrganizationModels';
import { Role } from '../store/Roles/RoleModels';
import { User } from '../store/Users/UserModels';
import {
  Id,
  ResourceNames,
  Resource
} from '../types/types';
import { FederatedGroupMapping } from '../store/FederatedGroupMappings/FederatedGroupMappingModels';

/**
 * Generates a uuid
 *
 * ONLY used to create a key in findOrCreateKey if a resource type is not
 * passed. Key is not used for any other purpose other than for a key when mapping.
 *
 * @return {String} - a unique id
 */
const generateUUID = (): string => {
  let d = new Date().getTime();

  if (window.performance && typeof window.performance.now === 'function') {
    d += performance.now();
  }

  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === 'x' ? r : ((r & 0x3) | 0x8)).toString(16);
  });
};

/**
 * Find and returns key based on resource, or returns a unique Id used as a key
 * @param {User | Client | Group | Role | Organization | Application} resource - Resource object
 * @param {String} type - Resource type
 * @return {String} - a unique id used as a key
 */
export default function findOrCreateKey(
  resource: Resource,
  type: ResourceNames
): Id {
  switch (type) {
    case 'applications':
      return (resource as Application).code;

    case 'clients':
      return (resource as Client).id;

    case 'groups':
      return (resource as Group).id;

    case 'organizations':
      return (resource as Organization).code;

    case 'permissions':
      return (resource as Permission);

    case 'roles':
      return (resource as Role).id;

    case 'users':
      return (resource as User).id;

    case 'federated group mappings':
      return (resource as FederatedGroupMapping).id;

    case 'domains':
      return (resource as OrganizationDomain).name;

    default:
      return generateUUID();
  }
};
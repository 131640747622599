import React, { FocusEvent, useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import {
  ClickAwayListener,
  Grid,
  IconButton,
  TextField,
  Tooltip,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/ClearRounded';
import DoneIcon from '@mui/icons-material/Done';

import { Group, GroupFormValues, groupFormSchema } from '../../../store/Groups/GroupModels';
import {
  updateGroup
} from '../../../store/Groups/GroupActions';
import InputSkeleton from '../../InputSkeleton/InputSkeleton';
import useStyles from './styles';

const FORM_ID = 'edit-group-form';

type DispatchToProps = {
	updateGroup: (organizationCode: string, group: Partial<Group>, callbacksOnSuccess: () => void) => void
};

interface GroupFormProps {
	group: Group;
	isLoading: boolean;
	organizationCode: string;
};

type Props = GroupFormProps & DispatchToProps;

function GroupForm({
  group,
  isLoading,
  organizationCode,
  updateGroup,
}: Props) {
  const classes = useStyles();
  const { id, name, isUpdating } = group;
  const { control, formState: { isDirty, isValid }, handleSubmit, reset, setValue } = useForm<GroupFormValues>({
    defaultValues: useMemo(() => {
      return { name };
    }, [name]),
    mode: 'onChange',
    resolver: yupResolver(groupFormSchema)
  });

  const [editing, setEditing] = useState<boolean>(false);

  // Ensures the initial value gets set once the input renders
  useEffect(() => {
    reset({ name });
  }, [name, reset]);

  const onGroupNameTextFieldFocus = (event: FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setEditing(true);
  };

  const onSubmit = (values: GroupFormValues) => {
    if (!isDirty) return;

    const updatePayload: Partial<Group> = {
      id,
      name: values.name
    };
    updateGroup(organizationCode, updatePayload, () => setEditing(false));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} id={FORM_ID}>
      <ClickAwayListener
        mouseEvent="onMouseDown"
        touchEvent="onTouchStart"
        onClickAway={() => {
          setValue('name', name, { shouldDirty: true, shouldValidate: true });
          setEditing(false);
        }}
      >
        <Grid container item xs={6} direction="column">
          <Controller
            name="name"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                placeholder="Enter group name"
                onFocus={onGroupNameTextFieldFocus}
                required
                inputProps={{
                  'aria-label': 'group-name-input',
                  className: classes.input
                }}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                    root: clsx(classes.inputField, classes.nameField)
                  },
                  inputComponent: (isLoading || isUpdating) ? InputSkeleton : 'input'
                }}
                fullWidth
                error={Boolean(error)}
                helperText={error?.message ?? ''}
              />
            )}
          />
          <Grid xs={12} item>
            <Grid
              container
              justifyContent="flex-end"
              spacing={1}
              className={clsx(classes.editControls, { [classes.editControlsHidden]: !editing })}
            >
              <Grid item>
                <Tooltip title="Save">
                  <span>
                    <IconButton
                      aria-label="input-submit-button"
                      disabled={!isDirty || !isValid || isLoading || isUpdating}
                      className={classes.iconButton}
                      data-edit-control
                      type="submit"
                      form={FORM_ID}
                      size="large">
                      <DoneIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title="Exit">
                  <IconButton
                    aria-label="input-clear-button"
                    className={classes.iconButton}
                    data-edit-control
                    onClick={() => {
                      setValue('name', name, { shouldDirty: true, shouldValidate: true });
                      setEditing(false);
                    }}
                    size="large">
                    <ClearIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ClickAwayListener>
    </form>
  );
}

const mapDispatchToProps: DispatchToProps = {
  updateGroup
};

export default connect(null, mapDispatchToProps)(GroupForm);

import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import {
  AppBar,
  Menu,
  MenuItem,
  Skeleton,
  Toolbar
} from '@mui/material';

import Logout from '../Logout/Logout';
import SwitchContext from '../SwitchContext/SwitchContext';
import UserAvatar from '../UserAvatar/UserAvatar';
import { UMAReduxState } from '../../store/rootReducer';
import useStyles from './styles';

const Header = () => {
  const classes = useStyles();
  const history = useHistory();
  const isLoading = useSelector((state: UMAReduxState) => state.my.profile.isLoading);
  const firstName = useSelector((state: UMAReduxState) => state.my.profile.firstName);
  const lastName = useSelector((state: UMAReduxState) => state.my.profile.lastName);
  const [anchorElProfile, setAnchorElProfile] = useState<null | HTMLElement>(null);

  const handleProfileMenu = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setAnchorElProfile(event.currentTarget);
  };

  const handleProfileClick = () => {
    history.push('/profile');
    handleClose();
  };

  const handleClose = () => {
    setAnchorElProfile(null);
  };

  const profileMenuOpen = Boolean(anchorElProfile);
  return (
    <AppBar
      position="fixed"
      className={classes.appBar}
    >
      <Toolbar className={classes.toolbar}>
        <div className={classes.grow} />
        <SwitchContext />
        {
          isLoading ?
            <Skeleton variant="circular" width={36} height={36} className={classes.avatarSkeleton} />
            : <UserAvatar onClick={handleProfileMenu} className={classes.avatar} user={{ firstName, lastName }} disableBadge>AC</UserAvatar>
        }
        <Menu
          id="menu-appbar"
          anchorEl={anchorElProfile}
          className={classes.profileMenu}
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'bottom',
          }}
          transformOrigin={{
            horizontal: 'right',
            vertical: 'top',
          }}
          open={profileMenuOpen}
          onClose={handleClose}
        >
          <div>
            <MenuItem onClick={handleProfileClick}>Profile</MenuItem>
            <Logout />
          </div>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
import { showError } from '../../utility';
import { AppThunkAction } from '../rootReducer';
import {
  CreateFederatedGroupMappingAction,
  DeleteFederatedGroupMappingAction,
  FederatedGroupMapping,
  GetFederatedGroupMappingsByGroupAction,
  UpdateFederatedGroupMappingAction
} from './FederatedGroupMappingModels';
import { federatedGroupMappingApi } from '../../api';
import { Id } from '../../types/types';

// =========================
// Actions
// =========================
export const GET_FEDERATED_GROUP_MAPPINGS_BY_GROUP_REQUESTED: string = 'GET_FEDERATED_GROUP_MAPPINGS_BY_GROUP_REQUESTED';
export const GET_FEDERATED_GROUP_MAPPINGS_BY_GROUP_SUCCESS: string = 'GET_FEDERATED_GROUP_MAPPINGS_BY_GROUP_SUCCESS';
export const GET_FEDERATED_GROUP_MAPPINGS_BY_GROUP_FAILURE: string = 'GET_FEDERATED_GROUP_MAPPINGS_BY_GROUP_FAILURE';

export const CREATE_FEDERATED_GROUP_MAPPING_REQUESTED: string = 'CREATE_FEDERATED_GROUP_MAPPING_REQUESTED';
export const CREATE_FEDERATED_GROUP_MAPPING_SUCCESS: string = 'CREATE_FEDERATED_GROUP_MAPPING_SUCCESS';
export const CREATE_FEDERATED_GROUP_MAPPING_FAILURE: string = 'CREATE_FEDERATED_GROUP_MAPPING_FAILURE';

export const UPDATE_FEDERATED_GROUP_MAPPING_REQUESTED: string = 'UPDATE_FEDERATED_GROUP_MAPPING_REQUESTED';
export const UPDATE_FEDERATED_GROUP_MAPPING_SUCCESS: string = 'UPDATE_FEDERATED_GROUP_MAPPING_SUCCESS';
export const UPDATE_FEDERATED_GROUP_MAPPING_FAILURE: string = 'UPDATE_FEDERATED_GROUP_MAPPING_FAILURE';

export const DELETE_FEDERATED_GROUP_MAPPING_REQUESTED: string = 'DELETE_FEDERATED_GROUP_MAPPING_REQUESTED';
export const DELETE_FEDERATED_GROUP_MAPPING_SUCCESS: string = 'DELETE_FEDERATED_GROUP_MAPPING_SUCCESS';
export const DELETE_FEDERATED_GROUP_MAPPING_FAILURE: string = 'DELETE_FEDERATED_GROUP_MAPPING_FAILURE';

// =========================
// Thunks
// =========================
export function getFederatedGroupMappingsByGroup(organizationCode: string, groupId: Id): AppThunkAction<GetFederatedGroupMappingsByGroupAction> {
  return async (dispatch) => {
    dispatch({ type: GET_FEDERATED_GROUP_MAPPINGS_BY_GROUP_REQUESTED, groupId });
    try {
      const mappings = await federatedGroupMappingApi.getFederatedGroupMappingsByGroup(organizationCode, groupId);
      dispatch({ type: GET_FEDERATED_GROUP_MAPPINGS_BY_GROUP_SUCCESS, mappings, groupId });
    } catch (err) {
      showError(err, dispatch);
      dispatch({ type: GET_FEDERATED_GROUP_MAPPINGS_BY_GROUP_FAILURE, groupId });
    }
  };
}

export function createFederatedGroupMapping(
  organizationCode: string,
  groupId: Id,
  federatedGroup: string
): AppThunkAction<CreateFederatedGroupMappingAction> {
  return async (dispatch) => {
    dispatch({ type: CREATE_FEDERATED_GROUP_MAPPING_REQUESTED });
    try {
      const mapping = await federatedGroupMappingApi.createFederatedGroupMapping(organizationCode, groupId, federatedGroup);
      dispatch({ type: CREATE_FEDERATED_GROUP_MAPPING_SUCCESS, mapping });
    } catch (err) {
      showError(err, dispatch);
      dispatch({ type: CREATE_FEDERATED_GROUP_MAPPING_FAILURE });
    }
  };
}

export function updateFederatedGroupMapping(
  organizationCode: string,
  mapping: FederatedGroupMapping
): AppThunkAction<UpdateFederatedGroupMappingAction> {
  return async (dispatch) => {
    dispatch({ type: UPDATE_FEDERATED_GROUP_MAPPING_REQUESTED });
    try {
      const updatedMapping = await federatedGroupMappingApi.updateFederatedGroupMapping(organizationCode, mapping);
      dispatch({ type: UPDATE_FEDERATED_GROUP_MAPPING_SUCCESS, updatedMapping });
    } catch (err) {
      showError(err, dispatch);
      dispatch({ type: UPDATE_FEDERATED_GROUP_MAPPING_FAILURE });
    }
  };
}

export function deleteFederatedGroupMapping(
  organizationCode: string,
  groupId: Id,
  id: Id
): AppThunkAction<DeleteFederatedGroupMappingAction> {
  return async (dispatch) => {
    dispatch({ type: DELETE_FEDERATED_GROUP_MAPPING_REQUESTED });
    try {
      await federatedGroupMappingApi.deleteFederatedGroupMapping(organizationCode, groupId, id);
      dispatch({ type: DELETE_FEDERATED_GROUP_MAPPING_SUCCESS, id });
    } catch (err) {
      showError(err, dispatch);
      dispatch({ type: DELETE_FEDERATED_GROUP_MAPPING_FAILURE });
    }
  };
}
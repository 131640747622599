/**
 * Check for and return OKTA access token or an empty string
 * @return {string} - OKTA access token or an empty string
 */
export default function retrieveAccessToken(): string {
  const oktaTokenStorage = localStorage.getItem('okta-token-storage');
  // Check if okta token storage exists and isn't empty
  if (oktaTokenStorage && Object.keys(oktaTokenStorage).length !== 0) {
    return JSON.parse(oktaTokenStorage).accessToken.accessToken;
  }
  return '';
}
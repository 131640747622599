import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from '@mui/material';

import { Role } from '../../store/Roles/RoleModels';
import { Id } from '../../types/types';
import { updatePolicyRule } from '../../store/PolicyRules/PolicyRuleActions';
import { policyRulesFormSchema, PolicyRuleFormValues, PolicyRule } from '../../store/PolicyRules/PolicyRuleModels';
import { UMAReduxState } from '../../store/rootReducer';
import PolicyRuleFields from './PolicyRuleFields';
import useStyles from './styles';

const FORM_ID = 'edit-policy-rule-form';

type EditPolicyRuleModalProps = {
  onClose: () => void;
  open?: boolean;
  policyRuleId: Id;
  role: Role;
}

function EditPolicyRuleModal({
  onClose,
  open = true,
  policyRuleId,
  role
}: EditPolicyRuleModalProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const organizationCode = useSelector((state: UMAReduxState) => state.context.organizationCode);
  const policyRule = useSelector((state: UMAReduxState) => state.policyRules.byId[policyRuleId]);
  const isLoading = useSelector((state: UMAReduxState) => state.policyRules.isLoading);
  const isUpdating = useSelector((state: UMAReduxState) => state.policyRules.isUpdating);
  const methods = useForm<PolicyRuleFormValues>({
    defaultValues: useMemo(() => {
      return {
        applicationCode: policyRule?.applicationCode,
        clientCode: policyRule?.clientCode,
        tag: policyRule?.tag,
        permission: policyRule?.permission,
        effect: policyRule?.effect
      };
    }, [policyRule]),
    mode: 'onChange',
    resolver: yupResolver(policyRulesFormSchema)
  });
  const { formState: { isDirty, isValid }, handleSubmit, reset } = methods;

  // Ensures the initial value gets set once the input renders
  useEffect(() => {
    reset({
      applicationCode: policyRule?.applicationCode,
      clientCode: policyRule?.clientCode,
      tag: policyRule?.tag,
      permission: policyRule?.permission,
      effect: policyRule?.effect
    });
  }, [policyRule, reset]);

  const handleCloseModal = () => {
    onClose();
  };

  const onSubmit = (values: PolicyRuleFormValues) => {
    if (!isDirty) return;

    const updatePayload: Partial<PolicyRule> = {
      applicationCode: values.applicationCode,
      clientCode: values.clientCode,
      organizationCode,
      tag: values.tag,
      permission: values.permission?.toLowerCase(),
      id: policyRuleId,
      roleId: role.id,
      effect: values.effect
    };

    dispatch(updatePolicyRule(updatePayload, () => {
      onClose();
    }));
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="edit-policy-rule-modal"
      classes={{
        paper: classes.dialogPaper
      }}
    >
      <DialogTitle id="edit-policy-rule-modal" classes={{ root: classes.titleContainer }}>
        <Typography variant="h6">Edit Policy Rule</Typography>
        <Typography variant="body2" className={classes.subtitle}>
          For {role.name}
        </Typography>
      </DialogTitle>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} id={FORM_ID}>
          <DialogContent dividers classes={{ root: classes.contentContainer }}>
            <Box marginBottom={8}>
              <PolicyRuleFields isLoading={isLoading} />
            </Box>
          </DialogContent>
          <DialogActions className={classes.actionsContainer}>
            <Button onClick={handleCloseModal} color="primary" variant="outlined">
              Cancel
            </Button>
            <Button
              disabled={isLoading || isUpdating || !isDirty || !isValid}
              color="primary"
              variant="contained"
              type="submit"
              form={FORM_ID}
            >
              {isUpdating ? 'Saving' : 'Save'}
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
}

export default EditPolicyRuleModal;

import { ResourceNames } from '../types/types';

/**
 * Singularize one of the resources defined in global types
 * @param {String} resource - A string
 * @return {String} - a singular resource string
 */
export default function singularizeResource(resource: ResourceNames): string {
  switch (resource) {
    case 'applications':
      return 'application';

    case 'clients':
      return 'client';

    case 'context':
      return 'context';

    case 'errors':
      return 'error';

    case 'groups':
      return 'group';

    case 'organizations':
      return 'organization';

    case 'permissions':
      return 'permission';

    case 'roles':
      return 'role';

    case 'users':
      return 'user';

    case 'federated group mappings':
      return 'federated group mapping';

    default:
      return '';
  }
};
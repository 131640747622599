import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from '@mui/material';

import { Role } from '../../store/Roles/RoleModels';
import { createPolicyRule } from '../../store/PolicyRules/PolicyRuleActions';
import { WILDCARD } from '../../store/Clients/ClientModels';
import { policyRulesFormSchema, PolicyRuleFormValues, PolicyRule } from '../../store/PolicyRules/PolicyRuleModels';
import { UMAReduxState } from '../../store/rootReducer';
import PolicyRuleFields from './PolicyRuleFields';
import useStyles from './styles';

const FORM_ID = 'create-policy-rule-form';

type CreatePolicyRuleModalProps = {
  onClose: () => void;
  open?: boolean;
  role: Role;
}

const selectInitialApplicationValue = ({ applications, context, organizations }: UMAReduxState) => {
  const { organizationCode } = context;
  const applicationCodes = organizations.byId[organizationCode]?.applicationCodes ?? [];
  return applicationCodes.length === 1 ? applications.byId[applicationCodes[0]].code : undefined;
};

function CreatePolicyRuleModal({
  onClose,
  open = true,
  role
}: CreatePolicyRuleModalProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const organizationCode = useSelector((state: UMAReduxState) => state.context.organizationCode);
  const isCreating = useSelector((state: UMAReduxState) => state.policyRules.isCreating);
  const initialClientValue = WILDCARD;
  const initialApplicationValue = useSelector(selectInitialApplicationValue);

  const methods = useForm<PolicyRuleFormValues>({
    defaultValues: useMemo(() => {
      return {
        applicationCode: initialApplicationValue,
        clientCode: initialClientValue,
        tag: '',
        permission: '',
        effect: 'allow'
      };
    }, [initialApplicationValue, initialClientValue]),
    mode: 'onChange',
    resolver: yupResolver(policyRulesFormSchema)
  });
  const { formState: { isDirty, isValid }, handleSubmit, reset } = methods;

  // Ensures the initial value gets set once the input renders
  useEffect(() => {
    reset({
      applicationCode: initialApplicationValue,
      clientCode: initialClientValue,
      tag: '',
      permission: '',
      effect: 'allow'
    });
  }, [initialApplicationValue, initialClientValue, reset]);

  const handleCloseModal = () => {
    reset({ applicationCode: initialApplicationValue, clientCode: initialClientValue, tag: '', permission: '' });
    onClose();
  };


  const onSubmit = (values: PolicyRuleFormValues) => {
    if (!isDirty) return;

    const createPayload: PolicyRule = {
      applicationCode: values.applicationCode,
      clientCode: values.clientCode,
      organizationCode,
      tag: values.tag,
      permission: values.permission,
      roleId: role.id,
      effect: values.effect
    };

    dispatch(createPolicyRule(createPayload, () => onClose()));
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="create-policy-rule-modal"
      classes={{
        paper: classes.dialogPaper
      }}
    >
      <DialogTitle
        classes={{ root: classes.titleContainer }}>
        <Typography variant="h6" component="div" aria-label="title">Create Policy Rule</Typography>
        <Typography variant="body2" className={classes.subtitle} aria-label="subtitle">
          For {role?.name}
        </Typography>
      </DialogTitle>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} id={FORM_ID}>
          <DialogContent dividers classes={{ root: classes.contentContainer }}>
            <Box marginBottom={8}>
              <PolicyRuleFields />
            </Box>
          </DialogContent>
          <DialogActions className={classes.actionsContainer}>
            <Button onClick={handleCloseModal} color="primary" variant="outlined">
              {isDirty ? 'Cancel' : 'Close'}
            </Button>
            <Button
              aria-label="create-button"
              disabled={isCreating || !isDirty || !isValid}
              color="primary"
              variant="contained"
              type="submit"
              form={FORM_ID}
              sx={{ ml: 1 }}
            >
              {isCreating ? 'Creating' : 'Create'}
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
}

export default CreatePolicyRuleModal;

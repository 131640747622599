import environment from '../environment';
import { Id } from '../types/types';
import { Organization, OrganizationDomain } from '../store/Organizations/OrganizationModels';
import {
  handleErrors,
  retrieveAccessToken
} from '../utility';
import { Application } from '../store/Applications/ApplicationModels';

const { apiBasePath } = environment;

export function getAll(): Promise<Organization[]> {
  return fetch(`${apiBasePath}/organization`, {
    headers: {
      'Authorization': `Bearer ${retrieveAccessToken()}`
    }
  }).then(handleErrors).then((response) => response.json() as Promise<Organization[]>);
}

export function getAllWithClients(): Promise<Organization[]> {
  return fetch(`${apiBasePath}/organization/client`, {
    headers: {
      'Authorization': `Bearer ${retrieveAccessToken()}`
    }
  }).then(handleErrors).then((response) => response.json() as Promise<Organization[]>);
}

export function getByCode(organizationCode: Id): Promise<Organization> {
  return fetch(`${apiBasePath}/organization/${organizationCode}`, {
    headers: {
      'Authorization': `Bearer ${retrieveAccessToken()}`
    }
  }).then(handleErrors).then((response) => response.json() as Promise<Organization>);
}

export function getByCodeWithDomains(organizationCode: Id): Promise<Organization> {
  return fetch(`${apiBasePath}/organization/${organizationCode}/domain`, {
    headers: {
      'Authorization': `Bearer ${retrieveAccessToken()}`
    }
  }).then(handleErrors).then((response) => response.json() as Promise<Organization>);
}

export function getApplications(organizationCode: Id): Promise<Application[]> {
  return fetch(`${apiBasePath}/organization/${organizationCode}/applications`, {
    headers: {
      'Authorization': `Bearer ${retrieveAccessToken()}`,
    },
  }).then(handleErrors).then((response) => response.json());
}

export function addApplications(organizationCode: Id, applicationCodes: Id[]) {
  return fetch(`${apiBasePath}/organization/${organizationCode}/applications`, {
    body: JSON.stringify(applicationCodes),
    headers: {
      'Authorization': `Bearer ${retrieveAccessToken()}`,
      'Content-Type': 'application/json'
    },
    method: 'PUT'
  }).then(handleErrors).then((response) => response);
}

export function removeApplications(organizationCode: Id, applicationCodes: Id[]) {
  return fetch(`${apiBasePath}/organization/${organizationCode}/applications`, {
    body: JSON.stringify(applicationCodes),
    headers: {
      'Authorization': `Bearer ${retrieveAccessToken()}`,
      'Content-Type': 'application/json'
    },
    method: 'DELETE'
  }).then(handleErrors).then((response) => response);
}

export function addDomain(organizationCode: Id, domain: Partial<OrganizationDomain>): Promise<OrganizationDomain> {
  return fetch(`${apiBasePath}/organization/${organizationCode}/domain`, {
    body: JSON.stringify(domain),
    headers: {
      'Authorization': `Bearer ${retrieveAccessToken()}`,
      'Content-Type': 'application/json'
    },
    method: 'POST'
  }).then(handleErrors).then((response) => response.json() as Promise<OrganizationDomain>);
}

export function removeDomain(organizationCode: Id, domainId: Id): Promise<any> {
  return fetch(`${apiBasePath}/organization/${organizationCode}/domain/${domainId}`, {
    headers: {
      'Authorization': `Bearer ${retrieveAccessToken()}`,
      'Content-Type': 'application/json'
    },
    method: 'DELETE'
  }).then(handleErrors).then((response) => response);
}

export function updateStrictMode(organizationCode: string, organization: Partial<Organization>) {
  return fetch(`${apiBasePath}/organization/${organizationCode}/strict-mode`, {
    body: JSON.stringify(organization),
    headers: {
      'Authorization': `Bearer ${retrieveAccessToken()}`,
      'Content-Type': 'application/json'
    },
    method: 'PUT',
  }).then(handleErrors).then((response) => response.json());
}
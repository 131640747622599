/* eslint-disable sort-keys */
import { createTheme } from '@mui/material/styles';

let theme = createTheme({
  typography: {
    fontFamily: '\'Roboto\', sans-serif',
    h3: {
      color: '#323232',
      fontSize: 30,
      fontWeight: 600,
      letterSpacing: 0
    },
    h6: {
      color: '#323232',
      fontSize: 20,
      fontWeight: 600,
      letterSpacing: 0
    },
    body1: {
      color: '#323232',
      fontSize: 15,
      letterSpacing: 0
    }
  },
  palette: {
    mode: 'light',
    grey: {
      50: '#F8F8F8',
      100: '#F0F0F0',
      200: '#EBEBEB',
      300: '#DCDCDC',
      400: '#D2D2D2',
      500: '#BEBEBE',
      600: '#A0A0A0',
      700: '#555555',
      800: '#323232',
      900: '#232323'
    },
    green: {
      50: '#eeffde',
      100: '#e3ffc7',
      200: '#d1ffa2',
      300: '#81af52',
      // Hover Green
      400: '#4E7D20',
      // Button Green
      500: '#518221'
    },
    pink: '#F8D3D1',
    yellow: {
      // Light Yellow
      300: '#FFF593',
      // Yellow
      500: '#FFCC33',
      // Mustard
      700: '#F5C431'
    },
    primary: {
      // Baby Blue
      light: '#7DC9F0',
      // Cobalt
      main: '#0053A5',
      // Brand Blue
      dark: '#013A6F'
    },
    error: {
      // Error Red
      main: '#DE2E21',
      // Deep Red
      dark: '#A82823'
    },
    overlay: {
      modalStandard: 'rgba(45, 55, 62, 0.8)',
      modalHover: 'rgba(44, 62, 77, 0.8)'
    },
    text: {
      primary: '#323232',
      secondary: 'rgba(0, 0, 0, 0.54)',
      disabled: 'rgba(0, 0, 0, 0.26)'
    },
    background: {
      default: '#FFF',
      paper: '#FFF'
    },
    action: {
      hover: 'rgba(0, 0, 0, 0.05)',
      hoverOpacity: .2,
      selectedOpacity: .4,
      disabled: 'rgba(0, 0, 0, 0.34)',
      disabledBackground: 'rgba(0, 0, 0, 0.06)',
      disabledOpacity: .05
    },
  },
  shape: {
    borderRadius: 4
  }
});

theme = createTheme(theme, {
  components: {
    MuiAvatar: {
      styleOverrides: {
        colorDefault: {
          backgroundColor: '#7D8AF0'
        }
      }
    },
    MuiAvatarGroup: {
      styleOverrides: {
        avatar: {
          fontSize: 14,
          height: 32,
          width: 32,
        }
      }
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true
      },
      styleOverrides: {
        root: {
          fontFamily: '\'Saira\', sans-serif',
          fontSize: 15,
          fontWeight: 600,
          letterSpacing: 0.75,
          padding: theme.spacing(.75, 3.75)
        },
        text: {
          padding: theme.spacing(.75, 3.5)
        },
        textPrimary: {
          '&.Mui-disabled': {
            backgroundColor: 'transparent',
            color: theme.palette.grey[600]
          },
          '&:hover': {
            backgroundColor: 'transparent',
          },
          '&:focus': {
            color: theme.palette.green[500]
          },
          backgroundColor: 'transparent',
          color: theme.palette.green[400]
        },
        textSecondary: {
          '&.Mui-disabled': {
            backgroundColor: 'transparent',
            color: theme.palette.grey[600]
          },
          '&:hover': {
            backgroundColor: '#FFFFFF14',
            color: '#FCFFF5'
          },
          '&:focus': {
            color: '#FFF'
          },
          backgroundColor: 'transparent',
          color: '#FCFFF5'
        },
        containedPrimary: {
          '&.Mui-disabled': {
            backgroundColor: theme.palette.grey[400],
            color: theme.palette.grey[600]
          },
          '&:hover': {
            backgroundColor: theme.palette.green[400],
          },
          '&:focus': {
            backgroundColor: theme.palette.green[500],
          },
          backgroundColor: theme.palette.green[400],
          color: '#FFF'
        },
        outlinedPrimary: {
          '&.Mui-disabled': {
            backgroundColor: theme.palette.grey[400],
            color: theme.palette.grey[600]
          },
          '&:hover': {
            backgroundColor: theme.palette.green[50],
            borderColor: theme.palette.green[500],
          },
          '&:focus': {
            backgroundColor: theme.palette.green[100],
            borderColor: theme.palette.green[500],
          },
          color: theme.palette.green[500],
          borderColor: theme.palette.green[500],
        },
        sizeSmall: {
          minWidth: 120,
          padding: theme.spacing(.75, 3.5)
        },
        sizeLarge: {
          padding: theme.spacing(.75, 5.5)
        }
      }
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true
      }
    },
    MuiCheckbox: {
      defaultProps: {
        color: 'default'
      },
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: theme.palette.grey[700]
          },
          color: theme.palette.grey[600]
        },
        colorPrimary: {
          '&.Mui-checked': {
            '&:hover': {
              backgroundColor: '#00000010'
            },
            color: theme.palette.grey[700]
          }
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          '& .MuiChip-avatar': {
            color: '#FFF'
          }
        }
      }
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          '& .MuiDataGrid-row': {
            '&:hover': {
              backgroundColor: '#00000003'
            }
          },
          '& .MuiDataGrid-row.Mui-selected': {
            backgroundColor: '#00000003',
          },
          '& .MuiDataGrid-colCellWrapper': {
            border: 0
          },
          '& .MuiDataGrid-window': {
            outline: '1px solid #F0F0F0',
          },
          '& .MuiDataGrid-columnHeader': {
            fontFamily: '\'Saira\', sans-serif',
            '&:focus,  &:focus-within': {
              outline: 'none',
            },
          },
          '& .MuiDataGrid-cell': {
            '&:focus, &:focus-within': {
              outline: 'none',
            },
          },
          '& .MuiDataGrid-colCell': {
            '&:focus': {
              outline: 'none'
            },
            border: 0,
            fontFamily: '\'Saira\'',
            fontSize: 13,
            letterSpacing: 0.65
          },
          '& .MuiDataGrid-iconSeparator': {
            display: 'none',
          },
          border: 0,
        }
      }
    },
    MuiFormControl: {
      defaultProps: {
        variant: 'outlined'
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          '&:active': {
            backgroundColor: '#00000010'
          },
          '&:hover': {
            backgroundColor: '#00000004'
          },
          borderRadius: 3,
          color: theme.palette.grey[700]
        },
        colorPrimary: {
          '&:active': {
            backgroundColor: '#00000010'
          },
          '&:hover': {
            backgroundColor: '#00000004'
          },
          borderRadius: 3,
          color: theme.palette.grey[700]
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        formControl: {
          'label + &': {
            marginTop: 0,
          }
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.Mui-error': {
            color: theme.palette.error.main
          }
        },
        input: {
          height: 'initial',
          padding: theme.spacing(1, 0)
        },
        inputMarginDense: {
          paddingTop: theme.spacing(.5),
          paddingBottom: theme.spacing(.5)
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        outlined: {
          color: theme.palette.grey[700]
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.primary.light
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.primary.light,
            borderWidth: 1,
          },
          '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.grey[400]
          },
          '@media (hover: none)': {
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.primary.light,
            },
          }
        },
        colorSecondary: {
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.primary.light,
          },
        },
        adornedEnd: {
          paddingRight: theme.spacing(1.5)
        },
        adornedStart: {
          paddingLeft: theme.spacing(1.5)
        },
        inputMarginDense: {
          paddingTop: theme.spacing(.5),
          paddingBottom: theme.spacing(.5)
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            color: theme.palette.grey[800],
          },
          '&.Mui-error': {
            color: theme.palette.error.main
          },
          color: theme.palette.grey[700]
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginTop: theme.spacing(.5)
        },
        contained: {
          marginLeft: 0,
          marginRight: 0,
        }
      }
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '&:hover svg': {
            color: theme.palette.grey[700]
          }
        },
        button: {
          '&:active': {
            background: '#00000010'
          },
        }
      }
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            opacity: 0.5
          }
        }
      }
    },
    MuiListItemText: {
      styleOverrides: {
        secondary: {
          color: theme.palette.grey[600]
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            background: `${theme.palette.primary.main}10`
          }
        }
      }
    },
    MuiTextField: {
      defaultProps: {
        size: 'small'
      }
    },
  }
});

export default theme;
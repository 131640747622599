import { myApi } from '../../api';
import { AppThunkAction } from '../rootReducer';
import { MyAction } from './MyModels';
import { GroupAction } from '../Groups/GroupModels';
import { User } from '../Users/UserModels';
import { normalizeUserList, showError } from '../../utility';
import { Id } from '../../types/types';

// Action Types

export const SET_MY_USER_PROFILE_REQUESTED: string = 'SET_MY_USER_PROFILE_REQUESTED';
export const SET_MY_USER_PROFILE_REQUEST_SUCCESSFUL: string = 'SET_MY_USER_PROFILE_REQUEST_SUCCESSFUL';
export const SET_MY_USER_PROFILE_REQUEST_FAILED: string = 'SET_MY_USER_PROFILE_REQUEST_FAILED';

export const GET_MY_USER_PROFILE_REQUESTED: string = 'GET_MY_USER_PROFILE_REQUESTED';
export const GET_MY_USER_PROFILE_REQUEST_SUCCESSFUL: string = 'GET_MY_USER_PROFILE_REQUEST_SUCCESSFUL';
export const GET_MY_USER_PROFILE_REQUEST_FAILED: string = 'GET_MY_USER_PROFILE_REQUEST_FAILED';

// Action Creators

/**
 * Set the currently authenticated user's profile info returned from the claims on the access token
 * @param {User} user - currently authenticated user's primary organization code
 * @return {Function} - dispatches function response based on api results
 */
export function setMyUserProfile(user: User): AppThunkAction<MyAction | GroupAction> {
  return async (dispatch) => {
    dispatch({ type: SET_MY_USER_PROFILE_REQUESTED });
    try {
      dispatch({ type: SET_MY_USER_PROFILE_REQUEST_SUCCESSFUL, user });
    } catch (err) {
      showError(err, dispatch);
      dispatch({ type: SET_MY_USER_PROFILE_REQUEST_FAILED });
    }
  };
}

/**
	Gets the currently authenticated users info without groups
 * @param {string} userId - the user id
 * @return {Function} - dispatches function response based on api results
 */
export function getMyUserProfile(userId: Id): AppThunkAction<MyAction> {
  return async (dispatch) => {
    dispatch({ id: userId, type: GET_MY_USER_PROFILE_REQUESTED });
    try {
      const usersApiResponse = await myApi.getMyUserProfile(userId);
      const normalizedUser = normalizeUserList([usersApiResponse]);
      dispatch({ byId: normalizedUser.byId, id: userId, type: GET_MY_USER_PROFILE_REQUEST_SUCCESSFUL });
    } catch (err) {
      showError(err, dispatch);
      dispatch({ id: userId, type: GET_MY_USER_PROFILE_REQUEST_FAILED });
    }
  };
}
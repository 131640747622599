import {
  User,
  UsersState,
} from '../store/Users/UserModels';
import { Group, GroupsState } from '../store/Groups/GroupModels';
import { Id } from '../types/types';

/**
 * Create an array of `User`s with each user having a `groups` property being populated with `Group`s
 * and an id property.
 * @param {UsersState} usersState - a `CollectionState` of `User`s
 * @param {GroupsState} groupsState - a `CollectionState` of `Group`s
 * @return {UserWithId[]} an array of Users with each user having a `groups` property being populated with `Group`s and an ID.
 */
export default function usersDetailsSpread(usersState: UsersState, groupsState: GroupsState) {
  const { allIds, byId } = usersState;
  const idKey: keyof User = 'id';
  return allIds.map((id) => {
    const user = byId[id];
    return ({
      ...user,
      groups: user.groupIds?.reduce((acc: Group[], id: Id) => groupsState.byId[id] ? [...acc, groupsState.byId[id]] : acc, []) ?? [],
      id: user[idKey]
    });
  });
}
import React from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { CssBaseline, StyledEngineProvider, ThemeProvider as MuiThemeProvider } from '@mui/material';
import { ThemeProvider as LegacyThemeProvider } from '@mui/styles';
import { SingletonHooksContainer } from 'react-singleton-hook';

import rootStore from './store/rootStore';
import history from './history';
import AppRouter from './components/AppRouter/AppRouter';
import GlobalAlert from './components/GlobalAlert/GlobalAlert';
import theme from './mui-theme';
import './index.css';

const nonce = document.getElementsByTagName('meta')[0].content;

const cacheRtl = createCache({
  key: 'mui',
  nonce,
  prepend: true,
});

// Get the application-wide store instance, pre-populating with state from the server where available.
const store = rootStore(history);

/*
 Following MUI's documentation, we should be able to use the ThemeProvider solely from @mui/material
 per https://mui.com/guides/migration-v4/#mui-styles, but there seems to be a bug with `ThemeProvider - @mui/styles` only
 passing the theme to @mui/styles utilities and `ThemeProvider - @mui/material` only providing the theme to @mui/material components.
 TEMPORARY FIX: import both and pass the theme. - Andrew Mirshafiee | 10/20/2021
*/

const App = () => (
  <StyledEngineProvider injectFirst>
    <CacheProvider value={cacheRtl}>
      <MuiThemeProvider theme={theme}>
        <LegacyThemeProvider theme={theme}>
          <Provider store={store}>
            <ConnectedRouter history={history}>
              <SingletonHooksContainer />
              <CssBaseline />
              <GlobalAlert />
              <AppRouter />
            </ConnectedRouter>
          </Provider>
        </LegacyThemeProvider>
      </MuiThemeProvider>
    </CacheProvider>
  </StyledEngineProvider>
);

export default App;


import React, { useState, MouseEventHandler } from 'react';
import { connect, useSelector } from 'react-redux';
import clsx from 'clsx';
import {
  Box,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Skeleton,
  Typography
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import UserForm from './EditUserForm';
import { Id } from '../../types/types';
import { User, UserStatusActions, UserStatus } from '../../store/Users/UserModels';
import { changeUsersStatuses } from '../../store/Users/UserActions';
import UserStatusChip from '../StatusChip/StatusChip';
import UserAvatar from '../UserAvatar/UserAvatar';
import { toFullName } from '../../utility';
import useStyles from './styles';
import policyConstants from '../../constants/policyConstants';
import { useCasbin } from '../../hooks/useCasbin';
import If from '../If/If';
import { UMAReduxState } from '../../store/rootReducer';

type HandlerMap = {
  [action in UserStatusActions]: MouseEventHandler
}

type DispatchToProps = {
  changeUsersStatuses: (status: UserStatusActions, userIds: Id[], organizationCode: Id, callbacksOnSuccess: () => void) => void;
};

type UserDetailsProps = {
  disableUserStatusChanges?: boolean;
  handleDeactivateUser?: () => void;
  isLoading: boolean;
  user: User
};

type Props = DispatchToProps & UserDetailsProps;

function UserDetails({
  changeUsersStatuses,
  handleDeactivateUser,
  isLoading,
  user
}: Props) {
  const classes = useStyles();
  const [actionOverflowMenuAnchorEl, setActionOverflowMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [editing, setEditing] = useState<boolean>(false);
  const organizationCode = useSelector((store: UMAReduxState) => store.context.organizationCode);


  // Action Overflow Menu
  const handleActionOverflowMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setActionOverflowMenuAnchorEl(event.currentTarget);
  };
  const handleActionOverflowMenuClose = () => {
    setActionOverflowMenuAnchorEl(null);
  };

  // Edit User Handlers
  const handleEditClick = () => {
    setEditing(true);
  };
  const handleCancelEditClick = () => {
    setEditing(false);
  };

  // Menu Handlers
  const handleChangeUsersStatuses = (statusAction: UserStatusActions) => {
    changeUsersStatuses(statusAction, [user.id], organizationCode, handleActionOverflowMenuClose);
  };

  const handlerMap: Partial<HandlerMap> = {
    'activate': () => handleChangeUsersStatuses('activate'),
    'deactivate': handleDeactivateUser,
    'reactivate': () => handleChangeUsersStatuses('reactivate'),
    'resetPassword': () => handleChangeUsersStatuses('resetPassword'),
    'suspend': () => handleChangeUsersStatuses('suspend'),
    'unlock': () => handleChangeUsersStatuses('unlock'),
    'unsuspend': () => handleChangeUsersStatuses('unsuspend')
  };

  // For the editing pencil.
  const editUserPermitted = useCasbin(policyConstants.users.update(user?.primaryOrganizationCode, user?.id));

  // For the status change action menu.
  const activatePermitted = useCasbin(policyConstants.users.activate(organizationCode, user?.id));
  const deactivatePermitted = useCasbin(policyConstants.users.deactivate(organizationCode, user?.id));
  const reactivatePermitted = useCasbin(policyConstants.users.reactivate(organizationCode, user?.id));
  const suspendPermitted = useCasbin(policyConstants.users.suspend(organizationCode, user?.id));
  const unsuspendPermitted = useCasbin(policyConstants.users.unsuspend(organizationCode, user?.id));
  const unlockPermitted = useCasbin(policyConstants.users.unlock(organizationCode, user?.id));
  const resetPasswordPermitted = useCasbin(policyConstants.users.resetPassword(organizationCode, user?.id));

  const menuEnabled = (status: UserStatus) => {
    switch (status) {
      case 'active':
        return resetPasswordPermitted || suspendPermitted || deactivatePermitted;
      case 'locked':
        return unlockPermitted || resetPasswordPermitted || deactivatePermitted;
      case 'deprovisioned':
        return activatePermitted;
      case 'staged':
        return activatePermitted || resetPasswordPermitted || deactivatePermitted;
      case 'provisioned':
        return reactivatePermitted || deactivatePermitted;
      case 'suspended':
        return unsuspendPermitted || deactivatePermitted;
      default:
        return resetPasswordPermitted || deactivatePermitted;
    }
  };

  const getMenuItemsByStatus = (status: UserStatus = 'unspecified', handlerMap: Partial<HandlerMap>) => {
    switch (status) {
      case 'active':
        return (
          <React.Fragment>
            <If condition={resetPasswordPermitted}>
              <MenuItem onClick={handlerMap.resetPassword}>Reset password</MenuItem>
            </If>
            <If condition={suspendPermitted}>
              <MenuItem onClick={handlerMap.suspend}>Suspend</MenuItem>
            </If>
            <If condition={deactivatePermitted}>
              <MenuItem onClick={handlerMap.deactivate}>Deactivate</MenuItem>
            </If>
          </React.Fragment>
        );
      case 'locked':
        return (
          <React.Fragment>
            <If condition={unlockPermitted}>
              <MenuItem onClick={handlerMap.unlock}>Unlock</MenuItem>
            </If>
            <If condition={resetPasswordPermitted}>
              <MenuItem onClick={handlerMap.resetPassword}>Reset password</MenuItem>
            </If>
            <If condition={deactivatePermitted}>
              <MenuItem onClick={handlerMap.deactivate}>Deactivate</MenuItem>
            </If>
          </React.Fragment>
        );
      case 'deprovisioned':
        return (
          <React.Fragment>
            <If condition={activatePermitted}>
              <MenuItem onClick={handlerMap.activate}>Activate</MenuItem>
            </If>
          </React.Fragment>
        );
      case 'staged':
        return (
          <React.Fragment>
            <If condition={activatePermitted}>
              <MenuItem onClick={handlerMap.activate}>Activate</MenuItem>
            </If>
            <If condition={resetPasswordPermitted}>
              <MenuItem onClick={handlerMap.resetPassword}>Reset password</MenuItem>
            </If>
            <If condition={deactivatePermitted}>
              <MenuItem onClick={handlerMap.deactivate}>Deactivate</MenuItem>
            </If>
          </React.Fragment>
        );
      case 'provisioned':
        return (
          <React.Fragment>
            <If condition={reactivatePermitted}>
              <MenuItem onClick={handlerMap.reactivate}>Reactivate</MenuItem>
            </If>
            <If condition={deactivatePermitted}>
              <MenuItem onClick={handlerMap.deactivate}>Deactivate</MenuItem>
            </If>
          </React.Fragment>
        );
      case 'suspended':
        return (
          <React.Fragment>
            <If condition={unsuspendPermitted}>
              <MenuItem onClick={handlerMap.unsuspend}>Unsuspend</MenuItem>
            </If>
            <If condition={deactivatePermitted}>
              <MenuItem onClick={handlerMap.deactivate}>Deactivate</MenuItem>
            </If>
          </React.Fragment>
        );
      case 'pending':
      case 'recovery':
      case 'passwordExpired':
      case 'unspecified':
      default:
        return (
          <React.Fragment>
            <If condition={resetPasswordPermitted}>
              <MenuItem onClick={handlerMap.resetPassword}>Reset password</MenuItem>
            </If>
            <If condition={deactivatePermitted}>
              <MenuItem onClick={handlerMap.deactivate}>Deactivate</MenuItem>
            </If>
          </React.Fragment>
        );
    }
  };


  const menuOpen = Boolean(actionOverflowMenuAnchorEl);
  const notFederated = !user?.isFederated;
  const userStatusUpdated = user?.status === 'updated';
  return (
    <React.Fragment>
      <Paper>
        <Grid container>
          {
            editing ?
              (
                <UserForm isLoading={isLoading} user={user} stopEditing={handleCancelEditClick} />
              ) : (
                <React.Fragment>
                  {/* Profile Avatar */}
                  <Grid item className={classes.avatarContainer} xs={2}>
                    {
                      isLoading ?
                        <Skeleton variant="circular" width={64} height={64} />
                        : <UserAvatar user={user} className={classes.profileAvatar} />
                    }
                  </Grid>
                  <Grid container item justifyContent="space-between" xs={10}>

                    {/* Profile Info: name, email, username & status */}
                    <Grid item className={classes.details} xs={10}>
                      {isLoading ? <Skeleton height={40} width={200} /> : <Typography variant="h6">{toFullName(user)}</Typography>}
                      {isLoading || user.statusUpdating ? <Skeleton height={30} width={70} /> : <UserStatusChip status={user.status} />}
                      <Grid container className={classes.usernameEmailContainer}>
                        <Grid item className={classes.usernameEmail} xs={6}>
                          <Typography variant="body2" className={classes.label}>Username</Typography>
                          {
                            isLoading ?
                              <Skeleton height={30} width={150} />
                              : <Typography variant="body1" noWrap>{user.userName}</Typography>}
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="body2" className={classes.label}>Email</Typography>
                          {
                            isLoading ?
                              <Skeleton height={30} width={150} />
                              : <Typography variant="body1" noWrap>{user.email}</Typography>}
                        </Grid>
                      </Grid>
                    </Grid>

                    {/* Profile Edit/Action Buttons */}
                    <Grid container item justifyContent="flex-end" alignItems="flex-start" xs={2}>
                      <Box marginY={1} marginX={1.5}>
                        {
                          notFederated &&
                          <IconButton
                            id="edit-profile-button"
                            className={classes.editButton}
                            onClick={handleEditClick}
                            size="small"
                            disabled={isLoading || !editUserPermitted}
                          >
                            <EditIcon />
                          </IconButton>
                        }
                        <IconButton
                          id="profile-options-dropdown-button"
                          className={`${clsx({ [classes.activeState]: menuOpen })}`}
                          onClick={handleActionOverflowMenuClick}
                          size="small"
                          disabled={isLoading || userStatusUpdated || !menuEnabled(user.status)}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      </Box>
                    </Grid>
                  </Grid>
                </React.Fragment>
              )
          }
        </Grid>
      </Paper>
      <Menu
        anchorEl={actionOverflowMenuAnchorEl}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        PopoverClasses={{
          root: classes.menuRoot
        }}
        keepMounted
        open={menuOpen}
        onClose={handleActionOverflowMenuClose}
      >
        <div>
          {getMenuItemsByStatus(user?.status, handlerMap)}
        </div>
      </Menu>
    </React.Fragment>
  );
}

const mapDispatchToProps: DispatchToProps = {
  changeUsersStatuses
};

export default connect(null, mapDispatchToProps)(UserDetails);
import { createSelector } from 'reselect';

import { UMAReduxState } from '../store/rootReducer';
import { Id } from '../types/types';

export const getClientCodesSelector = createSelector(
  (state: UMAReduxState) => state.clients,
  (clients) => clients.allIds.map(
    (id: Id) => clients.byId[id].code
  )
);

import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import { connect } from 'react-redux';
import { Id } from '../../types/types';
import useStyles from './styles';
import { UMAReduxState } from '../../store/rootReducer';
import { importAllUsers, importUsersByOrganization } from '../../store/Users/UserActions';
import { useCasbin } from '../../hooks/useCasbin';
import policyConstants from '../../constants/policyConstants';
import If from '../If/If';

interface StateFromProps {
  organizationCode: string;
}

interface DispatchFromProps {
  importAllUsers: (organizationCode: Id, callbackOnSuccess: (() => void)) => void;
  importUsersByOrganization: (organizationCode: Id, callbackOnSuccess: (() => void)) => void;
}

interface ImportUsersModalProps {
  isLoading: boolean;
  onClose: () => void;
  open?: boolean;
}

type Props = StateFromProps & DispatchFromProps & ImportUsersModalProps;

function ImportUserModal({
  onClose,
  importAllUsers,
  importUsersByOrganization,
  isLoading,
  open = false,
  organizationCode,
}: Props) {
  const classes = useStyles();

  const canImportByOrg = useCasbin(policyConstants.users.importByOrganization(organizationCode));
  const canImportAll = useCasbin(policyConstants.users.importAll());

  const handleCloseModal = () => {
    onClose();
  };

  const handleImportByOrg = () => {
    importUsersByOrganization(organizationCode, () => onClose());
  };

  const handleImportAll = () => {
    importAllUsers(organizationCode, () => onClose());
  };

  const importMessage = (): string => {
    if (canImportAll && canImportByOrg) {
      return `Import Okta Users allows you to create users in UMA that have existing records in Okta.
      Users are imported based on the UMA organization code stored in their Okta profile.
      You can import users from the current organization (${organizationCode}) only 
      or you can import all users with valid UMA organization code.`;
    } else if (canImportAll) {
      return `Import Okta Users allows you to create users in UMA that have existing records in Okta.
      All Okta users with valid UMA organization code in their Okta profile will be imported.`;
    } else if (canImportByOrg) {
      return `Import Okta Users allows you to create users in UMA that have existing records in Okta.
      Users are imported based on the UMA organization code stored in their Okta profile.
      \nYou can import Okta users that are tied to the current organization: ${organizationCode}.`;
    }

    return 'Oops! Looks like you don\'t have permission to import Okta users. Sorry about that.';
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby='import-users-dialog'
      classes={{
        paper: classes.dialogPaper
      }}
    >
      <DialogTitle id='import-users-dialog-title' classes={{ root: classes.titleContainer }}>
        Import Okta Users
      </DialogTitle>
      <DialogContent
        dividers
        classes={{ root: classes.contentContainer }}>
        <DialogContentText color="initial">
          {importMessage()}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.actionsContainer}>
        <div className={classes.cancelButtonContainer}>
          <Button onClick={handleCloseModal} color="primary" variant="outlined">
            Cancel
          </Button>
        </div>
        <If condition={canImportByOrg}>
          <Button color="primary" variant="contained" onClick={handleImportByOrg} disabled={isLoading}>
            {canImportAll && canImportByOrg ? `${organizationCode} Users` : 'Import Users'}
          </Button>
        </If>
        <If condition={canImportAll}>
          <Button color="primary" variant="contained" onClick={handleImportAll} disabled={isLoading}>
            {canImportAll && canImportByOrg ? `All Users` : 'Import Users'}
          </Button>
        </If>
      </DialogActions>
    </Dialog>
  );
}

const mapStateToProps = (state: UMAReduxState): StateFromProps => ({
  organizationCode: state.context.organizationCode
});

const mapDispatchToProps: DispatchFromProps = {
  importAllUsers,
  importUsersByOrganization,
};

export default connect(mapStateToProps, mapDispatchToProps)(ImportUserModal);

import { AnyAction } from 'redux';
import { merge, omit, union, without } from 'lodash';

import { ById, Id } from '../../types/types';
import {
  User,
  UsersState
} from './UserModels';
import {
  GET_USER_BY_ID_REQUESTED,
  GET_USER_BY_ID_REQUEST_SUCCESSFUL,
  GET_USER_BY_ID_REQUEST_FAILED,
  GET_USER_GROUPS_BY_ID_REQUESTED,
  GET_USER_GROUPS_BY_ID_REQUEST_SUCCESSFUL,
  GET_USER_GROUPS_BY_ID_REQUEST_FAILED,
  GET_USERS_REQUESTED,
  GET_USERS_REQUEST_SUCCESSFUL,
  GET_USERS_REQUEST_FAILED,
  GET_USERS_DETAILS_REQUESTED,
  GET_USERS_DETAILS_REQUEST_SUCCESSFUL,
  GET_USERS_DETAILS_REQUEST_FAILED,
  GET_DEPROVISIONED_USERS_REQUESTED,
  GET_DEPROVISIONED_USERS_REQUEST_SUCCESSFUL,
  GET_DEPROVISIONED_USERS_REQUEST_FAILED,
  CREATE_USER_REQUESTED,
  CREATE_USER_REQUEST_SUCCESSFUL,
  CREATE_USER_REQUEST_FAILED,
  UPDATE_USER_REQUESTED,
  UPDATE_USER_REQUEST_SUCCESSFUL,
  UPDATE_USER_REQUEST_FAILED,
  CHANGE_USERS_STATUSES_REQUESTED,
  CHANGE_USERS_STATUSES_REQUEST_SUCCESSFUL,
  CHANGE_USERS_STATUSES_REQUEST_FAILED,
  DELETE_USER_REQUESTED,
  DELETE_USER_REQUEST_SUCCESSFUL,
  DELETE_USER_REQUEST_FAILED,
  IMPORT_OKTA_USERS_REQUESTED,
  IMPORT_OKTA_USERS_REQUEST_SUCCESSFUL,
  IMPORT_OKTA_USERS_REQUEST_FAILED
} from './UserActions';
import {
  GET_MY_USER_PROFILE_REQUESTED,
  GET_MY_USER_PROFILE_REQUEST_SUCCESSFUL,
  GET_MY_USER_PROFILE_REQUEST_FAILED
} from '../My/MyActions';
import {
  CONTEXT_RESET
} from '../Context/ContextActions';
import {
  ADD_USERS_TO_GROUPS_REQUEST_SUCCESSFUL,
  REMOVE_USERS_FROM_GROUP_REQUEST_SUCCESSFUL
} from '../Groups/GroupActions';
import { SET_MY_USER_PROFILE_REQUEST_SUCCESSFUL } from '../My/MyActions';

export const initialState: UsersState = {
  allIds: [],
  byId: {},
  isCreating: false,
  isDeleting: false,
  isLoading: false,
};

function users(state: UsersState = initialState, action: AnyAction): UsersState {
  let byId: ById<User>;
  let allIds: Id[];
  switch (action.type) {
    case GET_MY_USER_PROFILE_REQUESTED:
    case GET_USER_BY_ID_REQUESTED:
      allIds = state.allIds.slice();
      byId = { ...state.byId };
      if (!allIds.includes(action.id)) {
        allIds = allIds.concat(action.id);
      }
      if (!byId[action.id]) {
        (byId[action.id] as Partial<User>) = {};
      }
      allIds.forEach((id) => {
        if (id !== action.id) {
          byId[id] = { ...state.byId[id] };
          return;
        }

        byId[id] = {
          ...state.byId[id],
          isLoading: true
        };
      });
      return {
        ...state,
        allIds,
        byId
      };

    case GET_MY_USER_PROFILE_REQUEST_SUCCESSFUL:
    case GET_USER_BY_ID_REQUEST_SUCCESSFUL:
      byId = {};
      state.allIds.forEach((id) => {
        if (id !== action.id) {
          byId[id] = { ...state.byId[id] };
          return;
        }

        action.byId[id].isLoading = false;
        byId[id] = merge({}, state.byId[id], action.byId[id]);
      });
      return {
        ...state,
        byId
      };

    case GET_MY_USER_PROFILE_REQUEST_FAILED:
    case GET_USER_BY_ID_REQUEST_FAILED:
      byId = {};
      allIds = state.allIds.slice();
      state.allIds.forEach((id) => {
        if (id !== action.id) {
          byId[id] = { ...state.byId[id] };
          return;
        }

        byId[id] = { ...state.byId[id], isLoading: false };
      });
      if (!byId[action.id]?.id) {
        delete byId[action.id];
        allIds = without(state.allIds, action.id);
      }
      return {
        ...state,
        allIds,
        byId
      };

    case GET_USER_GROUPS_BY_ID_REQUESTED:
      allIds = state.allIds.slice();
      byId = { ...state.byId };
      if (!allIds.includes(action.id)) {
        allIds = allIds.concat(action.id);
      }
      if (!byId[action.id]) {
        (byId[action.id] as Partial<User>) = {};
      }
      allIds.forEach((id) => {
        if (id !== action.id) {
          byId[id] = { ...state.byId[id] };
          return;
        }

        byId[id] = {
          ...state.byId[id],
          groupsLoading: true
        };
      });
      return {
        ...state,
        allIds,
        byId
      };

    case GET_USER_GROUPS_BY_ID_REQUEST_SUCCESSFUL:
      byId = {};
      state.allIds.forEach((id) => {
        if (id !== action.id) {
          byId[id] = { ...state.byId[id] };
          return;
        }

        byId[id] = {
          ...state.byId[id],
          groupIds: union(state.byId[id].groupIds, action.groupIds),
          groupsLoading: false,
        };
      });
      return {
        ...state,
        byId
      };

    case GET_USER_GROUPS_BY_ID_REQUEST_FAILED:
      byId = {};
      allIds = state.allIds.slice();
      state.allIds.forEach((id) => {
        if (id !== action.id) {
          byId[id] = { ...state.byId[id] };
          return;
        }

        byId[id] = { ...state.byId[id], groupsLoading: false };
      });
      if (!byId[action.id]?.id) {
        delete byId[action.id];
        allIds = without(state.allIds, action.id);
      }
      return {
        ...state,
        allIds,
        byId
      };

    case GET_USERS_REQUESTED:
    case IMPORT_OKTA_USERS_REQUESTED:
      return {
        ...state,
        isLoading: true
      };

    case GET_USERS_REQUEST_SUCCESSFUL:
    case IMPORT_OKTA_USERS_REQUEST_SUCCESSFUL:
      byId = { ...state.byId };
      action.allIds.forEach((id: string) => {
        byId[id] = merge({}, state.byId[id], action.byId[id]);
      });
      return {
        ...state,
        allIds: union(state.allIds.slice(), action.allIds),
        byId,
        isLoading: false,
      };

    case GET_USERS_REQUEST_FAILED:
    case IMPORT_OKTA_USERS_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false
      };

    case GET_USERS_DETAILS_REQUESTED:
      return {
        ...state,
        isLoading: true
      };

    case GET_USERS_DETAILS_REQUEST_SUCCESSFUL:
      byId = { ...state.byId };
      action.allIds.forEach((id: string) => {
        byId[id] = merge({}, state.byId[id], action.byId[id]);
      });
      return {
        ...state,
        allIds: union(state.allIds.slice(), action.allIds),
        byId,
        isLoading: false,
      };

    case GET_USERS_DETAILS_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false
      };

    case GET_DEPROVISIONED_USERS_REQUESTED:
      return {
        ...state,
        isLoading: true
      };

    case GET_DEPROVISIONED_USERS_REQUEST_SUCCESSFUL:
      byId = { ...state.byId };
      action.allIds.forEach((id: string) => {
        byId[id] = merge({}, state.byId[id], action.byId[id]);
      });
      return {
        ...state,
        allIds: union(state.allIds.slice(), action.allIds),
        byId,
        isLoading: false,
      };

    case GET_DEPROVISIONED_USERS_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false
      };

    case CREATE_USER_REQUESTED:
      return {
        ...state,
        isCreating: true
      };

    case CREATE_USER_REQUEST_SUCCESSFUL:
      return {
        ...state,
        allIds: union(state.allIds.slice(), action.allIds),
        byId: merge({}, state.byId, action.byId),
        isCreating: false,
      };

    case CREATE_USER_REQUEST_FAILED:
      return {
        ...state,
        isCreating: false
      };

    case UPDATE_USER_REQUESTED:
      allIds = state.allIds.slice();
      byId = { ...state.byId };
      if (!allIds.includes(action.id)) {
        allIds = allIds.concat(action.id);
      }
      if (!byId[action.id]) {
        (byId[action.id] as Partial<User>) = {};
      }
      allIds.forEach((id) => {
        if (id !== action.id) {
          byId[id] = { ...state.byId[id] };
          return;
        }

        byId[id] = {
          ...state.byId[id],
          isLoading: true
        };
      });
      return {
        ...state,
        allIds,
        byId
      };

    case UPDATE_USER_REQUEST_SUCCESSFUL:
      byId = {};
      state.allIds.forEach((id) => {
        if (id !== action.id) {
          byId[id] = { ...state.byId[id] };
          return;
        }

        action.byId[id].isLoading = false;
        byId[id] = merge({}, state.byId[id], action.byId[id]);
      });
      return {
        ...state,
        byId
      };

    case UPDATE_USER_REQUEST_FAILED:
      byId = {};
      allIds = state.allIds.slice();
      state.allIds.forEach((id) => {
        if (id !== action.id) {
          byId[id] = { ...state.byId[id] };
          return;
        }

        byId[id] = { ...state.byId[id], isLoading: false };
      });
      if (!byId[action.id]?.id) {
        delete byId[action.id];
        allIds = without(state.allIds, action.id);
      }
      return {
        ...state,
        allIds,
        byId
      };

    case CHANGE_USERS_STATUSES_REQUESTED:
      byId = {};
      state.allIds.forEach((id) => {
        if (!action.userIds.includes(id)) {
          byId[id] = { ...state.byId[id] };
          return;
        }

        byId[id] = {
          ...state.byId[id],
          statusUpdating: true,
        };
      });
      return {
        ...state,
        byId
      };

    case CHANGE_USERS_STATUSES_REQUEST_SUCCESSFUL:
      const status = action.status === 'deactivate' ? 'deprovisioned' : 'updated';
      byId = {};
      state.allIds.forEach((id) => {
        if (!action.userIds.includes(id)) {
          byId[id] = { ...state.byId[id] };
          return;
        }

        byId[id] = {
          ...state.byId[id],
          status,
          statusUpdating: false
        };
      });
      return {
        ...state,
        byId
      };

    case CHANGE_USERS_STATUSES_REQUEST_FAILED:
      byId = {};
      state.allIds.forEach((id) => {
        if (!action.userIds.includes(id)) {
          byId[id] = { ...state.byId[id] };
          return;
        }

        byId[id] = {
          ...state.byId[id],
          statusUpdating: false
        };
      });
      return {
        ...state,
        byId
      };

    case DELETE_USER_REQUESTED:
      return {
        ...state,
        isDeleting: true
      };

    case DELETE_USER_REQUEST_SUCCESSFUL:
      return {
        ...state,
        allIds: without(state.allIds, action.id),
        byId: omit(state.byId, action.id),
        isDeleting: false
      };

    case DELETE_USER_REQUEST_FAILED:
      return {
        ...state,
        isDeleting: false
      };

    case SET_MY_USER_PROFILE_REQUEST_SUCCESSFUL:
      allIds = state.allIds.slice();
      byId = { ...state.byId };
      if (!allIds.includes(action.user.id)) {
        allIds = allIds.concat(action.user.id);
      }
      byId[action.user.id] = {
        ...byId[action.user.id],
        ...action.user
      };
      return {
        ...state,
        allIds,
        byId
      };

    case ADD_USERS_TO_GROUPS_REQUEST_SUCCESSFUL:
      byId = { ...state.byId };
      action.userIds.forEach((id: string) => {
        const user = byId[id];
        if (user) {
          byId[id] = { ...user, groupIds: union(user.groupIds, action.groupIds) };
        }
      });
      return {
        ...state,
        byId,
        isLoading: false,
      };

    case REMOVE_USERS_FROM_GROUP_REQUEST_SUCCESSFUL:
      byId = { ...state.byId };
      action.userIds.forEach((id: string) => {
        const user = byId[id];
        if (user) {
          byId[id] = { ...user, groupIds: without(user.groupIds, action.groupId) };
        }
      });
      return {
        ...state,
        byId,
        isLoading: false,
      };

    // Context Reset
    case CONTEXT_RESET:
      return {
        ...initialState
      };

    default:
      return state;
  }
}

export default users;
import React from 'react';
import {
  Box,
  Grid,
  Typography
} from '@mui/material';
import { GridOverlay } from '@mui/x-data-grid';
import NoSearchResultsIcon from '@mui/icons-material/FindInPage';

import useStyles from './styles';

type NoSearchResultsOverlayProps = {
	isFiltering?: boolean;
	noRowsLabel?: string;
}

const NoSearchResultsOverlay = ({ isFiltering = false, noRowsLabel = '' }: NoSearchResultsOverlayProps) => {
  const classes = useStyles();
  if (isFiltering) {
    // If DataGrid is being filtered, show no results found
    return (
      <GridOverlay>
        <Grid container justifyContent="center" sx={{ my: 15 }}>
          <Grid container item xs={12} justifyContent="center">
            <Box height={140} width={140} className={classes.circle}>
              <NoSearchResultsIcon className={classes.icon} />
            </Box>
          </Grid>
          <Grid container item xs={12} justifyContent="center">
            <Typography className={classes.header}>Sorry, no result found</Typography>
          </Grid>
          <Grid container item xs={12} justifyContent="center">
            <Typography variant="body2" className={classes.subheader}>What you&apos;ve searched for was not found or does not exist</Typography>
          </Grid>
        </Grid>
      </GridOverlay>
    );
  } else {
    // Else display message when there are zero results
    return (
      <GridOverlay>
        <Grid container justifyContent="center" sx={{ my: 15 }}>
          {noRowsLabel}
        </Grid>
      </GridOverlay>
    );
  }
};

export default NoSearchResultsOverlay;
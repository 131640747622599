import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

interface AssignModalStyleProps {
	selected: number;
}

const styles = makeStyles((theme: Theme) => ({
  actionsContainer: {
    padding: theme.spacing(2.5, 3, 2)
  },
  alreadyAssigned: {
    fontSize: 10,
    marginRight: theme.spacing(0.5)
  },
  alreadyAssignedAvatar: {
    backgroundColor: '#518221',
    display: 'inline-flex',
    fontSize: 10,
    height: 20,
    width: 20,
  },
  clearAll: {
    color: theme.palette.grey[800],
    textDecoration: 'underline'
  },
  contentContainer: {
    padding: theme.spacing(2.5, 0, 0),
  },
  dialogPaper: {
    maxHeight: 566,
    width: 500
  },
  listItem: {
    padding: theme.spacing(1, 5)
  },
  listItemIcon: {
    minWidth: 0,
    padding: theme.spacing(0, 0.5)
  },
  noResults: {
    justifyContent: 'center',
    padding: theme.spacing(5, 5)
  },
  numberSelected: {
    color: theme.palette.grey[600]
  },
  subtitle: {
    color: theme.palette.grey[600],
    letterSpacing: 0
  },
  titleContainer: {
    padding: theme.spacing(2.5, 5, 2)
  },
  toolbar: {
    height: ({ selected }: AssignModalStyleProps) => selected ? 40 : 20,
    padding: theme.spacing(.5, 5),
    transition: 'height .2s'
  }
}));

export default styles;
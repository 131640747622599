import { HttpResponse } from '@okta/okta-auth-js';

/**
 * Checks for and returns errors, otherwise passes response object along
 * @param {HttpResponse | any} response - an response object returned from fetch call
 * @return {T[]} - an array of each resource object
 */
export default async function handleErrors(response: HttpResponse | any) {
  const { status } = response;
  if (!(status >= 200 && status <= 299)) {
    const errorJson = await response.json();
    if (status === 403) {
      throw Error('Sorry, you do not have permission to perform this action.');
    }
    if (errorJson.title === 'Unable to complete the current request.') {
      throw Error(errorJson.detail);
    } else if (errorJson.title === 'Validation Error') {
      errorJson.body = {
        errors: [...errorJson.errors.validationErrors]
      };
      throw (errorJson);
    } else if (errorJson.title) {
      throw Error(errorJson.detail ?? errorJson.title);
    }
    throw Error(response.statusText);
  }
  return response;
}
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

const styles = makeStyles((theme: Theme) => ({
  dialogPaper: {
    maxHeight: 566,
    width: 500
  },
  titleContainer: {
    padding: theme.spacing(2.5, 5, 2)
  },
  actionsContainer: {
    padding: theme.spacing(2.5, 3, 2)
  }
}));

export default styles;
/* eslint-disable max-len */
import React from 'react';
import { Switch, Redirect, useHistory } from 'react-router';
import { Security, LoginCallback } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { useSelector } from 'react-redux';

import ApplicationPage from '../pages/ApplicationsPage/ApplicationsPage';
import ApplicationCreatePage from '../pages/ApplicationCreatePage/ApplicationCreatePage';
import ApplicationDetailPage from '../pages/ApplicationDetailPage/ApplicationDetailPage';
import AuthenticatedRoute from '../AuthenticatedRoute/AuthenticatedRoute';
import ClientsPage from '../pages/ClientsPage/ClientsPage';
import GroupsPage from '../pages/GroupsPage/GroupsPage';
import GroupCreatePage from '../pages/GroupCreatePage/GroupCreatePage';
import GroupDetailPage from '../pages/GroupDetailPage/GroupDetailPage';
import OrganizationDetailPage from '../pages/OrganizationDetailPage/OrganizationDetailPage';
import ProfilePage from '../pages/ProfilePage/ProfilePage';
import RolesPage from '../pages/RolesPage/RolesPage';
import RoleCreatePage from '../pages/RoleCreatePage/RoleCreatePage';
import RoleDetailPage from '../pages/RoleDetailPage/RoleDetailPage';
import TokenHookErrorPage from '../pages/TokenHookErrorPage/TokenHookErrorPage';
import UnauthenticatedRoute from '../UnauthenticatedRoute/UnauthenticatedRoute';
import UserCreatePage from '../pages/UserCreatePage/UserCreatePage';
import UserDeprovisionedPage from '../pages/UserDeprovisionedPage/UserDeprovisionedPage';
import UserDetailPage from '../pages/UserDetailPage/UserDetailPage';
import UsersPage from '../pages/UsersPage/UsersPage';
import TokenManager from '../TokenManager/TokenManager';
import { oktaAuthConfig } from '../../okta-config';
import policyConstants from '../../constants/policyConstants';
import { UMAReduxState } from '../../store/rootReducer';

const oktaAuth = new OktaAuth(oktaAuthConfig);

const AppRouter = () => {
  const history = useHistory();
  const organizationCode = useSelector((state: UMAReduxState) => state.context.organizationCode);

  const restoreOriginalUri = async (_oktaAuth: OktaAuth, originalUri: string) => {
    history.replace(toRelativeUrl(originalUri, window.location.origin));
  };

  return (
    <Security
      oktaAuth={oktaAuth}
      restoreOriginalUri={restoreOriginalUri}
    >
      <TokenManager />
      <Switch>
        <UnauthenticatedRoute path="/token-error" component={TokenHookErrorPage} />
        <UnauthenticatedRoute path="/login/callback" component={LoginCallback} />

        <AuthenticatedRoute path="/applications/create" component={ApplicationCreatePage} partialPermissions={false} requiredPolicies={[policyConstants.applications.create()]} />
        <AuthenticatedRoute path="/applications/:code" component={ApplicationDetailPage} requiredPolicies={[policyConstants.applications.getAny()]} />
        <AuthenticatedRoute path="/applications" component={ApplicationPage} partialPermissions={false} requiredPolicies={[policyConstants.applications.getAll()]} />

        <AuthenticatedRoute path="/clients" component={ClientsPage} requiredPolicies={[policyConstants.clients.getAll(organizationCode)]} />

        <AuthenticatedRoute path="/users/create" component={UserCreatePage} requiredPolicies={[policyConstants.users.create(organizationCode)]} />
        <AuthenticatedRoute path="/users/deprovisioned" component={UserDeprovisionedPage} requiredPolicies={[policyConstants.users.getDeprovisioned(organizationCode)]} />
        <AuthenticatedRoute path="/users/:userId" component={UserDetailPage} requiredPolicies={[policyConstants.users.getAny(organizationCode)]} />
        <AuthenticatedRoute path="/users" component={UsersPage} requiredPolicies={[policyConstants.users.getWithGroupIds(organizationCode)]} />

        <AuthenticatedRoute path="/roles/create" component={RoleCreatePage} requiredPolicies={[policyConstants.roles.createAnyApplication(), policyConstants.roles.createOrganization(organizationCode)]} />
        <AuthenticatedRoute path="/roles/:roleId" component={RoleDetailPage} requiredPolicies={[policyConstants.roles.getAny(organizationCode)]} />
        <AuthenticatedRoute path="/roles" component={RolesPage} requiredPolicies={[policyConstants.roles.getAll(organizationCode)]} />

        <AuthenticatedRoute path="/groups/create" component={GroupCreatePage} requiredPolicies={[policyConstants.groups.create(organizationCode)]} />
        <AuthenticatedRoute path="/groups/:groupId" component={GroupDetailPage} requiredPolicies={[policyConstants.groups.getAny(organizationCode)]} />
        <AuthenticatedRoute path="/groups" component={GroupsPage} requiredPolicies={[policyConstants.groups.getByOrg(organizationCode)]} />
        <AuthenticatedRoute path="/overview" component={OrganizationDetailPage} requiredPolicies={[policyConstants.organizations.getById(organizationCode)]} />
        <AuthenticatedRoute path="/profile" component={ProfilePage} />

        <Redirect to="/overview" />

      </Switch>
    </Security>
  );
};

export default AppRouter;
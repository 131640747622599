import * as Yup from 'yup';

import { CollectionState, Id } from '../../types/types';

// We define our schema based on the same keys in our form:
export const userFormSchema = Yup.object().shape({
  email: Yup.string().email('Please enter a valid email address.').required('Email is required.'),
  firstName: Yup.string().required('First Name is required.'),
  lastName: Yup.string().required('Last Name is required.')
}).required();

export const createUserFormSchema = Yup.object().shape({
  username: Yup.string().email('Please enter a valid email address as the username.').required('Username is required.'),
  firstName: Yup.string().required('First Name is required.'),
  lastName: Yup.string().required('Last Name is required.')
}).required();

export type UserFormValues = {
  firstName: string | undefined;
  lastName: string | undefined;
  email: string | undefined;
}

export type CreateUserFormValues = UserFormValues & {
  username: string | undefined;
}

export type UserStatus =
  | 'unspecified'
  | 'active'
  | 'locked'
  | 'suspended'
  | 'deprovisioned'
  | 'staged'
  | 'pending'
  | 'recovery'
  | 'passwordExpired'
  | 'provisioned'
  | 'updated';
export type UserStatusActions =
  | 'activate'
  | 'deactivate'
  | 'reactivate'
  | 'suspend'
  | 'unsuspend'
  | 'unlock'
  | 'resetPassword'
  | 'assignToGroup'
  | 'edit'
  | 'delete';

export interface User {
  email: string;
  externalUserId: string;
  firstName: string;
  groupIds?: Id[];
  groupsLoading?: boolean;
  isFederated: boolean;
  isLoading?: boolean;
  lastName: string;
  id: Id;
  primaryOrganizationCode: string;
  roles?: string[];
  status: UserStatus;
  statusUpdating?: boolean;
  userName: string;
  policies?: string[];
}

export interface UserWithId extends User {
  id: Id;
}

export interface UsersState extends CollectionState<User> {
  isCreating: boolean;
  isDeleting: boolean;
  isLoading: boolean;
}

interface RequestUsersAction {
  type: string;
}

interface RequestUserIdAction {
  type: string;
  id: Id;
}

interface ReceiveUserAction {
  type: string;
  user: User;
}

interface ChangeUsersStatusesAction {
  type: string;
  userIds: Id[];
  status: UserStatusActions;
}

interface ReceiveUsersAction {
  allIds: Id[];
  byId: { [id: string]: User };
  type: string;
}

export type UserAction = ReceiveUserAction | ReceiveUsersAction | RequestUsersAction | RequestUserIdAction | ChangeUsersStatusesAction;
import { AnyAction } from 'redux';
import { merge, omit, union, without } from 'lodash';

import { Group, GroupsState } from './GroupModels';
import {
  ADD_ROLES_TO_GROUPS_REQUESTED,
  ADD_ROLES_TO_GROUPS_REQUEST_SUCCESSFUL,
  ADD_ROLES_TO_GROUPS_REQUEST_FAILED,
  ADD_USERS_TO_GROUPS_REQUESTED,
  ADD_USERS_TO_GROUPS_REQUEST_SUCCESSFUL,
  ADD_USERS_TO_GROUPS_REQUEST_FAILED,
  DELETE_GROUPS_REQUESTED,
  DELETE_GROUPS_REQUEST_SUCCESSFUL,
  DELETE_GROUPS_REQUEST_FAILED,
  GET_GROUP_BY_ID_REQUESTED,
  GET_GROUP_BY_ID_REQUEST_SUCCESSFUL,
  GET_GROUP_BY_ID_REQUEST_FAILED,
  GET_GROUPS_REQUESTED,
  GET_GROUPS_REQUEST_SUCCESSFUL,
  GET_GROUPS_REQUEST_FAILED,
  GET_GROUPS_WITH_USERS_AND_ROLES_REQUESTED,
  GET_GROUPS_WITH_USERS_AND_ROLES_REQUEST_SUCCESSFUL,
  GET_GROUPS_DETAILS_REQUEST_FAILED,
  REMOVE_ROLES_FROM_GROUP_REQUESTED,
  REMOVE_ROLES_FROM_GROUP_REQUEST_SUCCESSFUL,
  REMOVE_ROLES_FROM_GROUP_REQUEST_FAILED,
  REMOVE_USERS_FROM_GROUP_REQUESTED,
  REMOVE_USERS_FROM_GROUP_REQUEST_SUCCESSFUL,
  REMOVE_USERS_FROM_GROUP_REQUEST_FAILED,
  CREATE_GROUP_REQUESTED,
  CREATE_GROUP_REQUEST_SUCCESSFUL,
  CREATE_GROUP_REQUEST_FAILED,
  UPDATE_GROUP_NAME_REQUESTED,
  UPDATE_GROUP_NAME_REQUEST_SUCCESSFUL,
  UPDATE_GROUP_NAME_REQUEST_FAILED
} from './GroupActions';
import { CONTEXT_RESET } from '../Context/ContextActions';
import { FILTER_ORGANIZATION_ROLES } from '../Roles/RoleActions';
import { ById, Id } from '../../types/types';

export const initialState: GroupsState = {
  allIds: [],
  byId: {},
  isAssigning: false,
  isCreating: false,
  isDeleting: false,
  isLoading: false
};

function groups(state: GroupsState = initialState, action: AnyAction): GroupsState {
  let byId: ById<Group>;
  let allIds: Id[];
  let group: Group;
  switch (action.type) {
    case GET_GROUP_BY_ID_REQUESTED:
      allIds = state.allIds.slice();
      byId = { ...state.byId };
      if (!allIds.includes(action.id)) {
        allIds = allIds.concat(action.id);
      }
      if (!byId[action.id]) {
        (byId[action.id] as Partial<Group>) = {};
      }
      allIds.forEach((id) => {
        if (id !== action.id) {
          byId[id] = { ...state.byId[id] };
          return;
        }

        byId[id] = {
          ...state.byId[id],
          isLoading: true
        };
      });
      return {
        ...state,
        allIds,
        byId
      };

    case GET_GROUP_BY_ID_REQUEST_SUCCESSFUL:
      byId = {};
      state.allIds.forEach((id) => {
        if (id !== action.id) {
          byId[id] = { ...state.byId[id] };
          return;
        }

        action.byId[id].isLoading = false;
        byId[id] = merge({}, state.byId[id], action.byId[id]);
      });
      return {
        ...state,
        byId
      };

    case GET_GROUP_BY_ID_REQUEST_FAILED:
      byId = {};
      allIds = state.allIds.slice();
      state.allIds.forEach((id) => {
        if (id !== action.id) {
          byId[id] = { ...state.byId[id] };
          return;
        }

        byId[id] = { ...state.byId[id], isLoading: false };
      });
      if (!byId[action.id]?.id) {
        delete byId[action.id];
        allIds = without(state.allIds, action.id);
      }
      return {
        ...state,
        allIds,
        byId
      };

    case GET_GROUPS_REQUESTED:
      return {
        ...state,
        isLoading: true
      };

    case GET_GROUPS_REQUEST_SUCCESSFUL:
      byId = { ...state.byId };
      action.allIds.forEach((id: string) => {
        byId[id] = merge({}, state.byId[id], action.byId[id]);
      });
      return {
        ...state,
        allIds: union(state.allIds.slice(), action.allIds),
        byId,
        isLoading: false,
      };

    case GET_GROUPS_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case GET_GROUPS_WITH_USERS_AND_ROLES_REQUESTED:
      return {
        ...state,
        isLoading: true
      };

    case GET_GROUPS_WITH_USERS_AND_ROLES_REQUEST_SUCCESSFUL:
      byId = { ...state.byId };
      action.allIds.forEach((id: string) => {
        byId[id] = merge({}, state.byId[id], action.byId[id]);
      });
      return {
        ...state,
        allIds: union(state.allIds.slice(), action.allIds),
        byId,
        isLoading: false,
      };

    case GET_GROUPS_DETAILS_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case DELETE_GROUPS_REQUESTED:
      return {
        ...state,
        isDeleting: true
      };

    case DELETE_GROUPS_REQUEST_SUCCESSFUL:
      return {
        ...state,
        allIds: without(state.allIds, ...action.groupIds),
        byId: omit(state.byId, action.groupIds),
        isDeleting: false
      };

    case DELETE_GROUPS_REQUEST_FAILED:
      return {
        ...state,
        isDeleting: false
      };

    case ADD_ROLES_TO_GROUPS_REQUESTED:
      return {
        ...state,
        isAssigning: true
      };

    case ADD_ROLES_TO_GROUPS_REQUEST_SUCCESSFUL:
      byId = { ...state.byId };
      action.groupIds.forEach((id: string) => {
        const group = byId[id];
        if (group) {
          byId[id] = { ...group, roleIds: union(group.roleIds, action.roleIds) };
        }
      });
      return {
        ...state,
        byId,
        isAssigning: false,
      };

    case ADD_ROLES_TO_GROUPS_REQUEST_FAILED:
      return {
        ...state,
        isAssigning: false
      };

    case ADD_USERS_TO_GROUPS_REQUESTED:
      return {
        ...state,
        isAssigning: true
      };

    case ADD_USERS_TO_GROUPS_REQUEST_SUCCESSFUL:
      byId = { ...state.byId };
      action.groupIds.forEach((id: string) => {
        const group = byId[id];
        if (group) {
          byId[id] = { ...group, userIds: union(group.userIds, action.userIds) };
        }
      });
      return {
        ...state,
        byId,
        isAssigning: false,
      };

    case ADD_USERS_TO_GROUPS_REQUEST_FAILED:
      return {
        ...state,
        isAssigning: false
      };
    case REMOVE_USERS_FROM_GROUP_REQUESTED:
      return {
        ...state,
        isLoading: true
      };

    case REMOVE_USERS_FROM_GROUP_REQUEST_SUCCESSFUL:
      byId = { ...state.byId };
      group = byId[action.groupId];
      if (group) {
        byId[action.groupId] = { ...group, userIds: without(group.userIds, ...action.userIds) };
      }
      return {
        ...state,
        byId,
        isLoading: false,
      };

    case REMOVE_USERS_FROM_GROUP_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false
      };

    case REMOVE_ROLES_FROM_GROUP_REQUESTED:
      return {
        ...state,
        isLoading: true
      };

    case REMOVE_ROLES_FROM_GROUP_REQUEST_SUCCESSFUL:
      byId = { ...state.byId };
      group = byId[action.groupId];
      if (group) {
        byId[action.groupId] = { ...group, roleIds: without(group.roleIds, ...action.roleIds) };
      }
      return {
        ...state,
        byId,
        isLoading: false,
      };

    case REMOVE_ROLES_FROM_GROUP_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false
      };

    case CREATE_GROUP_REQUESTED:
      return {
        ...state,
        isCreating: true
      };

    case CREATE_GROUP_REQUEST_SUCCESSFUL:
      return {
        ...state,
        allIds: union(state.allIds.slice(), action.allIds),
        byId: merge({}, state.byId, action.byId),
        isCreating: false,
      };

    case CREATE_GROUP_REQUEST_FAILED:
      return {
        ...state,
        isCreating: false
      };

    case UPDATE_GROUP_NAME_REQUESTED:
      allIds = state.allIds.slice();
      byId = { ...state.byId };
      if (!allIds.includes(action.id)) {
        allIds = allIds.concat(action.id);
      }
      if (!byId[action.id]) {
        (byId[action.id] as Partial<Group>) = {};
      }
      allIds.forEach((id) => {
        if (id !== action.id) {
          byId[id] = { ...state.byId[id] };
          return;
        }

        byId[id] = {
          ...state.byId[id],
          isUpdating: true
        };
      });
      return {
        ...state,
        allIds,
        byId
      };

    case UPDATE_GROUP_NAME_REQUEST_SUCCESSFUL:
      byId = {};
      state.allIds.forEach((id) => {
        if (id !== action.id) {
          byId[id] = { ...state.byId[id] };
          return;
        }

        byId[id] = {
          ...state.byId[id],
          isUpdating: false,
          name: action.name
        };
      });
      return {
        ...state,
        byId
      };

    case UPDATE_GROUP_NAME_REQUEST_FAILED:
      byId = {};
      allIds = state.allIds.slice();
      state.allIds.forEach((id) => {
        if (id !== action.id) {
          byId[id] = { ...state.byId[id] };
          return;
        }

        byId[id] = { ...state.byId[id], isUpdating: false };
      });
      if (!byId[action.id]?.id) {
        delete byId[action.id];
        allIds = without(state.allIds, action.id);
      }
      return {
        ...state,
        allIds,
        byId
      };

    case FILTER_ORGANIZATION_ROLES:
      /*
				Remove Group's role ids when roles reducer is filtered to organization roles
				to prevent mapping through Role objects that don't exist.
				*/
      allIds = state.allIds.slice();
      byId = { ...state.byId };
      allIds.map((id) => byId[id].roleIds = []);
      return {
        ...state,
        allIds,
        byId
      };

      // Context Reset
    case CONTEXT_RESET:
      return {
        ...initialState
      };

    default:
      return state;
  }
}

export default groups;
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import {
  Button,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Toolbar,
  Typography
} from '@mui/material';
import {
  GridCellParams,
  GridColDef,
  DataGrid,
  GridSelectionModel,
  GridValueFormatterParams
} from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { UMAReduxState } from '../../store/rootReducer';
import { ById, Id } from '../../types/types';
import { WILDCARD, ALL_CLIENTS } from '../../store/Clients/ClientModels';
import { PolicyRule } from '../../store/PolicyRules/PolicyRuleModels';
import { Role } from '../../store/Roles/RoleModels';
import { rowCountByPage } from '../../utility';
import { deletePolicyRules, getPolicyRulesByRole } from '../../store/PolicyRules/PolicyRuleActions';
import { getClients } from '../../store/Clients/ClientActions';
import { getOrganizationApplications } from '../../store/Organizations/OrganizationActions';
import CreatePolicyRuleModal from '../PolicyRuleModal/CreatePolicyRuleModal';
import EditPolicyRuleModal from '../PolicyRuleModal/EditPolicyRuleModal';
import useStyles from './styles';
import { useCasbin } from '../../hooks/useCasbin';
import policyConstants from '../../constants/policyConstants';
import If from '../If/If';
import { INITIAL_PAGE_SIZE, PAGE_SIZE_OPTIONS } from '../../constants/pageSizeConstants';

type OwnProps = {
  role: Role;
};

type StateToProps = {
  byId: ById<PolicyRule>;
  hideClientColumn: boolean;
  isLoading: boolean;
  organizationCode: string;
  rows: PolicyRule[];
}

type DispatchToProps = {
  deletePolicyRules: (organizationCode: Id, roleId: Id, policyRuleIds: Id[], callbacksOnSuccess: () => void) => void;
  getOrganizationApplications: (organizationCode: string) => void;
  getClients: (organizationCode: string) => void;
  getPolicyRulesByRole: (organizationCode: Id, roleId: Id) => void;
}

type PolicyRulesDataGridProps = StateToProps & DispatchToProps & OwnProps;

function PolicyRulesDataGrid({
  byId,
  deletePolicyRules,
  getOrganizationApplications,
  getClients,
  getPolicyRulesByRole,
  hideClientColumn,
  isLoading,
  organizationCode,
  rows,
  role
}: PolicyRulesDataGridProps) {
  const [actionOverflowMenuAnchorEl, setActionOverflowMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [selected, setSelected] = useState<Id[]>([]);
  const [createPolicyRulesModalOpen, setCreatePolicyRulesModalOpen] = useState(false);
  const [editPolicyRulesModalOpen, setEditPolicyRulesModalOpen] = useState(false);
  const [selectedActionPolicyRules, setSelectedActionPolicyRules] = useState<PolicyRule[]>([]);

  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(INITIAL_PAGE_SIZE);
  const classes = useStyles();

  const canCreatePolicies = useCasbin(policyConstants.policyRules.create(organizationCode, role.id));
  const canEditPolicies = useCasbin(policyConstants.policyRules.updateAny(organizationCode, role.id));
  const canDeletePolicies = useCasbin(policyConstants.policyRules.deleteAny(organizationCode, role.id));

  useEffect(() => {
    if (organizationCode && role.id) {
      getPolicyRulesByRole(organizationCode, role.id);
      getOrganizationApplications(organizationCode);
      getClients(organizationCode);
    }
  }, [getPolicyRulesByRole, getOrganizationApplications, getClients, organizationCode, role.id]);

  // Data Grid Actions
  const onPageChange = (page: number) => setPage(page);
  const onPageSizeChange = (pageSize: number) => setPageSize(pageSize);
  const onSelectionModelChange = (selectionModel: GridSelectionModel) => {
    setSelected(selectionModel);
    setSelectedActionPolicyRules(selectionModel.map((id) => byId[id]));
  };

  const onPolicyRuleDelete = () => {
    setSelected([]);
    setSelectedActionPolicyRules([]);
  };

  // Action Overflow Menu
  const handleActionOverflowMenuClick = (event: React.MouseEvent<HTMLButtonElement>, cellParams?: GridCellParams) => {
    if (cellParams !== undefined) {
      setSelectedActionPolicyRules([byId[cellParams.row.id]]);
    } else {
      setSelectedActionPolicyRules(selected.map((id) => byId[id]));
    }
    setActionOverflowMenuAnchorEl(event.currentTarget);
  };
  const handleActionOverflowMenuClose = () => {
    setActionOverflowMenuAnchorEl(null);
  };

  // Edit PolicyRule - Route to PolicyRule Detail
  const handleEditPolicyRuleClick = () => {
    setEditPolicyRulesModalOpen(true);
    handleActionOverflowMenuClose();
  };

  // Create/Delete PolicyRule Actions
  const handleCreatePolicyRuleClick = () => {
    setCreatePolicyRulesModalOpen(true);
  };

  const handleHeaderDeletePolicyRuleClick = () => {
    handleActionOverflowMenuClose();
    deletePolicyRules(organizationCode, role.id, selected, onPolicyRuleDelete);
  };

  const handleDeletePolicyRuleClick = () => {
    handleActionOverflowMenuClose();
    deletePolicyRules(organizationCode, role.id, selectedActionPolicyRules.map(policyRule => policyRule.id) as Id[], onPolicyRuleDelete);
  };

  const columns: GridColDef[] = [
    {
      field: 'applicationCode',
      headerName: 'APPLICATION',
      valueFormatter(params: GridValueFormatterParams) {
        return typeof params.value === 'string' ? params.value.toUpperCase() : params.value;
      },
      width: 150
    },
    {
      field: 'tag',
      headerName: 'TAG',
      width: 300
    },
    {
      field: 'permission',
      headerName: 'PERMISSION',
      width: 200
    },
    {
      field: 'clientCode',
      flex: 1,
      headerName: 'CLIENT',
      hide: hideClientColumn,
      valueFormatter(params: GridValueFormatterParams) {
        return params.value === WILDCARD ? ALL_CLIENTS : params.value;
      }
    },
    {
      field: 'effect',
      headerName: 'EFFECT',
      width: 200
    },
    {
      align: 'right',
      field: '',
      flex: 1,
      renderCell(params: GridCellParams) {
        const id = `${params.row.policyRuleId}-menu-button`;
        return (
          <If condition={canEditPolicies || canDeletePolicies}>
            <IconButton
              aria-label="Actions"
              id={id}
              className={`${clsx({ [classes.activeState]: actionOverflowMenuAnchorEl?.id === id })}`}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleActionOverflowMenuClick(e, params)}
              size="small"
              disabled={isLoading}
              sx={{ mr: 2 }}
            >
              <MoreVertIcon />
            </IconButton>
          </If>
        );
      },
      sortable: false
    }
  ];

  const tableMenuId = 'table-menu-button';
  const tableMenuOpen = actionOverflowMenuAnchorEl?.id === tableMenuId;
  return (
    <Paper className={classes.root}>
      <Grid item container alignItems="center" justifyContent="center" className={classes.titleSubheader}>
        <Grid item container alignItems="center" xs={6}>
          <Typography variant="h6" display="inline" className={classes.title}>Policy Rules</Typography>
          <Typography variant="body2" display="inline">{isLoading ? '(--)' : `(${rows.length})`}</Typography>
        </Grid>
        <Grid xs={6} item container alignItems="center" justifyContent="flex-end">
          <If condition={canCreatePolicies}>
            <Button
              color="primary"
              size="large"
              variant="contained"
              onClick={handleCreatePolicyRuleClick}
              disabled={isLoading}
            >
              Create Policy Rule
            </Button>
          </If>
        </Grid>
      </Grid>
      <If condition={canDeletePolicies}>
        <Divider />
        <Toolbar>
          <If condition={!!selected.length}>
            <Typography variant="body2" className={classes.selectedResultsDisplay}>
              {selected.length} selected
            </Typography>
            <IconButton
              id={tableMenuId}
              className={`${clsx({ [classes.activeState]: tableMenuOpen })} ${classes.tableMenuButton}`}
              onClick={handleHeaderDeletePolicyRuleClick}
              size="small"
              disabled={isLoading}
            >
              <DeleteIcon />
            </IconButton>
          </If>
          <If condition={!selected.length}>
            <Typography variant="body2" className={classes.selectedResultsDisplay}>
              {rowCountByPage(page, pageSize, rows.length)} of {rows.length} results
            </Typography>
          </If>
        </Toolbar>
      </If>
      <Divider />
      <DataGrid
        autoHeight={true}
        checkboxSelection={canDeletePolicies}
        columnBuffer={0}
        columns={columns}
        disableColumnFilter
        disableColumnMenu
        disableSelectionOnClick
        hideFooterSelectedRowCount
        loading={isLoading}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        onSelectionModelChange={onSelectionModelChange}
        pageSize={pageSize}
        rows={rows}
        rowsPerPageOptions={PAGE_SIZE_OPTIONS}
        getRowId={pr => pr.id}
        localeText={{
          noRowsLabel: 'No policy rules have been defined.'
        }}
      />
      <Menu
        anchorEl={actionOverflowMenuAnchorEl}
        anchorOrigin={{
          horizontal: tableMenuOpen ? 'left' : 'right',
          vertical: 'bottom',
        }}
        transformOrigin={{
          horizontal: tableMenuOpen ? 'left' : 'right',
          vertical: 'top',
        }}
        PopoverClasses={{
          root: classes.menuRoot
        }}
        keepMounted
        open={Boolean(actionOverflowMenuAnchorEl)}
        onClose={handleActionOverflowMenuClose}
      >
        <If condition={canDeletePolicies}>
          <MenuItem onClick={handleDeletePolicyRuleClick}>Delete</MenuItem>
        </If>
        <If condition={canEditPolicies}>
          <MenuItem onClick={handleEditPolicyRuleClick}>Edit Policy Rule</MenuItem>
        </If>
      </Menu>
      <CreatePolicyRuleModal
        open={createPolicyRulesModalOpen}
        onClose={() => setCreatePolicyRulesModalOpen(false)}
        role={role}
      />
      <EditPolicyRuleModal
        open={editPolicyRulesModalOpen}
        onClose={() => setEditPolicyRulesModalOpen(false)}
        policyRuleId={selectedActionPolicyRules[0]?.id ?? ''}
        role={role}
      />
    </Paper>
  );
}

const mapStateToProps = (state: UMAReduxState, ownProps: OwnProps) => {
  const roleId = ownProps.role.id;
  return ({
    byId: state.policyRules.byId,
    hideClientColumn: state.clients.allIds.length < 2,
    isLoading: state.policyRules.isLoading,
    organizationCode: state.context.organizationCode,
    rows: state.policyRules.allIds.map((id) => state.policyRules.byId[id]).filter((policyRule: PolicyRule) => policyRule.roleId === roleId)
  });
};

const mapDispatchToProps = {
  deletePolicyRules,
  getClients,
  getOrganizationApplications,
  getPolicyRulesByRole
};

export default connect(mapStateToProps, mapDispatchToProps)(PolicyRulesDataGrid);

import { Group, GroupsState } from '../store/Groups/GroupModels';
import { Role, RolesState } from '../store/Roles/RoleModels';
import { User, UsersState } from '../store/Users/UserModels';
import { Id } from '../types/types';

/**
 * Create an array of `Group`s with each group having a `users` property being populated with `User`s
 * and an id property.
 * @param {GroupsState} groupsState - a `CollectionState` of `Group`s
 * @param {UsersState} usersState - a `CollectionState` of `User`s
 * @param {RolesState} rolesState - a `CollectionState` of `Role`s
 * @return {GroupRow[]} an array of Groups with each group having a `users` property being populated with `User`s and an ID.
 */
export default function groupsDetailsSpread(groupsState: GroupsState, usersState: UsersState, rolesState: RolesState) {
  const { allIds, byId } = groupsState;
  const idKey: keyof Group = 'id';
  return allIds.map((id) => {
    const group = byId[id];
    return ({
      ...group,
      id: group[idKey],
      roles: group.roleIds?.reduce((acc: Role[], id: Id) => rolesState.byId[id] ? [...acc, rolesState.byId[id]] : acc, []) ?? [],
      users: group.userIds?.reduce((acc: User[], id: Id) => usersState.byId[id] ? [...acc, usersState.byId[id]] : acc, []) ?? []
    });
  });
}
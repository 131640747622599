import React, { forwardRef } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import {
  MenuItem
} from '@mui/material';

const Logout = () => {
  const { oktaAuth } = useOktaAuth();

  const logout = async () => {
    oktaAuth.signOut();
    oktaAuth.tokenManager.clear();
  };

  return (
    <MenuItem onClick={logout}>Logout</MenuItem>
  );
};

export default forwardRef(Logout);
import { alpha, Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

const styles = makeStyles((theme: Theme) => ({
  appBar: {
    backgroundColor: '#013A6F',
    boxShadow: 'none',
    transition: theme.transitions.create(['width', 'margin'], {
      duration: theme.transitions.duration.leavingScreen,
      easing: theme.transitions.easing.sharp,
    }),
    zIndex: theme.zIndex.drawer + 1,
  },
  avatar: {
    cursor: 'pointer',
    fontSize: '15px',
    height: theme.spacing(4.5),
    width: theme.spacing(4.5)
  },
  avatarSkeleton: {
    background: '#FFFFFF30'
  },
  grow: {
    flexGrow: 1
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
  },
  inputRoot: {
    color: 'inherit',
    fontSize: '12px',
    width: '100%'
  },
  profileMenu: {
    marginTop: theme.spacing(0.5)
  },
  root: {
    display: 'flex',
  },
  search: {
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    'backgroundColor': alpha(theme.palette.common.white, 0.10),
    'borderRadius': theme.shape.borderRadius,
    'fontSize': '12px',
    'marginLeft': 0,
    'marginRight': theme.spacing(2),
    'position': 'relative',
    'width': 276,
  },
  searchIcon: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    padding: theme.spacing(0, 2),
    pointerEvents: 'none',
    position: 'absolute',
  },
  switchContext: {
    color: '#ffffff',
    fontSize: '15px',
  },
  toolbar: {
    display: 'flex',
    minHeight: 60,
    paddingRight: theme.spacing(2) + 3
  },
  typography: {
    padding: theme.spacing(2),
  }
}));

export default styles;
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Avatar,
  Box,
  Button,
  Breadcrumbs,
  Grid,
  Link,
  Paper,
  TextField,
  Typography
} from '@mui/material';

import { UMAReduxState } from '../../../store/rootReducer';
import { createUser } from '../../../store/Users/UserActions';
import { User, createUserFormSchema, CreateUserFormValues } from '../../../store/Users/UserModels';
import GroupsList from '../../FilterList/FilterList';
import LeavePageModal from '../../LeavePageModal/LeavePageModal';
import useStyles from './styles';
import policyConstants from '../../../constants/policyConstants';
import Can from '../../Can/Can';

const FORM_ID = 'create-user-form';

type StateToProps = {
  isCreating: boolean;
  organizationCode: string,
};

type DispatchToProps = {
  createUser: (organizationCode: string, user: Partial<User>) => void
};

type UserCreatePageTypes = StateToProps & DispatchToProps;

function UserCreatePage({
  createUser,
  isCreating,
  organizationCode
}: UserCreatePageTypes) {
  const classes = useStyles();
  const history = useHistory();
  const { control, formState: { isValid, isDirty }, handleSubmit } = useForm<CreateUserFormValues>({
    mode: 'onChange',
    resolver: yupResolver(createUserFormSchema)
  });

  const [leavePageModalOpen, setLeavePageModalOpen] = useState<boolean>(false);

  const onSubmit = (values: CreateUserFormValues) => {
    if (!isDirty) return;

    const createPayload = {
      email: values.username,
      firstName: values.firstName,
      lastName: values.lastName,
      userName: values.username
    };
    createUser(organizationCode, createPayload);
  };

  const handleBreadcrumbClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    history.push('/users');
  };

  const handleLeavePageModalOpened = () => setLeavePageModalOpen(true);
  const handleLeavePageModalClosed = () => setLeavePageModalOpen(false);
  const handleLeavePageClick = () => history.push('/users');

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} id={FORM_ID}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Breadcrumbs className={classes.breadcrumbs} separator="›" aria-label="breadcrumb">
              <Link
                className={classes.baseBreadcrumb}
                color="inherit"
                href="/users"
                onClick={handleBreadcrumbClick}
                variant="body2"
              >
                Users
              </Link>
              <Typography variant="body2">Create User</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item>
            <Button color="primary" size="large" variant="outlined" onClick={handleLeavePageModalOpened}>
              Cancel
            </Button>
            <Button
              disabled={isCreating || !isDirty || !isValid}
              color="primary"
              size="large"
              variant="contained"
              type="submit"
              form={FORM_ID}
              sx={{ ml: 1 }}
            >
              {isCreating ? 'Saving' : 'Save'}
            </Button>
          </Grid>
        </Grid>
        <Box mt={2} mb={3}>
          <Typography variant="h3">Create User</Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid xs={7} item className={classes.detailsContainer}>
            <Paper>
              {/* Profile Avatar */}
              <Grid item className={classes.editAvatarContainer} xs={12}>
                <Avatar alt="Create User" className={classes.profileAvatar}>AA</Avatar>
              </Grid>
              {/* Editable Fields */}
              <Grid container item xs={12} className={classes.editableFieldsContainer}>
                <Typography className={classes.contact}>Contact</Typography>
                <Grid container item xs={12} spacing={2}>
                  <Grid item xs={6}>
                    <Controller
                      name="firstName"
                      control={control}
                      defaultValue=""
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          id="user-first-name"
                          label="First Name"
                          autoFocus
                          fullWidth
                          error={Boolean(error)}
                          helperText={error?.message ?? ''}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name="lastName"
                      control={control}
                      defaultValue=""
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          id="user-last-name"
                          label="Last Name"
                          fullWidth
                          error={Boolean(error)}
                          helperText={error?.message ?? ''}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Box marginTop={1}>
                      <Controller
                        name="username"
                        control={control}
                        defaultValue=""
                        render={({ field, fieldState: { error } }) => (
                          <TextField
                            {...field}
                            id="user-email"
                            label="Username"
                            fullWidth
                            error={Boolean(error)}
                            helperText={error?.message ?? ''}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Can requiredPolicy={policyConstants.users.getAnyWithGroupIdsById(organizationCode)}>
            <Grid xs={5} item>
              <GroupsList
                parentResourceName="users"
                addItemProps={{
                  handleAddClick: () => { }
                }}
                removeItemProps={{
                  handleDeleteClick: () => { }
                }}
                isLoading={false}
                listResourceName="groups"
                listResources={[]}
                onCreateResourcePage={true}
              />
            </Grid>
          </Can>
        </Grid>
      </form>
      <LeavePageModal
        open={leavePageModalOpen}
        onClose={handleLeavePageModalClosed}
        onLeave={handleLeavePageClick}
      />
    </div>
  );
}

function mapStateToProps(state: UMAReduxState): StateToProps {
  return {
    isCreating: state.users.isCreating,
    organizationCode: state.context.organizationCode,
  };
}

const mapDispatchToProps: DispatchToProps = {
  createUser
};

export default connect(mapStateToProps, mapDispatchToProps)(UserCreatePage);
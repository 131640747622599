import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { useDispatch } from 'react-redux';
import { updateFederatedGroupMapping } from '../../../store/FederatedGroupMappings/FederatedGroupMappingActions';
import { FederatedGroupMapping } from '../../../store/FederatedGroupMappings/FederatedGroupMappingModels';
import useStyles from './styles';

interface EditFederaterdGroupMappingModalProps {
  isOpen: boolean;
  onClose: () => void;
  organizationCode: string;
  mapping: FederatedGroupMapping
}

const EditFederaterdGroupMappingModal = ({
  isOpen,
  onClose,
  organizationCode,
  mapping
}: EditFederaterdGroupMappingModalProps) => {
  const dispatch = useDispatch();
  const [federatedGroup, setFederatedGroup] = useState('');
  const classes = useStyles();

  useEffect(() => {
    setFederatedGroup(mapping?.federatedGroupId ?? '');
  }, [mapping]);

  const submit = () => {
    dispatch(updateFederatedGroupMapping(organizationCode, { ...mapping, federatedGroupId: federatedGroup }));
    onClose();
    setFederatedGroup('');
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby='edit-federated-group-mapping-dialog'
      classes={{
        paper: classes.dialogPaper
      }}
    >
      <DialogTitle className={classes.titleContainer} id="edit-federated-group-mapping-dialog">Edit Federated Group</DialogTitle>
      <DialogContent>
        <TextField
          label='Federated Group'
          variant='outlined'
          fullWidth
          id='federated-group'
          type='text'
          autoFocus
          margin='dense'
          value={federatedGroup}
          onChange={(e) => setFederatedGroup(e.target.value)}
          error={federatedGroup.length < 1 || federatedGroup.length > 255}
          helperText="Must be between 1 and 255 characters."
        >
        </TextField>
      </DialogContent>
      <DialogActions className={classes.actionsContainer}>
        <Button onClick={onClose} color="primary" variant="outlined">Close</Button>
        <Button
          type="submit"
          aria-label="edit-federated-group-mapping-submit-button"
          variant="contained"
          disabled={federatedGroup.length < 1 || federatedGroup.length > 255}
          onClick={submit}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditFederaterdGroupMappingModal;
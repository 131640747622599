/**
 * Calculate the number of rows for the page (used with our DataGrid list pages)
 * @param {number} pageNum - page number
 * @param {number} itemsPerPage - number of items per page
 * @param {number} itemCount - total number of items
 * @return {number} - row count for the page
 */
export default function rowCountByPage(pageNum: number, itemsPerPage: number, itemCount: number): number {
  if (itemsPerPage * (pageNum) < itemCount) {
    return itemsPerPage;
  }
  return itemCount - (pageNum - 1) * itemsPerPage;
};
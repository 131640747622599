import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

const styles = makeStyles((theme: Theme) => ({
  activeState: {
    backgroundColor: '#00000010'
  },
  menuRoot: {
    marginTop: theme.spacing(0.5)
  },
  root: {
    '& .MuiDataGrid-row.Mui-selected': {
      '&:hover': {
        backgroundColor: '#00000003'
      },
      backgroundColor: 'transparent',
    },
    '& .MuiDataGrid-window': {
      border: 0
    },
    width: '100%'
  },
  selectedResultsDisplay: {
    color: theme.palette.grey[600]
  },
  subheaderGutters: {
    padding: theme.spacing(0, 2.5)
  },
  tableMenuButton: {
    marginLeft: theme.spacing(0.5)
  },
  title: {
    marginRight: theme.spacing(1)
  },
  titleSubheader: {
    padding: theme.spacing(2, 2.5)
  }
}));

export default styles;
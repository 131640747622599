import React from 'react';
import {
  Grid,
  Skeleton,
} from '@mui/material';
import clsx from 'clsx';

import useStyles from './styles';

export default function ContentPlaceholder() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Skeleton className={clsx(classes.skeleton, classes.rowOne)} variant="rectangular" />
        </Grid>
        <Grid item xs={6}>
          <Skeleton className={clsx(classes.skeleton, classes.rowTwo)} variant="rectangular" />
        </Grid>
        <Grid item xs={6}>
          <Skeleton className={clsx(classes.skeleton, classes.rowTwo)} variant="rectangular" />
        </Grid>
        <Grid item xs={6}>
          <Skeleton className={clsx(classes.skeleton, classes.rowThree)} variant="rectangular" />
        </Grid>
        <Grid item xs={6}>
          <Skeleton className={clsx(classes.skeleton, classes.rowThree)} variant="rectangular" />
        </Grid>
      </Grid>
    </div>
  );
}

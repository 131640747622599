import { UserClaims } from '@okta/okta-auth-js';

import { User } from '../store/Users/UserModels';

/**
 * Create a `User` profile object from the claims returned in the access token
 * @param {UserClaims} claims - claims from the access token
 * @return {User} - a `User` for the currently authenticated user's profile
 */
export default function userProfileFromClaims(claims: UserClaims): User {
  return {
    email: claims.email ?? '',
    externalUserId: claims.uid,
    firstName: claims.given_name ?? '',
    isFederated: claims.is_federated ?? false,
    lastName: claims.family_name ?? '',
    id: claims.user_id,
    primaryOrganizationCode: claims.organization_id,
    roles: claims.roles ?? [],
    status: 'active',
    userName: claims.email ?? '',
    policies: claims.casbin_policies,
  };
}
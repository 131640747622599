import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Grid,
  Skeleton,
  TextField,
  Typography
} from '@mui/material';

import { UMAReduxState } from '../../store/rootReducer';
import { Id } from '../../types/types';
import { updateUser } from '../../store/Users/UserActions';
import { User, userFormSchema, UserFormValues } from '../../store/Users/UserModels';
import InputSkeleton from '../InputSkeleton/InputSkeleton';
import UserAvatar from '../UserAvatar/UserAvatar';
import useStyles from './styles';

const FORM_ID = 'edit-user-form';

type StateToProps = {
  organizationCode: string;
}

type DispatchToProps = {
  updateUser: (organizationCode: string, userId: Id, user: Partial<User>, callbacksOnSuccess: () => void) => void
};

type UserDetailPageProps = {
  stopEditing: () => void;
  isLoading: boolean;
  user: User
};

type Props = UserDetailPageProps & DispatchToProps & StateToProps;

function EditUserForm({
  stopEditing,
  isLoading,
  organizationCode,
  updateUser,
  user
}: Props) {
  const classes = useStyles();
  const { control, formState: { isValid, isDirty }, handleSubmit, reset } = useForm<UserFormValues>({
    defaultValues: useMemo(() => {
      return {
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName
      };
    }, [user]),
    mode: 'onChange',
    resolver: yupResolver(userFormSchema)
  });

  // Ensures the intial value gets set once the input renders
  useEffect(() => {
    reset({
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName
    });
  }, [user, reset]);

  const onSubmit = (values: UserFormValues) => {
    if (!isDirty) return;

    const updatePayload: Partial<User> = {
      email: values.email,
      externalUserId: user.externalUserId,
      firstName: values.firstName,
      lastName: values.lastName,
      primaryOrganizationCode: user.primaryOrganizationCode,
      userName: user.userName
    };
    updateUser(organizationCode, user.id, updatePayload, () => {
      stopEditing();
    });
  };

  return (
    <Grid item xs={12}>
      <form onSubmit={handleSubmit(onSubmit)} id={FORM_ID}>
        <Grid container>
          {/* Profile Avatar */}
          <Grid item className={classes.editAvatarContainer} xs={12}>
            {
              isLoading ?
                <Skeleton variant="circular" width={64} height={64} />
                : <UserAvatar user={user} className={classes.profileAvatar} />
            }
          </Grid>
          {/* Editable Fields */}
          <Grid container item xs={12} className={classes.editableFieldsContainer}>
            <Typography className={classes.contact}>Contact</Typography>
            <Grid container item xs={12} spacing={2}>
              <Grid item xs={6}>
                <Controller
                  name="firstName"
                  control={control}
                  defaultValue=""
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      id="user-first-name"
                      label="First Name"
                      fullWidth
                      InputProps={{
                        inputComponent: isLoading ? InputSkeleton : 'input'
                      }}
                      error={Boolean(error)}
                      helperText={error?.message ?? ''}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="lastName"
                  control={control}
                  defaultValue=""
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      id="user-last-name"
                      label="Last Name"
                      fullWidth
                      InputProps={{
                        inputComponent: isLoading ? InputSkeleton : 'input'
                      }}
                      error={Boolean(error)}
                      helperText={error?.message ?? ''}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Box marginTop={1}>
                  <Controller
                    name="email"
                    control={control}
                    defaultValue=""
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        id="user-email"
                        label="Email"
                        type="email"
                        fullWidth
                        InputProps={{
                          inputComponent: isLoading ? InputSkeleton : 'input'
                        }}
                        error={Boolean(error)}
                        helperText={error?.message ?? ''}
                      />
                    )}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>

          {/* Details Save/Cancel Buttons */}
          <Grid container item justifyContent="flex-end" alignItems="flex-start" xs={12} className={classes.buttonContainer}>
            <Box marginX={1}>
              <Button onClick={stopEditing} color="primary" variant="outlined">
                Cancel
              </Button>
            </Box>
            <Button
              disabled={isLoading || !isDirty || !isValid}
              color="primary"
              size="large"
              variant="contained"
              type="submit"
              form={FORM_ID}
              sx={{ ml: 1 }}
            >
              {isLoading ? 'Saving' : 'Save'}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
}

function mapStateToProps(state: UMAReduxState): StateToProps {
  return {
    organizationCode: state.context.organizationCode
  };
}

const mapDispatchToProps: DispatchToProps = {
  updateUser
};

export default connect(mapStateToProps, mapDispatchToProps)(EditUserForm);
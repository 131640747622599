import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Enforcer, MemoryAdapter, Model, newEnforcer, newModel } from 'casbin.js';
import { singletonHook } from 'react-singleton-hook';
import { UMAReduxState } from '../store/rootReducer';
import { PolicyRequest } from '../store/My/MyModels';
import environment from '../environment';

/* 
 * This casbin model is ALMOST identical to the one used on the backend.
 * subject mapping was pulled out of the matchers b/c we can safely assume
 * all policies sent to the client are for the current user
 */
export const casbinModel: Model = newModel(`
[request_definition]
r = obj, act

[policy_definition]
p = app, sub, org, client, obj, act, eft

[role_definition]
g = _, _, _

[policy_effect]
e = some(where (p.eft == allow)) && !some(where (p.eft == deny))

[matchers]
m = (keyMatch2(r.obj, p.obj) || keyMatch(p.obj, r.obj)) && r.act == p.act
`);

export const useEnforcer = singletonHook(null, () => {
  const { enableCasbinEnforcerLogging } = environment;
  const casbinPolicies: string[] = useSelector((store: UMAReduxState) => store.my.policies);
  const receivedPermissions: boolean = useSelector((store: UMAReduxState) => store.my.receivedPermissions);

  const [enforcer, setEnforcer] = useState<Enforcer | null>(null);
  const [setupEnforcerCalled, setSetupEnforcerCalled] = useState(false);

  useEffect(() => {
    const instantiateEnforcer = async () => {
      const enforcer = await newEnforcer(casbinModel, new MemoryAdapter(casbinPolicies.join('\n')));
      setEnforcer(enforcer);
      setSetupEnforcerCalled(true);
    };

    if (setupEnforcerCalled || !receivedPermissions) return;

    instantiateEnforcer();
  }, [setupEnforcerCalled, receivedPermissions]); // eslint-disable-line react-hooks/exhaustive-deps

  if (enableCasbinEnforcerLogging) {
    enforcer?.enableLog(true);
  }

  return enforcer;
});

export function useCasbin(request: PolicyRequest | undefined): boolean {
  const enforcer = useEnforcer();

  const result = useMemo(() => {
    if (request === undefined) return false;
    return enforcer?.enforceSync(request.obj, request.act) ?? false;
  }, [request?.obj, request?.act, enforcer]); // eslint-disable-line react-hooks/exhaustive-deps

  return result;
}

export function useCasbinSome(requests: PolicyRequest[] | undefined = []): boolean {
  const enforcer = useEnforcer();

  const result = useMemo(() => {
    if (!requests?.length) return false;
    return requests.some(request => enforcer?.enforceSync(request.obj, request.act));
  }, [requests, enforcer]);

  return result;
}

export function useCasbinEvery(requests: PolicyRequest[] | undefined = []): boolean {
  const enforcer = useEnforcer();

  const result = useMemo(() => {
    if (!requests?.length) return false;
    return requests.every(request => enforcer?.enforceSync(request.obj, request.act));
  }, [requests, enforcer]);

  return result;
};
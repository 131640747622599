import React, { useEffect, useState, useMemo } from 'react';
import { connect, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import {
  ClickAwayListener,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import ClearIcon from '@mui/icons-material/ClearRounded';
import DoneIcon from '@mui/icons-material/Done';

import { Role, updateRoleFormSchema, UpdateRoleFormValues } from '../../../store/Roles/RoleModels';
import { updateRole } from '../../../store/Roles/RoleActions';
import InputSkeleton from '../../InputSkeleton/InputSkeleton';
import RoleTypeBadge from '../../RoleTypeBadge/RoleTypeBadge';
import TextAreaInputSkeleton from '../../InputSkeleton/TextAreaInputSkeleton';
import useStyles from './styles';
import { UMAReduxState } from '../../../store/rootReducer';
import { Id } from '../../../types/types';

const EDIT_ROLE_FORM_ID = 'edit-role-form';

interface RoleFormProps {
  isLoading: boolean;
  isUpdatingDescription: boolean;
  isUpdatingName: boolean;
  role: Role;
  organizationCode: string;
};

type DispatchToProps = {
  updateRole: (organizationCode: string, applicationCode: string, role: Partial<Role>, callbacksOnSuccess: () => void) => void;
};

type Props = RoleFormProps & DispatchToProps;

function RoleDetailPage({
  isLoading,
  isUpdatingDescription,
  isUpdatingName,
  organizationCode,
  role,
  updateRole
}: Props) {
  const classes = useStyles();
  const { applicationId, description, id, name, roleType } = role;
  const { control, formState: { isDirty, isValid }, handleSubmit, reset, setValue } = useForm<UpdateRoleFormValues>({
    defaultValues: useMemo(() => {
      return {
        description,
        name,
        roleType
      };
    }, [description, name, roleType]),
    mode: 'onChange',
    resolver: yupResolver(updateRoleFormSchema)
  });

  const [nameEditing, setNameEditing] = useState<boolean>(false);
  const [descriptionEditing, setDescriptionEditing] = useState<boolean>(false);

  const organizationId: Id = useSelector((state: UMAReduxState) => state.organizations.byId[state.context.organizationCode]?.id ?? '');

  // Ensures the initial value gets set once the input renders
  useEffect(() => {
    reset({ description, name, roleType });
  }, [description, name, roleType, reset]);

  const onNameTextFieldFocus = () => setNameEditing(true);
  const onDescriptionTextFieldFocus = () => setDescriptionEditing(true);

  const onSubmit = (values: UpdateRoleFormValues) => {
    if (!isDirty) return;

    const updatePayload: Partial<Role> = {
      description: values.description || null,
      id,
      name: values.name,
      roleType
    };

    if (updatePayload.roleType === 'organization') {
      updatePayload.organizationId = organizationId;
    } else if (updatePayload.roleType === 'application') {
      updatePayload.applicationId = applicationId;
    }

    const appCode = role.application?.code || '';
    updateRole(organizationCode, appCode, updatePayload, () => {
      setNameEditing(false);
      setDescriptionEditing(false);
    });
  };

  const organizationRoleType = roleType === 'organization';
  return (
    <form onSubmit={handleSubmit(onSubmit)} id={EDIT_ROLE_FORM_ID}>
      <ClickAwayListener
        mouseEvent="onMouseDown"
        touchEvent="onTouchStart"
        onClickAway={() => {
          setValue('name', name, { shouldDirty: true, shouldValidate: true });
          setNameEditing(false);
        }}
      >
        <Grid container item xs={6} direction="column">
          <Grid item xs={12}>
            <Controller
              name="name"
              control={control}
              defaultValue=""
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  placeholder="Enter role name"
                  onFocus={onNameTextFieldFocus}
                  required
                  inputProps={{
                    className: classes.input
                  }}
                  InputProps={{
                    classes: {
                      disabled: classes.disabled,
                      notchedOutline: classes.notchedOutline,
                      root: classes.nameField
                    },
                    inputComponent: (isLoading || isUpdatingName) ? InputSkeleton : 'input'
                  }}
                  fullWidth
                  error={Boolean(error)}
                  helperText={error?.message ?? ''}
                />
              )}
            />
          </Grid>
          <Grid container xs={12} item justifyContent="space-between">
            {/* Only show badge for Application and System role types */}
            <Grid item className={classes.roleTypeBadge}>
              {!isLoading && !organizationRoleType && <RoleTypeBadge roleType={roleType} applicationName={role.application?.name} />}
            </Grid>

            <Grid
              container
              item
              className={clsx(classes.editControls, { [classes.editControlsHidden]: !nameEditing })}
            >
              <Grid item>
                <Tooltip title="Save">
                  <span>
                    <IconButton
                      disabled={isLoading || !isDirty || !isValid}
                      className={clsx(classes.iconButton, classes.buttonSpacing)}
                      data-edit-control
                      type="submit"
                      form={EDIT_ROLE_FORM_ID}
                      size="large">
                      <DoneIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title="Exit">
                  <IconButton
                    className={classes.iconButton}
                    data-edit-control
                    onClick={() => {
                      setValue('name', name, { shouldDirty: true, shouldValidate: true });
                      setNameEditing(false);
                    }}
                    size="large">
                    <ClearIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ClickAwayListener>

      <ClickAwayListener
        mouseEvent="onMouseDown"
        touchEvent="onTouchStart"
        onClickAway={() => {
          setValue('description', description ?? '', { shouldDirty: true, shouldValidate: true });
          setDescriptionEditing(false);
        }}
      >
        <Grid container item xs={6} direction="column">
          <Grid item xs={12}>
            <Typography variant="h6">Description</Typography>
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="description"
              control={control}
              defaultValue=""
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  placeholder="Enter description"
                  onFocus={onDescriptionTextFieldFocus}
                  multiline
                  fullWidth
                  minRows={3}
                  maxRows={5}
                  InputProps={{
                    classes: {
                      disabled: classes.disabled,
                      notchedOutline: classes.notchedOutline,
                      root: classes.descriptionField
                    },
                    inputComponent: (isLoading || isUpdatingDescription) ? TextAreaInputSkeleton : 'input'
                  }}
                  inputProps={{
                    className: classes.input
                  }}
                  error={Boolean(error)}
                />
              )}
            />
          </Grid>
          <Grid xs={12} item>
            <Grid
              container
              justifyContent="flex-end"
              spacing={1}
              className={clsx(classes.editControls, { [classes.editControlsHidden]: !descriptionEditing })}
            >
              <Grid item>
                <Tooltip title="Save">
                  <span>
                    <IconButton
                      disabled={isLoading || !isDirty || !isValid}
                      className={classes.iconButton}
                      data-edit-control
                      type="submit"
                      form={EDIT_ROLE_FORM_ID}
                      size="large">
                      <DoneIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title="Exit">
                  <IconButton
                    className={classes.iconButton}
                    data-edit-control
                    onClick={() => {
                      setValue('description', description ?? '', { shouldDirty: true, shouldValidate: true });
                      setDescriptionEditing(false);
                    }}
                    size="large">
                    <ClearIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ClickAwayListener>
    </form>
  );
}

const mapDispatchToProps: DispatchToProps = {
  updateRole
};

export default connect(null, mapDispatchToProps)(RoleDetailPage);

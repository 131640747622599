import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemText,
  Skeleton,
  TextField,
  Typography
} from '@mui/material';

import InputSkeleton from '../InputSkeleton/InputSkeleton';
import SearchInput from '../SearchInput/SearchInput';
import { normalizeStrings } from '../../utility';
import useStyles from './styles';
import { CreateDomainFormValues, Organization, OrganizationDomain } from '../../store/Organizations/OrganizationModels';
import { addDomainToOrganization } from '../../store/Organizations/OrganizationActions';

const FORM_ID = 'create-application-permission-form';

interface DispatchFromProps {
  addDomainToOrganization: (organizationCode: string, domain: Partial<OrganizationDomain>, callbacksOnSuccess: () => void) => void;
}

interface CreatePermissionModalProps {
  organization: Organization;
  domains: OrganizationDomain[];
  isLoading: boolean;
  isAssigning: boolean;
  onClose: () => void;
  open?: boolean;
}

type Props = DispatchFromProps & CreatePermissionModalProps;

function CreateDomainModal({
  organization,
  domains,
  isAssigning,
  isLoading,
  onClose,
  open = false,
  addDomainToOrganization
}: Props) {
  const classes = useStyles();
  const { control, formState: { isValid, isDirty }, handleSubmit, reset } = useForm<CreateDomainFormValues>({
    mode: 'onChange',
  });

  const [filteredResources, setFilteredResources] = useState<OrganizationDomain[]>([]);
  const [filterText, setFilterText] = useState<string>('');

  /* Filter logic */
  useEffect(() => {
    if (filterText.length > 0) {
      setFilteredResources((domains).filter((domain) => domain.name.includes(filterText)));
    } else {
      setFilteredResources(domains);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domains, filterText]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => setFilterText(normalizeStrings(event.target.value));

  const handleCloseModal = () => {
    setFilterText('');
    reset({ domain: '' });
    onClose();
  };

  const onSubmit = (formValues: CreateDomainFormValues) => {
    if (!isDirty) return;

    const newDomain: Partial<OrganizationDomain> = {
      name: formValues.domain,
      organizationId: organization.id
    };

    addDomainToOrganization(organization.code, newDomain, () => {
      reset({ domain: '' });
      onClose();
    });
  };

  const renderResults = () => {
    // Loading state
    if (filteredResources.length === 0 && filterText === '' && isLoading) {
      return (
        <>
          <ListItem disableGutters className={classes.listItem}>
            <Skeleton role="loading-skeleton" width={500} height={60} animation="wave" />
          </ListItem>
          <ListItem disableGutters className={classes.listItem}>
            <Skeleton role="loading-skeleton" width={500} height={60} animation="wave" />
          </ListItem>
          <ListItem disableGutters className={classes.listItem}>
            <Skeleton role="loading-skeleton" width={500} height={60} animation="wave" />
          </ListItem>
        </>
      );

      // Zero results in redux state
    } else if (filteredResources.length === 0 && filterText === '' && !isLoading) {
      return (
        <ListItem disableGutters className={classes.noResults}>
          <Typography role="message" noWrap>There are currently no domains assigned.</Typography>
        </ListItem>
      );

      // No results from search filter state
    } else if (filteredResources.length === 0 && filterText !== '') {
      return (
        <ListItem disableGutters className={classes.noResults}>
          <Typography role="message" noWrap>{`No domains matching "${filterText}"`}</Typography>
        </ListItem>
      );

      // Show assignable options
    } else {
      return (
        (filteredResources).map((domain) => {
          return (
            <ListItem
              aria-label={domain.name}
              role="domain-list-item"
              button
              className={classes.listItem}
              disableGutters
              key={domain.name}
            >
              <ListItemText
                primary={domain.name}
                primaryTypographyProps={{
                  noWrap: true
                }}
              />
            </ListItem>
          );
        })
      );
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleCloseModal}
      aria-label="assign-domain-to-organization-modal"
      classes={{
        paper: classes.dialogPaper
      }}
    >
      <DialogTitle className={classes.titleContainer}>
        <Typography aria-label="domain-list-title" sx={{ fontSize: 20, fontWeight: 600 }}>Assign Domains</Typography>
        {
          isLoading ? <Skeleton aria-label="subtitle-loading-skeleton" role="loading-skeleton" width={300} height={30} /> : (
            <Typography aria-label="domain-list-subtitle" variant="body2" className={classes.subtitle}>
              To {organization?.name}
            </Typography>
          )
        }
      </DialogTitle>

      <DialogContent dividers className={classes.contentContainer}>
        <Box px={5}>
          <SearchInput
            onChange={handleChange}
            onReset={() => setFilterText('')}
          />
        </Box>

        {/* Render various ListItem displays dependent on state */}
        <List>
          {renderResults()}
        </List>
      </DialogContent>

      <DialogActions classes={{ root: classes.actionsContainer }}>
        <form onSubmit={handleSubmit(onSubmit)} id={FORM_ID}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name="domain"
                control={control}
                defaultValue=""
                rules={{
                  minLength: { message: 'Domain should be 2 or more characters.', value: 2 },
                  required: { message: 'Domain is required.', value: true },
                  validate: {
                    alreadyExists: (v) => !!domains.findIndex(d => d.name.toLowerCase() === v?.toLowerCase() ?? '') || 'Domain already exists.',
                    validDomain: (v) => v.includes('@') ? 'Must not contain the \'@\' character' : true
                  }
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    label="Domain"
                    autoFocus
                    fullWidth
                    FormHelperTextProps={{
                      'aria-label': 'domain-helper-text'
                    }}
                    inputProps={{
                      'aria-label': 'domain-input',
                    }}
                    InputProps={{
                      inputComponent: isLoading ? InputSkeleton : 'input'
                    }}
                    error={Boolean(error)}
                    helperText={error?.message ?? ''}
                  />
                )}
              />
            </Grid>
            <Grid container item xs={12} justifyContent="flex-end">
              <Button onClick={handleCloseModal} color="primary" variant="outlined">
                {isDirty ? 'Cancel' : 'Close'}
              </Button>
              <Button
                aria-label="assign-domain-button"
                disabled={isLoading || isAssigning || !isDirty || !isValid}
                color="primary"
                variant="contained"
                type="submit"
                size="large"
                form={FORM_ID}
                sx={{ ml: 1 }}
              >
                {isAssigning ? 'Assigning' : 'Assign'}
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogActions>
    </Dialog>
  );
}

const mapDispatchToProps: DispatchFromProps = {
  addDomainToOrganization
};

export default connect(
  null, mapDispatchToProps
)(CreateDomainModal);
/* eslint-disable max-len */
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  List,
  ListItem,
  ListItemProps,
  ListItemIcon,
  ListItemText,
  SvgIcon
} from '@mui/material';
import ApplicationIcon from '@mui/icons-material/AccountTreeRounded';
import ClientIcon from '@mui/icons-material/BusinessRounded';
import GroupIcon from '@mui/icons-material/PeopleRounded';
import OverviewIcon from '@mui/icons-material/DeviceHub';
import RoleIcon from '@mui/icons-material/AssignmentRounded';
import UserIcon from '@mui/icons-material/PersonRounded';

import { UMAReduxState } from '../../store/rootReducer';
import useStyles from './styles';
import { useEnforcer } from '../../hooks/useCasbin';
import policyConstants from '../../constants/policyConstants';
import { PolicyRequest } from '../../store/My/MyModels';

enum Context {
  organization = 'organization'
}

interface NavigationItem {
  Icon: typeof SvgIcon;
  featureFlag?: boolean;
  link: string;
  name: string;
  policyRequestCreator: (...args: any) => PolicyRequest,
  context?: Context
}

const navigationItems: NavigationItem[] = [
  {
    Icon: OverviewIcon,
    link: '/overview',
    name: 'Overview',
    policyRequestCreator: policyConstants.organizations.getById,
    context: Context.organization
  },
  {
    Icon: GroupIcon,
    link: '/groups',
    name: 'Groups',
    policyRequestCreator: policyConstants.groups.getByOrg,
    context: Context.organization
  },
  {
    Icon: UserIcon,
    link: '/users',
    name: 'Users',
    policyRequestCreator: policyConstants.users.getWithGroupIds,
    context: Context.organization
  },
  {
    Icon: RoleIcon,
    link: '/roles',
    name: 'Roles',
    policyRequestCreator: policyConstants.roles.getAll,
    context: Context.organization
  },
  {
    Icon: ClientIcon,
    link: '/clients',
    name: 'Clients',
    context: Context.organization,
    policyRequestCreator: policyConstants.clients.getAll
  },
  {
    Icon: ApplicationIcon,
    link: '/applications',
    name: 'Applications',
    policyRequestCreator: policyConstants.applications.getAll
  },
];

function ListItemLink(props: ListItemProps<'a', { button?: true }>) {
  return <ListItem button component="a" {...props} />;
}

export default function NavigationList() {
  const classes = useStyles();
  const history = useHistory();
  const currentPath = history.location.pathname;
  const organizationCode = useSelector((state: UMAReduxState) => state.context.organizationCode);

  const enforcer = useEnforcer();

  const handleNavClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    history.push(event.currentTarget.pathname);
  };

  return (
    <div className={classes.root}>
      <List component="nav" aria-label="navigation-link-list">
        {
          navigationItems.filter((item: NavigationItem) => {
            if (item.featureFlag === false) return false;
            let policyRequest;
            switch (item.context) {
              case Context.organization:
                policyRequest = item.policyRequestCreator(organizationCode);
                break;
              default:
                policyRequest = item.policyRequestCreator();
            }
            return enforcer?.enforceSync(policyRequest.obj, policyRequest.act);
          }).map((item, i) => (
            <ListItemLink
              className={classes.listItemLink}
              classes={{
                gutters: classes.gutter,
                selected: classes.selected
              }}
              key={item.name}
              href={item.link}
              onClick={(event: React.MouseEvent<HTMLAnchorElement>) => handleNavClick(event)}
              selected={currentPath === item.link}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <item.Icon />
              </ListItemIcon>
              <ListItemText className={classes.listItemText} primary={item.name} />
            </ListItemLink>
          ))
        }
      </List>
    </div>
  );
} 
import React from 'react';
import clsx from 'clsx';
import {
  Avatar,
  AvatarProps,
  Chip,
  Tooltip
} from '@mui/material';

import { RoleType } from '../../store/Roles/RoleModels';
import { toTitleCase } from '../../utility';
import useStyles from './styles';

interface UserAvatarProps extends AvatarProps {
	roleType: RoleType;
	applicationName?: string;
	smallBadge?: boolean;
}

export default function UserAvatar({
  roleType,
  applicationName,
  smallBadge = false,
  ...rest
}: UserAvatarProps) {
  const text = (roleType === 'application' && applicationName) ? `${roleType} - ${applicationName}` : roleType;
  const classes = useStyles();
  if (smallBadge) {
    return (
      <Tooltip title={`${toTitleCase(text)} Role`}>
        <Avatar alt={roleType} className={clsx(classes.smallBadge, classes[roleType])} {...rest}>{toTitleCase(text.substring(0, 1))}</Avatar>
      </Tooltip>
    );
  } else {
    return (
      <Chip
        size="small"
        avatar={
          <Avatar alt={roleType} {...rest}>{toTitleCase(roleType.substring(0, 1))}</Avatar>
        }
        classes={{
          avatar: classes[roleType]
        }}
        label={toTitleCase(text)}
      />
    );
  }
};
import { AnyAction } from 'redux';
import { merge } from 'lodash';

import { MyState } from './MyModels';
import { User } from '../Users/UserModels';
import {
  SET_MY_USER_PROFILE_REQUESTED,
  SET_MY_USER_PROFILE_REQUEST_SUCCESSFUL,
  SET_MY_USER_PROFILE_REQUEST_FAILED,
  GET_MY_USER_PROFILE_REQUEST_SUCCESSFUL
} from './MyActions';
import {
  UPDATE_USER_REQUESTED,
  UPDATE_USER_REQUEST_SUCCESSFUL,
  UPDATE_USER_REQUEST_FAILED
} from '../Users/UserActions';

export const initialState: MyState = {
  isLoading: false,
  permissions: [],
  profile: {
    isLoading: true
  } as User,
  policies: [],
  receivedPermissions: false
};

function my(state: MyState = initialState, action: AnyAction): MyState {
  let profile: Partial<User> = {};
  switch (action.type) {
    case SET_MY_USER_PROFILE_REQUESTED:
      return {
        ...state,
        profile: {
          isLoading: true
        } as User,
        receivedPermissions: false
      };

    case SET_MY_USER_PROFILE_REQUEST_SUCCESSFUL:
      return {
        ...state,
        permissions: action.user.roles,
        profile: {
          ...action.user,
          isLoading: false
        },
        policies: action.user.policies,
        receivedPermissions: true
      };

    case SET_MY_USER_PROFILE_REQUEST_FAILED:
      return {
        ...state,
        profile: {
          isLoading: false
        } as User,
        receivedPermissions: false
      };

    case GET_MY_USER_PROFILE_REQUEST_SUCCESSFUL:
      action.byId[action.id].isLoading = false;
      profile = merge({}, state.profile, action.byId[action.id]);
      return {
        ...state,
        profile: profile as User
      };

    case UPDATE_USER_REQUESTED:
      if (action.id === state.profile.id) {
        return {
          ...state,
          profile: {
            ...state.profile,
            isLoading: true
          }
        };
      }
      return {
        ...state,
      };

    case UPDATE_USER_REQUEST_SUCCESSFUL:
      if (action.id === state.profile.id) {
        action.byId[action.id].isLoading = false;
        const profile = merge({}, state.profile, action.byId[action.id]);
        return {
          ...state,
          profile
        };
      }
      return {
        ...state
      };

    case UPDATE_USER_REQUEST_FAILED:
      if (action.id === state.profile.id) {
        return {
          ...state,
          profile: {
            ...state.profile,
            isLoading: false
          }
        };
      }
      return {
        ...state,
      };

    default:
      return state;
  }
}

export default my;
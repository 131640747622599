/* eslint-disable sort-keys */
import * as Yup from 'yup';

import { CollectionState, Id, ReceiveCollectionAction } from '../../types/types';

// We define our schema based on the same keys in our form:
export const policyRulesFormSchema = Yup.object().shape({
  applicationCode: Yup.string().required('Application is required.').nullable(true),
  clientCode: Yup.string().required('Client is required.').nullable(true),
  tag: Yup.string().required('Tag is required.'),
  permission: Yup.string().required('Permission is required.').nullable(true)
}).required();

export type PolicyRuleFormValues = {
  applicationCode: string;
  clientCode: string;
  tag: string;
  permission: string;
  effect: 'allow' | 'deny';
}

export type PolicyRule = {
  id?: Id;
  applicationCode: string;
  roleId: Id;
  organizationCode: Id;
  clientCode: string;
  tag: string;
  permission: string;
  effect: 'allow' | 'deny'
}

export interface PolicyRulesState extends CollectionState<PolicyRule> {
  isCreating: boolean;
  isDeleting: boolean;
  isLoading: boolean;
  isUpdating: boolean;
}

interface RequestPolicyRulesAction {
  type: string;
  policyRules?: PolicyRule[]
}

interface RequestPolicyRuleByApplicationCodeAction {
  type: string;
  applicationCode: string;
}

interface UpdatePolicyRuleAction {
  id: Id;
  type: string;
  policyRule: Partial<PolicyRule>;
}

interface DeletePolicyRulesAction {
  type: string;
  policyRuleIds: Id[];
}

export type PolicyRuleAction =
  | RequestPolicyRulesAction
  | ReceiveCollectionAction<PolicyRule>
  | RequestPolicyRuleByApplicationCodeAction
  | UpdatePolicyRuleAction
  | DeletePolicyRulesAction;

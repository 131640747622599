import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

const styles = makeStyles((theme: Theme) => ({
  activeState: {
    backgroundColor: '#00000010'
  },
  avatarContainer: {
    padding: theme.spacing(3.5, 3, 3.5, 3.5),
  },
  baseBreadcrumb: {
    textDecoration: 'underline'
  },
  breadcrumbs: {
    color: theme.palette.grey[600],
    fontSize: '12px'
  },
  details: {
    padding: theme.spacing(3.75, 0, 8)
  },
  detailsContainer: {
    minWidth: 750
  },
  editButton: {
    marginRight: theme.spacing(0.5)
  },
  headerContainer: {
    minHeight: theme.spacing(4.75)
  },
  label: {
    color: theme.palette.grey[600],
    fontSize: 12
  },
  menuRoot: {
    marginTop: theme.spacing(0.5)
  },
  profileAvatar: {
    height: 64,
    width: 64
  },
  usernameEmail: {
    minWidth: 200,
    paddingRight: theme.spacing(1)
  },
  usernameEmailContainer: {
    paddingTop: theme.spacing(2.5)
  }
}));

export default styles;
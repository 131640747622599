import { AnyAction } from 'redux';
import {
  CREATE_FEDERATED_GROUP_MAPPING_FAILURE,
  CREATE_FEDERATED_GROUP_MAPPING_REQUESTED,
  CREATE_FEDERATED_GROUP_MAPPING_SUCCESS,
  DELETE_FEDERATED_GROUP_MAPPING_FAILURE,
  DELETE_FEDERATED_GROUP_MAPPING_REQUESTED,
  DELETE_FEDERATED_GROUP_MAPPING_SUCCESS,
  GET_FEDERATED_GROUP_MAPPINGS_BY_GROUP_FAILURE,
  GET_FEDERATED_GROUP_MAPPINGS_BY_GROUP_REQUESTED,
  GET_FEDERATED_GROUP_MAPPINGS_BY_GROUP_SUCCESS,
  UPDATE_FEDERATED_GROUP_MAPPING_FAILURE,
  UPDATE_FEDERATED_GROUP_MAPPING_REQUESTED,
  UPDATE_FEDERATED_GROUP_MAPPING_SUCCESS
} from './FederatedGroupMappingActions';
import { FederatedGroupMappingState } from './FederatedGroupMappingModels';

export const initialState: FederatedGroupMappingState = {
  mappings: [],
  isCreating: false,
  isDeleting: false,
  isUpdating: false,
  groupsLoading: []
};

function federatedGroupMappings(state: FederatedGroupMappingState = initialState, action: AnyAction): FederatedGroupMappingState {
  switch (action.type) {
    case GET_FEDERATED_GROUP_MAPPINGS_BY_GROUP_REQUESTED:
      return { ...state, groupsLoading: [...state.groupsLoading, action.groupId] };
    case GET_FEDERATED_GROUP_MAPPINGS_BY_GROUP_FAILURE:
      return { ...state, groupsLoading: [...state.groupsLoading.filter((x) => x !== action.groupId)] };
    case GET_FEDERATED_GROUP_MAPPINGS_BY_GROUP_SUCCESS:
      return {
        ...state,
        groupsLoading: [...state.groupsLoading.filter((x) => x !== action.groupId)],
        mappings: [
          ...state.mappings.filter((x) => x.groupId !== action.groupId),
          ...action.mappings
        ]
      };
    case CREATE_FEDERATED_GROUP_MAPPING_REQUESTED:
      return { ...state, isCreating: true };
    case CREATE_FEDERATED_GROUP_MAPPING_FAILURE:
      return { ...state, isCreating: false };
    case CREATE_FEDERATED_GROUP_MAPPING_SUCCESS:
      return {
        ...state,
        isCreating: false,
        mappings: [...state.mappings, action.mapping]
      };
    case UPDATE_FEDERATED_GROUP_MAPPING_REQUESTED:
      return { ...state, isUpdating: true };
    case UPDATE_FEDERATED_GROUP_MAPPING_FAILURE:
      return { ...state, isUpdating: false };
    case UPDATE_FEDERATED_GROUP_MAPPING_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        mappings: [
          ...state.mappings.filter((x) => x.id !== action.updatedMapping.id),
          action.updatedMapping
        ]
      };
    case DELETE_FEDERATED_GROUP_MAPPING_REQUESTED:
      return { ...state, isDeleting: true };
    case DELETE_FEDERATED_GROUP_MAPPING_FAILURE:
      return { ...state, isDeleting: false };
    case DELETE_FEDERATED_GROUP_MAPPING_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        mappings: [
          ...state.mappings.filter((x) => x.id !== action.id)
        ]
      };
    default:
      return state;
  }
};

export default federatedGroupMappings; 
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

const styles = makeStyles((theme: Theme) => ({
  avatarContainer: {
    padding: theme.spacing(3.5, 3, 3.5, 3.5),
  },
  baseBreadcrumb: {
    textDecoration: 'underline'
  },
  breadcrumbs: {
    color: theme.palette.grey[600],
    fontSize: '12px'
  },
  buttonContainer: {
    margin: theme.spacing(0, 5.5, 5, 0)
  },
  contact: {
    color: theme.palette.grey[900],
    fontSize: 13,
    fontWeight: 600,
    letterSpacing: .5,
    marginBottom: theme.spacing(2)
  },
  details: {
    padding: theme.spacing(3.75, 0, 8)
  },
  detailsContainer: {
    minWidth: 750
  },
  editAvatarContainer: {
    padding: theme.spacing(3.5, 3, 3.5, 6),
  },
  editableFieldsContainer: {
    padding: theme.spacing(0, 6, 8)
  },
  profileAvatar: {
    height: 64,
    width: 64
  }
}));

export default styles;
import React from 'react';
import { useOktaAuth } from '@okta/okta-react';
import {
  Box,
  Button,
  Grid,
  Typography
} from '@mui/material';
import ErrorIcon from '@mui/icons-material/ErrorOutline';
import BackIcon from '@mui/icons-material/KeyboardBackspace';

import useStyles from './styles';

const TokenHookFailurePage = () => {
  const classes = useStyles();
  const { oktaAuth } = useOktaAuth();

  return (
    <Box marginY={10} >
      <Grid container justifyContent="center" className={classes.container}>
        <Grid container item xs={12} justifyContent="center">
          <Box height={140} width={140} className={classes.circle}>
            <ErrorIcon className={classes.icon} />
          </Box>
        </Grid>
        <Grid className={classes.detailsContainer}>
          <Grid container item xs={12} justifyContent="center">
            <Typography className={classes.header}>
							Access Token Error
            </Typography>
          </Grid>
          <Grid container item xs={12} justifyContent="center">
            <Typography variant="body2" className={classes.subheader}>
							Sorry, there was an issue indentifying you in our system
							This is likely a result of an error with our token hook.
							Please contact support for further assistance.
            </Typography>
          </Grid>
          <Grid container item xs={12} justifyContent="flex-end" className={classes.buttonContainer}>
            <Button
              color="primary"
              size="large"
              onClick={() => oktaAuth.signInWithRedirect()}
              startIcon={<BackIcon />}
            >
							Try Signing In Again
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TokenHookFailurePage;
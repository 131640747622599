import * as Yup from 'yup';

import { ById, CollectionState, Id, ReceiveCollectionAction } from '../../types/types';
import { Role } from '../Roles/RoleModels';
import { User } from '../Users/UserModels';

// We define our schema based on the same keys in our form:
export const groupFormSchema = Yup.object().shape({
  name: Yup.string().required('Group Name is required.'),
}).required();

export type GroupFormValues = {
	name: string;
}

export interface Group {
	name: string;
	id: Id;
	organizationId?: Id;
	roleIds?: Id[];
	userIds?: Id[];
	isLoading?: boolean;
	isUpdating?: boolean;

  // Used during the api call.
  roles?: Role[];
  users?: User[];
}

export interface GroupsState extends CollectionState<Group> {
	isAssigning: boolean;
	isCreating: boolean;
	isDeleting: boolean;
	isLoading: boolean;
}

interface GetGroupsDetailsAction extends ReceiveCollectionAction<Group> { }

interface GetGroupDetailsAction {
	type: string;
	id: Id;
	byId: ById<Group>;
}

interface GetGroupsAction {
	type: string;
}

interface DeleteGroupsAction {
	type: string;
	groupIds: Id[];
}

interface SaveGroupAction {
	type: string;
	group: Group;
}

interface UpdateGroupAction {
	type: string;
	groupId: Id,
	name: string
}

interface AddUsersToGroupsAction {
	type: string;
	groupIds: Id[];
	userIds: Id[];
	organizationCode: string;
}

interface AddRolesToGroupsAction {
	type: string;
	groupIds: Id[];
	roleIds: Id[];
	organizationCode: string;
}

interface RemoveRolesFromGroupAction {
	type: string;
	groupId: Id;
	roleIds: Id[];
	organizationCode: string;
}

interface RequestGroupByIdAction {
	type: string;
	id: Id;
}

export type GroupAction =
	| GetGroupDetailsAction
	| GetGroupsDetailsAction
	| RequestGroupByIdAction
	| GetGroupsAction
	| DeleteGroupsAction
	| AddUsersToGroupsAction
	| AddRolesToGroupsAction
	| RemoveRolesFromGroupAction
	| UpdateGroupAction
	| SaveGroupAction;
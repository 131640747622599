import { OktaAuthOptions } from '@okta/okta-auth-js';

import environment from './environment';

const oktaAuthConfig: OktaAuthOptions = {
  clientId: environment.oktaClientId,
  issuer: environment.oktaIssuer,
  pkce: true,
  redirectUri: window.location.origin + '/login/callback',
  scopes: ['openid', 'profile', 'email', 'uma:read', 'uma:write'],
  transactionManager: {
    saveNonceCookie: false,
    saveParamsCookie: false,
    saveStateCookie: false
  }
};

export { oktaAuthConfig };
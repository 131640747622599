import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

const styles = makeStyles((theme: Theme) => ({
  'gutter': {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2.75)
  },
  'listItemIcon': {
    color: '#555555',
    minWidth: theme.spacing(6),
  },
  'listItemLink': {
    '&:active': {
      backgroundColor: `${theme.palette.primary.light}`,
      borderLeft: `4px solid ${theme.palette.primary.light}`,
      color: theme.palette.grey[800],
      fontWeight: 600
    },
    '&:hover': {
      '& svg': {
        color: theme.palette.primary.light
      },
      backgroundColor: `${theme.palette.primary.light}24`,
      borderLeft: `4px solid ${theme.palette.primary.light}`,
      color: theme.palette.grey[800],
      fontWeight: 600
    },
    borderLeft: '4px solid transparent',
    paddingBottom: theme.spacing(1.5),
    paddingTop: theme.spacing(1.5),

  },
  'listItemText': {
    color: theme.palette.grey[800]
  },
  'root': {
    backgroundColor: theme.palette.background.paper,
    maxWidth: theme.spacing(45),
    paddingTop: theme.spacing(9),
    width: '100%',
  },
  'selected': {
    '& span': {
      fontWeight: 500
    },
    '& svg': {
      color: theme.palette.primary.light
    },
    backgroundColor: `${theme.palette.primary.light}24 !important`,
    borderLeft: `4px solid ${theme.palette.primary.light}`
  }
}));

export default styles;
import { AnyAction } from 'redux';
import { CONTEXT_RESET } from '../Context/ContextActions';
import { IMPORT_ROLES_REQUESTED, IMPORT_ROLES_REQUEST_FAILED, IMPORT_ROLES_REQUEST_SUCCESSFUL, RESET_ROLE_IMPORT } from './RoleImportActions';
import { RoleImportState } from './RoleImportModels';


export const initialState: RoleImportState = {
  isImporting: false,
  importResult: undefined,
};

function roleImports(state: RoleImportState = initialState, action: AnyAction): RoleImportState {
  switch (action.type) {
    case IMPORT_ROLES_REQUESTED:
      return {
        ...state,
        isImporting: true
      };

    case IMPORT_ROLES_REQUEST_SUCCESSFUL:
      return {
        ...state,
        isImporting: false,
        importResult: action.importResult
      };

    case IMPORT_ROLES_REQUEST_FAILED:
      return {
        ...state,
        isImporting: false
      };

    case CONTEXT_RESET:
    case RESET_ROLE_IMPORT:
      return {
        ...initialState
      };

    default:
      return state;
  }
}

export default roleImports;
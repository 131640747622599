import { applyMiddleware, compose, createStore, Store } from 'redux';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
import { History } from 'history';

import rootReducer, { UMAReduxState } from './rootReducer';

function rootStore(history: History, initialState: Partial<UMAReduxState> = {}): Store<UMAReduxState> {
  const middleware = [
    thunk,
    routerMiddleware(history)
  ];

  const enhancers = [];
  const windowIfDefined = typeof window === 'undefined' ? null : window as any;
  if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
    enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
  }

  return createStore(
    rootReducer(history),
		initialState as UMAReduxState,
		compose(applyMiddleware(...middleware), ...enhancers)
  );
}

export default rootStore;